// @ts-ignore
import ReactJsonViewCompare from 'react-json-view-compare';
import * as React from 'react';
import { Modal, Button } from 'antd';
import { HistoryField } from '../../services/types';
import { formatFieldName, parseJson } from '../../misc/helpers';

interface Props {
    historyField: HistoryField | null;
    onClose: () => void;
}

const HistorySerializedFieldModal: React.FC<Props> = ({ historyField, onClose }) => {
    return (
        <Modal
            className="alpha-portal-dialog"
            bodyStyle={{ maxHeight: 'calc(100vh - 310px)' }}
            width={800}
            visible={!!historyField}
            title={`${formatFieldName(historyField?.name)} changes`}
            onCancel={onClose}
            footer={[
                <Button key="back" type="primary" onClick={onClose}>
                    Close
                </Button>
            ]}
        >
            <ReactJsonViewCompare
                oldData={parseJson(historyField?.prevValue)}
                newData={parseJson(historyField?.value)}
            />
        </Modal>
    );
};

export default HistorySerializedFieldModal;
