import { Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../../../common/services/Utils';
import { TestProjectDashboardStore } from '../../stores';

type Props = {
    store: TestProjectDashboardStore;
};

const TestProjectDetails: React.FC<Props> = ({ store }) => {
    if (!store.currentTestProjectId) {
        return null;
    }

    const loadingDetailsPlaceholder = () => {
        return (
            <Skeleton active paragraph={{ rows: 4, style: { marginTop: 0 } }} title={{ style: { display: 'none' } }} />
        );
    };

    const projectDetailsItem = (label: string, value: string) => {
        return (
            <div className="project-details-item">
                <div className="project-details-item-label">{label}</div>
                <div className="project-details-item-value">{value}</div>
            </div>
        );
    };

    const projectDetailsContent = () => {
        if (!store.testProject) {
            return null;
        }

        return (
            <>
                {projectDetailsItem('Created time', Utils.formatDateStringShort(store.testProject.createdTime))}
                {projectDetailsItem('Created by', store.getUserNameById(store.testProject.createdBy))}
                {store.testProject.updatedTime &&
                    projectDetailsItem(
                        'Last modified time',
                        Utils.formatDateStringShort(store.testProject.updatedTime)
                    )}
                {store.testProject.updatedBy &&
                    projectDetailsItem('Last modified by', store.getUserNameById(store.testProject.updatedBy))}
            </>
        );
    };

    return (
        <div className="test-project-dashboard-item">
            <div className="item-title">Project details</div>
            <div className="item-content">
                {store.isLoadingTestProject || store.isLoadingUsers
                    ? loadingDetailsPlaceholder()
                    : projectDetailsContent()}
            </div>
        </div>
    );
};

export default observer(TestProjectDetails);
