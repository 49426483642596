import { Alert, Button, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { DocumentSelectionStep } from '.';
import { TestProjectDashboardStore, TestProjectWizardStore } from '../stores';

type Props = {
    store: TestProjectWizardStore;
    dashboardStore: TestProjectDashboardStore;
};

const TestProjectPackagesEditDialog: React.FC<Props> = ({ store, dashboardStore }) => {
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const handleCancel = () => {
        store.setPackagesEditDialogVisible(false);
        store.resetWizard();
        setErrorMessage(null);
    };

    const handleSave = () => {
        if (store.selectedPackages.length === 0) {
            setErrorMessage('Please select at least one document!');
        } else {
            setErrorMessage(null);
            store
                .updateTestProjectPackages()
                .then(successful => {
                    if (successful) {
                        handleCancel();
                        dashboardStore.loadTestProject();
                    }
                })
                .catch(() => {
                    // Do nothing
                });
        }
    };

    return (
        <Modal
            visible={store.packagesEditDialogVisible}
            title={store.currentTestProject ? `Edit documents for ${store.currentTestProject.name}` : 'Edit documents'}
            onCancel={handleCancel}
            maskClosable={false}
            centered
            destroyOnClose
            width={850}
            footer={[
                <Button key="save-packages" type="primary" onClick={handleSave} size="large">
                    Save
                </Button>
            ]}
        >
            {errorMessage && <Alert message={errorMessage} style={{ marginBottom: 12 }} type="error" />}
            <DocumentSelectionStep store={store} />
        </Modal>
    );
};

export default observer(TestProjectPackagesEditDialog);
