import { ColumnProps } from 'antd/lib/table';
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { ReferenceDataVisualStore } from '../stores';
import { STORE_REFERENCE_DATA } from '../constant';
import { Table, Modal, Row, Col, Button } from 'antd';
import type { ValueMapping } from '../services/ReferenceDataService';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

type Props = {
    [STORE_REFERENCE_DATA]: ReferenceDataVisualStore;
};

export const ImportedDataDialog: React.FC<Props> = ({ ReferenceDataUI: store }) => {
    const columns: ColumnProps<ValueMapping>[] = [
        ...store.columns.map((k: string) => {
            const width = 1000 / store.columns.length;
            return { title: k, key: k, dataIndex: k, width: width };
        })
    ];

    const onSelectChange = (selectedRows: string[]) => {
        store!.setSelectedRowKeys(selectedRows);
    };

    const handleCancel = () => {
        store.setImportedResultDialogVisible(false);
        store.removeImportResults();
    };

    const layout = (
        <Modal
            title="Imported results"
            closable={false}
            className="alpha-portal-dialog table scrollable full-screen"
            visible={store.isImportedResultDialogVisible}
            onCancel={() => store.setImportedResultDialogVisible(false)}
            bodyStyle={{ height: 600 }}
            width="70%"
            centered
            getContainer={false}
            footer={
                <Row align="middle">
                    <Col span={24}>
                        <Button
                            data-id="imported-data-dialog-cancel"
                            size="large"
                            className="light"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-id="imported-data-dialog-import"
                            type="primary"
                            size="large"
                            disabled={!store!.selectedRowKeys?.length || !store.importResult.length}
                            onClick={store.saveImportedData}
                        >
                            Import selected
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Table
                data-id="imported-data-dialog-table"
                className="alpha-portal-table ref-data-import-table"
                dataSource={store.importResult}
                rowSelection={{ selectedRowKeys: store!.selectedRowKeys, onChange: onSelectChange }}
                columns={columns}
                rowKey="rowId"
                pagination={false}
                scroll={{ y: 540 }}
            />
        </Modal>
    );

    if (store!.isLoading) {
        return <LoadingIndicator />;
    } else {
        return layout;
    }
};
export default observer(ImportedDataDialog);
