import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { FlexContentContainer } from '../../common/components';
import { RulesStores } from '../stores';
import { STORE_RULE_EDIT } from '../constants';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { RuleEditPage } from '../components';

type Props = RulesStores;

@inject(STORE_RULE_EDIT)
@observer
export default class RulesEditPage extends React.Component<Props, object> {
    componentDidMount() {
        this.props.RuleEditUI!.loadData();

        const projectLayoutWrapper = document.getElementById('project-layout-wrapper');
        if (projectLayoutWrapper) {
            projectLayoutWrapper.classList.add('no-padding');
        }
    }

    componentWillUnmount() {
        const projectLayoutWrapper = document.getElementById('project-layout-wrapper');
        if (projectLayoutWrapper) {
            projectLayoutWrapper.classList.remove('no-padding');
        }
    }

    render() {
        const store = this.props.RuleEditUI!;
        const lines = !store.isLoading ? <RuleEditPage RuleEditUI={this.props.RuleEditUI!} /> : <LoadingIndicator />;

        return <FlexContentContainer style={{ overflow: 'hidden' }} content={lines} />;
    }
}
