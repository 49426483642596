/* eslint-disable max-len */
import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { Button, Col, Dropdown, Menu, Modal, Row } from 'antd';
import { AdministrationStores } from '../stores';
import { UserForm } from './UserForm';
import WrappedResetPasswordForm from '../../user_profile/components/ResetPasswordDialog';
import Form, { useForm } from 'antd/lib/form/Form';
import { reaction } from 'mobx';
import { UserModel } from '../types/UserModel';
import { LoginTypes } from '../stores/UsersManagerStore';

type Props = AdministrationStores;

export const EditUserDialog: React.FC<Props> = observer(({ UsersManager: store }) => {
    const additionalRoles = ['auth_level_1', 'auth_level_2', 'auth_level_3'];
    const [form] = useForm();
    const [deleteConfirmVisible, setDeleteConfirmVisible] = React.useState(false);

    const mapUserRoles = (user: UserModel) => {
        const defaultRoles = store!.sortedRoles.map(r => r.name);
        const defaultUserRoles = user.roles.filter(r => defaultRoles.includes(r.name)).map(r => r.name);
        const customRoles = user.roles.filter(r => !defaultRoles.includes(r.name)).map(r => r.id);
        const roles = [...defaultUserRoles, ...customRoles];
        return roles.filter(r => !additionalRoles.includes(r));
    };

    React.useEffect(() => {
        store!.init();

        const disposer = reaction(
            () => store!.selectedUser,
            (user: UserModel) => {
                if (!user) {
                    form.resetFields(['email', 'firstName', 'lastName', 'roles']);
                    return;
                }

                form.setFieldsValue({
                    userName: user!.userName,
                    email: user!.email,
                    firstName: user!.firstName,
                    lastName: user!.lastName,
                    roles: mapUserRoles(user!),
                    additionalRoles: additionalRoles.filter(r => user!.roles.map(role => role.name).includes(r)),
                    loginType: (user!.isDomainUser && LoginTypes.domainUser) || LoginTypes.internalUser,
                    group: user!.groups && user!.groups.length ? user!.groups[0] : ''
                });
            }
        );
        return () => {
            disposer();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, store]);

    const handleSaveClick = () => {
        form.validateFields()
            .then(data => {
                if (data.loginType === LoginTypes.domainUser) {
                    data.userName = store!.selectedUser!.id;
                    data.isDomainUser = true;
                } else {
                    data.email = data.email.toLowerCase();
                }

                data.roles = [...data.roles, ...data.additionalRoles];
                store!.createUpdateUser(data as UserModel, true);
                store!.setIsEditUserDialogVisible(false);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleCancel = () => {
        form.resetFields(['email', 'firstName', 'lastName', 'roles']);
        store!.setIsEditUserDialogVisible(false);
    };

    const deleteConfirm = () => {
        setDeleteConfirmVisible(true);
    };
    const isDomainUser = store!.selectedUser?.isDomainUser;

    const menu = () => {
        return (
            <Menu>
                <Menu.Item
                    data-id="edit-user-dialog-reset-pwd"
                    data-testid="edit-user-dialog-reset-pwd"
                    onClick={() => store!.setResetPasswordDialogVisible(true)}
                    style={isDomainUser ? { display: 'none' } : {}}
                >
                    Reset password
                </Menu.Item>
                <Menu.Item
                    data-id="edit-user-dialog-remove"
                    data-testid="edit-user-dialog-remove"
                    style={{ color: 'red' }}
                    onClick={deleteConfirm}
                >
                    Remove user
                </Menu.Item>
            </Menu>
        );
    };

    const deleteUser = () => {
        store!.deleteUser();
        setDeleteConfirmVisible(false);
    };

    const { selectedUser } = store!;
    return (
        <Modal
            visible={store!.isEditUserDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            title="Edit user"
            destroyOnClose
            width={660}
            centered
            className="alpha-portal-dialog two-columns"
            footer={[
                <Row key="edit-user-footer-actions">
                    <Col span={8} style={{ textAlign: 'left' }}>
                        <Dropdown overlay={menu} trigger={['click']} key="more" overlayClassName="alpha-dropdown-menu">
                            <Button
                                className="light"
                                data-id="edit-user-dialog-more"
                                data-testid="edit-user-dialog-more"
                                size="large"
                            >
                                More...
                            </Button>
                        </Dropdown>
                    </Col>
                    <Col span={16} style={{ textAlign: 'right' }}>
                        <Button
                            className="light"
                            data-id="edit-user-dialog-cancel"
                            size="large"
                            key="back"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-id="edit-user-dialog-submit"
                            data-testid="edit-user-dialog-submit"
                            size="large"
                            key="submit"
                            type="primary"
                            onClick={handleSaveClick}
                        >
                            Save changes
                        </Button>
                    </Col>
                </Row>
            ]}
        >
            <Observer>
                {() => (
                    <>
                        <Modal
                            visible={deleteConfirmVisible}
                            closable={false}
                            maskClosable={false}
                            title="Please confirm"
                            onOk={deleteUser}
                            okButtonProps={{
                                size: 'large'
                            }}
                            okText="Remove user"
                            onCancel={() => setDeleteConfirmVisible(false)}
                            cancelButtonProps={{
                                size: 'large',
                                className: 'light'
                            }}
                            cancelText="Cancel"
                            centered
                            className="alpha-portal-dialog two-columns confirm"
                        >
                            Are you sure that you want to delete user{' '}
                            {store!.selectedUser
                                ? `${store!.selectedUser.firstName} ${store!.selectedUser.lastName} (${store!.selectedUser.userName})`
                                : ''}
                            ? This action cannot be undone.
                        </Modal>
                        <WrappedResetPasswordForm store={store!} />
                        <Form
                            data-id="edit-user-content-form"
                            form={form}
                            layout="vertical"
                            initialValues={
                                (selectedUser && {
                                    userName: selectedUser!.userName,
                                    email: selectedUser!.email!,
                                    firstName: selectedUser!.firstName,
                                    lastName: selectedUser!.lastName,
                                    roles:
                                        selectedUser!.roles && selectedUser!.roles.length
                                            ? selectedUser!.roles.map(x => x.name)
                                            : '',
                                    loginType: LoginTypes.internalUser,
                                    group:
                                        selectedUser.groups && selectedUser.groups.length ? selectedUser.groups[0] : ''
                                }) ||
                                {}
                            }
                        >
                            <UserForm UsersManager={store} form={form} />
                        </Form>
                    </>
                )}
            </Observer>
        </Modal>
    );
});

export default EditUserDialog;
