import { action, computed, observable, runInAction } from 'mobx';
import { PackageLine } from '../../common/models';
import { ProjectsRootVisualStore } from '../../common/stores';
import { RulesStore } from '../../rules/stores';
import PreviewVisualStoreBase from '../../rules/stores/PreviewVisualStoreBase';
import { RuleResult, TagPreviewResultWithPackageId } from '../../rules/types';
import { FieldBindingsService } from '../services';

export default class FieldPreviewVisualStore extends PreviewVisualStoreBase {
    @observable
    selectedField: string | undefined;

    @observable
    selectedPackageIds: string[] = [];

    @observable
    isExecuting: boolean = false;

    groupId: string;

    isEditable: boolean = false;

    constructor(
        public projectStore: ProjectsRootVisualStore,
        private service: FieldBindingsService,
        public ruleStore: RulesStore
    ) {
        super(projectStore, ruleStore);
    }

    @computed
    get project() {
        return this.projectStore.currentProject;
    }

    @action.bound
    setSelectedFieldData(groupId: string, inputId: string, fieldName: string | undefined) {
        this.groupId = groupId;
        this.selectedField = inputId;
        this.selectedItemLabel = fieldName;
    }

    @action.bound
    async loadPreviewResults() {
        if (this.selectedField && this.selectedPackageIds.length) {
            runInAction(() => {
                this.isExecuting = true;
            });
            let cumulativeResult: TagPreviewResultWithPackageId[] = [];
            const promises = this.selectedPackageIds.map(id =>
                this.service.getFieldsBindingResults(this.groupId, this.selectedField!, id!)
            );
            const previewResults = await Promise.all(promises);
            previewResults.forEach(result => {
                cumulativeResult.push({
                    packageId: result.packageId,
                    previewResults: [
                        {
                            entries: [
                                {
                                    field: {} as PackageLine,
                                    tokens: [],
                                    tag: { tagId: '', values: result.values?.map(v => v.value) || [] },
                                    resultTokens: []
                                }
                            ],
                            rule: {} as RuleResult
                        }
                    ]
                });
            });

            if (cumulativeResult && cumulativeResult.length) {
                runInAction(() => {
                    this.previewResults = cumulativeResult;
                });
            }

            runInAction(() => {
                this.isExecuting = false;
            });
        }
    }

    @action.bound
    goToInitialList() {
        if (this.project) {
            this.projectStore.navigateToFieldBindingsPage(this.project);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setItem(m: any) {
        this.selectedItemId = m;
    }

    setUrl() {
        return;
    }

    initUsingProjectId(projectId: string) {
        return projectId;
    }
}
