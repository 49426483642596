import * as React from 'react';
import { Input, Select, Form, Row, Col, FormInstance } from 'antd';
import { inject } from 'mobx-react';
import RuleEditVisualStore from '../stores/RuleEditVisualStore';
import { RefDataRule } from '../models';
import { STORE_RULE_EDIT } from '../constants';
import { SelectWhenScroll } from '../../common/components/SelectWhenScroll';
import RuleEditGeneralSettings from './RuleEditGeneralSettings';
import RuleType from '../types/RuleType';
import { observer } from 'mobx-react-lite';

const Option = Select.Option;

type Props = {
    store: RuleEditVisualStore;
    selectType?: (type: RuleType) => void;
    form: FormInstance;
};
export const RuleEditRefDataForm: React.FC<Props> = ({ store, selectType, form }) => {
    const rule = store.editableRule as RefDataRule;
    React.useEffect(() => {
        form.setFieldsValue(getInitialValues());
        if (!rule.id) {
            form.setFieldsValue({ excludedBlockTypes: [] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, rule]);

    const getInitialValues = () => {
        return {
            name: rule.name,
            tag: rule.tag,
            connectionId: rule.connectionId,
            sqlQuery: rule.sqlQuery,
            priority: rule.priority,
            description: rule.description,
            overridePriority: rule.overridePriority
        };
    };

    return (
        <>
            {store.error.map((e, i) => (
                <h4 key={e + i} style={{ color: 'red' }}>
                    {e}
                </h4>
            ))}
            <Form form={form} layout="vertical" className="rule-edit-form" data-id="rule-edit-form">
                <RuleEditGeneralSettings store={store} selectType={selectType} form={form} />
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="SQL"
                            name="sqlQuery"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input sql query!'
                                }
                            ]}
                            data-id="rule-sql-input"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Connection"
                            name="connectionId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select connection!'
                                }
                            ]}
                            data-id="rule-connection-select"
                        >
                            <SelectWhenScroll placeholder="Select connection">
                                {store.connections.map(t => (
                                    <Option key={t.id} value={t.id}>
                                        {t.name}
                                    </Option>
                                ))}
                            </SelectWhenScroll>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default inject(STORE_RULE_EDIT)(observer(RuleEditRefDataForm));
