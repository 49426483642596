import type { SessionResult } from '../../common/types';
export type { SearchSessionsAutoCompleteSourceItem } from './SearchSessionsAutoCompleteSourceItem';

export class SessionsResponse {
    total: number = 0;
    lines: SessionResult[] = [];
}

export type SessionModelsResult = {
    getSessionModels: SessionResult[] | null;
};

export type SessionModelResult = {
    getSessionModelById: SessionResult[];
};

export type ApplicationName = {
    applicationName: string;
};

export type ApplicationSessionPayload = {
    id: string;
    sessionId: string;
    created: string;
    data: { [id: string]: string };
};

export type ApplicationNamesResult = {
    applicationNames: ApplicationName[];
};

export type ApplicationSessionPayloadsResult = {
    getSessionPayloads: ApplicationSessionPayload[];
};
