export type IotaAuditModelResult = {
    getIotaAudit: IotaAuditResult[] | null;
};

export type IotaAuditResult = {
    id: string;
    applicationName: string;
    packageName: string;
    date: string;
    sessionId: string;
    inputs: AuditInput[];
};

export class IotaAuditResponse {
    total: number = 0;
    lines: IotaAuditResult[] = [];
}

export type FieldComment = {
    value: string;
    userId: string;
    date: string;
};

export type AuditInput = {
    field: string;
    value: string;
    comments: FieldComment[];
    isAuto: boolean;
    auditInputsMeta: AuditInputsMeta[];
};

export type AuditInputsMeta = {
    source: string;
    page: number | null;
    user: string;
    date: Date;
};

export type SearchAuditRequest = {
    projectId: string;
    page: number;
    pageSize: number;
    searchTerm?: string | null;
    dates: string[];
};

export type IotaProjectPayloadsResult = {
    getIotaProjectPayloads: AuditInput[];
};
