import { AppPermissions, AppRoles } from '../../authorization/Permissions';
import { Package, Project } from '../models';
import * as React from 'react';
import ProjectDropdownMenu from '../../../components/ProjectDropdownMenu';
import { HasPermission } from '../../authorization/components/HasPermission';
import security from '../../common/services/SecurityService';
import { FormPartCoordinatesReference } from '../../form_types/types';
import { message } from 'antd';

export class Utils {
    private static symbols = /[()!.-1234567890:_=+@#$%^&*±[\]\n\t\\/]/gim;
    private static spaces = /\s{2,}/gim;

    static clearText(text: string | null) {
        if (!text) {
            return text;
        }

        return text.replace(this.symbols, '').replace(this.spaces, ' ');
    }

    static sortPackagesByDate(p1: Package, p2: Package) {
        return p1.sortingDate > p2.sortingDate ? -1 : p1.sortingDate < p2.sortingDate ? 1 : 0;
    }

    static formatDateStringShort(date: string | null | undefined, excludeSeconds: boolean = false) {
        if (date) {
            return Intl.DateTimeFormat('en-Gb', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: excludeSeconds ? undefined : '2-digit'
            }).format(new Date(date));
        } else {
            return '';
        }
    }

    static formatDateShort(date: Date) {
        if (date) {
            return Intl.DateTimeFormat('en-Gb', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }).format(date);
        } else {
            return '';
        }
    }

    static getAppRoleDisplayName(roleName: string) {
        switch (roleName) {
            case AppRoles.admin:
                return 'Admin';
            case AppRoles.client_app:
                return 'Client app';
            case AppRoles.editor:
                return 'Editor';
            case AppRoles.user:
                return 'User';
            case AppRoles.isolated_user:
                return 'Isolated user';
            default:
                return roleName;
        }
    }

    static safeLocaleCompare = (a: string | undefined | null, b: string | undefined | null) => {
        if (!a && b) {
            return -1;
        } else if (a && !b) {
            return 1;
        } else if (!a && !b) {
            return 0;
        } else {
            return a!.localeCompare(b!);
        }
    };

    static safeStringArrayCompare = (a: string[] | undefined | null, b: string[] | undefined | null) => {
        if (!a && b) {
            return -1;
        } else if (a && !b) {
            return 1;
        } else if (!a && !b) {
            return 0;
        } else {
            return a!.join(',').localeCompare(b!.join(','));
        }
    };

    static safeDateStringCompare = (a: string | undefined | null, b: string | undefined | null) => {
        if (!a && b) {
            return -1;
        } else if (a && !b) {
            return 1;
        } else if (!a && !b) {
            return 0;
        } else {
            const date1 = new Date(a!);
            const date2 = new Date(b!);
            return date1 > date2 ? 1 : date1 < date2 ? -1 : 0;
        }
    };

    static safeDateCompare = (a: Date | undefined | null, b: Date | undefined | null) => {
        if (!a && b) {
            return -1;
        } else if (a && !b) {
            return 1;
        } else if (!a && !b) {
            return 0;
        } else {
            return a! > b! ? 1 : a! < b! ? -1 : 0;
        }
    };

    static getSubtitle(project: Project | null, hideProjectEditOption?: boolean) {
        const subtitleText = project ? project.title : undefined;
        if (!subtitleText) {
            return undefined;
        }

        return (
            <span className="subtitle-wrapper">
                <span>{subtitleText}</span>
                <HasPermission
                    permissionClaim={AppPermissions.CanAddEditDeleteAssignProject}
                    yes={() => <ProjectDropdownMenu hideProjectEditOption={hideProjectEditOption} />}
                />
            </span>
        );
    }

    static humanizeString(str: string) {
        return str
            .replace(/^[\s_]+|[\s_]+$/g, '')
            .replace(/[_\s]+/g, ' ')
            .replace(/^[a-z]/, function (m) {
                return m.toUpperCase();
            })
            .replace(/([A-Z])/g, ' $1')
            .trim();
    }

    static downloadFile(url: string, mapForm: HTMLFormElement, formId: string) {
        mapForm.action = url;
        document.body.appendChild(mapForm);
        mapForm.submit();
        document.getElementById(formId)!.remove();
    }

    static getAuthToken() {
        return security.invoke(token => {
            return Promise.resolve(token);
        });
    }

    static async CreateTabForDownload(id: string, path: string) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'postForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';
        const connIdInput = document.createElement('input');
        connIdInput.type = 'text';
        connIdInput.name = 'id';
        connIdInput.value = id;
        mapForm.appendChild(connIdInput);

        await security.invoke(token => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url = process.env.REACT_APP_MANAGE_URL + `${path}${t}`;
            Utils.downloadFile(url, mapForm, 'postForm');
            return Promise.resolve();
        });
    }

    static convertRegionSelectBboxToCoordinates(
        bbox: number[],
        width: number,
        height: number,
        coordinatesReference?: FormPartCoordinatesReference
    ) {
        let widthSizeCoefficient = 1;
        let heightSizeCoefficient = 1;

        if (coordinatesReference) {
            widthSizeCoefficient = coordinatesReference.width / width;
            heightSizeCoefficient = coordinatesReference.height / height;
        }

        const x1 = Math.round((bbox[0] / 100) * width * widthSizeCoefficient);
        const y1 = Math.round((bbox[1] / 100) * height * heightSizeCoefficient);
        const x2 = Math.round(((bbox[0] + bbox[2]) / 100) * width * widthSizeCoefficient);
        const y2 = Math.round(((bbox[1] + bbox[3]) / 100) * height * heightSizeCoefficient);
        return [x1, y1, x2, y2];
    }

    static convertCoordinatesToRegionSelectBbox(
        bbox: number[],
        width: number,
        height: number,
        coordinatesReference?: FormPartCoordinatesReference
    ) {
        let widthSizeCoefficient = 1;
        let heightSizeCoefficient = 1;

        if (coordinatesReference) {
            widthSizeCoefficient = coordinatesReference.width / width;
            heightSizeCoefficient = coordinatesReference.height / height;
        }

        const x1 = ((bbox[0] / width) * 100) / widthSizeCoefficient;
        const y1 = ((bbox[1] / height) * 100) / heightSizeCoefficient;
        const x2 = (((bbox[2] - bbox[0]) / width) * 100) / widthSizeCoefficient;
        const y2 = (((bbox[3] - bbox[1]) / height) * 100) / heightSizeCoefficient;
        return [x1, y1, x2, y2];
    }

    static stringToColourRgb(str: string) {
        let color: string = '';
        if (str && str.length > 0) {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                // eslint-disable-next-line no-bitwise
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
                // eslint-disable-next-line no-bitwise
                hash = hash & hash;
            }
            var rgb = [0, 0, 0];
            for (var i = 0; i < 3; i++) {
                // eslint-disable-next-line no-bitwise
                var value = (hash >> (i * 8)) & 255;
                rgb[i] = value;
            }
            color = `${rgb[0]}, ${rgb[1]}, ${rgb[2]}`;
        }

        return color;
    }

    static copyValToClipboard(val: string) {
        navigator.clipboard.writeText(val);
        message.success('Value copied to clipboard');
    }

    static formatKilobytes(kilobytes: number) {
        const megabyte = 1024;
        const gigabyte = megabyte * 1024;

        if (kilobytes >= gigabyte) {
            return `${(kilobytes / gigabyte).toFixed(2)} GB`;
        }

        if (kilobytes >= megabyte) {
            return `${(kilobytes / megabyte).toFixed(0)} MB`;
        }

        return `${kilobytes.toFixed(kilobytes >= 1 ? 0 : 2)} KB`;
    }
}
