import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { Table, Button, Popconfirm, Avatar, Layout, Tooltip, Menu, Dropdown, Tag, Switch } from 'antd';
import WrappedAddApplicationDefinition from './AddApplicationDefinition';
import WrappedApplicationGroupingTree from './ApplicationGroupingTree';
import { IotaAppStores } from '../stores';
import { ApplicationDefinitionBase } from '../types/ApplicationDefinition';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import LayoutHeader from '../../../components/LayoutHeader';
import { useForm } from 'antd/lib/form/Form';
import ApplicationSettingsDialog from './ApplicationSettingsDialog';
import EditIotaApplicationDialog from './EditIotaApplicationDialog';
import { Utils } from '../../common/services/Utils';
import { RulesStores } from '../../rules/stores';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { InfoCircleOutlined } from '@ant-design/icons';

type Props = IotaAppStores & RulesStores;
const { Content, Sider } = Layout;

export const ApplicationDefinitionList: React.FC<Props> = ({
    projectApplicationDefinitionsUI: store,
    RulesImportUI: rulesImportStore
}) => {
    const treeStore = store!.groupTreeStore;
    const appDefStore = store!;
    const [form] = useForm();

    React.useEffect(() => {
        store!.loadIotaInputsMetadata();
    }, [store]);

    const iotaAppMenu = (appDef: ApplicationDefinitionBase) => {
        return (
            <Menu data-id-type="popup-menu-list-root" data-id-name={`${appDef.name}-table`}>
                <Menu.Item
                    key="edit"
                    data-id-type="popup-menu-list-item"
                    data-id-name="Edit Application"
                    data-id={`iota-application-list-edit-app-${appDef.id}`}
                    onClick={() => store!.handleEditAppClick(appDef.id)}
                >
                    Edit application
                </Menu.Item>
                <Menu.Item
                    key="export"
                    data-id-type="popup-menu-list-item"
                    data-id-name="Export Application"
                    data-id={`iota-application-list-export-app-${appDef.id}`}
                    onClick={() => store!.handleExportAppClick(appDef.id)}
                >
                    Export
                </Menu.Item>
                <Menu.Item key="delete" data-id-type="popup-menu-list-item" data-id-name="Delete" danger>
                    <Popconfirm
                        title="Are you sure that you want to delete this application definition?"
                        okText="Yes"
                        onConfirm={() => deleteAppDefinition(appDef.id)}
                        cancelText="No"
                        okButtonProps={{ id: `iota-application-list-delete-confirm-${appDef.id}` }}
                        cancelButtonProps={{ id: `iota-application-list-delete-cancel-${appDef.id}` }}
                    >
                        <div>Delete</div>
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        );
    };
    const columns = [
        {
            title: 'Name',
            key: 'name',
            sorter: (a: ApplicationDefinitionBase, b: ApplicationDefinitionBase) => a.name.localeCompare(b.name),
            render: (appDef: ApplicationDefinitionBase) => {
                return {
                    children: (
                        <span>
                            <Avatar shape="square" size="small" src={appDef.iconUrl} style={{ marginRight: '32px' }} />
                            <span>{appDef.name}</span>
                            <span className="tooltip-container" style={{ marginLeft: 10 }}>
                                <Tooltip
                                    title={store!.getWorkflowName(
                                        appDef.extension,
                                        appDef.applicationId,
                                        appDef.workflowId
                                    )}
                                >
                                    <InfoCircleOutlined style={{ color: '#9ba0aa' }} />
                                </Tooltip>
                            </span>
                        </span>
                    ),
                    props: {
                        'data-id-value': appDef.name,
                        'data-id-type': 'applications-list',
                        'data-id-cells': 'Name'
                    }
                };
            }
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            sorter: (a: ApplicationDefinitionBase, b: ApplicationDefinitionBase) => a.state.localeCompare(b.state),
            render: (state: string) => {
                return {
                    children: (
                        <Tag className="alpha-portal-tag" color={state === 'Draft' ? '#9BA0AA' : '#1B5E20'}>
                            {state}
                        </Tag>
                    ),
                    props: {
                        'data-id-value': state,
                        'data-id-type': 'applications-list',
                        'data-id-cells': 'State'
                    }
                };
            }
        },
        {
            width: '280px',
            key: 'actions',
            render: (appDef: ApplicationDefinitionBase) => (
                <HasPermission
                    entityId={store?.currentProject?.id}
                    permissionClaim={AppPermissions.CanEditIotaApplications}
                    yes={() => (
                        <div style={{ display: 'flex', gap: 8 }}>
                            <div className="row-actions-wrapper">
                                <Button
                                    size="small"
                                    type="link"
                                    data-id-cells="Edit inputs"
                                    data-id-name={appDef.name}
                                    onClick={() => store!.editInputs(appDef.id)}
                                >
                                    <Tooltip title="Edit inputs">
                                        <i className="alpha-icon lg input-icon" />
                                    </Tooltip>
                                </Button>
                                <Button
                                    size="small"
                                    type="link"
                                    data-id-cells="Edit settings"
                                    data-id-name={appDef.name}
                                    onClick={() => navigateToSettings(appDef)}
                                >
                                    <Tooltip title="Edit settings">
                                        <i className="alpha-icon lg gear-gray-icon" />
                                    </Tooltip>
                                </Button>
                                <Dropdown
                                    overlay={() => iotaAppMenu(appDef)}
                                    trigger={['click']}
                                    overlayClassName="alpha-dropdown-menu"
                                >
                                    <Button type="link" data-id-cells="More" data-id-name={appDef.name} size="small">
                                        <i className="alpha-icon lg more-icon" />
                                    </Button>
                                </Dropdown>
                            </div>
                            <Switch
                                data-id={`iota-application-list-switch-${appDef.id}`}
                                checked={appDef.state === 'Published'}
                                onChange={() => updateApplicationState(appDef)}
                                disabled={
                                    appDef.state === 'Published' ? false : !store!.validateSettingsAndInputs(appDef)
                                }
                            />
                        </div>
                    )}
                    no={() => (
                        <div className="row-actions-wrapper">
                            <Button
                                size="small"
                                type="link"
                                data-id={`iota-application-list-inputs-${appDef.id}`}
                                onClick={() => store!.editInputs(appDef.id)}
                            >
                                <Tooltip title="See inputs">
                                    <i className="alpha-icon lg input-icon" />
                                </Tooltip>
                            </Button>
                        </div>
                    )}
                />
            )
        }
    ];

    React.useEffect(() => {
        appDefStore.loadApplicationDefinitions();
    }, [appDefStore.currentProject, appDefStore]);

    const navigateToSettings = (appDef: ApplicationDefinitionBase) => {
        store!.goToSettingsPage(appDef);
    };

    const deleteAppDefinition = async (appDefId: string) => {
        await store!.deleteApplicationDefinition(appDefId);
    };

    const updateApplicationState = async (appDef: ApplicationDefinitionBase) => {
        const newState = appDef.state === 'Draft' ? 'Published' : 'Draft';
        await store!.updateApplicationState(appDef.id, newState);
    };

    const addApplicationDefGroup = () => {
        treeStore!.openGroupFormDialog(undefined, () => {
            form.setFieldsValue({ name: undefined });
        });
    };

    const layout = (
        <Layout className="screen-size" style={{ ...{ height: '100%', background: 'white' } }}>
            <LayoutHeader
                subtitle={Utils.getSubtitle(treeStore!.currentProject)}
                title="Applications"
                helpMessage={
                    // eslint-disable-next-line max-len
                    "To access actions for an application or group, click its name in the left panel to open a context menu. To create a new application, choose 'Add application definition'. Configure field bindings in the Inputs section, while Settings manage the connection to external databases."
                }
                buttons={[
                    <HasPermission
                        entityId={store?.currentProject?.id}
                        key="iota-add-group-wrapper"
                        permissionClaim={AppPermissions.CanEditIotaApplications}
                    >
                        <Button
                            data-id="iota-add-group-button"
                            type="primary"
                            size="large"
                            onClick={addApplicationDefGroup}
                            style={{ marginBottom: 10 }}
                        >
                            {' '}
                            Add Group
                        </Button>
                    </HasPermission>
                ]}
            />
            <Layout>
                <Sider
                    data-id="iota-tree-container"
                    width={360}
                    style={{ background: 'white', overflow: 'auto', paddingRight: 15 }}
                >
                    <WrappedApplicationGroupingTree
                        projectApplicationDefinitionsUI={store}
                        RulesImportUI={rulesImportStore}
                        form={form}
                    />
                </Sider>
                <Content data-id="iota-application-list-container" style={{ background: 'white' }}>
                    <WrappedAddApplicationDefinition projectApplicationDefinitionsUI={store} />
                    <ApplicationSettingsDialog store={store} />
                    <EditIotaApplicationDialog store={store} />
                    <Observer>
                        {() => (
                            <Table
                                className="alpha-portal-table"
                                dataSource={treeStore!.filteredApplicationDefinitions}
                                columns={columns}
                                rowKey={m => m.id!}
                                pagination={false}
                            />
                        )}
                    </Observer>
                </Content>
            </Layout>
        </Layout>
    );
    if (store!.isLoading) {
        return <LoadingIndicator />;
    } else {
        return layout;
    }
};

export default observer(ApplicationDefinitionList);
