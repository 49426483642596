import { observable, computed } from 'mobx';
import { RefDataRuleResult, RuleError } from '../types';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import Connection from './Connection';
import TagRuleBase from './TagRuleBase';
import PipelineStepType from '../types/PipelineStepType';
import { RuleStatus } from './RuleBase';

export default class RefDataRule extends TagRuleBase<RefDataRule, RefDataRuleResult> {
    @observable
    sqlQuery: string;

    @observable
    connection: Connection | null;

    @computed
    get connectionId() {
        return this.connection ? this.connection!.id : null;
    }

    constructor(
        id: string | null,
        projectId: string | null,
        name: string,
        tagId: string | null,
        tag: string,
        sqlQuery: string,
        connection: Connection | null,
        pipeline: PipelineStep<PipelineStepType>[],
        groupId: string | null,
        updateDate?: Date,
        priority: number = 1,
        description?: string,
        state?: 'Enabled' | 'Disabled',
        status: RuleStatus = RuleStatus.Valid,
        overridePriority: boolean = false,
        errors?: RuleError[]
    ) {
        super(
            id,
            projectId,
            name,
            tagId,
            tag,
            'RefDataRule',
            pipeline,
            groupId,
            updateDate,
            priority,
            description,
            state,
            status,
            overridePriority,
            errors
        );
        this.sqlQuery = sqlQuery;
        this.connection = connection;
    }

    clone(): RefDataRule {
        return new RefDataRule(
            this.id,
            this.projectId,
            this.name,
            this.tagId,
            this.tag,
            this.sqlQuery,
            this.connection!,
            this.clonePipeline(),
            this.groupId,
            this.updateDate,
            this.priority,
            this.description,
            this.state,
            this.status,
            this.overridePriority
        );
    }

    validateState(): string[] {
        if (!this.name || !this.connection || !this.tag) {
            return ['Fill all fields'];
        }

        const errors = this.validatePipeline();

        return errors;
    }

    toJson(): RefDataRuleResult {
        const json: RefDataRuleResult = {
            ruleType: this.ruleType,
            connectionId: this.connectionId!,
            connection: {
                id: this.connection!.id,
                name: this.connection!.name,
                connectionString: this.connection!.connectionString,
                connectionType: this.connection!.connectionType,
                projectId: this.connection!.projectId
            },
            id: this.id,
            groupId: this.groupId,
            name: this.name,
            sqlQuery: this.sqlQuery,
            tagId: this.tagId,
            tag: this.tag,
            pipeline: this.pipelineToJSON(),
            projectId: this.projectId,
            priority: this.priority,
            description: this.description,
            state: this.state,
            status: this.status,
            overridePriority: this.overridePriority
        };

        return json;
    }
}
