import { Button, Modal, Select } from 'antd';
import { Observer, observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormTypeMapperTable } from '.';
import { FormTypesStore } from '../stores';
import { SelectWhenScroll } from '../../common/components/SelectWhenScroll';
import { debounce } from 'lodash';

const Option = Select.Option;

type Props = {
    store: FormTypesStore;
    reuploadFile: () => Promise<void>;
};

const FormTypeImportDialog: React.FC<Props> = ({ store, reuploadFile }) => {
    React.useEffect(() => {
        if (!store.isFormTypeImportDialogVisible) {
            store.setSelectedTags([]);
        }
    }, [store, store.isFormTypeImportDialogVisible]);

    const debouncedReupload = React.useMemo(() => debounce(reuploadFile, 500), [reuploadFile]);

    const onTagChange = (selectedTags: string[]) => {
        store.setSelectedTags(selectedTags);
        debouncedReupload();
    };

    return (
        <Modal
            wrapProps={{ 'data-id': 'Imported Results' }}
            title={
                <React.Fragment>
                    <span>Imported Results</span>
                    <SelectWhenScroll
                        style={{ marginLeft: 15, width: 300 }}
                        mode="tags"
                        placeholder="Tags..."
                        value={store.selectedTags}
                        onChange={onTagChange}
                    >
                        {store.tags.map((tag, i) => (
                            <Option key={`tag-${i}`} value={tag}>
                                {tag}
                            </Option>
                        ))}
                    </SelectWhenScroll>
                </React.Fragment>
            }
            visible={store.isFormTypeImportDialogVisible}
            onCancel={() => store.setIsFormTypeImportDialogVisible(false)}
            maskClosable={false}
            destroyOnClose={false}
            closable={false}
            width={1300}
            className="alpha-portal-dialog rule-import table scrollable full-screen"
            centered
            footer={[
                <Button
                    data-id="form-type-import-dialog-cancel"
                    size="large"
                    className="light"
                    key="back"
                    onClick={store.resetImportParams}
                >
                    Cancel
                </Button>,
                <Observer key="next">
                    {() => (
                        <Button
                            className="dark"
                            data-id="form-type-import-button"
                            size="large"
                            key="submit"
                            type="primary"
                            onClick={store.importRules}
                            loading={store.isImportingFormType}
                            disabled={
                                store.formTypeImportData?.mapperTableData.some(x => !x.bestValue) ||
                                store.isImportingFormType
                            }
                        >
                            Import
                        </Button>
                    )}
                </Observer>
            ]}
        >
            <FormTypeMapperTable store={store} />
        </Modal>
    );
};

export default observer(FormTypeImportDialog);
