import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { STORE_USER_PROFILE } from '../constants';
import UserProfile from './UserProfile';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserProfileControls: React.FC<any> = ({ UserProfileStore: store }) => {
    return <>{store!.userProfile !== undefined ? <UserProfile store={store} /> : <></>}</>;
};

export default inject(STORE_USER_PROFILE)(observer(UserProfileControls));
