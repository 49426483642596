import { Button, Form, Input, Modal, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormTypesStore, FormTypesVisualStore } from '../stores';
import { PackageState } from '../../common/models';

type Props = {
    store: FormTypesStore;
    visualStore: FormTypesVisualStore;
};

const FormTypeVariationCreateDialog: React.FC<Props> = ({ store, visualStore }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        initFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.referenceFormVariation]);

    const initFormData = () => {
        if (!store.referenceFormVariation) {
            return;
        }

        form.setFieldsValue({
            name: store.referenceFormVariation.name + '(copy)',
            packageId: store.referenceFormVariation.packageId
        });
    };

    const handleCancel = () => {
        visualStore.setIsFormVariationCreateDialogVisible(false);
        store.setRefernceFormVariation(undefined);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(async values => {
                const createdSuccessfully = await store.createFormVariation(values.name, values.packageId);
                if (createdSuccessfully) {
                    handleCancel();
                }
            })
            .catch(() => {
                // do nothing
            });
    };

    const validateName = (name: string) => {
        if (!store.selectedFormType) {
            return Promise.reject('Select form type, please');
        }

        const currentGroup = store.formTypes.find(type => type.id === store.selectedFormType);

        if (!currentGroup) {
            return Promise.reject('Select form type, please');
        }

        if (currentGroup.variations.find(variation => variation.name === name)) {
            return Promise.reject('Form variation with such name already exists');
        }

        return Promise.resolve();
    };

    return (
        <Modal
            wrapProps={{ 'data-id': 'New form type variation' }}
            className="alpha-portal-dialog"
            title="New form type variation"
            visible={visualStore.isFormVariationCreateDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button
                    data-id="form-variations-add-dialog-cancel"
                    className="light"
                    key="back"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="form-variations-add-dialog-submit"
                    key="submit"
                    size="large"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Add form type variation
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        { required: true, message: 'Provide name for the form type variation, please' },
                        { validator: (_, value) => validateName(value) }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="packageId"
                    label="Package"
                    rules={[{ required: true, message: 'Provide package for the form type variation, please' }]}
                >
                    <Select
                        disabled={store.loadingPackages}
                        loading={store.loadingPackages}
                        options={store.packages
                            ?.filter(x => x.serverState === PackageState.Ready)
                            .map(p => ({ title: p.name, label: p.name, value: p.id }))}
                        showSearch
                        filterOption={(input, option) =>
                            option?.title && option.title.toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(FormTypeVariationCreateDialog);
