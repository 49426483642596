import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { AutoComplete, AutoCompleteProps } from 'antd';
import { handleDropdownItemSelection } from '../Utils';

export const AutocompleteWithTextSelection: React.FC<AutoCompleteProps> = observer(props => {
    const sel = window.getSelection();
    const handleOnMouseDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        handleDropdownItemSelection(e, sel!);
    };

    const handleVisibleChange = (isOpen: boolean) => {
        if (!isOpen) {
            sel!.removeAllRanges();
        }
    };
    return (
        <AutoComplete {...props} onMouseDown={handleOnMouseDown} onDropdownVisibleChange={handleVisibleChange}>
            {props.children}
        </AutoComplete>
    );
});
