import * as React from 'react';
import { Alert } from 'antd';
import { observer, inject } from 'mobx-react';
import { ErrorStore, RootStores } from '../stores';
import { STORE_ERRORS } from '../constants';

type Props = Partial<RootStores>;

export class ErrorNotification extends React.Component<Props> {
    private store: ErrorStore;

    constructor(props: Props) {
        super(props);
        this.store = this.props.error!;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClose(err: Error) {
        this.store.removeError(err);
    }

    render() {
        return (
            <div className="error-container">
                {this.store.errors.map((error, index) => (
                    <Alert
                        message={error.message}
                        key={index}
                        type="error"
                        // eslint-disable-next-line react/jsx-boolean-value
                        closable
                        onClose={() => this.onClose(error)}
                    />
                ))}
            </div>
        );
    }
}

export default inject(STORE_ERRORS)(observer(ErrorNotification));
