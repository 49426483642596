import { observable, action, computed, autorun, runInAction } from 'mobx';
import { UploadFile } from 'antd/lib/upload/interface';
import { ProjectsRootVisualStore } from '../../common/stores';
import security from '../../common/services/SecurityService';

export default class ProjectUploadVisualStore {
    @observable
    uploadedFiles: UploadFile[] = [];

    @observable
    currentTags: string[] = [];

    @observable
    filesList: UploadFile[] = [];

    @observable
    fileUploadHeaders = {};

    @computed
    get tags() {
        return this.rootStore.tags;
    }

    @computed.struct
    get completedFiles() {
        return this.uploadedFiles.filter(f => f.status === 'done');
    }

    @computed
    get currentProject() {
        return this.rootStore.currentProject;
    }

    constructor(private rootStore: ProjectsRootVisualStore) {
        autorun(() => {
            if (rootStore.currentProject != null && rootStore.currentProject.isPackagesLoaded) {
                let id = 0;
                const ready = rootStore.currentProject.packages.map(x => {
                    return {
                        uid: (id++).toString(),
                        name: x.name,
                        size: x.size,
                        type: '',
                        status: 'success'
                    } as UploadFile;
                });

                runInAction(() => {
                    this.uploadedFiles = [
                        ...ready,
                        ...rootStore.currentProject!.importingPackages.map(x => {
                            return {
                                uid: (id++).toString(),
                                name: x.name,
                                size: x.size,
                                type: '',
                                status: 'done'
                            } as UploadFile;
                        })
                    ];
                });
            } else {
                runInAction(() => {
                    this.uploadedFiles = [];
                });
            }
        });
    }

    @action.bound
    async setHeaders() {
        security.invoke(async token => {
            this.fileUploadHeaders = {
                Authorization: 'Bearer ' + token
            };
        });
    }

    @action
    async fetchTags() {
        await this.rootStore.fetchTags();
    }

    @action.bound
    setTags(tags: string[]) {
        this.currentTags = tags;
    }

    @action
    uploadProgress(file: UploadFile) {
        if (!this.filesList.find(x => x.uid === file.uid)) {
            this.filesList.push(file);
        }
        let storageFile = this.uploadedFiles.find(f => f.uid === file.uid);

        if (!storageFile) {
            this.uploadedFiles.push(file);
            storageFile = file;
        }

        if (storageFile.status !== file.status) {
            storageFile.status = file.status;
            if (file.status === 'done') {
                // this.rootStore.currentProject!.addPackage(file);
                this.rootStore.fetchTags();
            }
        }
    }
}
