import { Progress, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormTypesStore } from '../stores';

type Props = {
    store: FormTypesStore;
};

const PackagesLoadingIndicator: React.FC<Props> = ({ store }) => {
    if (!store.loadingPackages) {
        return null;
    }

    return (
        <Tooltip title="Loading packages... Please wait">
            <div className="packages-loading-indicator">
                <Progress
                    percent={store.loadingPackagesProgress}
                    size="small"
                    status={store.loadingPackagesErrorMessage ? 'exception' : undefined}
                />
            </div>
        </Tooltip>
    );
};

export default observer(PackagesLoadingIndicator);
