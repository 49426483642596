/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action } from 'mobx';

type AliasChangeEvent = (anchor: Anchor, alias: string) => void;

export default class Anchor {
    keyword: string;

    @observable
    aliases: string[] = [];

    constructor(
        keyword: string,
        private addedAlias: AliasChangeEvent,
        private removedAlias: AliasChangeEvent,
        aliases?: string[]
    ) {
        this.keyword = keyword;

        if (aliases) {
            this.aliases = observable(aliases);
        }
    }

    @action
    addAlias(alias: string) {
        if (this.aliases.find(a => a === alias) != null) {
            return;
        }

        this.aliases.push(alias);
        this.addedAlias(this, alias);
    }

    @action
    removeAlias(alias: string) {
        const a = this.aliases.indexOf(alias);
        if (a === -1) {
            return;
        }

        this.aliases.splice(a, 1);
        this.removedAlias(this, alias);
    }

    hasAlias(alias: string): boolean {
        return !!this.aliases.find(a => a === alias);
    }
}
