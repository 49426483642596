import * as React from 'react';
import { observer } from 'mobx-react-lite';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import { InferenceModelsService } from '../services';
import { RuleLabelsSelectWrapped } from './RuleLabelsSelect';
import PipelineVisualStore from '../stores/PipelineVisualStore';
import { Parameter } from '../types';
import Form, { FormInstance } from 'antd/lib/form';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
    dataId: string;
};
export const ParametersLabelsEditor: <
    T extends PipelineVisualStore<TPipelineStepType>,
    TPipelineStepType extends string
>(
    props: Props<T, TPipelineStepType>
) => React.ReactElement<Props<T, TPipelineStepType>> = ({ param, form, toolTip, step, store, stepKey, dataId }) => {
    const [modelId, setModelId] = React.useState<string | undefined>(form.getFieldValue('model_id'));

    const handleSubmit = async () => {
        await store.validateForm(form, step, stepKey, false);
    };
    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    const changeName = (value: string | string[], name: string, labelsForm: FormInstance) => {
        labelsForm.setFieldsValue({
            [name]: value
        });
    };

    const service = new InferenceModelsService();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shouldUpdate = (prevValues: any, currentValues: any) => {
        const shouldBeUpdated = prevValues.model_id !== currentValues.model_id;
        setModelId(currentValues.model_id);
        return shouldBeUpdated;
    };

    return (
        <div>
            <Form.Item
                label={toolTip}
                name={param.name}
                rules={[
                    {
                        required: !step.isDisabled && param.required,
                        message: 'Please select a label'
                    }
                ]}
                initialValue={param.defaultValue}
                shouldUpdate={shouldUpdate}
            >
                <RuleLabelsSelectWrapped
                    dataId={dataId}
                    service={service}
                    selectedModelId={modelId}
                    onChange={value => changeName(value, param.name || 'value', form)}
                    defaultValues={param.values}
                />
            </Form.Item>
        </div>
    );
};

export default observer(ParametersLabelsEditor);
