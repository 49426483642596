import { Button, Dropdown, Menu, Modal, Tree } from 'antd';
// import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { DataNode } from 'rc-tree/lib/interface';
import * as React from 'react';
import { FieldBindingsStore } from '../stores';
import { BindingGroup } from '../types';

type Props = {
    store: FieldBindingsStore;
};

const FieldBindingsTree: React.FC<Props> = ({ store }) => {
    const [treeData, setTreeData] = React.useState<DataNode[]>([]);
    const [deleteConfirmVisible, setDeleteConfirmVisible] = React.useState(false);
    const [targetGroup, setTargetGroup] = React.useState<BindingGroup | undefined>(undefined);

    React.useEffect(() => {
        setTreeData(renderTreeNodes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.bindingGroups]);

    const handleEntitiesUpdate = (entityId: string) => {
        if (!store.treeExpandedKeys.includes(entityId)) {
            store.setTreeExpandedKeys([...store.treeExpandedKeys, entityId]);
        }
    };

    const renderTreeNodes = () => {
        return store.bindingGroups
            .sort((a, b) => a.order - b.order)
            .map(group => {
                return {
                    key: group.id,
                    selectable: true,
                    id: group.id,
                    title: groupDropDown(group)
                    // children: renderTreeSections(group)
                };
            });
    };

    // Commented it in case we decide to return sections
    // const renderTreeSections = (group: BindingGroup) => {
    //     const { sections } = group;

    //     if (!sections) {
    //         return [];
    //     }

    //     return sections.map(section => {
    //         return {
    //             key: section.sectionId,
    //             selectable: false,
    //             id: section.sectionId,
    //             title: (<span onClick={() => handleEntitiesUpdate(section.sectionId)}>{section.name}</span>),
    //             children: renderTreeInputs(section.sectionId, group)
    //         };
    //     });
    // };

    // const renderTreeInputs = (sectionId: string, group: BindingGroup) => {
    //     if (!group?.fields) {
    //         return [];
    //     }

    //     const fields = group.fields.filter(f => f.sectionId === sectionId);
    //     const uniqueFields = _.uniqBy(fields, function (e) {
    //         return e.inputId;
    //     });
    //     return uniqueFields.map(field => {
    //         return {
    //             key: field.inputId,
    //             selectable: false,
    //             id: field.inputId,
    //             title: field.name,
    //         };
    //     });
    // };

    const createSection = (groupId: string) => {
        store.setSelectedGroupId(groupId);
        store.setIsCreateSectionDialogVisible(true);
    };

    const editGroup = (groupId: string) => {
        store.setSelectedGroupId(groupId);
        store.setIsUpdateGroupDialogVisible(true);
    };

    const deleteGroup = (groupId: string) => {
        const group = store.bindingGroups.find(g => g.id === groupId);
        setTargetGroup(group);
        setDeleteConfirmVisible(true);
    };

    const handleDeleteCancel = () => {
        setDeleteConfirmVisible(false);
        setTargetGroup(undefined);
    };

    const handleDeleteSubmit = () => {
        if (targetGroup) {
            store.setSelectedGroupId(targetGroup.id);
            store.deleteGroup();
            handleDeleteCancel();
        }
    };

    const deleteConfirmModal = () => {
        if (!targetGroup) {
            return null;
        }

        return (
            <Modal
                className="alpha-portal-dialog two-columns"
                title="Delete group"
                visible={deleteConfirmVisible}
                onCancel={handleDeleteCancel}
                maskClosable={false}
                closable={false}
                destroyOnClose
                width={600}
                centered
                footer={[
                    <Button
                        data-id="field-binding-delete-group-dialog-cancel"
                        className="light"
                        key="back"
                        size="large"
                        onClick={handleDeleteCancel}
                    >
                        Cancel
                    </Button>,
                    <Button
                        data-id="field-binding-delete-group-dialog-submit"
                        key="submit"
                        size="large"
                        type="primary"
                        onClick={handleDeleteSubmit}
                    >
                        Delete
                    </Button>
                ]}
            >
                Are you sure that you want to delete {targetGroup.name}?
            </Modal>
        );
    };

    const groupMenu = (groupId: string, groupName: string) => {
        return (
            <Menu data-id-type="popup-menu-list-root" data-id-name={groupName}>
                <Menu.Item
                    data-id-type="popup-menu-list-item"
                    data-id-name="Add section"
                    key="1"
                    onClick={info => {
                        info.domEvent.stopPropagation();
                        createSection(groupId);
                    }}
                >
                    Add section
                </Menu.Item>
                <Menu.Item
                    data-id-type="popup-menu-list-item"
                    data-id-name="Edit"
                    key="4"
                    onClick={info => {
                        info.domEvent.stopPropagation();
                        editGroup(groupId);
                    }}
                >
                    Edit group
                </Menu.Item>
                <Menu.Item
                    data-id-type="popup-menu-list-item"
                    data-id-name="Delete"
                    key="5"
                    onClick={info => {
                        info.domEvent.stopPropagation();
                        deleteGroup(groupId);
                    }}
                >
                    Delete
                </Menu.Item>
            </Menu>
        );
    };

    const groupDropDown = (group: BindingGroup) => {
        return (
            <>
                <Dropdown
                    key={group.id + 'i'}
                    overlay={() => groupMenu(group.id, group.name)}
                    trigger={['contextMenu']}
                >
                    <span onClick={() => handleEntitiesUpdate(group.id)}>
                        <span
                            className="node-title with-menu"
                            data-id={`field-bindings-group-${group.id}`}
                            data-id-name={group.name}
                            data-id-type="group-0"
                        >
                            {group.name}
                        </span>
                    </span>
                </Dropdown>

                <Dropdown
                    key={group.id + 'settings'}
                    overlay={() => groupMenu(group.id, group.name)}
                    trigger={['click']}
                >
                    <i className="alpha-icon md more-icon node-action" onClick={e => e.stopPropagation()} />
                </Dropdown>
            </>
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onDrop = (info: any) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const loop = (
            data: DataNode[],
            key: React.Key,
            callback: (node: DataNode, i: number, data: DataNode[]) => void
        ) => {
            for (let idx = 0; idx < data.length; idx++) {
                if (data[idx].key === key) {
                    return callback(data[idx], idx, data);
                }
                if (data[idx].children) {
                    loop(data[idx].children!, key, callback);
                }
            }
        };
        const newTreeData = [...treeData];

        let dragObj: DataNode;
        loop(newTreeData, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        let ar: DataNode[] = [];
        let i: number;
        loop(newTreeData, dropKey, (_item, index, arr) => {
            ar = arr;
            i = index;
        });
        if (dropPosition === -1) {
            ar.splice(i!, 0, dragObj!);
        } else {
            ar.splice(i! + 1, 0, dragObj!);
        }

        setTreeData(newTreeData);
        saveGroupOrder(newTreeData);
    };

    const saveGroupOrder = (newTreeData: DataNode[]) => {
        const groupKeys = newTreeData
            .filter(node => store.bindingGroups.map(gr => gr.id).includes(node.key.toString()))
            .map(node => node.key.toString());
        let groupOrders = {};
        let groupIndex = 0;

        for (let groupKey of groupKeys) {
            groupOrders[groupKey] = groupIndex;
            groupIndex++;
        }

        store.saveGroupsOrder(groupOrders);
    };

    return (
        <>
            {deleteConfirmModal()}
            <Tree
                showLine={{ showLeafIcon: true }}
                treeData={treeData}
                className="alpha-portal-tree without-line with-leaf-icons sm-labels field-bindings-tree"
                onSelect={(keys: string[]) => store.setSelectedGroupId(keys[0])}
                selectedKeys={[store.selectedGroupId || '']}
                expandedKeys={store.treeExpandedKeys}
                onExpand={(keys: string[], node) => {
                    if (node.expanded && store.bindingGroups.map(gr => gr.id).includes(node.node.key.toString())) {
                        store.setSelectedGroupId(node.node.key.toString());
                    }
                    store.setTreeExpandedKeys(keys);
                }}
                draggable={(node: DataNode) => {
                    return store.bindingGroups.map(group => group.id).includes(node.key.toString());
                }}
                allowDrop={allowDrop => {
                    return store.bindingGroups.map(group => group.id).includes(allowDrop.dropNode.key.toString());
                }}
                onDrop={onDrop}
            />
        </>
    );
};

export default observer(FieldBindingsTree);
