import * as React from 'react';
import DocumentViewerPage from '../screens/DocumentViewerPage';
import { ProtectedRoute } from '../../common/components';

export const ViewerPagesNavigation = {
    DocumentViewerPage: '/document-viewer'
};

export default () => {
    return <ProtectedRoute component={DocumentViewerPage} path={ViewerPagesNavigation.DocumentViewerPage + '/:path'} />;
};
