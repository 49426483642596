import * as React from 'react';
import Col from 'antd/lib/grid/col';
import Row from 'antd/lib/grid/row';
// import AddProjectForm from '../components/AddProjectForm';
import { inject, observer } from 'mobx-react';
import { ProjectStores } from '../stores';
import { RootStores } from '../../common/stores';
import { STORE_PROJECTS } from '../../common/constants';
import { STORE_PROJECT_FORM } from '../constants';

type Props = ProjectStores & RootStores;

@inject(STORE_PROJECTS, STORE_PROJECT_FORM)
@observer
export default class AddProjectPage extends React.Component<Props, object> {
    render() {
        // const projectsStore = this.props.projects!;
        // const projectFormStore = this.props.projectForm!;
        return (
            <div style={{ padding: '24px' }}>
                <Row>
                    <Col span={12}>
                        {/* <AddProjectForm
              store={projectFormStore}
              onAddProject={(name: string, type: string, keywords: string[]) =>
                projectsStore.addProject({ id: '123', title: name, type: type, keywords: keywords })}
              lockSubmit={projectsStore.lockNewProjectsSubmitting}
            /> */}
                    </Col>
                </Row>
            </div>
        );
    }
}
