import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PackageLine } from '../../common/models';
import { getDetectorAutocompleteOptions } from '../misc/FormTypesUtils';
import { FormTypesStore } from '../stores';

type Props = {
    store: FormTypesStore;
};

const FormPartEditDialog: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();
    const [autocompleteOptions, setAutocompleteOptions] = React.useState<{ value: string; label: string }[]>([]);
    const [initializingData, setInitializingData] = React.useState(false);

    React.useEffect(() => {
        if (store.editableFormPart && !store.isLoadingPackageLines) {
            if (store.linesForPackageAreLoaded(store.editableFormPartPackageId)) {
                initFormData();
            } else {
                store
                    .getLineBlocksForPackage()
                    .then(() => {
                        initFormData();
                    })
                    .catch(err => {
                        console.error(err);
                        setInitializingData(false);
                    });
            }
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.editableFormPart, store.isLoadingPackageLines]);

    const initFormData = async () => {
        if (!store.editableFormPart) {
            return;
        }

        setInitializingData(true);

        const formPartId = store.editableFormPart.id;

        const formVariationId = store.editableFormPart.formVariationId;

        const formType = store.formTypes.find(p => p.variations.find(v => v.variationId === formVariationId));

        const formTypePackageLinesFetched = store.formTypePackageLines.some(t => t.formTypeId === formType?.id);

        if (formType && !formTypePackageLinesFetched) {
            await store.getFormTypePackageLines(formType.id);
        }

        const formPart = store.allFormTypeParts.find(p => p.formPartId === formPartId);

        const obj = {
            name: store.editableFormPart.name,
            detectorParts: store.editableFormPart.detector?.detectorParts ?? [],
            ignoreParts: store.editableFormPart.detector?.ignoreParts ?? [],
            packageLineId: formPart?.packageFieldId
        };

        form.setFieldsValue(obj);
        populateAutocompleteOptions();
        setInitializingData(false);
    };

    const populateAutocompleteOptions = () => {
        const options = store.packageLines.map(getDetectorAutocompleteOptions);
        setAutocompleteOptions(_.uniqBy(options, 'value'));
    };

    const handleCancel = () => {
        store.setEditableFormPart(undefined);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(async values => {
                const updateSuccessfully = await store.updateFormPart(
                    values.name,
                    values.detectorParts,
                    values.ignoreParts,
                    values.packageLineId
                );

                if (updateSuccessfully) {
                    handleCancel();
                }
            })
            .catch(() => {
                // do nothing
            });
    };

    const validateName = (name: string) => {
        const currentPart = store.formParts.find(part => part.name === name);

        if (
            currentPart &&
            currentPart.id !== store.editableFormPart?.id &&
            currentPart.formVariationId === store.editableFormPart?.formVariationId
        ) {
            return Promise.reject('Form part with such name already exists');
        }

        return Promise.resolve();
    };

    const getPackageLineLabel = (pl: PackageLine) => {
        const label = `${pl.text ?? ''} [${pl.coordinates?.x ?? ''}, ${pl.coordinates?.y ?? ''}] (${pl.coordinates?.page})`;

        return {
            title: label,
            label: label,
            value: pl.id
        };
    };

    return (
        <Modal
            wrapProps={{ 'data-id': 'Edit form part' }}
            className="alpha-portal-dialog two-columns"
            title="Edit form part"
            visible={store.isFormPartEditDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button
                    data-id="form-types-edit-part-dialog-cancel"
                    className="light"
                    key="back"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="form-types-edit-part-dialog-submit"
                    key="submit"
                    size="large"
                    type="primary"
                    onClick={handleSubmit}
                    disabled={store.isLoadingPackageLines || initializingData}
                >
                    Submit
                </Button>
            ]}
        >
            {(store.isLoadingPackageLines || initializingData) && (
                <div className="dialog-loading-overlay">
                    <Spin />
                </div>
            )}
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        { required: true, message: 'Provide name for the form type, please' },
                        { validator: (__, name) => validateName(name) }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: 'Provide detector parts, please' }]}
                    name="detectorParts"
                    label="Detector parts"
                >
                    <Select options={autocompleteOptions} mode="tags" showSearch />
                </Form.Item>
                <Form.Item name="ignoreParts" label="Ignore parts">
                    <Select options={autocompleteOptions} mode="tags" showSearch />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: 'Provide detector coordinates reference package line, please' }]}
                    name="packageLineId"
                    label="Coordinates reference package line"
                >
                    <Select
                        loading={store.isLoadingPackageLines}
                        options={store.packageLines
                            .filter(pl => pl.blockType === 'HORIZONTAL_LINE_BLOCK')
                            .map(getPackageLineLabel)}
                        showSearch
                        filterOption={(input, option) =>
                            option?.title && option.title.toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(FormPartEditDialog);
