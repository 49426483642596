import { Button, Form, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ConnectionEditPage } from '../screens/ConnectionEditPage';
import { ConnectionEditorStore } from '../stores';

type Props = {
    store: ConnectionEditorStore;
};

const ConnectionEditDialog: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (!store.connectionEditDialogVisible) {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.connectionEditDialogVisible]);

    if (!store || !store.editableConnection) {
        return <Form form={form} />;
    }

    const handleCancel = () => {
        store.cancel();
    };

    const id = store!.editableConnection!.id;

    return (
        <Modal
            className="alpha-portal-dialog"
            title="Edit connection"
            visible={store!.connectionEditDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            forceRender
            width={600}
            getContainer={false}
            centered
            footer={[
                <Button
                    data-id="rd-connection-edit-dialog-cancel"
                    className="light"
                    key="back"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="rd-connection-edit-dialog-validate"
                    key="validate"
                    style={{ backgroundColor: 'green', borderColor: 'green' }}
                    type="primary"
                    size="large"
                    onClick={() => {
                        store!.validateConnection(
                            id,
                            form.getFieldValue('connectionString'),
                            form.getFieldValue('connectionType')
                        );
                    }}
                    loading={store!.isLoading}
                >
                    Validate
                </Button>,
                <Button
                    data-id="rd-connection-edit-dialog-submit"
                    key="submit"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    form="edit-rd-connection-form"
                >
                    Save changes
                </Button>
            ]}
        >
            <ConnectionEditPage RuleConnection={store} form={form} />
        </Modal>
    );
};

export default observer(ConnectionEditDialog);
