import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../../common/services/Utils';
import { AuditInputsMeta } from '../types/IotaAuditResult';

type Props = {
    inputsMeta: AuditInputsMeta[];
};

export const ResultMetaTable: React.FC<Props> = ({ inputsMeta }) => {
    const columns: ColumnProps<AuditInputsMeta>[] = [
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source'
        },
        {
            title: 'Page',
            dataIndex: 'page',
            key: 'page'
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (_, record: AuditInputsMeta) => {
                return Utils.formatDateStringShort(record.date.toString(), true);
            }
        },
        {
            title: 'View In Document',
            dataIndex: 'viewInDocument',
            key: 'viewInDocument',
            render: () => {
                return <span>Click to Preview</span>;
            }
        }
    ];

    return (
        <Table
            dataSource={inputsMeta}
            columns={columns}
            pagination={false}
            rowKey={s => `${s.date.toString()}-${s.source}`}
        />
    );
};

export default observer(ResultMetaTable);
