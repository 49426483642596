import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { AutoComplete } from 'antd';
import { FormTypesStore } from '../stores';

type Props = {
    store: FormTypesStore;
};

const FormTypeSearch: React.FC<Props> = ({ store }) => {
    React.useEffect(() => {
        return () => store.setFormTypeFilter('');
    }, [store]);

    if (store.formTypes.length <= 1) {
        return null;
    }

    return (
        <AutoComplete
            style={{ width: 280, marginBottom: 8 }}
            value={store.formTypeFilter}
            options={store.formTypes.map(t => ({
                value: t.name,
                label: t.name,
                title: t.name
            }))}
            filterOption={(input, option) =>
                option && option.title && option.title.toLowerCase().includes(input.toLowerCase())
            }
            onSelect={store.setFormTypeFilter}
            onSearch={store.setFormTypeFilter}
            placeholder="Search form template..."
            allowClear
        />
    );
};

export default observer(FormTypeSearch);
