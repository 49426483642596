import * as React from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react-lite';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineVisualStore from '../stores/PipelineVisualStore';
import { Parameter } from '../types';
import { Select } from 'antd';
import { RulesService } from '../services';
import { Connection } from '../models';
import Form, { FormInstance } from 'antd/lib/form';

const Option = Select.Option;

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};
export const ParametersConnectionIdSelector: <
    T extends PipelineVisualStore<TPipelineStepType>,
    TPipelineStepType extends string
>(
    props: Props<T, TPipelineStepType>
) => React.ReactElement<Props<T, TPipelineStepType>> = ({ param, form, toolTip, step, projectId, store, stepKey }) => {
    const handleSubmit = async () => {
        await store.validateForm(form, step, stepKey, false);
    };
    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }
    const localState = useLocalStore(() => ({
        service: new RulesService(),
        connection: '',
        connections: new Array<Connection>(),
        async load() {
            var res = await localState.service.getConnections(projectId);
            runInAction(() => {
                localState.connections = res;
            });
        }
    }));

    React.useEffect(() => {
        localState.load();
    }, [localState]);

    return (
        <div>
            <Form.Item
                label={toolTip}
                name={param.name}
                rules={[
                    {
                        required: !step.isDisabled && param.required,
                        message: 'Please select connection Id'
                    }
                ]}
                initialValue={param.defaultValue}
            >
                <Select style={{ width: '100%' }}>
                    {localState.connections.map(x => (
                        <Option className="connection-id-parameter" key={x.id} value={x.id}>
                            {x.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    );
};

export default observer(ParametersConnectionIdSelector);
