import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FieldBindingsStore } from '../stores';

type Props = {
    store: FieldBindingsStore;
};

const FieldBindingSectionCreateDialog: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (store.currentSectionId && store.selectedGroup) {
            const section = store.selectedGroup.sections.find(s => s.sectionId === store.currentSectionId);
            const obj = { name: section?.name };
            form.setFieldsValue(obj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentSectionId]);

    const handleCancel = () => {
        store.setIsUpdateSectionDialogVisible(false);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(async values => {
                var updatedSuccessfully = await store.updateSection(values.name);
                if (updatedSuccessfully) {
                    handleCancel();
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <Modal
            className="alpha-portal-dialog"
            title="Edit field binding section"
            visible={store!.isUpdateSectionDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button
                    data-id="field-binding-edit-section-dialog-cancel"
                    className="light"
                    key="back"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="field-binding-edit-group-section-submit"
                    key="submit"
                    size="large"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Save section
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Provide name for the project field group section, please' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(FieldBindingSectionCreateDialog);
