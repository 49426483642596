import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input, Tooltip } from 'antd';
import { WorkflowTypeMessagesTemplateDto, WorkflowTypeInputDto } from '../../types';
import InstructWorkflowModel from '../../model/InstructWorkflowModel';
import { InfoCircleOutlined } from '@ant-design/icons';
import './WorkflowTextInputCell.less';

interface Props {
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof WorkflowTypeMessagesTemplateDto & keyof WorkflowTypeInputDto;
    input?: WorkflowTypeInputDto;
    workflow?: InstructWorkflowModel;
}

const WorkflowTextInputCell: React.FC<Props> = ({ editable, children, dataIndex, input, workflow, ...restProps }) => {
    if (!editable) {
        return <td {...restProps}>{children}</td>;
    }

    if (dataIndex === 'value' && workflow && input) {
        const existingInputValue = workflow.getInputValueById(input.id);

        const inputDescription = () => {
            if (!input.description) {
                return undefined;
            }

            return (
                <Tooltip title={input.description}>
                    <InfoCircleOutlined />
                </Tooltip>
            );
        };

        return (
            <td {...restProps}>
                <Form.Item
                    className="workflow-text-input-cell"
                    name={['inputValues', input.id]}
                    initialValue={existingInputValue ? existingInputValue.value : input.defaultValue}
                    rules={[{ required: true, message: 'Please enter value!' }]}
                    extra={inputDescription()}
                >
                    <Input />
                </Form.Item>
            </td>
        );
    }

    return null;
};

export default observer(WorkflowTextInputCell);
