import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { IotaReferenceDataStores } from '../stores';
import { Row, Col, Select, Button, AutoComplete, Input, Form, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import SearchCodeRefAutoCompleteOptionsRenderer from './SearchCodeRefAutoCompleteOptionsRenderer';

type Props = IotaReferenceDataStores;

const { Option } = Select;
export const ReferenceDataHeader: React.FC<Props> = observer(
    ({ ReferenceDataUI: store, ReferenceDataUsageUI: referenceDataUsageStore }) => {
        const [form] = Form.useForm();
        const formItemLayout = {
            labelCol: {
                style: {
                    width: '135px'
                }
            },
            wrapperCol: {
                style: {
                    width: 'calc(100% - 135px)',
                    minWidth: '100px'
                }
            }
        };

        const refCodeOptionRenderer = SearchCodeRefAutoCompleteOptionsRenderer(store!);
        return (
            <Form form={form} className="ant-advanced-search-form">
                <Row gutter={10}>
                    <Col span={4}>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select connection..."
                            onChange={store!.getTableData}
                            value={store?.currentConnectionId || undefined}
                        >
                            {store!.filteredConnections.map(t => (
                                <Option key={t.id} value={t.id}>
                                    {t.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={4}>
                        <FormItem {...formItemLayout}>
                            <div className="global-search-wrapper" style={{ width: '100%' }}>
                                <AutoComplete
                                    className="global-search alpha-autocomplete"
                                    style={{ width: '100%' }}
                                    options={store!.searchData.map((t, i) => ({
                                        value: t,
                                        label: refCodeOptionRenderer(t, i)
                                    }))}
                                    defaultActiveFirstOption={false}
                                    showSearch
                                    filterOption
                                    disabled={!store!.selectedTable}
                                    value={store!.refDataFilteredValue}
                                    onSearch={store!.setRefDataFilteredValue}
                                    onSelect={v => {
                                        const value = v as string;
                                        store!.setRefDataFilteredValue(value);
                                    }}
                                >
                                    <Input
                                        allowClear
                                        placeholder="Search..."
                                        data-id="rules-list-filter-tag-input"
                                        suffix={
                                            <Button className="search-btn" type="ghost">
                                                <i
                                                    className="alpha-icon md search-icon"
                                                    style={{ verticalAlign: 'middle' }}
                                                />
                                            </Button>
                                        }
                                    />
                                </AutoComplete>
                            </div>
                        </FormItem>
                    </Col>
                    <Col>
                        <Tooltip title="Reference data usage in current project rules">
                            <Button
                                disabled={!store!.currentConnectionId}
                                type="link"
                                size="small"
                                onClick={() => referenceDataUsageStore!.setSingleProjectDialogVisible(true)}
                                style={{ marginTop: 2 }}
                            >
                                <i className="alpha-icon lg label-icon-gray" />
                            </Button>
                        </Tooltip>
                    </Col>
                    <Col span={7}>
                        <div style={{ opacity: 0.5, marginTop: 6 }}>{store!.updateInfo}</div>
                    </Col>
                </Row>
            </Form>
        );
    }
);

export default ReferenceDataHeader;
