import * as React from 'react';
import PipelineVisualStore from '../../stores/PipelineVisualStore';
import ParametersCustomModelSelect from './ParametersCustomModelSelect';
import { Props } from './types';

const ParametersCustomCrossEncoderModelSelect: <T extends PipelineVisualStore<S>, S extends string>(
    props: Props<T, S>
) => React.ReactElement<Props<T, S>> = props => <ParametersCustomModelSelect {...props} modelType="cross-encoder" />;

export default ParametersCustomCrossEncoderModelSelect;
