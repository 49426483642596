import * as React from 'react';
import { observer, inject } from 'mobx-react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import ProjectConnectionsDefinitionsList from '../components/ProjectConnectionsDefinitionsList';
import { IotaConnStores } from '../stores';
import { STORE_PROJECT_CONNECTIONS_DEFINITIONS } from '../constants';

type Props = IotaConnStores;

export const ProjectConnectionsDefinitionsPage: React.FC<Props> = ({ projectConnectionsDefinitionsUI: store }) => {
    return (
        <FlexContentContainer content={<ProjectConnectionsDefinitionsList projectConnectionsDefinitionsUI={store} />} />
    );
};

export default inject(STORE_PROJECT_CONNECTIONS_DEFINITIONS)(observer(ProjectConnectionsDefinitionsPage));
