/* eslint-disable max-len */
import * as React from 'react';
import {
    AutoSizer,
    CellMeasurerCache,
    Table,
    Column,
    TableCellProps,
    Index,
    InfiniteLoader,
    defaultTableRowRenderer,
    RowMouseEventHandlerParams,
    SortIndicator,
    TableHeaderProps
} from 'react-virtualized';
import { Table as AntdTable, Modal, Button, Layout } from 'antd';
import { observer, Observer } from 'mobx-react';
import Popover from 'antd/lib/popover';
import { SessionsVisualStore } from '../stores';
import { reaction } from 'mobx';
import { ApplicationSessionPayload } from '../types';
import ReactJson from 'react-json-view';
import { MultiSortReturn } from 'react-virtualized/dist/es/Table';
import { Utils } from '../../common/services/Utils';
import LayoutHeader from '../../../components/LayoutHeader';
import SessionsFilter from './SessionsFilter';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
const { Content } = Layout;

type Props = {
    store: SessionsVisualStore;
};

const COLUMN_HEIGHT = 48;

class SessionsGrid extends React.Component<Props> {
    sortState: MultiSortReturn;
    private tableRef: React.MutableRefObject<Table | null>;
    private cache: CellMeasurerCache;
    private promiseResolver: (val: boolean) => void;
    private lastPromise: Promise<boolean>;
    private store: SessionsVisualStore;
    constructor(props: Props) {
        super(props);
        this.store = this.props.store;
        this.state = { sortBy: 'created', sortDirection: 'DESC' };
        this.errorRenderer = this.errorRenderer.bind(this);
        this.getRowDetailsHeightForSession = this.getRowDetailsHeightForSession.bind(this);
        this.cache = new CellMeasurerCache({
            fixedWidth: true,
            minHeight: COLUMN_HEIGHT
        });
        this.tableRef = React.createRef();
        reaction(
            () => this.store.selectedSessionIndex,
            () => {
                if (this.tableRef.current) {
                    this.tableRef.current.recomputeRowHeights();
                }
            }
        );

        reaction(
            () => this.store.loadedSessionPayloads,
            () => {
                if (this.store.selectedSessionIndex) {
                    this.cache.set(
                        this.store.selectedSessionIndex,
                        0,
                        1,
                        COLUMN_HEIGHT +
                            this.getRowDetailsHeightForSession(this.store.sessions[this.store.selectedSessionIndex].id)
                    );
                }
                if (this.tableRef.current) {
                    this.tableRef.current.recomputeRowHeights();
                }
            }
        );
    }

    cutText(text: string) {
        if (text.length > 50) {
            return text.substring(0, 50) + '...';
        } else {
            return text;
        }
    }

    render() {
        const rowHeight = (info: Index) => {
            return info.index === this.store.selectedSessionIndex
                ? COLUMN_HEIGHT +
                      this.getRowDetailsHeightForSession(this.store.sessions[this.store.selectedSessionIndex].id) -
                      13
                : COLUMN_HEIGHT;
        };

        const { hasNextPage, isNextPageLoading } = this.store.pager;
        const sessionsCount = this.store.sessions ? this.store.sessions.length : 0;

        const rowCount = hasNextPage ? sessionsCount + 1 : sessionsCount;

        if (!isNextPageLoading && this.promiseResolver) {
            this.promiseResolver(true);
        }

        const isRowLoaded = ({ index }: Index) => index < sessionsCount;
        const loadMoreRows = isNextPageLoading
            ? () => {
                  return this.lastPromise;
              }
            : () => {
                  this.store.loadNextPage();
                  this.lastPromise = new Promise<boolean>(resolve => (this.promiseResolver = resolve));
                  return this.lastPromise;
              };

        const mainColumns = (width: number) => {
            const tail = [
                <Column
                    key="expandRow"
                    width={50}
                    dataKey=""
                    label=""
                    className={'tableColumn'}
                    cellRenderer={this.expandRowRenderer}
                />,
                <Column
                    key="packageName"
                    width={900}
                    dataKey="packageName"
                    label="Package"
                    className={'tableColumn'}
                    style={{ whiteSpace: 'pre' }}
                    headerRenderer={headerRenderer}
                />,
                <Column
                    key="applicationName"
                    width={600}
                    dataKey="applicationName"
                    label="Application"
                    className={'tableColumn'}
                    headerRenderer={headerRenderer}
                />,
                <Column
                    key="user"
                    width={400}
                    dataKey="user"
                    label="User"
                    className={'tableColumn'}
                    headerRenderer={headerRenderer}
                />,
                <Column
                    key="created"
                    width={500}
                    dataKey="created"
                    label="Create Date"
                    className={'tableColumn'}
                    headerRenderer={headerRenderer}
                    cellRenderer={(props: TableCellProps) => {
                        return new Date(props.cellData).toLocaleString();
                    }}
                />,
                <Column
                    key="runtimeSessionId"
                    width={600}
                    dataKey="runtimeSessionId"
                    label="Runtime Session Id"
                    className={'tableColumn'}
                />,
                <Column
                    key="error"
                    width={width - 900}
                    dataKey="error"
                    label="Error"
                    className={'tableColumn'}
                    style={{ cursor: 'pointer' }}
                    cellRenderer={this.errorRenderer}
                />
            ];

            return tail;
        };

        const selectRow = async (params: RowMouseEventHandlerParams) => {
            if (params.index === this.store.selectedSessionIndex) {
                this.store.setselectedSessionIndex(undefined);
            } else {
                if (!this.store.loadedSessionPayloads || !this.store.loadedSessionPayloads[params.rowData.id]) {
                    await this.store.loadSessionPayloads(params.rowData.id);
                }

                this.store.setselectedSessionIndex(params.index);
            }
        };

        const showJsonModal = (jsonString: string, title: string) => {
            const jsonObj = JSON.parse(jsonString);
            this.store.setDisplayedJson(jsonObj);
            this.store.setShowJsonModal(true);
            this.store.setJsonModalTitle(title);
        };

        const onModalClose = () => {
            this.store.setShowJsonModal(false);
        };

        const headerRenderer = (props: TableHeaderProps) => {
            return (
                <Observer>
                    {() => (
                        <>
                            <span>{props.label}</span>
                            {this.store.currentSortedField === props.dataKey && (
                                <SortIndicator sortDirection={this.store.sortDirection} />
                            )}
                        </>
                    )}
                </Observer>
            );
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rowRenderer = (props: any) => {
            const { index, style, className, key, rowData } = props;
            const columns = [
                {
                    title: 'Id',
                    dataIndex: 'id',
                    key: 'id'
                },
                {
                    title: 'Date/Time',
                    dataIndex: 'created',
                    key: 'created',
                    render: (date: string) => {
                        return (
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {new Date(date).toLocaleString()}
                            </div>
                        );
                    }
                },
                {
                    title: 'Inputs',
                    key: 'inputs',
                    render: (p: ApplicationSessionPayload) => {
                        return (
                            <div>
                                <a
                                    onClick={() => showJsonModal(p.data.inputs, 'Inputs')}
                                    style={{
                                        maxWidth: 250,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        display: 'inline-block',
                                        verticalAlign: 'middle'
                                    }}
                                >
                                    {p.data.inputs}
                                </a>
                            </div>
                        );
                    }
                },
                {
                    title: 'Settings',
                    key: 'settings',
                    render: (p: ApplicationSessionPayload) => {
                        return (
                            <div>
                                <a
                                    onClick={() => showJsonModal(p.data.settings, 'Settings')}
                                    style={{
                                        maxWidth: 250,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        display: 'inline-block',
                                        verticalAlign: 'middle'
                                    }}
                                >
                                    {p.data.settings}
                                </a>
                            </div>
                        );
                    }
                }
            ];
            if (index === this.store.selectedSessionIndex) {
                return (
                    <div
                        style={{ ...style, display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}
                        className={className}
                        key={key}
                    >
                        {defaultTableRowRenderer({
                            ...props,
                            style: { width: style.width, height: COLUMN_HEIGHT, backgroundColor: '#F5F6F8' }
                        })}
                        <AntdTable
                            className="ant-sm-header session-data-table alpha-portal-table nested"
                            style={{ marginLeft: '64px', backgroundColor: 'white', width: '96%' }}
                            dataSource={this.store.loadedSessionPayloads[rowData.id]}
                            columns={columns}
                            rowKey={p => p.id!}
                            pagination={false}
                        />
                    </div>
                );
            }
            return defaultTableRowRenderer(props);
        };

        return (
            <Layout className="screen-size" style={{ ...{ height: '100%', background: 'white', overflow: 'hidden' } }}>
                <Modal
                    className="alpha-portal-dialog two-columns scrollable"
                    visible={this.store.showJsonModal}
                    title={this.store.jsonModalTitle}
                    centered
                    onCancel={onModalClose}
                    footer={[
                        <Button key="back" onClick={onModalClose}>
                            Close
                        </Button>
                    ]}
                >
                    <ReactJson displayDataTypes={false} src={this.store!.displayedJson} />
                </Modal>
                <LayoutHeader
                    subtitle={Utils.getSubtitle(this.store!.currentProject)}
                    title="Sessions"
                    helpMessage="Monitor and manage active user sessions within the project"
                    helpMessageTooltipPlacement="leftBottom"
                />
                <Layout>
                    <Content>
                        <SessionsFilter sessionsStore={this.store} />
                        {!this.store.isLoading ? (
                            <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={rowCount}>
                                {({ onRowsRendered }) => (
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <Observer>
                                                {() => (
                                                    <Table
                                                        headerHeight={44}
                                                        headerClassName={'tableHeaderColumn'}
                                                        height={height - 100}
                                                        overscanRowCount={2}
                                                        rowClassName={'tableRow'}
                                                        onRowsRendered={onRowsRendered}
                                                        rowRenderer={rowRenderer}
                                                        onRowClick={selectRow}
                                                        rowHeight={rowHeight}
                                                        rowGetter={this.rowGetter}
                                                        rowCount={rowCount}
                                                        sortBy={this.store.currentSortedField}
                                                        sortDirection={this.store.sortDirection}
                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        sort={this.store.sortColumns as any}
                                                        width={width}
                                                        ref={this.tableRef}
                                                    >
                                                        {mainColumns(width)}
                                                    </Table>
                                                )}
                                            </Observer>
                                        )}
                                    </AutoSizer>
                                )}
                            </InfiniteLoader>
                        ) : (
                            <LoadingIndicator />
                        )}
                    </Content>
                </Layout>
            </Layout>
        );
    }

    private rowGetter = ({ index }: Index) => {
        const { sessions } = this.store;
        return sessions[index % sessions.length] || {};
    };

    private expandRowRenderer = (props: TableCellProps) => {
        return props.rowIndex === this.store.selectedSessionIndex ? (
            <i className="alpha-icon xs expand-row arrow-expand expanded" />
        ) : (
            <i className="alpha-icon xs expand-row arrow-expand" />
        );
    };

    private errorRenderer(props: TableCellProps) {
        let text = '';
        if (props.cellData) {
            text = this.cutText(props.cellData);
        }
        return (
            <Popover
                content={<div className="sesssions-error-popup">{props.cellData}</div>}
                title="error"
                autoAdjustOverflow
                placement="right"
            >
                <span>{text}</span>
            </Popover>
        );
    }

    private getRowDetailsHeightForSession(sessionId: string) {
        if (this.store.loadedSessionPayloads[sessionId] && this.store.loadedSessionPayloads[sessionId].length > 0) {
            return COLUMN_HEIGHT * (this.store.loadedSessionPayloads[sessionId].length + 1) + 10 + COLUMN_HEIGHT;
        } else {
            return COLUMN_HEIGHT * 4.5;
        }
    }
}

export default observer(SessionsGrid);
