import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Select, Dropdown, Menu, Layout } from 'antd';
import { AdministrationStores } from '../stores';
import { STORE_GLOBAL_ADMINISTRATION } from '../constants';
import { ProcessType } from '../../../modules/common/types';
import LayoutHeader from '../../../components/LayoutHeader';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
const Option = Select.Option;
const { Sider } = Layout;

type Props = AdministrationStores;

export class GlobalIndexer extends React.Component<Props, object> {
    processMenuRenderer = () => {
        const store = this.props.GlobalAdministration!;
        return (
            <Menu>
                <Menu.Item
                    key="1"
                    onClick={() => {
                        store.processByStatus(ProcessType.Reparse);
                    }}
                >
                    Re-parse
                </Menu.Item>
                <Menu.Item
                    key="2"
                    onClick={() => {
                        store.processByStatus(ProcessType.Reindex);
                    }}
                >
                    Re-index
                </Menu.Item>
            </Menu>
        );
    };

    render() {
        const store = this.props.GlobalAdministration!;
        const reindexControls = !store.isLoading ? (
            <Layout className="screen-size" style={{ ...{ height: '100%', background: '#fff' } }}>
                <LayoutHeader title="Indexing" helpMessage=" " />
                <Layout>
                    <Sider width="250" style={{ background: '#fff' }}>
                        <Dropdown overlay={() => this.processMenuRenderer()} trigger={['click']}>
                            <Button type="primary">Process</Button>
                        </Dropdown>
                        <Select
                            style={{ marginLeft: '20px', width: '110px' }}
                            defaultValue="All"
                            onChange={(v: string) => store.handleOptionsChange(v)}
                        >
                            <Option value="All">All</Option>
                            <Option value="Ready">Ready</Option>
                            <Option value="Importing">Importing</Option>
                            <Option value="Broken">Broken</Option>
                        </Select>
                    </Sider>
                </Layout>
            </Layout>
        ) : (
            <LoadingIndicator />
        );

        return reindexControls;
    }
}

export default inject(STORE_GLOBAL_ADMINISTRATION)(observer(GlobalIndexer));
