import { ConnectionDescription } from '../../iota_applications/types';

export default class ConnectionsDefinitionCreateModel {
    constructor(
        public name: string,
        public applicationId: string,
        public workflowId: string,
        public projectId: string,
        public settings: string,
        public extension: string,
        public connections: ConnectionDescription[]
    ) {}
}
