/* eslint-disable @typescript-eslint/member-ordering */
import { observable, computed } from 'mobx';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import { RuleBaseResult, PipelineStepResult, RuleError } from '../types';
import RuleType from '../types/RuleType';

export enum RuleStatus {
    Valid = 'Valid',
    Broken = 'Broken'
}

export default abstract class RuleBase<T, TRuleTypes, TStepType, R extends RuleBaseResult<TRuleTypes, TStepType>> {
    @observable
    name: string;

    @observable
    tag: string;

    @observable
    tagId: string | null;

    @observable
    pipeline: PipelineStep<TStepType>[] = [];

    @observable
    groupId: string | null;

    @observable
    priority: number;

    @observable
    description: string | undefined;

    @observable
    state: 'Enabled' | 'Disabled' | undefined;

    @observable
    overridePriority: boolean;

    @observable
    status: RuleStatus;

    @observable
    errors?: RuleError[];

    constructor(
        public id: string | null,
        public projectId: string | null,
        name: string,
        tagId: string | null,
        tag: string,
        public readonly ruleType: RuleType,
        pipeline: PipelineStep<TStepType>[] = [],
        groupId: string | null,
        public updateDate?: Date,
        priority: number = 1,
        description?: string,
        state?: 'Enabled' | 'Disabled',
        status: RuleStatus = RuleStatus.Valid,
        overridePriority: boolean = false,
        errors?: RuleError[]
    ) {
        this.name = name;
        this.tagId = tagId;
        this.tag = tag;
        this.pipeline = pipeline;
        this.groupId = groupId;
        this.priority = priority;
        this.description = description;
        this.state = state;
        this.status = status;
        this.overridePriority = overridePriority;
        this.errors = errors;
    }

    abstract clone(): T;
    abstract validateState(): string[];
    abstract toJson(): R;

    clonePipeline(): PipelineStep<TStepType>[] {
        return this.pipeline.map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            p => new PipelineStep(p.name, p.type, (p.parameters as any).toJSON(), p.stepId, undefined, p.isDisabled)
        );
    }

    validatePipeline(): string[] {
        return this.pipeline.map(x => x.validate()).reduce((p, c) => [...p!, ...c!], []);
    }

    pipelineToJSON(): PipelineStepResult<TStepType>[] {
        return this.pipeline.map(p => ({
            stepId: p.stepId,
            name: p.name,
            type: <TStepType>(<unknown>p.type),
            isDisabled: p.isDisabled,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            parameters: (p.parameters as any).toJSON()
        }));
    }

    @computed
    get errorMessages() {
        if (!this.errors) {
            return [];
        }

        return this.errors
            .sort((a, b) => (a.meta ? 1 : 0) - (b.meta ? 1 : 0))
            .reduce<string[]>((acc, { message, meta }) => {
                if (!meta) {
                    acc.push(message);
                    return acc;
                }

                if ('stepId' in meta) {
                    const step = this.pipeline.find(p => p.stepId === meta.stepId);

                    if (step) {
                        acc.push(`${step.name}: ${message}`);
                    }
                }

                return acc;
            }, []);
    }
}
