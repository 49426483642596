import * as React from 'react';
import { Upload, message, Select, Form, Collapse, Checkbox } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { inject, observer } from 'mobx-react';
import { UploadStores } from '../stores';
import { STORE_PROJECT_UPLOAD } from '../constants';
import { reaction, Lambda } from 'mobx';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';
import { STORE_UPLOADED_PACKAGES } from '../../project_management/constants';
import { ProjectStores } from '../../project_management/stores';
import { FormInstance } from 'antd/lib/form';
import { AlphaConfig } from '../../administration/models/AlphaConfig';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
    importFeatureFlags,
    setFormFeatureFlags,
    transformFeatureFlags,
    setFormFeatureFlagsDefaults
} from '../../common/FeatureFlagsHelpers';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { FeatureFlagsTreeRenderer } from '../../common/components';

const Dragger = Upload.Dragger;
const { Option } = Select;
const FormItem = Form.Item;

type UploadDocumentsProps = UploadStores &
    ProjectStores & {
        alphaConfigs: AlphaConfig[];
        form: FormInstance;
    };

type State = {
    fileList: UploadFile[];
    featureFlagFileList: UploadFile[];
    overrideFeatureFlags: boolean;
    overrideImageFeatureFlags: boolean;
    overrideTextFeatureFlags: boolean;
};

class UploadDocuments extends React.Component<UploadDocumentsProps, State> {
    private action: string;
    private readonly disposer: Lambda;
    private form: FormInstance;
    constructor(props: UploadDocumentsProps) {
        super(props);
        this.init();
        this.setProjectFeatureFlags = this.setProjectFeatureFlags.bind(this);

        if (this.props.uploadedPackagesUI) {
            this.props.projectUploadUI!.setHeaders();
            const r1 = reaction(
                () => this.props.projectUploadUI!.currentProject,
                () => {
                    this.init();
                }
            );

            const r2 = reaction(
                () => this.props.uploadedPackagesUI!.uploadDocsDialogVisible,
                p => {
                    if (!p) {
                        this.setState({ fileList: [], featureFlagFileList: [] });
                        this.props.projectUploadUI!.setTags([]);
                        this.form.setFields([
                            { name: 'tags', value: [] },
                            { name: 'isProtected', value: false }
                        ]);
                        setFormFeatureFlagsDefaults(this.form);
                    } else {
                        this.setProjectFeatureFlags();
                    }
                }
            );

            this.disposer = () => {
                r1();
                r2();
            };
        } else {
            this.disposer = () => {
                console.log('uploadedPackagesUI is undefined');
            };
        }

        this.state = {
            fileList: [],
            featureFlagFileList: [],
            overrideFeatureFlags: false,
            overrideImageFeatureFlags: false,
            overrideTextFeatureFlags: false
        };
    }

    init = () => {
        const { currentProject: project } = this.props.projectUploadUI!;
        if (project) {
            this.action = `${process.env.REACT_APP_MANAGE_URL}projects/${project.id}/portal/upload`;
            this.props.projectUploadUI!.filesList = [];
        }
        this.setProjectFeatureFlags();
    };

    setProjectFeatureFlags() {
        const { currentProject: project } = this.props.projectUploadUI!;

        if (project == null) {
            return;
        }

        const { overrideFeatureFlags, overrideImageFeatureFlags, overrideTextFeatureFlags } = setFormFeatureFlags(
            this.props.form,
            project.storedFeatureFlags
        );

        setTimeout(() => {
            this.setState({
                overrideFeatureFlags,
                overrideImageFeatureFlags,
                overrideTextFeatureFlags
            });
        }, 0);
    }

    componentWillUnmount = () => {
        this.disposer();
    };

    render() {
        this.form = this.props.form!;
        const { tags, currentTags } = this.props.projectUploadUI!;
        const store = this.props.projectUploadUI!;

        const onChange = (info: UploadChangeParam) => {
            let fileList = info.fileList && [...info.fileList];
            this.setState({ fileList });
            const status = info.file.status;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }

            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            } else if (status === 'removed') {
                return;
            }

            store.uploadProgress(info.file);
        };

        const featureFlagsPanelExtra = (checked: boolean, onCheckboxChange: (e: CheckboxChangeEvent) => void) => {
            return (
                <div
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <label>
                        Override flags:
                        <Checkbox style={{ marginLeft: 12 }} checked={checked} onChange={onCheckboxChange} />
                    </label>
                </div>
            );
        };

        const beforeDocumentUpload = () => {
            if (this.props.uploadedPackagesUI) {
                this.props.uploadedPackagesUI.setOrderBy(undefined);

                if (this.props.uploadedPackagesUI.currentPage !== 0) {
                    this.props.uploadedPackagesUI.setCurrentPage(1);
                }
            }
        };

        const beforeFeatureFlagsUpload = (file: RcFile) => {
            importFeatureFlags(file, this.props.form, featureFlags => {
                this.setState({
                    overrideFeatureFlags: featureFlags.overrideFeatureFlags,
                    overrideImageFeatureFlags: featureFlags.overrideImageFeatureFlags,
                    overrideTextFeatureFlags: featureFlags.overrideTextFeatureFlags
                });
            });

            return false;
        };

        const prepareData = () => {
            return {
                tags: currentTags.join(';'),
                featureFlags: transformFeatureFlags(this.props.form, {
                    overrideFeatureFlags: this.state.overrideFeatureFlags,
                    overrideTextFeatureFlags: this.state.overrideTextFeatureFlags,
                    overrideImageFeatureFlags: this.state.overrideImageFeatureFlags
                }),
                isProtected: this.props.form?.getFieldValue('isProtected') ?? false
            };
        };

        return (
            <div data-id="upload-documents-form">
                <Form
                    form={this.form}
                    layout="vertical"
                    style={{ paddingBottom: 20 }}
                    initialValues={store.currentTags || []}
                >
                    <FormItem
                        name="tags"
                        initialValue={currentTags}
                        rules={[{ required: false, message: 'Please select tags!' }]}
                    >
                        <Select
                            placeholder="Tags..."
                            data-id="upload-documents-tag-select"
                            mode="tags"
                            onChange={this.props.projectUploadUI!.setTags}
                        >
                            {tags.map((k, i) => (
                                <Option data-id={`upload-documents-tag-select-${k}`} key={`kw-${i}`} value={k}>
                                    {k}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <div className="feature-flag-import-export-container">
                        <Upload
                            name="file"
                            maxCount={1}
                            fileList={this.state.featureFlagFileList}
                            beforeUpload={beforeFeatureFlagsUpload}
                            onRemove={this.setProjectFeatureFlags}
                            onChange={info => this.setState({ featureFlagFileList: info.fileList })}
                        >
                            <span className="feature-flag-import-export-button">
                                <i className="alpha-icon xs arrow-down" />
                                <span>Import feature flags</span>
                            </span>
                        </Upload>
                    </div>
                    <Collapse>
                        <Collapse.Panel
                            key="1"
                            header="General feature flags"
                            forceRender
                            extra={featureFlagsPanelExtra(this.state.overrideFeatureFlags, e => {
                                this.setState({ overrideFeatureFlags: e.target.checked });
                            })}
                        >
                            <FeatureFlagsTreeRenderer disableInputs={!this.state.overrideFeatureFlags} />
                        </Collapse.Panel>
                        <Collapse.Panel
                            key="2"
                            header="Text feature flags"
                            forceRender
                            extra={featureFlagsPanelExtra(this.state.overrideTextFeatureFlags, e => {
                                this.setState({ overrideTextFeatureFlags: e.target.checked });
                            })}
                        >
                            <FeatureFlagsTreeRenderer
                                disableInputs={!this.state.overrideTextFeatureFlags}
                                featureFlagKeyPrefix="TEXT::"
                            />
                        </Collapse.Panel>
                        <Collapse.Panel
                            key="3"
                            header="Images feature flags"
                            forceRender
                            extra={featureFlagsPanelExtra(this.state.overrideImageFeatureFlags, e => {
                                this.setState({ overrideImageFeatureFlags: e.target.checked });
                            })}
                        >
                            <FeatureFlagsTreeRenderer
                                disableInputs={!this.state.overrideImageFeatureFlags}
                                featureFlagKeyPrefix="IMAGE::"
                            />
                        </Collapse.Panel>
                    </Collapse>
                    <HasPermission
                        entityId={store.currentProject?.id}
                        permissionClaim={AppPermissions.CanAccessAdministration}
                    >
                        <FormItem
                            name="isProtected"
                            initialValue={false}
                            valuePropName="checked"
                            style={{ paddingTop: 20, marginBottom: 0 }}
                        >
                            <Checkbox>Protected</Checkbox>
                        </FormItem>
                    </HasPermission>
                </Form>
                <Dragger
                    data-testid="upload-documents-fileupload"
                    data-id="upload-documents-fileupload"
                    className="alpha-portal-upload-dragger"
                    name="file"
                    onChange={onChange}
                    multiple
                    data={() => prepareData()}
                    style={{ height: '30%' }}
                    action={this.action}
                    fileList={this.state.fileList}
                    beforeUpload={beforeDocumentUpload}
                    headers={this.props.projectUploadUI!.fileUploadHeaders}
                >
                    <p className="ant-upload-hint">
                        <i className="alpha-icon lg upload-icon" />
                        Drag and drop or browse file to upload...
                    </p>
                </Dragger>
            </div>
        );
    }
}

export default inject(STORE_PROJECT_UPLOAD, STORE_UPLOADED_PACKAGES)(observer(UploadDocuments));
