import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from 'antd';
import ReactJson from 'react-json-view';

type Props = {
    modelName: string | undefined;
    manifest: string | undefined;
    isVisible: boolean;
    handleClose: () => void;
};

const PreviewManifestDialog: React.FC<Props> = ({ modelName, manifest, isVisible, handleClose }) => {
    if (manifest == null) {
        return null;
    }

    const parseManifest = () => {
        try {
            return JSON.parse(manifest);
        } catch {
            return { error: 'Invalid JSON' };
        }
    };

    return (
        <Modal
            title={`Manifest of ${modelName}`}
            visible={isVisible}
            className="alpha-portal-dialog two-columns"
            destroyOnClose
            width={800}
            centered
            onCancel={handleClose}
            footer={null}
        >
            <ReactJson src={parseManifest()} displayDataTypes={false} name={false} />
        </Modal>
    );
};

export default observer(PreviewManifestDialog);
