import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { ChatbotStore } from '../../stores';
import ChatbotDialog from './ChatbotDialog';
import ChatbotButton from './ChatbotButton';

type Props = {
    store: ChatbotStore;
};

const Chatbot: React.FC<Props> = ({ store }) => {
    React.useEffect(() => {
        store.getInstructWorkflows();
        return () => store.setDialogVisible(false);
    }, [store]);

    return (
        <>
            <ChatbotDialog store={store} />
            <ChatbotButton store={store} />
        </>
    );
};

export default observer(Chatbot);
