import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { DocumentViewVisualStore } from '../stores';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import PdfViewer from '../components/PdfViewer';
import TextViewer from '../components/TextViewer';
import ExcelViewer from '../components/ExcelViewer';

type Props = {
    store: DocumentViewVisualStore;
    path: string;
};

const DocumentViewer: React.FC<Props> = ({ store, path }) => {
    React.useEffect(() => {
        store.getDocument(path);
    }, [store, path]);

    if (store.isLoading) {
        return (
            <div className="document-viewer-container">
                <LoadingIndicator />
            </div>
        );
    }

    switch (store.fileType) {
        case 'pdf':
            return <PdfViewer store={store} />;
        case 'text':
            return <TextViewer store={store} />;
        case 'excel':
            return <ExcelViewer store={store} />;
        default:
            return null;
    }
};

export default observer(DocumentViewer);
