import { Button, Modal, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ConnectionsDefinitionsVisualStore } from '../stores';
import WrappedApplicationSettingsForm from '../../iota_applications/components/ApplicationSettingsForm';

type Props = {
    store: ConnectionsDefinitionsVisualStore | undefined;
};

const EditConnectionDefinitionDialog: React.FC<Props> = ({ store }) => {
    const [settingsForm] = Form.useForm();

    if (!store) {
        return null;
    }

    const handleCancel = () => {
        store.setConnectionSettingsModalVisible(false);
    };

    return (
        <Modal
            destroyOnClose
            width={600}
            visible={store.connectionSettingsModalVisible}
            title="Edit connection"
            maskClosable={false}
            closable={false}
            className="alpha-portal-dialog"
            centered
            footer={[
                <Button
                    className="light"
                    data-id="iota-edit-connection-dialog-cancel"
                    size="large"
                    key="back"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    form="iota-app-settings-form"
                    data-id="iota-edit-settings-submit"
                    size="large"
                    key="submit"
                    type="primary"
                    htmlType="submit"
                >
                    Save changes
                </Button>
            ]}
        >
            {store!.current ? (
                <WrappedApplicationSettingsForm
                    settingsForm={settingsForm}
                    settingsJson={store!.current!.settings!}
                    settingsValues={store!.current!.settingValues!}
                    back={() => store!.goToList()}
                    id={store!.current!.id}
                    update={(id, settings) => store!.updateSettings(id, settings)}
                />
            ) : (
                <div />
            )}
        </Modal>
    );
};

export default observer(EditConnectionDefinitionDialog);
