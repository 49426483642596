import { Checkbox, Collapse } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FeatureFlagsOverride } from '../FeatureFlagsHelpers';
import { FeatureFlagsTreeRenderer } from '.';

type Props = {
    featureFlagsOverride: FeatureFlagsOverride;
    setFeatureFlagsOverride: React.Dispatch<React.SetStateAction<FeatureFlagsOverride>>;
};

const FeatureFlagsRenderer: React.FC<Props> = ({ featureFlagsOverride, setFeatureFlagsOverride }) => {
    const featureFlagsPanelExtra = (onCheckboxChange: (e: CheckboxChangeEvent) => void, checked: boolean) => {
        return (
            <div onClick={e => e.stopPropagation()}>
                <label>
                    Override flags:
                    <Checkbox style={{ marginLeft: 12 }} checked={checked} onChange={onCheckboxChange} />
                </label>
            </div>
        );
    };

    return (
        <Collapse>
            <Collapse.Panel
                key="1"
                header="General feature flags"
                forceRender
                extra={featureFlagsPanelExtra(e => {
                    setFeatureFlagsOverride(prev => ({ ...prev, overrideFeatureFlags: e.target.checked }));
                }, featureFlagsOverride.overrideFeatureFlags)}
            >
                <FeatureFlagsTreeRenderer disableInputs={!featureFlagsOverride.overrideFeatureFlags} />
            </Collapse.Panel>
            <Collapse.Panel
                key="2"
                header="Text feature flags"
                forceRender
                extra={featureFlagsPanelExtra(e => {
                    setFeatureFlagsOverride(prev => ({ ...prev, overrideTextFeatureFlags: e.target.checked }));
                }, featureFlagsOverride.overrideTextFeatureFlags)}
            >
                <FeatureFlagsTreeRenderer
                    disableInputs={!featureFlagsOverride.overrideTextFeatureFlags}
                    featureFlagKeyPrefix="TEXT::"
                />
            </Collapse.Panel>
            <Collapse.Panel
                key="3"
                header="Images feature flags"
                forceRender
                extra={featureFlagsPanelExtra(e => {
                    setFeatureFlagsOverride(prev => ({ ...prev, overrideImageFeatureFlags: e.target.checked }));
                }, featureFlagsOverride.overrideImageFeatureFlags)}
            >
                <FeatureFlagsTreeRenderer
                    disableInputs={!featureFlagsOverride.overrideImageFeatureFlags}
                    featureFlagKeyPrefix="IMAGE::"
                />
            </Collapse.Panel>
        </Collapse>
    );
};

export default observer(FeatureFlagsRenderer);
