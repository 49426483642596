import { Button, Collapse, Form, FormInstance, Input, List, Modal, Table, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import PipelineStep from '../../../modules/pipeline_base/models/PipelineStep';
import { RuleTypes } from '../models';
import { PipelineVisualStore, RulesPipelineVisualStore } from '../stores';
import { Parameter } from '../types';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
    dataId: string;
};

type PipelineStepModel = {
    stepId: string;
    stepName: string;
};

type ValueModel = {
    ruleId: string;
    stepIds: string[];
};

const ParametersPipelineSelect: <T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string>(
    props: Props<T, TPipelineStepType>
) => React.ReactElement<Props<T, TPipelineStepType>> = ({ param, form, toolTip, step, store, stepKey, dataId }) => {
    const ruleStore = store instanceof RulesPipelineVisualStore ? (store as RulesPipelineVisualStore) : null;
    const [modalVisible, setModalVisible] = React.useState(false);
    const [filteredTagsWithRules, setFilteredTagsWithRules] = React.useState(ruleStore?.tagsWithRules);
    const [selectedRule, setSelectedRule] = React.useState<string | null>(null);
    const [pipelineSteps, setPipelineSteps] = React.useState<PipelineStepModel[]>([]);
    const [selectedPipelineSteps, setSelectedPipelineSteps] = React.useState<string[]>([]);
    const [activeRules, setActiveRules] = React.useState<string[] | undefined>(undefined);

    React.useEffect(() => {
        const stepVal = step.parameters.get('pipeline');
        if (stepVal && ruleStore?.rules) {
            const val: ValueModel = JSON.parse(stepVal);
            setSelectedRule(val.ruleId);
            const rule = ruleStore.rules.find(r => r.id === val.ruleId);
            setPipelineSteps(
                rule?.pipeline.map((s, i) => {
                    return { stepId: s.stepId ?? `${i}`, stepName: s.name };
                }) ?? []
            );
            const allSteps = rule?.pipeline.map(s => s.stepId ?? '') ?? [];
            setSelectedPipelineSteps(val.stepIds?.length ? val.stepIds : allSteps);
            setActiveRules([rule?.tagId ?? '']);
        }
    }, [step.parameters, ruleStore?.rules]);

    const handleSubmit = async () => {
        await store.validateForm(form, step, stepKey, false);
    };

    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    const closeDialog = () => {
        setModalVisible(false);
    };

    const onSubmit = () => {
        if (!selectedRule) {
            return;
        }

        let obj = {};
        const selectedPipeline = selectedPipelineSteps.length === pipelineSteps.length ? [] : selectedPipelineSteps;
        const ruleObj: ValueModel = { ruleId: selectedRule, stepIds: selectedPipeline };
        obj[param.name] = JSON.stringify(ruleObj);
        form.setFieldsValue(obj);
        closeDialog();
    };

    const searchRules = (searchKey: string) => {
        const searchKeyFormatted = searchKey.trim().toLowerCase();
        setFilteredTagsWithRules(
            ruleStore?.tagsWithRules?.filter(
                t =>
                    t.tag.name.toLowerCase().includes(searchKeyFormatted) ||
                    t.rules.some(r => r.name.toLowerCase().includes(searchKeyFormatted))
            )
        );
    };

    const selectRule = (rule: RuleTypes | null) => {
        if (rule?.id === selectedRule || rule?.id === ruleStore?.rule.id) {
            setSelectedRule(null);
            setPipelineSteps([]);
            setSelectedPipelineSteps([]);
        } else if (rule) {
            setSelectedRule(rule.id);
            setSelectedPipelineSteps([]);
            setPipelineSteps(
                rule?.pipeline.map((s, i: number) => {
                    return { stepId: s.stepId ?? `${i}`, stepName: s.name };
                })
            );
        }
    };

    const onPipelineStepSelectChange = (selectedRowKeys: string[]) => {
        setSelectedPipelineSteps(selectedRowKeys);
    };

    const pipelineColumns = [
        {
            title: 'Step',
            dataIndex: 'stepName'
        }
    ];

    return (
        <div>
            <Modal
                visible={modalVisible}
                title="Select pipeline steps"
                className="alpha-portal-dialog two-columns"
                closable={false}
                maskClosable={false}
                onCancel={closeDialog}
                centered
                footer={[
                    <Button
                        data-id="pipeline-select-dialog-cancel"
                        data-testid="pipeline-select-dialog-cancel"
                        key="back"
                        onClick={closeDialog}
                        size="large"
                    >
                        Cancel
                    </Button>,
                    <Button
                        data-id="pipeline-select-dialog-submit"
                        data-testid="pipeline-select-dialog-submit"
                        key="submit"
                        type="primary"
                        disabled={!selectedPipelineSteps || !selectedPipelineSteps.length}
                        onClick={onSubmit}
                        size="large"
                    >
                        Submit
                    </Button>
                ]}
            >
                <Tabs
                    defaultActiveKey="1"
                    className="limited-height-tabs"
                    animated={false}
                    data-id="pipeline-select-dialog-content"
                >
                    <Tabs.TabPane key="1" tab="Rules">
                        <Input.Search
                            className="no-shadow-input"
                            onSearch={searchRules}
                            enterButton
                            placeholder="Search tags and rules"
                            style={{ paddingBottom: 16 }}
                        />
                        <Collapse activeKey={activeRules} onChange={key => setActiveRules([...key])}>
                            {filteredTagsWithRules?.map((t, i: number) => (
                                <Collapse.Panel key={t.tag.id ?? `tag-${i}`} header={t.tag.name}>
                                    <List
                                        className="rule-selection-list"
                                        size="small"
                                        dataSource={t.rules}
                                        renderItem={item => (
                                            <List.Item
                                                className={
                                                    selectedRule != null && selectedRule === item.id
                                                        ? 'selected-item'
                                                        : ''
                                                }
                                                onClick={() => selectRule(item)}
                                                role="selectable-item"
                                            >
                                                {item.name}
                                            </List.Item>
                                        )}
                                    />
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="2" tab="Steps" disabled={selectedRule == null}>
                        <Table
                            pagination={false}
                            columns={pipelineColumns}
                            rowKey={r => r.stepId}
                            dataSource={pipelineSteps}
                            rowSelection={{
                                selectedRowKeys: selectedPipelineSteps,
                                onChange: onPipelineStepSelectChange
                            }}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
            <Form.Item
                label={toolTip}
                name={param.name}
                rules={[
                    {
                        required: !step.isDisabled && param.required,
                        message: 'Please select pipeline steps'
                    }
                ]}
            >
                <Button data-id={dataId} aria-label="Select pipeline" onClick={() => setModalVisible(true)}>
                    Select pipeline
                </Button>
            </Form.Item>
        </div>
    );
};

export default observer(ParametersPipelineSelect);
