import * as React from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react-lite';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import { ConnectionsDefinitionsService } from '../../iota_connections/services';
import PipelineVisualStore from '../stores/PipelineVisualStore';
import { Parameter } from '../types';
import { ConnectionsDefinition } from '../../iota_connections/types';
import { ConnectionDescription } from '../../iota_applications/types';
import { Select } from 'antd';
import { FieldDescription } from '../../iota_applications/types/ConnectionDescription';
import Form, { FormInstance } from 'antd/lib/form';

const Option = Select.Option;

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};
export const ParametersIotaConnectionFieldSelector: <
    T extends PipelineVisualStore<TPipelineStepType>,
    TPipelineStepType extends string
>(
    props: Props<T, TPipelineStepType>
) => React.ReactElement<Props<T, TPipelineStepType>> = ({ param, form, toolTip, step, store, stepKey }) => {
    const [connectionId, setConnectionId] = React.useState<string | undefined>(form.getFieldValue('connection_id'));

    const handleSubmit = async () => {
        await store.validateForm(form, step, stepKey, false);
    };
    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }
    const localState = useLocalStore(
        () => ({
            service: new ConnectionsDefinitionsService(),
            connection: undefined as ConnectionDescription | undefined,
            get fields() {
                if (localState.connection) {
                    return localState.connection.fields;
                }

                return new Array<FieldDescription>();
            },
            packages: new Array<ConnectionsDefinition>(),
            setConnection(cid: string | undefined) {
                for (const p of localState.packages) {
                    var c = p.connections!.find(x => x.id === cid);
                    if (c) {
                        runInAction(() => {
                            localState.connection = c;
                        });
                    }
                }
            },
            async load() {
                var res = await localState.service.getAll();
                runInAction(() => {
                    const connId = form.getFieldValue('connection_id');
                    setConnectionId(connId);
                    localState.packages = res;
                    localState.setConnection(connId);
                });
            }
        }),
        { connection: connectionId }
    );

    React.useEffect(() => {
        localState.load();
    }, [localState]);

    React.useEffect(() => {
        localState.setConnection(connectionId);
    }, [connectionId, form, localState]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shouldUpdate = (prevValues: any, currentValues: any) => {
        const shouldBeUpdated = prevValues.connection_id !== currentValues.connection_id;
        setConnectionId(currentValues.connection_id);
        return shouldBeUpdated;
    };

    return (
        <div>
            <Form.Item
                label={toolTip}
                name={param.name}
                rules={[
                    {
                        required: !step.isDisabled && param.required,
                        message: 'Please select a field'
                    }
                ]}
                initialValue={param.defaultValue}
                shouldUpdate={shouldUpdate}
            >
                <Select style={{ width: '100%' }}>
                    {localState.fields.map(x => (
                        <Option key={x.name} value={x.name}>
                            {x.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    );
};

export default observer(ParametersIotaConnectionFieldSelector);
