import * as React from 'react';
import { Modal, Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { RulesStores } from '../stores';
import { NewGroupForm } from './NewGroupForm';

type Props = RulesStores;

export const NewGroupModal: React.FC<Props> = ({ TagsGroupUI: store, RulesListUI: rulesListStore }) => {
    const handleCancel = () => {
        store!.setAddGroupDialogVisible(false);
    };

    return (
        <Modal
            title={store!.editableTagsGroup ? 'Edit group' : 'New group'}
            visible={store!.addGroupDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            destroyOnClose={false}
            centered
            closable={false}
            className="alpha-portal-dialog"
            footer={[
                <Button
                    data-id="rule-group-dialog-cancel"
                    size="large"
                    className="light"
                    key="back"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="rule-group-dialog-submit"
                    size="large"
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    form="new-rule-group-form"
                >
                    {store!.editableTagsGroup ? 'Save group' : 'Create group'}
                </Button>
            ]}
        >
            <NewGroupForm store={store} rulesListStore={rulesListStore} />
        </Modal>
    );
};

export default observer(NewGroupModal);
