import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import { RuleTypes } from '../models';

interface Props {
    rule: RuleTypes;
}

const RuleErrorIcon: React.FC<Props> = ({ rule }: Props) => {
    const getTooltipTitle = () => {
        if (!rule.errorMessages.length) {
            return null;
        }

        return (
            <div>
                <ul style={{ marginBottom: 0, paddingInlineStart: 20 }}>
                    {rule.errorMessages.map((message, index) => (
                        <li key={index}>{message}</li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <Tooltip title={getTooltipTitle()} placement="topLeft" overlayStyle={{ maxWidth: 800 }}>
            <i
                className="alpha-icon md rule-problem-icon"
                style={{
                    display: rule.status === 'Broken' ? 'visible' : 'none',
                    verticalAlign: 'middle'
                }}
            />
        </Tooltip>
    );
};

export default observer(RuleErrorIcon);
