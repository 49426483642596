import * as React from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Input, InputNumber, Checkbox, Form, Row, Col, FormInstance, Tooltip } from 'antd';
import { STORE_RULE_EDIT } from '../constants';
import { ElasticSearchFuzzyQueryRule } from '../models';
import { RuleEditVisualStore } from '../stores';
import { RuleEditGeneralSettings } from '.';
import { BlockTypesDropdown } from '../../common/components/BlockTypesDropdown';
import RuleType from '../types/RuleType';

const fuzzinessValidationMessage = 'Invalid format! Please use "0", "1", "AUTO", or "AUTO:1,2" etc.';

type Props = {
    store: RuleEditVisualStore;
    selectType?: (type: RuleType) => void;
    form: FormInstance;
};

const RuleEditElasticSearchFuzzyQueryForm: React.FC<Props> = ({ store, selectType, form }) => {
    const rule = store.editableRule as ElasticSearchFuzzyQueryRule;

    const getInitialValues = React.useCallback(() => {
        return {
            name: rule.name,
            tag: rule.tag,
            value: rule.value,
            fuzziness: rule.fuzziness,
            prefixLength: rule.prefixLength,
            transpositions: rule.transpositions,
            priority: rule.priority,
            description: rule.description,
            overridePriority: rule.overridePriority,
            excludedBlockTypes: rule.excludedBlockTypes
        };
    }, [rule]);

    React.useEffect(() => {
        form.setFieldsValue(getInitialValues());
    }, [form, getInitialValues]);

    const getFuzzinessTooltipTitle = () => (
        <div>
            <p>Examples:</p>
            <ul>
                <li>
                    <strong>0</strong>: Exact match
                </li>
                <li>
                    <strong>1</strong>: Allow 1 edit
                </li>
                <li>
                    <strong>AUTO</strong>: Automatic edits
                </li>
                <li>
                    <strong>AUTO:1,2</strong>: Automatic with specific edit distances
                </li>
            </ul>
        </div>
    );

    const validateFuzzyParameter = (_: unknown, value: string) => {
        if (!value) {
            return Promise.resolve();
        }

        const fuzzyRegex = /^(AUTO|[0-9]|[1-9][0-9]|AUTO:\d{1,2},\d{1,2})$/;

        if (!fuzzyRegex.test(value)) {
            return Promise.reject(new Error(fuzzinessValidationMessage));
        }

        if (value.startsWith('AUTO:')) {
            const autoParts = value.split(':')[1];
            const parts = autoParts.split(',');

            if (parts.length !== 2 || parts.some(part => !/^\d{1,2}$/.test(part))) {
                return Promise.reject(new Error(fuzzinessValidationMessage));
            }
        }

        return Promise.resolve();
    };

    const trimValue = () => {
        const value = form.getFieldValue('value');

        if (value) {
            form.setFieldsValue({ value: value.trim() });
        }
    };

    return (
        <>
            {store.error.map((e, i) => (
                <h4 key={e + i} style={{ color: 'red' }}>
                    {e}
                </h4>
            ))}
            <Form form={form} layout="vertical" className="rule-edit-form" data-id="rule-edit-form">
                <RuleEditGeneralSettings store={store} selectType={selectType} form={form} />
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Value"
                            name="value"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input value!'
                                }
                            ]}
                            data-id="rule-value-input"
                        >
                            <Input onBlur={trimValue} />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            name="fuzziness"
                            data-id="rule-fuzziness-input"
                            rules={[{ validator: validateFuzzyParameter }]}
                            label={
                                <span>
                                    <span>Fuzziness</span>
                                    <Tooltip title={getFuzzinessTooltipTitle()}>
                                        <i
                                            className="alpha-icon md question-icon"
                                            style={{ verticalAlign: 'middle' }}
                                        />
                                    </Tooltip>
                                </span>
                            }
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={12}>
                        <Form.Item label="Prefix length" name="prefixLength" data-id="rule-prefix-length-input">
                            <InputNumber max={100} min={0} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <BlockTypesDropdown mode="multiple" />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={null}
                            name="transpositions"
                            valuePropName="checked"
                            data-id="rule-transpositions-checkbox"
                        >
                            <Checkbox>Transpositions</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default inject(STORE_RULE_EDIT)(observer(RuleEditElasticSearchFuzzyQueryForm));
