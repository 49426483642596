import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { RuleEditVisualStore } from '../stores';

type Props = {
    store: RuleEditVisualStore;
    onSaveCallback: () => void;
};

const RuleSaveAsDialog: React.FC<Props> = ({ store, onSaveCallback }) => {
    const [form] = Form.useForm();
    const [saveAsName, setSaveAsName] = React.useState('');
    const initialName = React.useRef<string | undefined>(undefined);

    React.useEffect(() => {
        if (store.editableRule) {
            setSaveAsName(store.editableRule.name);

            if (initialName.current === undefined) {
                initialName.current = store.editableRule.name;
            }
        }
    }, [store.editableRule]);

    const closeSaveAsDialog = () => {
        store.setSaveAsDialogVisible(false);
        setSaveAsName('');
    };

    const handleFormFinish = () => {
        form.validateFields()
            .then(async () => {
                await store.submitPipelineForms();
                if (!store.isValidationFailed()) {
                    store.saveRuleAs(form.getFieldValue('name'));
                    onSaveCallback();
                    store.setSaveAsDialogVisible(false);
                }
            })
            .catch(() => {
                console.log('Fields are invalid');
            });
    };

    return (
        <Modal
            title="Save rule as..."
            className="alpha-portal-dialog"
            closable={false}
            maskClosable={false}
            visible={store.saveAsDialogVisible}
            onCancel={closeSaveAsDialog}
            centered
            footer={[
                <Button
                    className="light"
                    data-id="save-rule-as-dialog-cancel"
                    size="large"
                    key="back"
                    onClick={closeSaveAsDialog}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="save-rule-as-dialog-submit"
                    size="large"
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    form="rule-save-as-form"
                >
                    Save
                </Button>
            ]}
        >
            <Form id="rule-save-as-form" form={form} onFinish={handleFormFinish} layout="vertical">
                <Form.Item
                    label="Name"
                    name="name"
                    initialValue={saveAsName}
                    rules={[
                        { required: true, whitespace: true, message: 'Input new name, please' },
                        () => ({
                            validator(_, value) {
                                if (!value || (value !== initialName.current && !store.ruleExists(value))) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('You must choose different name'));
                            }
                        })
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(RuleSaveAsDialog);
