import React from 'react';
import { Spin } from 'antd';

export const LoadingIndicator = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <Spin style={{ flex: '1' }} size="large" />
        </div>
    );
};
