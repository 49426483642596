import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ApplicationDefinitionConditionalStores } from '../stores';
import {
    STORE_APPLICATION_DEFINITION_CONDITIONAL_EDIT,
    STORE_APPLICATION_DEFINITION_CONDITIONAL_BINDING_PREVIEW
} from '../stores/constants';
import { STORE_PROJECT_APPLICATION_DEFINITION_EDIT } from '../../iota_applications/stores/constants';
import { IotaAppStores } from '../../iota_applications/stores';
import { ApplicationDefinitionConditionalEditor } from '../components';

const ApplicationDefinitionConditionalEditPage: React.FC<ApplicationDefinitionConditionalStores & IotaAppStores> = ({
    applicationDefinitionConditionalEditUI: store,
    applicationDefinitionConditionalBindingPreviewUI: previewStore,
    projectApplicationDefinitionEditUI: applicationDefinitionEditStore
}) => {
    if (!store || !previewStore || !applicationDefinitionEditStore) {
        return null;
    }

    return (
        <ApplicationDefinitionConditionalEditor
            store={store}
            previewStore={previewStore}
            applicationDefinitionEditStore={applicationDefinitionEditStore}
        />
    );
};

export default inject(
    STORE_APPLICATION_DEFINITION_CONDITIONAL_EDIT,
    STORE_APPLICATION_DEFINITION_CONDITIONAL_BINDING_PREVIEW,
    STORE_PROJECT_APPLICATION_DEFINITION_EDIT
)(observer(ApplicationDefinitionConditionalEditPage));
