import { RootStores } from '../../common/stores';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import { STORE_REFERENCE_DATA, STORE_REFERENCE_DATA_USAGE } from '../constant';
import ReferenceDataService from '../services/ReferenceDataService';
import ReferenceDataVisualStore from './ReferenceDataVisualStore';
import ReferenceDataUsageStore from './ReferenceDataUsageStore';
export { default as ReferenceDataVisualStore } from './ReferenceDataVisualStore';
export { default as ReferenceDataUsageStore } from './ReferenceDataUsageStore';

export function injectReferenceDataStores(obj: RootStores) {
    const service = new ReferenceDataService();
    obj[STORE_REFERENCE_DATA] = new ReferenceDataVisualStore(service, obj[STORE_PROJECTS_ROOT_UI]);
    obj[STORE_REFERENCE_DATA_USAGE] = new ReferenceDataUsageStore(
        service,
        obj[STORE_REFERENCE_DATA],
        obj[STORE_PROJECTS_ROOT_UI]
    );
}

export type IotaReferenceDataStores = {
    [STORE_REFERENCE_DATA]?: ReferenceDataVisualStore;
    [STORE_REFERENCE_DATA_USAGE]?: ReferenceDataUsageStore;
};
