import * as React from 'react';
import { Input, Select, InputNumber, Form, Row, Col, FormInstance } from 'antd';
import { inject } from 'mobx-react';
import RuleEditVisualStore from '../stores/RuleEditVisualStore';
import { ElasticSearchMatchRule } from '../models';
import { STORE_RULE_EDIT } from '../constants';
import { SelectWhenScroll } from '../../common/components/SelectWhenScroll';
import RuleEditGeneralSettings from './RuleEditGeneralSettings';
import RuleType from '../types/RuleType';
import { observer } from 'mobx-react-lite';
import { BlockTypesDropdown } from '../../common/components/BlockTypesDropdown';

const Option = Select.Option;
type Props = {
    store: RuleEditVisualStore;
    selectType?: (type: RuleType) => void;
    form: FormInstance;
};

export const RuleEditElasticSearchMatchForm: React.FC<Props> = ({ store, selectType, form }) => {
    const rule = store.editableRule as ElasticSearchMatchRule;
    React.useEffect(() => {
        form.setFieldsValue(getInitialValues());
        if (!rule.id) {
            form.setFieldsValue({ excludedBlockTypes: ['WORD_BLOCK'] });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, rule]);

    const getInitialValues = () => {
        return {
            name: rule.name,
            tag: rule.tag,
            minimumPercentageShouldMatch: rule.minimumPercentageShouldMatch,
            query: rule.query,
            operator: rule.operator,
            priority: rule.priority,
            description: rule.description,
            overridePriority: rule.overridePriority,
            excludedBlockTypes: rule.excludedBlockTypes
        };
    };

    React.useEffect(() => {
        if (!rule.id) {
            form.setFieldsValue({ minimumPercentageShouldMatch: 100, operator: 'Or' });
        }
    }, [form, rule]);

    return (
        <>
            {store.error.map((e, i) => (
                <h4 key={e + i} style={{ color: 'red' }}>
                    {e}
                </h4>
            ))}
            <Form form={form} layout="vertical" className="rule-edit-form" data-id="rule-edit-form">
                <RuleEditGeneralSettings store={store} selectType={selectType} form={form} />
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Match query"
                            name="query"
                            data-id="rule-match-query-input"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input query!'
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Minimum % should match"
                            name="minimumPercentageShouldMatch"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input percentage!'
                                }
                            ]}
                            data-id="rule-minimum-percent-match-input"
                        >
                            <InputNumber max={100} min={0} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Operator"
                            name="operator"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select operator!'
                                }
                            ]}
                            data-id="rule-operator-select"
                        >
                            <SelectWhenScroll placeholder="Select operator">
                                {['Or', 'And'].map(t => (
                                    <Option key={t} value={t}>
                                        {t}
                                    </Option>
                                ))}
                            </SelectWhenScroll>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <BlockTypesDropdown mode="multiple" />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default inject(STORE_RULE_EDIT)(observer(RuleEditElasticSearchMatchForm));
