import * as Highlight from 'react-highlighter';
import * as React from 'react';
import { ReferenceDataVisualStore } from '../stores';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function (store: ReferenceDataVisualStore): any {
    // eslint-disable-next-line no-unused-vars
    return function (item: string, idx: number) {
        return (
            <div className="global-search-item-container">
                <div className="global-search-item-result">
                    <Highlight search={store.refDataFilteredValue}>{item}</Highlight>
                </div>
            </div>
        );
    };
}
