import { message } from 'antd';
import { action, computed, observable, runInAction } from 'mobx';
import { RulesStore } from '../../rules/stores';
import TagsGroupVisualStore from '../../rules/stores/TagsGroupVisualStore';
import { ProjectsRootVisualStore, RouterStore } from '../../common/stores';
import { FieldBindingsService } from '../services';
import { BindingGroup, BindingGroupSection, FieldBinding, FieldBindingPropKeys } from '../types';
import type { FieldBindingOrientation } from '../types';
import { EnvironmentVariable } from '../../iota_applications/types';
import { EnvironmentVariablesService } from '../../iota_applications/services';
import { Utils } from '../../common/services/Utils';
import { FieldBindingsPageNavigation } from '../routes';
import FieldPreviewVisualStore from './FieldPreviewVisualStore';

export default class FieldBindingsStore {
    @observable
    bindingGroups: BindingGroup[] = [];

    @observable
    isCreateGroupDialogVisible: boolean = false;

    @observable
    isUpdateGroupDialogVisible: boolean = false;

    @observable
    isCreateSectionDialogVisible: boolean = false;

    @observable
    isUpdateSectionDialogVisible: boolean = false;

    @observable
    isCreateInputDialogVisible: boolean = false;

    @observable
    isRenameInputDialogVisible: boolean = false;

    @observable
    currentBinding: FieldBinding | undefined = undefined;

    @observable
    currentSectionId: string | undefined = undefined;

    @observable
    selectedGroupId: string | undefined = undefined;

    @observable
    environmentVariables: EnvironmentVariable[];

    @observable
    fileImportActionHeaders: {};

    @observable
    openedSections: string[];

    @observable
    treeExpandedKeys: string[] = [];

    @computed
    get currentProject() {
        return this.projectsRootStore.currentProject;
    }

    @computed
    get selectedGroup() {
        return this.bindingGroups.find(g => g.id === this.selectedGroupId);
    }

    @computed
    get tags() {
        return Object.getOwnPropertyNames(this.ruleStore.groupedByTag)
            .slice()
            .sort((a, b) => a.localeCompare(b));
    }

    @computed
    get tagGroups() {
        return this.tagsGroupStore.tagsGroups ?? [];
    }

    constructor(
        private projectsRootStore: ProjectsRootVisualStore,
        private service: FieldBindingsService,
        private ruleStore: RulesStore,
        private tagsGroupStore: TagsGroupVisualStore,
        private routerStore: RouterStore,
        private previewStore: FieldPreviewVisualStore,
        private envVariablesService: EnvironmentVariablesService
    ) {
        this.loadEnvironmentVariables();
    }

    @action.bound
    setTreeExpandedKeys(keys: string[]) {
        this.treeExpandedKeys = keys;
    }

    @action.bound
    async setHeaders() {
        const tk = await Utils.getAuthToken();
        runInAction(() => {
            this.fileImportActionHeaders = {
                Authorization: 'Bearer ' + tk
            };
        });
    }

    @action.bound
    setEnvironmentVariables(envVars: EnvironmentVariable[]) {
        this.environmentVariables = envVars;
    }

    @action.bound
    setCurrentFieldBinding(binding: FieldBinding | undefined) {
        this.currentBinding = binding;
    }

    @action.bound
    setOpenedSections(sections: string[]) {
        this.openedSections = sections;
    }

    @action.bound
    async saveGroupsOrder(groupOrders: { [groupId: string]: number }) {
        if (!this.currentProject?.id) {
            return;
        }
        await this.service.updateProjectFieldGroupsOrder(this.currentProject.id, groupOrders);
    }

    @action.bound
    async loadEnvironmentVariables() {
        const envVars = await this.envVariablesService.getAllEnvironmentVariables();
        this.setEnvironmentVariables(envVars);
    }

    @action.bound
    getTagsForGroup(groupId: string | null) {
        return this.ruleStore.ruleTags
            .filter(x => x.groupId === groupId)
            .map(y => {
                return { name: y.name, id: y.id };
            });
    }

    @action.bound
    getTagById(id: string) {
        const tag = this.ruleStore.ruleTags.find(x => x.id === id);
        return (tag && tag.name) || '';
    }

    @action.bound
    setIsCreateGroupDialogVisible(visible: boolean) {
        this.isCreateGroupDialogVisible = visible;
    }

    @action.bound
    setIsUpdateGroupDialogVisible(visible: boolean) {
        this.isUpdateGroupDialogVisible = visible;
    }

    @action.bound
    setIsCreateSectionDialogVisible(visible: boolean) {
        this.isCreateSectionDialogVisible = visible;
    }

    @action.bound
    setIsUpdateSectionDialogVisible(visible: boolean) {
        this.isUpdateSectionDialogVisible = visible;
    }

    @action.bound
    setIsCreateInputDialogVisible(visible: boolean) {
        this.isCreateInputDialogVisible = visible;
    }

    @action.bound
    setIsRenameInputDialogVisible(visible: boolean) {
        this.isRenameInputDialogVisible = visible;
    }

    @action.bound
    setSelectedGroupId(groupId: string | undefined) {
        this.selectedGroupId = groupId;
    }

    @action.bound
    setCurrentSectionId(sectionId: string | undefined) {
        this.currentSectionId = sectionId;
    }

    @action.bound
    async createFieldGroup(name: string, alias: string) {
        if (!this.currentProject) {
            return;
        }

        try {
            const resp = await this.service.createProjectFieldsGroup(this.currentProject.id, name, alias);

            if (resp.isOk()) {
                message.success(`Created project fields group '${name}' successfully.`);
                await this.getFieldGroups();
            } else {
                message.error('Failed to create project fields group.');
                console.error(resp.error);
            }

            return resp.isOk();
        } catch (err) {
            message.error('Failed to create project fields group.');
            console.error(err);

            return false;
        }
    }

    @action.bound
    async updateFieldGroup(name: string, alias: string) {
        if (!this.selectedGroupId) {
            return;
        }

        try {
            const resp = await this.service.updateProjectFieldsGroup(this.selectedGroupId, name, alias);

            if (resp.isOk()) {
                message.success('Updated project fields group successfully.');
                await this.getFieldGroups();
            } else {
                message.error('Failed to update project fields group.');
                console.error(resp.error);
            }

            return resp.isOk();
        } catch (err) {
            message.error('Failed to update project fields group.');
            console.error(err);

            return false;
        }
    }

    @action.bound
    async getFieldGroups() {
        if (!this.currentProject) {
            return;
        }

        try {
            const resp = await this.service.getProjectFieldGroups(this.currentProject.id);
            await this.getRules();
            this.setBindingGroups(resp);
        } catch (err) {
            message.error('Failed to fetch field group sections.');
            console.error(err);
        }
    }

    @action.bound
    setBindingGroups(groups: BindingGroup[]) {
        this.bindingGroups = groups;
    }

    @action.bound
    async createFieldGroupSection(name: string) {
        if (!this.selectedGroupId) {
            return;
        }

        try {
            const resp = await this.service.createProjectFieldsSection(this.selectedGroupId, name);

            if (resp.isOk()) {
                message.success(`Created project fields section '${name}' successfully.`);
                await this.getFieldGroups();
            } else {
                message.error('Failed to create project fields section.');
                console.error(resp.error);
            }

            return resp.isOk();
        } catch (err) {
            message.error('Failed to create project fields section.');
            console.error(err);

            return false;
        }
    }

    @action.bound
    async createField(name: string) {
        if (!this.selectedGroupId) {
            return;
        }

        try {
            const resp = await this.service.createField(this.selectedGroupId, name, this.currentSectionId);

            if (resp.isOk()) {
                message.success(`Created project field '${name}' successfully.`);
                await this.getFieldGroups();
            } else {
                message.error('Failed to create project field.');
                console.error(resp.error);
            }

            return resp.isOk();
        } catch (err) {
            message.error('Failed to create project field.');
            console.error(err);

            return false;
        }
    }

    @action.bound
    async updateSection(name: string) {
        if (!this.selectedGroupId || !this.currentSectionId) {
            return;
        }

        try {
            const resp = await this.service.updateProjectFieldsGroupSection(
                this.currentSectionId,
                this.selectedGroupId,
                name
            );

            if (resp.isOk()) {
                message.success('Updated project fields group section successfully.');
                await this.getFieldGroups();
            } else {
                message.error('Failed to update project fields group section.');
                console.error(resp.error);
            }

            return resp.isOk();
        } catch (err) {
            message.error('Failed to update project fields group section.');
            console.error(err);

            return false;
        }
    }

    @action.bound
    async updateGroup(
        name: string,
        showMsg: boolean = true,
        fields?: FieldBinding[],
        sections?: BindingGroupSection[]
    ) {
        if (!this.selectedGroup) {
            return;
        }

        try {
            const resp = await this.service.updateProjectFieldsGroup(
                this.selectedGroup.id,
                name,
                this.selectedGroup.alias,
                fields,
                sections
            );

            if (resp.isOk()) {
                if (showMsg) {
                    message.success(`Updated project fields group '${name}' successfully.`);
                }
                await this.getFieldGroups();
            } else {
                message.error('Failed to update project fields group.');
                console.error(resp.error);
            }

            return resp.isOk();
        } catch (err) {
            message.error('Failed to update project fields group.');
            console.error(err);

            return false;
        }
    }

    @action.bound
    async deleteGroup() {
        if (!this.selectedGroupId) {
            return;
        }

        try {
            const resp = await this.service.deleteProjectFieldGroup(this.selectedGroupId);

            if (resp.isOk()) {
                message.success('Deleted project fields group successfully.');
                await this.getFieldGroups();
                this.setSelectedGroupId(undefined);
            } else {
                message.error('Failed to delete project fields group.');
                console.error(resp.error);
            }

            return resp.isOk();
        } catch (err) {
            message.error('Failed to delete project fields group.');
            console.error(err);

            return false;
        }
    }

    @action.bound
    async deleteField(fieldId: string) {
        if (!this.selectedGroupId) {
            return;
        }

        try {
            const resp = await this.service.deleteProjectField(fieldId, this.selectedGroupId);

            if (resp.isOk()) {
                message.success('Deleted project field successfully.');
                await this.getFieldGroups();
            } else {
                message.error('Failed to delete project field.');
                console.error(resp.error);
            }

            return resp.isOk();
        } catch (err) {
            message.error('Failed to delete project field.');
            console.error(err);

            return false;
        }
    }

    @action.bound
    getFieldNamebyId(inputId: string) {
        return this.selectedGroup?.fields?.find(f => f.inputId === inputId)?.name;
    }

    @action.bound
    getBindingOrienation(inputId: string, index: number) {
        const fields = this.selectedGroup?.fields?.filter(f => f.inputId === inputId);

        if (fields && fields.length > index) {
            return fields[index].properties
                ? (fields[index].properties[FieldBindingPropKeys.Orientation] ?? 'Vertical')
                : 'Vertical';
        }

        return 'Vertical';
    }

    @action.bound
    updateBindingOrientation(inputId: string, index: number, orientation: FieldBindingOrientation) {
        const fields = this.selectedGroup?.fields?.filter(f => f.inputId === inputId);
        if (fields && fields.length > index) {
            if (fields[index].properties) {
                fields[index].properties[FieldBindingPropKeys.Orientation] = orientation;
            } else {
                fields[index].properties = {
                    [FieldBindingPropKeys.Orientation]: orientation
                };
            }

            return true;
        }

        return false;
    }

    @action.bound
    getRules() {
        if (!this.ruleStore.ruleTags?.length) {
            return this.ruleStore.getRules();
        }
        return Promise.resolve();
    }

    @action.bound
    isTagDisabled(tag: string) {
        const rules = this.ruleStore.rules.filter(r => r.tag === tag);
        return !rules.length ? false : !rules.some(r => r.state === 'Enabled');
    }

    @action.bound
    async deleteSection(sectionId: string) {
        if (!this.selectedGroupId) {
            return;
        }

        try {
            const resp = await this.service.deleteProjectFieldGroupSection(this.selectedGroupId, sectionId);

            if (resp.isOk()) {
                message.success('Deleted project fields group section successfully.');
                await this.getFieldGroups();
            } else {
                message.error('Failed to delete project fields group section.');
                console.error(resp.error);
            }

            return resp.isOk();
        } catch (err) {
            message.error('Failed to delete project fields group section.');
            console.error(err);

            return false;
        }
    }

    @action.bound
    async renameField(name: string) {
        if (!this.currentBinding || !this.selectedGroupId) {
            return;
        }

        try {
            const resp = await this.service.updateProjectField(
                this.currentBinding.inputId,
                this.selectedGroupId,
                this.currentBinding.sectionId,
                name,
                this.currentBinding.type,
                this.currentBinding.value
            );

            if (resp.isOk()) {
                message.success('Updated project field successfully.');
                await this.getFieldGroups();
            } else {
                message.error('Failed to update project field.');
                console.error(resp.error);
            }

            return resp.isOk();
        } catch (err) {
            message.error('Failed to update project fields.');
            console.error(err);

            return false;
        }
    }

    @action
    previewField(bindingId: string) {
        if (this.currentProject) {
            const group = this.bindingGroups.find(g => g.fields.map(f => f.inputId).includes(bindingId))!;
            this.previewStore.setSelectedFieldData(group.id, bindingId, this.getFieldNamebyId(bindingId));
            const encodedName = encodeURIComponent(bindingId);
            this.routerStore.pushToHistory(
                FieldBindingsPageNavigation.BindingResultsPreviewPage.replace(':id', this.currentProject.id).replace(
                    ':bindingId',
                    encodedName
                )
            );
        }
    }

    @action.bound
    async exportProjectFieldBindings(projectFieldGroupIds?: string[]) {
        if (!this.currentProject?.id) {
            return;
        }

        await this.service.exportProjectFields(this.currentProject.id, projectFieldGroupIds);
    }
}
