import * as React from 'react';
import { Menu, Dropdown } from 'antd';
import { inject } from 'mobx-react';
import { Package } from '../models';
import { RootStores } from '../stores';
import { STORE_PROJECTS } from '../constants';

type Props = {
    pkg: Package;
} & Partial<RootStores>;

@inject(STORE_PROJECTS)
class PackageContextMenu extends React.Component<Props, object> {
    render() {
        const projectsStore = this.props.projects!;
        const { pkg } = this.props;
        const menu = (id: string) => {
            return (
                <Menu>
                    <Menu.Item key="1" onClick={() => projectsStore.handleDownload(id, 'pdf')}>
                        Download Pdf
                    </Menu.Item>
                    <Menu.Item key="2" onClick={() => projectsStore.handleDownload(id, 'apkg')}>
                        Download apkg
                    </Menu.Item>
                </Menu>
            );
        };
        return (
            <Dropdown overlay={() => menu(pkg.id)} trigger={['contextMenu']}>
                <span style={{ whiteSpace: 'pre' }} data-id={pkg.name}>
                    {pkg.name}
                </span>
            </Dropdown>
        );
    }
}
export default PackageContextMenu;
