import { computed, action, observable, reaction } from 'mobx';
import { RcFile } from 'antd/lib/upload/interface';
import { MLModel, MLModelRevision, MLModelRule, MLModelType } from '../types';
import { ProjectsRootVisualStore } from '../../common/stores';
import { MLService } from '../services';
import { message } from 'antd';
import _ from 'lodash';

export class ProjectMLModelsVisualStore {
    @observable
    mlModels: MLModel[];

    @observable
    currentModel: MLModel | undefined;

    @observable
    uploadingRevision: boolean;

    @observable
    busyModelIds: string[];

    @observable
    mlModelTypes: MLModelType[];

    @observable
    revisionFileList: RcFile[];

    @observable
    isAddModelDialogVisible: boolean = false;

    @observable
    isAddRevisionDialogVisible: boolean = false;

    @observable
    fileImportActionHeaders: {};

    @observable
    isImportingModel = false;

    @observable
    isModelRulesDialogVisible = false;

    @observable
    currentModelRules: MLModelRule[] = [];

    @observable
    isLoadingModelRules: boolean = false;

    @computed
    get currentProject() {
        return this.rootStore.currentProject;
    }

    @computed
    get currentRulesGroups() {
        const groups = this.currentModelRules.map(rule => {
            return { name: rule.groupName, id: rule.groupId };
        });
        return _.uniqBy(groups, 'id');
    }

    @computed
    get currentRuleTags() {
        const tags = this.currentModelRules.map(rule => {
            return { name: rule.tagName, id: rule.tagId, groupId: rule.groupId };
        });
        return _.uniqBy(tags, 'id');
    }

    constructor(
        private rootStore: ProjectsRootVisualStore,
        private mlService: MLService
    ) {
        this.busyModelIds = new Array<string>();
        this.revisionFileList = new Array<RcFile>();
    }

    @action.bound
    setIsImportingModel(val: boolean) {
        this.isImportingModel = val;
    }

    @action.bound
    setIsModelRulesDialogVisible(visible: boolean) {
        this.isModelRulesDialogVisible = visible;
    }

    @action.bound
    setMLModels(mlModels: MLModel[]) {
        this.mlModels = mlModels;
    }

    @action.bound
    setCurrentMLModel(mlModel: MLModel | undefined) {
        this.currentModel = mlModel;
    }

    @action.bound
    setUploadingRevision(uploading: boolean) {
        this.uploadingRevision = uploading;
    }

    @action.bound
    setBusyModelIds(modelIds: string[]) {
        this.busyModelIds = modelIds;
    }

    @action.bound
    setMLModelTypes(types: MLModelType[]) {
        this.mlModelTypes = types;
    }

    @action.bound
    setRevisionFileList(files: RcFile[]) {
        this.revisionFileList = files;
    }

    @action
    setIsAddModelDialogVisible(val: boolean) {
        this.isAddModelDialogVisible = val;
    }

    @action
    setIsAddRevisionDialogVisible(val: boolean) {
        this.isAddRevisionDialogVisible = val;
    }

    @action.bound
    removeRevisionFile(file: RcFile) {
        const index = this.revisionFileList.indexOf(file);
        const newFileList = this.revisionFileList.slice();
        newFileList.splice(index, 1);
        this.setRevisionFileList(newFileList);
    }

    @action.bound
    async uploadFiles() {
        this.setUploadingRevision(true);
        const formData = new FormData();
        this.revisionFileList.forEach(file => {
            formData.append('files', file);
        });

        formData.append('projectId', this.currentModel!.projectId);
        formData.append('modelId', this.currentModel!.id!);

        await this.uploadRevision(formData);
        this.setUploadingRevision(false);
        this.setRevisionFileList([]);
        this.navigateToMLList();
        this!.loadProjectMLModels();
        this.setIsAddRevisionDialogVisible(false);
    }

    @action.bound
    removeModelFromBusyList(modelId: string) {
        var newBusyList = this.busyModelIds.slice();
        var modelIndex = newBusyList.indexOf(modelId);
        if (modelIndex >= 0) {
            newBusyList.splice(modelIndex, 1);
            this.setBusyModelIds(newBusyList);
        }
    }

    @action.bound
    async loadProjectMLModels() {
        if (!this.currentProject) {
            reaction(
                () => this.currentProject,
                (p, r) => {
                    this.loadProjectMLModels();
                    r.dispose();
                }
            );
            return;
        }

        const mlModels = await this.mlService.getMLModelsForProject(this.currentProject!.id);
        this.setMLModels(mlModels);

        if (window.location.href.indexOf('mlModel') >= 0 && !this.currentModel) {
            var url = new URL(window.location.href);
            var pathSplit = url.pathname.split('/');
            var mlModelIndex = pathSplit.indexOf('mlModel');
            var mlModelId = pathSplit[mlModelIndex + 1];

            var currModel = mlModels.find(m => m.id === mlModelId);
            if (currModel) {
                this.setCurrentMLModel(currModel);
            }
        }
    }

    @action.bound
    removeModelFromList(model: MLModel) {
        let newModelList = this.mlModels.slice();
        const modelIndex = newModelList.indexOf(model);
        if (modelIndex >= 0) {
            newModelList.splice(modelIndex, 1);
            this.setMLModels(newModelList);
        }
    }

    @action.bound
    async getMLModelTypes() {
        const types = await this.mlService.getMLModelTypes();
        this.setMLModelTypes(types);
    }

    @action
    async openMlModelDialog(model: MLModel | undefined) {
        this.setCurrentMLModel(model);
        this.setIsAddModelDialogVisible(true);
    }

    @action
    async openAddRevisionDialog(model: MLModel | undefined) {
        this.setCurrentMLModel(model);
        this.setIsAddRevisionDialogVisible(true);
    }

    @action.bound
    openMLModelRulesDialog(model: MLModel | undefined) {
        this.setMlModelRules([]);
        this.setCurrentMLModel(model);
        this.setIsModelRulesDialogVisible(true);
        if (model && model.id) {
            this.loadMlModelRules(model!.id!);
        }
    }

    @action.bound
    setMlModelRules(rules: MLModelRule[]) {
        this.currentModelRules = rules;
    }

    @action.bound
    setIsLoadingModelRules(loading: boolean) {
        this.isLoadingModelRules = loading;
    }

    @action.bound
    async loadMlModelRules(modelId: string) {
        if (!this.currentProject) {
            return;
        }

        try {
            this.setIsLoadingModelRules(true);
            const resp = await this.mlService.getModelRules(this.currentProject!.id, modelId);

            if (resp.isOk()) {
                this.setMlModelRules(resp.value || []);
            } else {
                message.error('Failed to load model rules');
                console.error(resp.error);
            }
        } catch (err) {
            message.error('Failed to load model rules');
            console.error(err);
        } finally {
            this.setIsLoadingModelRules(false);
        }
    }

    @action
    removeRevisionFromMlModel(model: MLModel, revision: MLModelRevision) {
        let newModelList = this.mlModels.slice();
        const modelIndex = newModelList.indexOf(model);
        if (modelIndex >= 0) {
            var modelToUpdate = newModelList[modelIndex];
            const revisionIndex = modelToUpdate.revision.indexOf(revision);

            if (revisionIndex >= 0) {
                modelToUpdate.revision.splice(revisionIndex, 1);
                this.setMLModels(newModelList);
            }
        }
    }

    @action
    async saveMLModel(model: MLModel, revisionFilePath: string | undefined) {
        if (!model.id) {
            return this.mlService.addMLModel(model, revisionFilePath);
        } else {
            return this.mlService.updateMLModel(model);
        }
    }

    @action
    async uploadRevision(formData: FormData) {
        return this.mlService.uploadRevision(formData, this.currentModel!);
    }

    @action
    async deleteRevision(model: MLModel, revision: MLModelRevision) {
        this.setBusyModelIds([...this.busyModelIds, model.id!]);
        var deleteResp = await this.mlService.deleteRevision(model, revision.storagePath);
        deleteResp.map(() => this.removeRevisionFromMlModel(model, revision));
        this.removeModelFromBusyList(model.id!);
    }

    @action
    async downloadRevision(model: MLModel, revision: MLModelRevision) {
        await this.mlService.downloadRevision(model, revision.storagePath);
    }

    @action
    async updateRevisionState(model: MLModel, storagePath: string, isActive: boolean) {
        const resp = await this.mlService.updateRevisionState(model, storagePath, isActive);
        await resp.asyncMap(() => this.updateActiveRevisionList(model, storagePath));
    }

    @action
    async updateActiveRevisionList(model: MLModel, storagePath: string) {
        let newList = this.mlModels.slice();
        const modelIndex = newList.indexOf(model);

        if (modelIndex >= 0) {
            let modelToUpdate = newList[modelIndex];
            modelToUpdate.revision.forEach(r => {
                r.isActive = r.storagePath === storagePath;
            });

            this.setMLModels(newList);
        }
    }

    @action
    async deleteMLModel(model: MLModel) {
        this.setBusyModelIds([...this.busyModelIds, model.id!]);
        var deleteResp = await this.mlService.deleteMLModel(model);
        deleteResp.map(() => this.removeModelFromList(model));
        this.removeModelFromBusyList(model.id!);
    }

    @action
    async navigateToMLList() {
        await this.rootStore.navigateToMlStoragePage(this.currentProject!);
    }

    @action
    async exportModel(modelId: string) {
        if (!this.currentProject) {
            return;
        }

        await this.mlService.exportMlModel(this.currentProject!.id, modelId);
    }

    @action
    async setHeaders() {
        const token = await this.mlService.getAuthToken();
        this.fileImportActionHeaders = {
            Authorization: 'Bearer ' + token
        };
        return Promise.resolve();
    }

    @action
    async importModel(acceptedFiles: File[]) {
        if (!this.currentProject) {
            return;
        }

        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        const resp = await this.mlService.importMlModel(formData, this.currentProject!.id);

        if (resp.isOk()) {
            message.success('Model imported successfully.');
            this.loadProjectMLModels();
        } else {
            console.error(resp.error);
            message.error('Failed to import model.');
        }
    }
}

export default ProjectMLModelsVisualStore;
