import * as React from 'react';
import AdministrationProjectVisualStore from '../stores/AdministrationProjectVisualStore';
import UploadedPackagesVisualStore from '../stores/UploadedPackagesVisualStore';
import { Button, Select, Dropdown, Menu, Layout, Row, Col } from 'antd';
import { observer, Observer } from 'mobx-react-lite';
import { ProcessType } from '../../../modules/common/types';
import LayoutHeader from '../../../components/LayoutHeader';
import { Utils } from '../../../modules/common/services/Utils';

const { Option } = Select;
const { Content } = Layout;
type Props = {
    adminStore: AdministrationProjectVisualStore;
    uploadedPackagesStore: UploadedPackagesVisualStore;
};

export const ReindexingTabs: React.FC<Props> = ({ adminStore, uploadedPackagesStore }) => {
    const [selectedTag, setSelectedTag] = React.useState<string | undefined>(undefined);
    const [selectedState, setSelectedState] = React.useState<string | undefined>(undefined);

    const handleTagSelectionChange = (tag: string) => {
        setSelectedTag(tag);
    };

    const handleStateSelectionChange = (state: string) => {
        setSelectedState(state);
    };

    const processMenuRenderer = (onClickHandler: (processType: ProcessType) => {}, dataIdName: string) => {
        return (
            <Menu data-id-type="popup-menu-list-root" data-id-name={dataIdName}>
                <Menu.Item
                    key="1"
                    data-id-type="popup-menu-list-item"
                    onClick={() => onClickHandler(ProcessType.Reparse)}
                >
                    Re-parse
                </Menu.Item>
                <Menu.Item
                    key="2"
                    data-id-type="popup-menu-list-item"
                    onClick={() => onClickHandler(ProcessType.Reindex)}
                >
                    Re-index
                </Menu.Item>
            </Menu>
        );
    };

    return (
        /* eslint-disable max-len */
        <Layout className="screen-size" style={{ ...{ height: '100%', background: 'white', overflow: 'hidden' } }}>
            <LayoutHeader
                subtitle={Utils.getSubtitle(uploadedPackagesStore.currentProject)}
                title="Administration"
                helpMessage="Management of project with restricted access"
                buttons={[
                    <Button
                        data-id="button-Clean-up Project"
                        size="large"
                        key="clean-up"
                        onClick={() => adminStore.cleanupProject()}
                        type="primary"
                    >
                        Clean-up Project
                    </Button>
                ]}
            />
            <Layout>
                <Content style={{ marginLeft: 2 }}>
                    <Row gutter={12} style={{ marginBottom: 10 }}>
                        <Col>
                            <Dropdown
                                overlay={() =>
                                    processMenuRenderer(
                                        (processType: ProcessType) => adminStore.indexProject(processType),
                                        'Process Project'
                                    )
                                }
                                trigger={['click']}
                            >
                                <Button type="primary" data-id="button-Process-Project">
                                    Process Project
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{ marginBottom: 10 }}>
                        <Col>
                            <Select
                                data-id="input-Process-by-tag"
                                style={{ width: 300 }}
                                value={selectedTag}
                                onChange={handleTagSelectionChange}
                            >
                                {uploadedPackagesStore &&
                                    uploadedPackagesStore.tags.map((k, i) => (
                                        <Option key={`kw-${i}`} value={k}>
                                            {k}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col>
                            <Dropdown
                                data-id="input-Administration-Process-by-tag"
                                overlay={() =>
                                    processMenuRenderer(
                                        (processType: ProcessType) =>
                                            adminStore.indexByValue(selectedTag!, 'ByUserTag', processType),
                                        'Process by tag'
                                    )
                                }
                                trigger={['click']}
                                disabled={!selectedTag || selectedTag.trim() === ''}
                            >
                                <Button data-id="button-Process-by-tag" style={{ width: 140 }} type="primary">
                                    Process by tag
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row gutter={12} style={{ marginBottom: 10 }}>
                        <Col>
                            <Observer>
                                {() => (
                                    <Select
                                        data-id="input-Process-by-state"
                                        style={{ width: 300 }}
                                        value={selectedState}
                                        onChange={handleStateSelectionChange}
                                    >
                                        <Option value="Ready">Ready</Option>
                                        <Option value="Importing">Importing</Option>
                                        <Option value="Processing">Processing</Option>
                                    </Select>
                                )}
                            </Observer>
                        </Col>
                        <Col>
                            <Dropdown
                                data-id="input-Administration-Process-by-state"
                                overlay={() =>
                                    processMenuRenderer(
                                        (processType: ProcessType) =>
                                            adminStore.indexByValue(selectedState!, 'ByState', processType),
                                        'Process by state'
                                    )
                                }
                                trigger={['click']}
                                disabled={!selectedState || selectedState.trim() === ''}
                            >
                                <Button data-id="button-Process-by-state" style={{ width: 140 }} type="primary">
                                    Process by state
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(ReindexingTabs);
