import { Button, Checkbox, Dropdown, Menu, Popconfirm, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Observer, observer } from 'mobx-react-lite';
import * as React from 'react';
import { ViewerPagesNavigation } from '../../viewer_base/routes';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { FeatureFlags, Package, PackageState } from '../../common/models';
import { UploadedPackagesVisualStore } from '../stores';
import PackageDownloadMenu from './PackageDownloadMenu';
import PackageTagsEditor from './PackageTagsEditor';
import { Utils } from '../../common/services/Utils';
import { SorterResult } from 'antd/lib/table/interface';
import FeatureFlagsModal from './FeatureFlagsModal';
import { LIST_PAGE_SIZE } from '../../common/stores/Pager';
import VirtualTable from '../../common/components/VirtualTable';
import { OrderBy } from '../../common/types';
import { useDynamicHeight } from '../../common/hooks';

type Props = {
    store: UploadedPackagesVisualStore;
};

const UploadedPackagesGridFc: React.FC<Props> = ({ store }) => {
    const [menuPlacement, setMenuPlacement] = React.useState('bottomRight');
    const [featureFlagsDialogVisible, setFeatureFlagsDialogVisible] = React.useState(false);
    const [currentFeatureFlags, setCurrentFeatureFlags] = React.useState<FeatureFlags>(new FeatureFlags());
    const [currentPackageId, setCurrentPackageId] = React.useState<string>('');

    React.useEffect(() => {
        const packagesInSameProject =
            store.currentProjectPackages.length > 0 &&
            store.currentProjectPackages.every(p => p.project.id === store.currentProject?.id);

        if (store.currentProject && !packagesInSameProject) {
            store.setCurrentPage(store.currentPage + 1);
            store.searchForAutocomplete(store.searchTerm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject]);

    const processMenuRenderer = (pkg: Package) => {
        return (
            <Menu>
                <Menu.Item
                    key="1"
                    eventKey="1"
                    onClick={() => {
                        store.reparsePackage(pkg.id);
                    }}
                >
                    Re-parse
                </Menu.Item>
                <Menu.Item
                    key="2"
                    eventKey="2"
                    onClick={() => {
                        store.reindexPackage(pkg.id);
                    }}
                >
                    Re-index
                </Menu.Item>
                <HasPermission
                    entityId={store?.currentProject?.id}
                    permissionClaim={AppPermissions.CanAccessAdministration}
                >
                    <Menu.Item
                        key="3"
                        eventKey="3"
                        onClick={() => {
                            store.updatePackagesProtection([pkg.id], !pkg.isProtected);
                        }}
                    >
                        {pkg.isProtected ? 'Disable protection' : 'Enable protection'}
                    </Menu.Item>
                </HasPermission>
                <Popconfirm
                    title="Are you sure that you want to delete this package?"
                    id={`data-id-popconfirm-box-Delete-${pkg.id}`}
                    disabled={pkg.isProtected}
                    okText="Yes"
                    onConfirm={() => {
                        store.deletePackage(pkg.id);
                    }}
                    cancelText="No"
                >
                    <Menu.Item style={{ color: pkg.isProtected ? 'lightgray' : 'red' }} key="4" eventKey="4">
                        Delete
                    </Menu.Item>
                </Popconfirm>
            </Menu>
        );
    };

    const handleProcessClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (window.innerHeight - e.clientY < 150) {
            setMenuPlacement('topRight');
        } else {
            setMenuPlacement('bottomRight');
        }
    };

    const actionsCellRenderer = (pkgName: string, id: string) => {
        const pkg = store.currentProjectPackages.find(p => p.id === id);
        if (!pkg) {
            return null;
        }
        return (
            <HasPermission
                entityId={store?.currentProject?.id}
                permissionClaim={AppPermissions.CanEditProcessDocuments}
            >
                <div data-id={`Actions-${pkgName}`}>
                    <PackageDownloadMenu pkg={pkg} />
                    <Observer>
                        {() => (
                            <Dropdown
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                placement={menuPlacement as any}
                                overlay={() => processMenuRenderer(pkg)}
                                trigger={['click']}
                            >
                                <Tooltip title="Process">
                                    <Button
                                        data-id={`uploaded-packages-reindex-${id}`}
                                        type="link"
                                        size="small"
                                        // @ts-ignore
                                        loading={store!.isReindexingInProcess[id]}
                                        onClick={handleProcessClick}
                                    >
                                        <i className="alpha-icon lg play-icon" />
                                    </Button>
                                </Tooltip>
                            </Dropdown>
                        )}
                    </Observer>
                    <Observer>
                        {() => (
                            <Tooltip title="Clean up">
                                <Button
                                    data-id={`uploaded-packages-cleanup-${id}`}
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                        store.cleanStorage(id);
                                    }}
                                    // @ts-ignore
                                    loading={store!.isCleanupInProcess[id]}
                                >
                                    <i className="alpha-icon lg cleanup-icon" />
                                </Button>
                            </Tooltip>
                        )}
                    </Observer>
                </div>
            </HasPermission>
        );
    };

    const getSorterOrder = (columnKey: string) => {
        if (!store.orderBy || store.orderBy.field !== columnKey) {
            return;
        }

        return store.orderBy.direction === 'ASC' ? 'ascend' : 'descend';
    };

    const columns: ColumnsType<Package> = [
        {
            key: 'selection',
            dataIndex: 'selection',
            width: 45,
            render: (name: string, record: Package) => (
                <Observer>
                    {() => (
                        <Checkbox
                            data-id={`CheckBox-${record.name}`}
                            checked={store.selectedPackages.includes(record.id)}
                            data-package-id={record.id}
                            style={{ marginTop: -3 }}
                            onChange={e => store.handlePackageSelection(e, record.id)}
                        />
                    )}
                </Observer>
            )
        },
        {
            key: '_id',
            dataIndex: 'id',
            title: 'Id',
            sorter: true,
            sortOrder: getSorterOrder('_id'),
            render: (name: string, record: Package) => <div data-id={`Id-${record.id}`}>{record.id}</div>
        },
        {
            key: 'fileName',
            dataIndex: 'name',
            title: 'Package',
            sorter: true,
            sortOrder: getSorterOrder('fileName'),
            render: (name: string, record: Package) =>
                !record.documentPath ? (
                    <div data-id={`Package-${record.name}`}>{record.name}</div>
                ) : (
                    <div>
                        <a
                            style={{ whiteSpace: 'pre' }}
                            data-id={`Package-${record.name}`}
                            href={`${process.env.PUBLIC_URL}${ViewerPagesNavigation.DocumentViewerPage}/${encodeURIComponent(record.id)}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Tooltip title={<span style={{ whiteSpace: 'pre-wrap' }}>{record.name}</span>}>
                                {record.name}
                            </Tooltip>
                        </a>
                    </div>
                )
        },
        {
            key: 'filePath',
            dataIndex: 'documentPath',
            title: 'Path',
            sorter: true,
            sortOrder: getSorterOrder('filePath'),
            render: (path: string, record: Package) => (
                <Tooltip title={path}>
                    <div data-id={`Path-${record.name}`}>{path}</div>
                </Tooltip>
            )
        },
        {
            key: 'state',
            dataIndex: 'serverState',
            title: 'State',
            width: 75,
            sorter: true,
            sortOrder: getSorterOrder('state'),
            render: (state: string, record: Package) => {
                const error =
                    record.error && record.error.length > 1000 ? record.error.substring(0, 999) : record.error;
                const tag = (
                    <Tag
                        className="alpha-portal-tag"
                        data-id={`State-${record.name}`}
                        color={state === PackageState.Ready ? '#52C41A' : '#F5222D'}
                        style={{ textTransform: 'capitalize' }}
                    >
                        {state}
                    </Tag>
                );

                if (state === PackageState.Broken) {
                    return (
                        <Tooltip overlayStyle={{ maxWidth: '500px' }} title={error}>
                            {tag}
                        </Tooltip>
                    );
                }

                return tag;
            }
        },
        {
            key: 'userTags',
            title: 'Tags',
            width: 150,
            sorter: true,
            sortOrder: getSorterOrder('userTags'),
            render: (val: unknown, record: Package) => (
                <div data-id={`Tags-${record.name}`}>
                    <PackageTagsEditor store={store} line={record} />
                </div>
            )
        },
        {
            key: 'featureFlags',
            dataIndex: 'featureFlags',
            title: 'Flags',
            sorter: true,
            sortOrder: getSorterOrder('featureFlags'),
            render: (flags: string, record: Package) => {
                const featureFlags = getFeatureFlags(record);

                return (
                    <Button
                        type="link"
                        size="small"
                        onClick={() => openFeatureFlagsModal(record)}
                        data-id={`Flags-${record.name}`}
                        style={{
                            padding: 0,
                            lineHeight: '10px',
                            height: 18,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%'
                        }}
                    >
                        <span style={{ display: 'inline' }}>
                            {featureFlags?.length > 0 ? featureFlags.join('\n') : 'None'}
                        </span>
                    </Button>
                );
            }
        },
        {
            key: 'uploadedTime',
            dataIndex: 'uploadDate',
            title: 'Upload Date',
            width: 155,
            sorter: true,
            sortOrder: getSorterOrder('uploadedTime'),
            render: (uploadDate: string, record: Package) => (
                <span data-id={`UploadDate-${record.name}`} data-id-timestamp={uploadDate}>
                    {Utils.formatDateStringShort(uploadDate, true)}
                </span>
            )
        },
        {
            key: 'indexDate',
            dataIndex: 'indexDate',
            title: 'Index date',
            width: 155,
            sorter: true,
            sortOrder: getSorterOrder('indexDate'),
            render: (indexDate: string, record: Package) => (
                <div className="overlaying-row-actions">
                    <span data-id={`IndexDate-${record.name}`} className="cell-text" data-id-timestamp={indexDate}>
                        {Utils.formatDateStringShort(indexDate, true)}
                    </span>
                    <span className="cell-actions">{actionsCellRenderer(record.name, record.id)}</span>
                </div>
            )
        }
    ];

    const getFeatureFlags = (record: Package) => {
        if (!record.featureFlags?.general) {
            return [];
        }

        let generalFlags = Object.keys(record.featureFlags.general);
        generalFlags = generalFlags.map(flag => `${flag} = ${record.featureFlags.general[flag]}`);

        let textFlags = Object.keys(record.featureFlags.text);
        textFlags = textFlags.map(flag => `${flag} = ${record.featureFlags.text[flag]}`);

        let imageFlags = Object.keys(record.featureFlags.image);
        imageFlags = imageFlags.map(flag => `${flag} = ${record.featureFlags.image[flag]}`);

        let allFlags: string[] = [];

        if (generalFlags.length) {
            allFlags = [...allFlags, 'General flags: ', ...generalFlags];
        }

        if (textFlags.length) {
            allFlags = [...allFlags, '\nText flags: ', ...textFlags];
        }

        if (imageFlags.length) {
            allFlags = [...allFlags, '\nImage flags: ', ...imageFlags];
        }

        return allFlags;
    };

    const openFeatureFlagsModal = (record: Package) => {
        setCurrentFeatureFlags(record.featureFlags);
        setCurrentPackageId(record.id);
        setFeatureFlagsDialogVisible(true);
    };

    const getHighlightedRowStyle = React.useCallback(() => ({ backgroundColor: '#E4E5ED' }), []);

    const containerRef = React.useRef<HTMLDivElement>(null);

    const { height } = useDynamicHeight(containerRef, 120);

    return (
        <div ref={containerRef}>
            {featureFlagsDialogVisible && (
                <FeatureFlagsModal
                    currentFeatureFlags={currentFeatureFlags}
                    changeVisible={setFeatureFlagsDialogVisible}
                    store={store}
                    visible={featureFlagsDialogVisible}
                    currentPackageId={currentPackageId}
                />
            )}
            <VirtualTable
                data-id="table-packages-list"
                columns={columns}
                dataSource={store.currentProjectPackages}
                highlightedRowsIndexes={store.protectedPackageIndexes}
                getHighlightedRowStyle={getHighlightedRowStyle}
                scroll={{ y: height }}
                className="uploaded-packages-table"
                loading={store.isLoading}
                pagination={{
                    pageSize: store.pageSize,
                    hideOnSinglePage: store.packages.length <= LIST_PAGE_SIZE,
                    pageSizeOptions: [LIST_PAGE_SIZE.toString(), '100', '150', '200', '250'],
                    total: store.totalPackagesInProject,
                    onChange: (page: number, pageSize?: number) => {
                        store.setPageSize(pageSize);
                        store.setCurrentPage(page);
                    },
                    current: store.currentPage + 1
                }}
                rowSelection={{
                    hideSelectAll: false,
                    onChange: store.handleSelection
                }}
                onChange={(pagination, _, sorter: SorterResult<Package>) => {
                    let orderBy: OrderBy | undefined;

                    if (sorter.columnKey && sorter.order) {
                        orderBy = {
                            field: sorter.columnKey as string,
                            direction: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                        };
                    }

                    store.setOrderBy(orderBy);
                    store.setPageSize(pagination.pageSize);
                    store.loadProjectPackages(store.searchTerm.trim());
                }}
            />
        </div>
    );
};

export default observer(UploadedPackagesGridFc);
