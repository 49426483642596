import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { WorkflowOutputSchemeType, CreateEditWorkflowFormValues, WorkflowOutputSchemeDto } from '../../types';
import { workflowOutputSchemeTypeOptions } from '../../misc';
import InstructWorkflowModel from '../../model/InstructWorkflowModel';
import './CreateEditOutputSchemeDialog.less';

type OutputSchemeFormValues = {
    outputSchemeName: string;
    outputSchemeType: WorkflowOutputSchemeType;
    outputSchemeScheme: string;
};

const fields: Record<keyof WorkflowOutputSchemeDto, keyof OutputSchemeFormValues> = {
    name: 'outputSchemeName',
    type: 'outputSchemeType',
    scheme: 'outputSchemeScheme'
};

const fieldList = Object.values(fields);

interface Props {
    workflow: InstructWorkflowModel;
    editableOutputSchemeIndex: number | null;
    form: FormInstance<CreateEditWorkflowFormValues & Partial<OutputSchemeFormValues>>;
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
    clearEditableOutputSchemeIndex: () => void;
}

const CreateEditOutputSchemeDialog: React.FC<Props> = ({
    workflow,
    editableOutputSchemeIndex,
    form,
    isVisible,
    setIsVisible,
    clearEditableOutputSchemeIndex
}) => {
    const editableOutputScheme = React.useMemo(() => {
        if (typeof editableOutputSchemeIndex !== 'number') {
            return;
        }

        return workflow.modifiedOutputSchemes[editableOutputSchemeIndex];
    }, [workflow, editableOutputSchemeIndex]);

    const onCreate = () => {
        form.validateFields(fieldList).then(() => {
            const formValues: OutputSchemeFormValues = form.getFieldsValue(fieldList);

            const outputScheme = {
                name: formValues.outputSchemeName,
                type: formValues.outputSchemeType,
                scheme: formValues.outputSchemeScheme
            };

            if (typeof editableOutputSchemeIndex === 'number') {
                workflow.updateOuputScheme(outputScheme, editableOutputSchemeIndex);
            } else {
                workflow.addOuputScheme(outputScheme);
            }

            onClose();
        });
    };

    const onClose = () => {
        setIsVisible(false);
        clearEditableOutputSchemeIndex();
    };

    return (
        <Modal
            className="create-edit-output-scheme-dialog"
            title={editableOutputScheme ? 'Update Output Scheme' : 'Add Output Scheme'}
            visible={isVisible}
            width={500}
            onCancel={onClose}
            maskClosable={false}
            closable={false}
            destroyOnClose
            centered
            footer={[
                <Button
                    data-id="create-edit-output-scheme-cancel"
                    key="create-edit-output-scheme-cancel"
                    className="light"
                    size="large"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="create-output-scheme"
                    key="create-output-scheme"
                    size="large"
                    type="primary"
                    onClick={onCreate}
                >
                    {editableOutputScheme ? 'Update' : 'Add'}
                </Button>
            ]}
        >
            <Form.Item
                label="Name"
                name={fields.name}
                initialValue={editableOutputScheme ? editableOutputScheme.name : null}
                rules={[{ required: true, message: 'Please enter scheme name!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Type"
                name={fields.type}
                initialValue={editableOutputScheme ? editableOutputScheme.type : null}
                rules={[{ required: true, message: 'Please select type!' }]}
            >
                <Select options={workflowOutputSchemeTypeOptions} />
            </Form.Item>

            <Form.Item
                label="Scheme"
                name={fields.scheme}
                initialValue={editableOutputScheme ? editableOutputScheme.scheme : null}
                rules={[{ required: true, message: 'Please enter scheme!' }]}
            >
                <Input.TextArea rows={12} />
            </Form.Item>
        </Modal>
    );
};

export default observer(CreateEditOutputSchemeDialog);
