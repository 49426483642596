enum PackageState {
    Uploaded = 'uploaded',
    ChangedAnchors = 'changedAnchors',
    ChangedLabels = 'changedLabels',
    ChangedManualText = 'changedManualText',
    Ready = 'ready',
    Busy = 'busy',
    Broken = 'broken'
}

export default PackageState;
