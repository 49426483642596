import * as React from 'react';
import { CodeSnippetsVisualStore } from '../stores/CodeSnippetsVisualStore';
import { observer } from 'mobx-react-lite';
import { Input, Select, Spin, Button, Form, Modal } from 'antd';
import MonacoEditor from '../../iota_applications/components/MonacoEditor';
import { editor } from 'monaco-editor';
import { SharedInstanceManager } from '../../iota_applications/services';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import { CodeSnippet } from '../models';

const { Option } = Select;

type Props = {
    store: CodeSnippetsVisualStore;
};

const CodeSnippetForm: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();
    const mdParser = new MarkdownIt();

    const [markdownDescription, setMarkdownDescription] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        if (store.selectedSnippet) {
            setMarkdownDescription(store.selectedSnippet.description || '');
            const snippet = store.selectedSnippet;
            const snippetGroup = store.selectedSnippetGroup;
            if (snippet) {
                form.setFieldsValue({
                    id: snippet.id,
                    name: snippet.name,
                    type: snippet.type,
                    code: snippet.code,
                    groupId: snippet.groupId
                });
            } else if (snippetGroup) {
                form.setFieldsValue({
                    groupId: snippetGroup.id
                });
            }
        }
    }, [store.selectedSnippet, form, store.selectedSnippetGroup]);

    const bindEditor = () => {
        const manager = new SharedInstanceManager();
        manager.loadCodeIntoEditor(form.getFieldValue('code')).then(() => setIsLoading(false));
    };

    const didMount = (e: editor.IStandaloneCodeEditor) => {
        e.updateOptions({
            glyphMargin: true,
            lightbulb: {
                enabled: true
            }
        });

        bindEditor();
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                values.description = markdownDescription;
                store.saveSnippet(values as CodeSnippet);
                window.location.reload();
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <Modal
            visible={store.formDialogVisible}
            centered
            title={store.selectedSnippet ? 'Edit snippet' : 'Add snippet'}
            onCancel={() => store.setFormDialogVisible(false)}
            maskClosable={false}
            destroyOnClose
            width={'80%'}
            footer={[
                <Button key="1" size="large" className="light" onClick={() => store.setFormDialogVisible(false)}>
                    Cancel
                </Button>,
                <Button key="2" type="primary" size="large" onClick={handleSubmit}>
                    Save
                </Button>
            ]}
            closable={false}
        >
            <Form form={form}>
                <Form.Item name="id">
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item
                    style={{ display: 'inline-block', width: 200, marginRight: 10 }}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Provide name for snippet.'
                        }
                    ]}
                >
                    <Input placeholder="Name" width={200} />
                </Form.Item>
                <Form.Item
                    style={{ display: 'inline-block', width: 210 }}
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: 'Select type of the snippet.'
                        }
                    ]}
                >
                    <Select placeholder="Type" style={{ width: 200 }}>
                        <Option value="code">Code</Option>
                        <Option value="step">Step</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    style={{ display: 'inline-block', width: 210 }}
                    name="groupId"
                    rules={[
                        {
                            required: true,
                            message: 'Select group of the snippet.'
                        }
                    ]}
                >
                    <Select placeholder="Group" style={{ width: 200 }}>
                        {store.codeSnippetGroups.map(g => (
                            <Option key={g.id} value={g.id as string}>
                                {g.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Code"
                    colon={false}
                    name="code"
                    rules={[
                        {
                            required: true,
                            message: 'Code is required.'
                        }
                    ]}
                >
                    <Spin tip="Loading..." spinning={isLoading}>
                        <MonacoEditor
                            width="100%"
                            height="400"
                            language="C#"
                            theme="vs-light"
                            editorDidMount={didMount}
                            value={form.getFieldValue('code')}
                            onChange={code => form.setFieldsValue({ code: code })}
                            snippets={store.codeSnippets}
                        />
                    </Spin>
                </Form.Item>
                <Form.Item label="Description" colon={false} style={{ display: 'block' }}>
                    <MdEditor
                        style={{ borderRadius: 4 }}
                        name="code-snippet-description"
                        renderHTML={text => mdParser.render(text.toString())}
                        onChange={({ text }) => setMarkdownDescription(text)}
                        value={markdownDescription}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(CodeSnippetForm);
