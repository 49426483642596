import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Form, DatePicker, Spin, Popconfirm } from 'antd';
import { Moment } from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { UploadedPackagesVisualStore } from '../../stores';
import { getFormattedDateRange } from '../../../common/Utils';
import './DeletePackagesDialog.less';

const dateFormat = 'YYYY-MM-DD';

const { RangePicker } = DatePicker;

type Props = {
    store: UploadedPackagesVisualStore;
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
};

type FormValues = {
    dateRange: [Moment, Moment];
};

const DeletePackagesDialog: React.FC<Props> = ({ store, isVisible, setIsVisible }) => {
    const [form] = Form.useForm<FormValues>();

    const [packageCountLoading, setPackageCountLoading] = React.useState(false);
    const [packageCount, setPackageCount] = React.useState(0);
    const [noPackagesFound, setNoPackagesFound] = React.useState(false);
    const [dateRangeValid, setDateRangeValid] = React.useState(false);

    const onClose = () => {
        setIsVisible(false);
        setPackageCountLoading(false);
        setPackageCount(0);
        setNoPackagesFound(false);
        setDateRangeValid(false);
    };

    const onFinish = () => {
        form.validateFields().then(({ dateRange }) => {
            const [startDate, endDate] = getFormattedDateRange(dateRange, dateFormat);
            store.bulkDeletePackagesByDateRange(startDate, endDate);
            onClose();
        });
    };

    const onValuesChange = ({ dateRange }: Partial<FormValues>) => {
        if (dateRange) {
            setDateRangeValid(true);
            const [startDate, endDate] = getFormattedDateRange(dateRange, dateFormat);
            getPackageCountByDateRange(startDate, endDate);
        } else {
            setPackageCount(0);
            setNoPackagesFound(false);
            setDateRangeValid(false);
        }
    };

    const getPackageCountByDateRange = async (startDate: string, endDate: string) => {
        try {
            setPackageCountLoading(true);
            setNoPackagesFound(false);

            const count = await store.getPackageCountByDateRange(startDate, endDate);

            setPackageCount(count);
            setNoPackagesFound(!count);
        } finally {
            setPackageCountLoading(false);
        }
    };

    const packagesLabel = () => {
        if (noPackagesFound) {
            return <span className="no-packages-found">No packages found!</span>;
        }

        if (dateRangeValid) {
            return (
                <span className="package-count">
                    Package count:{' '}
                    {packageCountLoading ? <Spin indicator={<LoadingOutlined />} size="small" /> : `${packageCount}`}
                </span>
            );
        }

        return null;
    };

    return (
        <Modal
            className="alpha-portal-dialog"
            title="Bulk Package Delete"
            visible={isVisible}
            onCancel={onClose}
            maskClosable={false}
            closable={false}
            destroyOnClose
            centered
            footer={[
                <Button
                    data-id="delete-packages-dialog-close"
                    key="back"
                    size="large"
                    className="light"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                !packageCountLoading && packageCount ? (
                    <Popconfirm
                        key="submit"
                        title={`Are you sure you want to delete ${packageCount} ${packageCount === 1 ? 'package' : 'packages'}?`}
                        onConfirm={onFinish}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button data-id="delete-packages-dialog-submit" size="large" type="primary">
                            Delete
                        </Button>
                    </Popconfirm>
                ) : (
                    <Button
                        key="submit-disabled"
                        data-id="delete-packages-dialog-submit-disabled"
                        size="large"
                        type="primary"
                        disabled
                    >
                        Delete
                    </Button>
                )
            ]}
        >
            <Form
                id="delete-packages-form"
                className="delete-packages-form"
                layout="vertical"
                form={form}
                preserve={false}
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    label="Upload date range"
                    name="dateRange"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a date range!'
                        }
                    ]}
                >
                    <RangePicker />
                </Form.Item>

                {packagesLabel()}
            </Form>
        </Modal>
    );
};

export default observer(DeletePackagesDialog);
