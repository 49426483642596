import { observable } from 'mobx';
import { SmartIndexRuleResult, RuleError, OverriddenWorkflowParameters } from '../types';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import TagRuleBase from './TagRuleBase';
import PipelineStepType from '../types/PipelineStepType';
import { RuleStatus } from './RuleBase';

export default class SmartIndexRule extends TagRuleBase<SmartIndexRule, SmartIndexRuleResult> {
    @observable
    instructWorkflowId: string;

    @observable
    prompt: string;

    @observable
    outputSchemeName: string | undefined;

    @observable
    overriddenWorkflowParameters: OverriddenWorkflowParameters | undefined;

    constructor(
        id: string | null,
        projectId: string | null,
        name: string,
        tagId: string | null,
        tag: string,
        instructWorkflowId: string,
        prompt: string,
        pipeline: PipelineStep<PipelineStepType>[] = [],
        groupId: string | null,
        updateDate?: Date,
        priority: number = 1,
        description?: string,
        state?: 'Enabled' | 'Disabled',
        status: RuleStatus = RuleStatus.Valid,
        overridePriority: boolean = false,
        outputSchemeName: string | undefined = undefined,
        overriddenWorkflowParameters: OverriddenWorkflowParameters | undefined = undefined,
        errors?: RuleError[]
    ) {
        super(
            id,
            projectId,
            name,
            tagId,
            tag,
            'SmartIndexRule',
            pipeline,
            groupId,
            updateDate,
            priority,
            description,
            state,
            status,
            overridePriority,
            errors
        );

        this.instructWorkflowId = instructWorkflowId;
        this.prompt = prompt;
        this.outputSchemeName = outputSchemeName;
        this.overriddenWorkflowParameters = overriddenWorkflowParameters;
    }

    clone(): SmartIndexRule {
        return new SmartIndexRule(
            this.id,
            this.projectId,
            this.name,
            this.tagId,
            this.tag,
            this.instructWorkflowId,
            this.prompt,
            this.clonePipeline(),
            this.groupId,
            this.updateDate,
            this.priority,
            this.description,
            this.state,
            this.status,
            this.overridePriority,
            this.outputSchemeName,
            this.overriddenWorkflowParameters
        );
    }

    validateState(): string[] {
        if (!this.name || !this.instructWorkflowId || !this.tag) {
            return ['Fill all fields'];
        }

        const errors = this.validatePipeline();

        return errors;
    }

    toJson(): SmartIndexRuleResult {
        const json: SmartIndexRuleResult = {
            ruleType: this.ruleType,
            id: this.id,
            groupId: this.groupId,
            name: this.name,
            instructWorkflowId: this.instructWorkflowId,
            tagId: this.tagId,
            tag: this.tag,
            pipeline: this.pipelineToJSON(),
            projectId: this.projectId,
            priority: this.priority,
            description: this.description,
            state: this.state,
            status: this.status,
            overridePriority: this.overridePriority,
            prompt: this.prompt,
            outputSchemeName: this.outputSchemeName,
            overriddenWorkflowParameters: this.overriddenWorkflowParameters
        };

        return json;
    }
}
