import { Button, Checkbox, Form, Input, Modal } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import permissions from '../../authorization/Permissions';
import UsersManagerStore from '../stores/UsersManagerStore';

type Props = {
    store: UsersManagerStore;
};

const CustomRoleDialog: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();
    const [title, setTitle] = React.useState('');

    React.useEffect(() => {
        if (!store.customRoleDialogModel) {
            form.resetFields();
        } else {
            form.setFieldsValue({
                id: store.customRoleDialogModel.id,
                name: store.customRoleDialogModel.name,
                permissions: store.customRoleDialogModel.permissions
            });
        }
        setTitle(store.customRoleDialogModel?.id ? 'Edit role' : 'Create role');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.customRoleDialogModel]);

    React.useEffect(() => {
        if (!store.customRoleDialogVisible) {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.customRoleDialogVisible]);

    const handleCancel = () => {
        store.setCustomRoleDialogVisible(false);
        store.setCustomRoleDialogModel(undefined);
    };

    const handleSubmit = () => {
        const name = form.getFieldValue('name');
        const rolePermissions = form.getFieldValue('permissions');
        const id = form.getFieldValue('id');
        store.saveCustomRole(name, rolePermissions, id);
    };

    const nameExists = (value: unknown) => {
        const role = store.customRoles.find(r => r.name === value);
        const id = form.getFieldValue('id');
        return role && id !== role.id;
    };

    return (
        <Modal
            title={title}
            closable={false}
            visible={store.customRoleDialogVisible}
            className="alpha-portal-dialog"
            onCancel={handleCancel}
            centered
            maskClosable={false}
            destroyOnClose
            forceRender
            footer={[
                <Button
                    data-id="role-create-edit-dialog-cancel"
                    key="back"
                    onClick={handleCancel}
                    size="large"
                    className="light"
                >
                    Cancel
                </Button>,
                <Button
                    data-id="role-create-edit-dialog-submit"
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    form="role-create-edit-dialog-form"
                    size="large"
                >
                    Save
                </Button>
            ]}
        >
            <Form
                id="role-create-edit-dialog-form"
                data-id="role-create-edit-dialog-form"
                form={form}
                layout="vertical"
                colon={false}
                onFinish={handleSubmit}
            >
                <FormItem name="id" style={{ display: 'none' }}>
                    <Input type="hidden" />
                </FormItem>
                <FormItem
                    name="name"
                    label="Name"
                    rules={[
                        {
                            validator: (_, value) =>
                                nameExists(value)
                                    ? Promise.reject(new Error('Role with that name already exists'))
                                    : Promise.resolve()
                        },
                        { required: true, message: 'Name is required' }
                    ]}
                >
                    <Input />
                </FormItem>
                <Form.Item
                    name="permissions"
                    label="Permissions"
                    rules={[{ required: true, message: 'Select at least one permission' }]}
                >
                    <Checkbox.Group className="vertical-checkbox-group" options={permissions.admin} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(CustomRoleDialog);
