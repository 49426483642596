import { CellMeasurerCache, TableCellProps, CellMeasurer } from 'react-virtualized';
import * as React from 'react';
import { HTMLAttributes } from 'react';
import { PackageLine } from '../models';

// eslint-disable-next-line max-len
type ElementRenderer<T extends JSX.Element, TRow> = (
    line: TRow,
    props: HTMLAttributes<T>
) => React.ReactElement<HTMLAttributes<T>>;

// eslint-disable-next-line max-len
export default function <T extends JSX.Element, TRow = PackageLine>(
    renderer: ElementRenderer<T, TRow>,
    packageLinesGetter: () => TRow[],
    cache: CellMeasurerCache,
    idx: number,
    width?: number
) {
    return function ({ dataKey, parent, rowIndex }: TableCellProps) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let style: any = { whiteSpace: 'normal', padding: '10px 5px' };

        if (rowIndex === packageLinesGetter().length) {
            return null;
        }

        if (width) {
            style = { ...style, width: `${width}px` };
        }

        return (
            <CellMeasurer
                cache={cache}
                columnIndex={idx}
                key={dataKey}
                parent={parent}
                className={'tableColumn'}
                rowIndex={rowIndex}
            >
                {renderer(packageLinesGetter()[rowIndex], { style: style })}
            </CellMeasurer>
        );
    };
}
