import * as React from 'react';
import { observer, inject } from 'mobx-react';
import ConnectionEditor from '../components/ConnectionEditor';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { RulesStores } from '../stores';
import { STORE_RULES_CONNECTIONS } from '../constants';

type Props = RulesStores;

export const RulesListPage: React.FC<Props> = () => {
    const content = <ConnectionEditor />;

    return <FlexContentContainer content={content} />;
};

export default inject(STORE_RULES_CONNECTIONS)(observer(RulesListPage));
