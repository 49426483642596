import { Button, Form, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { RuleEditForm } from '.';
import { STORE_RULES_LIST, STORE_RULE_EDIT } from '../constants';
import { RuleNewVisualStore, RulesStores } from '../stores';
import { inject } from 'mobx-react';
import type RuleType from '../types/RuleType';

type Props = RulesStores & {
    store: RuleNewVisualStore;
};

const RuleCreateDialog: React.FC<Props> = ({ RuleEditUI, RulesListUI, store }) => {
    const [isValidationFailed, setIsValidationFailed] = React.useState(false);
    const editStore = RuleEditUI!;
    const listStore = RulesListUI!;
    const [form] = Form.useForm();

    const handleCancel: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.preventDefault();
        store.setIsCreateDialogVisible(false);
        form.resetFields();
        form.setFieldsValue({ ruleType: 'ElasticSearchMatchRule' });
        editStore.clearPreselecedData();
        editStore.cancel();
        editStore.setCurrentGroupId({});
    };

    const handleSubmit = async () => {
        form.validateFields()
            .then(async () => {
                if (!editStore.isValidationFailed()) {
                    //  && isMounted.current
                    applyFields();
                    const savedRuleId = await editStore.save();

                    if (savedRuleId != null) {
                        store.setIsCreateDialogVisible(false);
                        form.resetFields();
                        form.setFieldsValue({ ruleType: 'ElasticSearchMatchRule' });
                        listStore.edit(savedRuleId);
                        editStore.clearPreselecedData();
                    }
                }
            })
            .catch(err => {
                console.error(err);
                setIsValidationFailed(true);
            });
    };

    const getHeaderButtons = () => {
        let buttons = [
            <Button key="cancel" size="large" className="light" htmlType="button" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button loading={editStore.isLoading} key="submit" type="primary" size="large" onClick={handleSubmit}>
                Create rule
            </Button>
        ];
        return buttons;
    };

    const applyFields = () => {
        const formFields = Object.entries(form.getFieldsValue(true)).map(([key, value]) => ({ name: [key], value }));
        editStore.applyFields(formFields);
    };

    const handleSelect = (type: RuleType | undefined) => {
        applyFields();
        store.selectType(type);
    };

    return (
        <Modal
            title="New Rule"
            visible={store.isCreateDialogVisible}
            onCancel={handleCancel}
            footer={getHeaderButtons()}
            destroyOnClose
            centered
            closable={false}
            maskClosable={false}
            className="alpha-portal-dialog two-columns"
            width={600}
        >
            <RuleEditForm form={form} isNew selectType={handleSelect} isValidationFailed={isValidationFailed} />
        </Modal>
    );
};

export default inject(STORE_RULE_EDIT, STORE_RULES_LIST)(observer(RuleCreateDialog));
