import * as React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Button, Tooltip, Input, Form } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { AdministrationStores } from '../stores';
import { observer } from 'mobx-react-lite';

type Props = AdministrationStores;
export const UserGroupDialog: React.FC<Props> = ({ ProjectAssignment: store }) => {
    const [form] = Form.useForm();

    const handleCancel = () => {
        store!.setIsAddGroupDialogVisible(false);
    };

    const handleSubmit = (e: React.FormEvent<object>) => {
        e.preventDefault();
        form.validateFields()
            .then(values => {
                store!.createUpdateGroup(values.name);
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <Modal
            visible={store!.isAddGroupDialogVisible}
            title="New group"
            className="alpha-portal-dialog"
            onCancel={handleCancel}
            centered
            closable={false}
            maskClosable={false}
            destroyOnClose
            footer={[
                <Button
                    data-id="project-assignments-add-group-dialog-cancel"
                    key="back"
                    onClick={handleCancel}
                    size="large"
                    className="light"
                >
                    Cancel
                </Button>,
                <Button
                    data-id="project-assignments-add-group-dialog-submit"
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                    size="large"
                >
                    Create group
                </Button>
            ]}
        >
            <Form form={form} data-id="project-assignments-add-group-form" layout="vertical">
                <FormItem
                    label={
                        <span>
                            Group name&nbsp;
                            <Tooltip title="User Group">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    name="name"
                    rules={[{ required: true, message: 'Please input user group name!', whitespace: true }]}
                >
                    <Input />
                </FormItem>
            </Form>
        </Modal>
    );
};

export default observer(UserGroupDialog);
