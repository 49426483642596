import appClient from '../../common/services/AppClient';
import { ProcessType } from '../../common/types';

export class AdministrationService {
    async indexPackagesByValue(
        value: string,
        type: 'ByState' | 'ByUserTag',
        processType: ProcessType,
        projectId?: string
    ) {
        const url = processType === ProcessType.Reparse ? this.getUrl() : this.getReindexUrl();
        const data = { options: type, value: value, projectId: projectId };
        const resp = await appClient.post(url, data);
        resp.mapErr(() => console.error(`Error occured while indexing ${type}: ${value}`));
    }

    async indexByProject(projectId: string, processType: ProcessType) {
        const url = processType === ProcessType.Reparse ? this.getUrl() : this.getReindexUrl();
        const data = { options: 'ByProject', value: projectId };
        const resp = await appClient.post(url, data);

        return resp.mapErr(err => {
            console.error(`Error occured while indexing project ${projectId}`);
            return new Error(err.text);
        });
    }

    async parseByPackage(packageId: string) {
        const url = this.getUrl();
        const data = { options: 'ByPackage', value: packageId };
        const resp = await appClient.post(url, data);
        return resp.mapErr(err => {
            console.error(`Error occured while indexing package ${packageId}`);
            return new Error(err.text);
        });
    }

    async parsePackagesBatch(packageIds: Array<string>) {
        const url = process.env.REACT_APP_MANAGE_URL! + 'admin/packages/parse-batch';
        const data = { packageIds: packageIds };
        return appClient.post(url, data);
    }

    async reindexByPackage(packageId: string) {
        const url = process.env.REACT_APP_MANAGE_URL! + 'admin/packages/reindex';
        const data = { options: 'ByPackage', value: packageId };
        const resp = await appClient.post(url, data);
        return resp.mapErr(err => {
            console.error(`Error occured while indexing package ${packageId}`);
            return new Error(err.text);
        });
    }

    async bulkDeletePackagesByDateRange(projectId: string, startDate: string, endDate: string) {
        const url = process.env.REACT_APP_MANAGE_URL + 'admin/packages/bulk-delete-by-date-range';
        return await appClient.post(url, { projectId, startDate, endDate });
    }

    async getPackageCountByDateRange(projectId: string, startDate: string, endDate: string) {
        const url = process.env.REACT_APP_MANAGE_URL + 'admin/packages/get-count-by-date-range';
        const resp = await appClient.post<number>(url, { projectId, startDate, endDate });
        return resp.unwrapOr(0);
    }

    async deletePackage(id: string) {
        const url = process.env.REACT_APP_MANAGE_URL! + `packages/${id}`;
        return appClient.delete(url);
    }

    async cleanStorage(packageId: string) {
        const url = process.env.REACT_APP_MANAGE_URL! + 'admin/projects/cleanup';
        const data = { options: 'ByPackage', id: packageId };
        const resp = await appClient.post(url, data);

        return resp.mapErr(err => {
            console.error(`Error occured while indexing package ${packageId}`);
            return new Error(err.text);
        });
    }

    async cleanupByProject(projectId: string) {
        const url = process.env.REACT_APP_MANAGE_URL! + 'admin/projects/cleanup';
        const data = { options: 'ByProject', id: projectId };

        const resp = await appClient.post(url, data);
        return resp.mapErr(err => {
            console.error(`Error occured while cleaning project ${projectId}`);
            return new Error(err.text);
        });
    }

    async updatePackagesProtection(packageIds: string[], isProtected: boolean) {
        const request = {
            packageIds,
            isProtected
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'packages/update-protection';
        return appClient.update(url, request);
    }

    private getUrl() {
        return process.env.REACT_APP_MANAGE_URL! + 'admin/packages/parse';
    }

    private getReindexUrl() {
        return process.env.REACT_APP_MANAGE_URL! + 'admin/packages/reindex';
    }
}
