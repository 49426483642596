/* eslint-disable no-dupe-class-members */
export default class LocalStorageService {
    setItem<T>(key: string, value: T): void {
        localStorage.setItem(key, JSON.stringify({ value }));
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    getItem<T>(key: string): T | null;
    getItem<T>(key: string, otherwise: T): T;
    getItem<T>(key: string, otherwise?: T): T | null {
        const data: string | null = localStorage.getItem(key);

        if (data !== null) {
            return JSON.parse(data).value;
        }

        if (otherwise) {
            return otherwise;
        }

        return null;
    }
}
