import * as React from 'react';
import { observer, inject } from 'mobx-react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { RulesStores } from '../stores';
import { STORE_RULES_LIST, STORE_RULES_IMPORT, STORE_TAGS_GROUP, STORE_RULES_NEW } from '../constants';
import { TagsGroupList } from '../components/TagsGroupList';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { reaction } from 'mobx';

type Props = RulesStores;
type State = { isDataLoaded: boolean };
@inject(STORE_RULES_LIST, STORE_RULES_IMPORT, STORE_TAGS_GROUP, STORE_RULES_NEW)
@observer
export default class RulesListPage extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = { isDataLoaded: false };
    }

    componentDidMount() {
        this.props.RulesListUI!.getAppDefinitions();

        if (this.props.RulesListUI!.currentProject) {
            this.props.RulesListUI!.loadData();
            this.props.RulesListUI!.getPinnedRules();
        } else {
            reaction(
                () => this.props.RulesListUI!.currentProject,
                (cp, r) => {
                    if (cp) {
                        this.props.RulesListUI!.getAppDefinitions();
                        this.props.RulesListUI!.getPinnedRules();
                        r.dispose();
                    }
                }
            );
        }

        this.props
            .TagsGroupUI!.loadTagsGroups()
            .then(() => {
                this.setState({ isDataLoaded: true });
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {
        const rulesListStore = this.props.RulesListUI!;
        const tagsGroupStore = this.props.TagsGroupUI!;
        const rulesImportStore = this.props.RulesImportUI!;
        const rulesNewUi = this.props.RuleNewUI!;
        const lines =
            !rulesListStore.isLoading &&
            !tagsGroupStore!.isLoading &&
            !rulesImportStore.isLoading &&
            this.state.isDataLoaded ? (
                <TagsGroupList
                    RulesListUI={rulesListStore}
                    TagsGroupUI={tagsGroupStore}
                    RulesImportUI={rulesImportStore}
                    RuleNewUI={rulesNewUi}
                />
            ) : (
                <LoadingIndicator />
            );

        return <FlexContentContainer content={lines} />;
    }
}
