import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { ChatbotStore } from '../../stores';
import { ChatbotErrorModel, ChatbotQuestionModel } from '../../models';
import ChatbotQuestion from './ChatbotQuestion';
import ChatbotErrorMessage from './ChatbotErrorMessage';
import './ChatbotDisplayItems.less';

type Props = {
    store: ChatbotStore;
};

const ChatbotDisplayItems: React.FC<Props> = ({ store }) => {
    const [lastViewedQuestionsPackageId, setLastViewedQuestionsPackageId] = React.useState('');

    const [refs, setRefs] = React.useState<Map<string, React.RefObject<HTMLDivElement>>>(new Map());

    const addRef = React.useCallback((id: string, ref: React.RefObject<HTMLDivElement>) => {
        setRefs(map => new Map(map.set(id, ref)));
    }, []);

    React.useEffect(() => {
        if (store.dialogVisible && store.selectedPackage) {
            setLastViewedQuestionsPackageId(store.selectedPackage.id);
        }
    }, [store, store.dialogVisible, store.selectedPackage]);

    React.useEffect(() => {
        if (!store.lastDisplayItem) {
            return;
        }

        const ref = refs.get(store.lastDisplayItem.id);

        // Set last question position immediately when changing documents
        if (store.uiBehaviour.scrollIntoViewImmediately) {
            ref?.current?.scrollIntoView({ block: 'start' });
            return;
        }

        // Smooth scrolling when receiving anwser to a question or error
        const timeout = setTimeout(() => {
            ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 50);

        return () => clearTimeout(timeout);
    }, [refs, lastViewedQuestionsPackageId, store.uiBehaviour, store.lastDisplayItem, store.lastQuestion?.processing]);

    return (
        <div className="chatbot-display-items">
            {store.displayItems.map(item => {
                if (item instanceof ChatbotQuestionModel) {
                    return <ChatbotQuestion key={item.id} store={store} question={item} addRef={addRef} />;
                }

                if (item instanceof ChatbotErrorModel) {
                    return <ChatbotErrorMessage key={item.id} error={item} addRef={addRef} />;
                }

                return null;
            })}
        </div>
    );
};

export default observer(ChatbotDisplayItems);
