import { AutoCompleteSourceItem } from '../types';
import * as Highlight from 'react-highlighter';
import * as React from 'react';

type StoreWithSearchTerm = {
    searchTerm: string | null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function (store: StoreWithSearchTerm): any {
    return function (item: AutoCompleteSourceItem, idx: number) {
        return (
            <div key={idx + item.text} className="global-search-item-container">
                <div className="global-search-item-result">
                    <Highlight search={store.searchTerm}>{item.text}</Highlight>
                </div>
                <div className="global-search-item-count">{item.packageName}</div>
            </div>
        );
    };
}
