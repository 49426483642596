export const STORE_ADMINISTRATION_PROJECT = 'administrationProjectUI';

export const STORE_PROJECT_METADATA = 'projectMetadataUI';

export const STORE_UPLOADED_PACKAGES = 'uploadedPackagesUI';

export const STORE_PROJECT_FORM = 'projectForm';

export const STORE_PACKAGE_PROBLEM_REPORTS_UI = 'packageProblemsReportsUI';

export const STORE_PROJECT_EXPORT = 'projectExport';

export const STORE_PROJECT_IMPORT = 'projectImport';
