import DocumentViewVisualStore from './DocumentViewVisualStore';
import { RootStores } from '../../common/stores';
import { STORE_DOCUMENT_VIEW } from '../constants';
import DocumentViewService from '../services/DocumentViewService';

export { default as PackageViewerVisualStoreBase } from './PackageViewerVisualStoreBase';
export { default as DocumentViewVisualStore } from './DocumentViewVisualStore';

export function injectViewerStores(obj: RootStores) {
    const service = new DocumentViewService();
    obj[STORE_DOCUMENT_VIEW] = new DocumentViewVisualStore(service);
}

export type ViewerStores = {
    [STORE_DOCUMENT_VIEW]?: DocumentViewVisualStore;
};
