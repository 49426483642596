import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip, Input, Modal, Button, Tree, Upload, message, Form } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { RcFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { IotaAppStores } from '../stores';
import { ApplicationCapabilities, ApplicationDefinitionCreateModel } from '../types';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { TreeNode } = Tree;

type Props = IotaAppStores;

interface Hash {
    [details: string]: string & { file: RcFile; files: RcFile[] };
}

export const AddApplicationDefinition: React.FC<Props> = observer(({ projectApplicationDefinitionsUI: store }) => {
    const [form] = Form.useForm();

    const initialImageUrl: string | undefined = undefined;
    const [fileUploading, setFileUploading] = React.useState(false);
    const [imageUrl, setimageUrl] = React.useState(initialImageUrl);
    const [meta, setMeta] = React.useState('');
    const [capabilities, setCapabilities] = React.useState<ApplicationCapabilities | undefined>(undefined);

    const handleSubmit = async (e: React.FormEvent<object>) => {
        e.preventDefault();
        form.validateFields()
            .then(async (values: Hash) => {
                const metaJSON = JSON.stringify(meta);

                const model = new ApplicationDefinitionCreateModel(
                    capabilities?.customApplicationDefinition ?? 'ApplicationDefinition',
                    values.name,
                    values.applicationId,
                    values.workflowId,
                    'Draft',
                    store!.currentProject!.id,
                    JSON.stringify(store!.selectedSettingsMetadata!),
                    metaJSON,
                    values.extension,
                    values.icon ? values.icon.file : undefined,
                    JSON.stringify(capabilities)
                );
                setimageUrl(undefined);
                await store!.createApplicationDefinition(model);
                form.resetFields();
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleCancel = () => {
        form.resetFields();
        store!.setAddDefinitionDialogVisible(false);
        setimageUrl(undefined);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSelect = (selectedKeys: string[], e: any) => {
        form.setFieldsValue({ workflowId: selectedKeys[0] });
        setMeta(e.node.props['data-meta']);
        setCapabilities(e.node.props['data-capabilities']);

        if (e.selectedNodes && e.selectedNodes.length > 0) {
            form.setFieldsValue({ applicationId: e.node.props['data-appid'], extension: e.node.props['data-extname'] });
            const extName = e.node.props['data-extname'];
            const extVersion = e.node.props['data-extver'];

            var extensions = store!.iotaExtensions.filter(ext => ext.name === extName && ext.version === extVersion);

            if (extensions && extensions.length > 0) {
                store!.setSelectedSettingsMetadata(extensions[0].settings);
            }
        }
    };

    const beforeUpload = (file: RcFile) => {
        var reader = new FileReader();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reader.onload = function (e: any) {
            setimageUrl(e.target.result);
        };

        reader.readAsDataURL(file);
        return false;
    };

    const handleFileChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        setFileUploading(true);
        if (status !== 'uploading') {
            setFileUploading(false);
            console.log(info.file, info.fileList);
        }

        if (status === 'done') {
            setFileUploading(false);
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            setFileUploading(false);
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const uploadButton = <div>{fileUploading ? <LoadingOutlined /> : <i className="alpha-icon lg plus-icon" />}</div>;

    return (
        <Modal
            className="alpha-portal-dialog"
            title="New application"
            visible={store!.addDefinitionDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button
                    data-id="iota-add-application-dialog-cancel"
                    className="light"
                    key="back"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="iota-add-application-dialog-submit"
                    key="submit"
                    size="large"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Add application
                </Button>
            ]}
        >
            <Form layout="vertical" form={form} data-id="iota-add-application-dialog-form">
                <FormItem
                    label={
                        <span>
                            Application name&nbsp;
                            <Tooltip title="Application name for Alpha">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    name={'name'}
                    rules={[{ required: true, message: 'Please input application name!', whitespace: true }]}
                >
                    <Input />
                </FormItem>

                <FormItem label={<span>Application icon&nbsp;</span>} name="icon">
                    <Upload
                        name="application-icon"
                        accept=".jpeg,.jpg,.png"
                        listType="picture-card"
                        className="icon-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleFileChange}
                    >
                        {imageUrl ? <img src={imageUrl} alt="application-icon" /> : uploadButton}
                    </Upload>
                </FormItem>

                <FormItem
                    label={
                        <span>
                            Workflow&nbsp;
                            <Tooltip title="Workflow for alpha">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    name="workflowId"
                    rules={[{ required: true, message: 'Please select a workflow!', whitespace: true }]}
                >
                    <Tree
                        className="alpha-portal-tree without-line with-leaf-icons sm-labels"
                        data-id="iota-add-application-dialog-workflow-tree"
                        showLine
                        onSelect={onSelect}
                    >
                        {store!.iotaExtensions &&
                            store!.iotaExtensions.map(e => (
                                /* eslint-disable max-len */
                                <TreeNode
                                    style={{ paddingLeft: 'unset' }}
                                    data-id={`iota-add-application-dialog-workflow-tree-${e.name}`}
                                    data-id-level={0}
                                    title={e.name}
                                    selectable={false}
                                    key={e.name}
                                >
                                    {e.applications.map(a => (
                                        <TreeNode
                                            data-id={`iota-add-application-dialog-workflow-tree-${e.name}-${a.id}`}
                                            data-id-level={1}
                                            title={a.name}
                                            key={a.id}
                                            selectable={false}
                                        >
                                            {a.workflows.map(w => (
                                                <TreeNode
                                                    title={w.name}
                                                    data-id={`iota-add-application-dialog-workflow-tree-${e.name}-${a.id}-${w.id}`}
                                                    data-id-level={2}
                                                    data-meta={w}
                                                    data-appid={a.id}
                                                    data-extname={e.name}
                                                    data-extver={e.version}
                                                    data-capabilities={w.capabilities}
                                                    key={w.id}
                                                    switcherIcon={<i className="alpha-icon md document-icon" />}
                                                />
                                            ))}
                                        </TreeNode>
                                    ))}
                                </TreeNode>
                            ))}
                    </Tree>
                </FormItem>
                <FormItem name="applicationId">
                    <Input type="hidden" />
                </FormItem>
                <FormItem name="extension">
                    <Input type="hidden" />
                </FormItem>
            </Form>
        </Modal>
    );
});

export default AddApplicationDefinition;
