import { observable, computed, action, runInAction } from 'mobx';
import { ProjectsRootVisualStore } from '../../common/stores';
import { InstructWorkflowDto } from '../types';
import InstructWorkflowTypeModel from '../model/InstructWorkflowTypeModel';
import InstructWorkflowModel from '../model/InstructWorkflowModel';
import InstructWorkflowService from '../services/InstructWorkflowService';

export default class InstructWorkflowStore {
    @observable
    workflowTypes: InstructWorkflowTypeModel[] = [];

    @observable
    workflows: InstructWorkflowModel[] = [];

    @observable
    createWorkflowDialogVisible: boolean = false;

    @observable
    isLoading: boolean = false;

    @computed
    get project() {
        return this.projectsRootStore.currentProject;
    }

    @computed
    get editableWorkflow() {
        return this.workflows.find(f => f.isEditable);
    }

    @computed
    get workflowTypeSelectOptions() {
        return this.workflowTypes.map(t => ({
            value: t.type,
            label: t.type
        }));
    }

    constructor(
        readonly projectsRootStore: ProjectsRootVisualStore,
        readonly service: InstructWorkflowService
    ) {}

    @action.bound
    setCreateWorkflowDialogVisible(createWorkflowDialogVisible: boolean) {
        this.createWorkflowDialogVisible = createWorkflowDialogVisible;
    }

    @action.bound
    addWorkflow(data: Partial<InstructWorkflowDto> & Pick<InstructWorkflowDto, 'projectId' | 'workflowTypeId'>) {
        const workflow = new InstructWorkflowModel(this, this.service, data);

        this.workflows.push(workflow);

        return workflow;
    }

    @action.bound
    addNewWorkflow(type: string) {
        const workflowType = this.workflowTypes.find(f => f.type === type);

        if (!this.project || !workflowType) {
            return;
        }

        return this.addWorkflow({
            projectId: this.project.id,
            workflowTypeId: workflowType.id
        });
    }

    @action.bound
    removeWorkflow(workflow: InstructWorkflowModel) {
        this.workflows.splice(this.workflows.indexOf(workflow), 1);

        if (this.projectsRootStore.currentProject?.smartIndexSettings?.instructWorkflowId === workflow.id) {
            this.projectsRootStore.updateProjectSmartIndexSettings({
                enabled: false,
                instructWorkflowId: null
            });
        }
    }

    @action.bound
    removeUnsavedWorkflows() {
        this.workflows = this.workflows.filter(f => !f.isNew);
    }

    @action.bound
    async getData() {
        this.isLoading = true;

        const promises = [this.getWorkflowTypes(), this.getWorkflows()];

        await Promise.all(promises);

        this.isLoading = false;
    }

    @action.bound
    async getWorkflowTypes() {
        if (!this.project) {
            return;
        }

        const data = await this.service.getWorkflowTypes(this.project.id);

        this.workflowTypes = data.map(workflowType => new InstructWorkflowTypeModel(workflowType));
    }

    @action.bound
    async getWorkflows() {
        if (!this.project) {
            return;
        }

        const data = await this.service.getWorkflows(this.project.id);
        this.workflows = [];
        data.forEach(workflow => this.addWorkflow(workflow));
    }

    dispose() {
        runInAction(() => {
            this.isLoading = false;
            this.createWorkflowDialogVisible = false;
            this.workflowTypes = [];
            this.workflows = [];
        });
    }
}
