/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action, computed, reaction, runInAction } from 'mobx';
import { Pager, PAGE_SIZE } from '../../common/stores/Pager';
import _ from 'lodash';
import { ProjectsRootVisualStore } from '../../common/stores';
import { SessionsResponse, SearchSessionsAutoCompleteSourceItem, ApplicationSessionPayload } from '../types';
import { Subject, Observable } from 'rxjs';
import { debounceTime, merge, switchMap, map } from 'rxjs/operators';
import { SessionResult } from '../../common/types';
import { Package } from '../../common/models';
import { ApplicationDefinitionsService } from '../../iota_applications/services';
import { SessionsService } from '../services';
import { SortDirectionType } from 'react-virtualized';
import { GlobalAdministrationService } from '../../administration/service/GlobalAdministrationService';
import { UserModel } from '../../administration/types/UserModel';

export const SEARCH_DEBOUNCE_TIME = 500;

export default class SessionsVisualStore {
    @observable
    selectedPackage: Package | null;

    @observable
    appNames: string[] = [];

    @computed
    get sessions(): SessionResult[] {
        return this.pager.data;
    }

    @computed
    get applicationNames() {
        return this.appNames;
    }

    @observable
    currentDates: string[] = [];

    @observable
    searchTerm: string = '';

    @observable
    isLoading: boolean = false;

    @observable
    pager: Pager<SessionResult>;

    @observable
    currentAppName: string = '';

    @observable
    sortDirection: 'DESC' | 'ASC' = 'DESC';

    @observable
    autocompleteSource: SearchSessionsAutoCompleteSourceItem[] = [];

    @observable
    loadedSessionPayloads: { [sessionId: string]: ApplicationSessionPayload[] } = {};

    @observable
    selectedSessionIndex: number | undefined;

    @observable
    showJsonModal: boolean = false;

    @observable
    displayedJson: object;

    @observable
    jsonModalTitle: string = '';

    userNames: string[] = ['Andrei', 'test', 'dummyUser'];

    @observable
    currentSortedField: string = 'created';

    @observable
    currentUserName: string = '';

    users: UserModel[];

    private searchForAutocompleteCallServer: (s: string) => Promise<void>;
    private searchSubject: Subject<string | null>;
    private searchResult: Observable<SessionsResponse>;

    constructor(
        public sessionService: SessionsService,
        public appDefinitionService: ApplicationDefinitionsService,
        public rootStore: ProjectsRootVisualStore,
        private adminService: GlobalAdministrationService
    ) {
        this.searchSubject = new Subject<string>();
        this.pager = new Pager();
        this.rootStore.projectsStore.sessionChanges.pipe(map(x => x.projectId)).subscribe(p => {
            this.updateSessions(p);
        });
        this.searchResult = this.searchSubject.pipe(
            debounceTime(SEARCH_DEBOUNCE_TIME),
            merge(this.pager.nextPageRequest.let(map(() => this.searchTerm))),
            switchMap(x => Observable.fromPromise(this.searchSessions(x)))
        );

        this.searchResult.subscribe(r => this.setFilteredData(r));
        reaction(
            () => this.rootStore.currentProject,
            p => {
                if (p) {
                    this.performSearch();
                }
            },
            {
                fireImmediately: true
            }
        );
        this.searchForAutocompleteCallServer = _.debounce(this.searchForAutocompleteImp, 200);
    }

    get currentProject() {
        return this.rootStore.currentProject;
    }

    async getUsers() {
        this.users = await this.adminService.getAppUsers();
    }

    @action.bound
    setShowJsonModal(show: boolean) {
        this.showJsonModal = show;
    }

    @action.bound
    setJsonModalTitle(title: string) {
        this.jsonModalTitle = title;
    }

    @action.bound
    setDisplayedJson(json: object) {
        this.displayedJson = json;
    }

    @action.bound
    setselectedSessionIndex(sessionId: number | undefined) {
        this.selectedSessionIndex = sessionId;
    }

    @action.bound
    performSearch(term: string = '') {
        this.searchTerm = term;
        this.pager.resetPager();
        this.isLoading = true;
        this.searchSubject.next(this.searchTerm);
    }

    @action
    async getApplicationNames() {
        this.isLoading = true;
        if (this.rootStore.currentProject) {
            const appDefs = await this.appDefinitionService.getApplicationDefinitionsForProject(
                this.rootStore.currentProject!.id
            );
            runInAction(() => (this.appNames = appDefs.map(a => a.name)));
            runInAction(() => (this.isLoading = false));
        } else {
            reaction(
                () => this.rootStore.currentProject,
                (p, r) => {
                    this.getApplicationNames();
                    r.dispose();
                }
            );
        }
    }

    @action
    clearFilter() {
        this.searchTerm = '';
        this.currentAppName = '';
        this.currentDates = [];
        this.currentUserName = '';
        this.performSearch();
    }

    @action
    loadNextPage() {
        this.pager.loadNextPage();
    }

    @action.bound
    async searchForAutocomplete(term: string) {
        this.searchTerm = term;
        return this.searchForAutocompleteCallServer(this.searchTerm);
    }

    @action.bound
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setDates(dates: any, dateStrings: [string, string]) {
        if (!dates.length) {
            this.currentDates = [];
        } else {
            this.currentDates = dateStrings;
        }
        console.log(dates);
        this.performSearch(this.searchTerm);
    }

    @action.bound
    setApplicationName(appName: string) {
        this.currentAppName = appName;
        this.performSearch(this.searchTerm);
    }

    @action
    setFilteredData(response: SessionsResponse) {
        const { lines, total } = response;
        this.pager.setData(lines, total);
        this.isLoading = false;
    }

    @action.bound
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortByDates(e: any) {
        this.sortDirection = e.sortDirection;
        this.performSearch(this.searchTerm);
    }

    @action.bound
    setUserName(userName: string) {
        this.currentUserName = userName;
        this.performSearch(this.searchTerm);
    }

    @action.bound
    sortColumns(params: { sortBy: string; sortDirection: SortDirectionType }) {
        this.currentSortedField = params.sortBy;
        this.sortDirection = params.sortDirection;
        this.performSearch(this.searchTerm);
    }

    @action
    async updateSessions(projectId: string) {
        if (this.rootStore.currentProject && projectId === this.rootStore.currentProject!.id) {
            this.performSearch(this.searchTerm);
        }
    }

    @action.bound
    async loadSessionPayloads(sessionId: string) {
        const res = await this.sessionService.getApplicationSessionPayloads(sessionId);
        runInAction(() => {
            this.loadedSessionPayloads[sessionId] = res;
        });
    }

    @action
    private async searchForAutocompleteImp(term: string) {
        const request = {
            projectId: this.rootStore.currentProject!.id,
            search: term,
            page: 0,
            pageSize: 15,
            dates: this.currentDates,
            appName: this.currentAppName,
            sortDirection: this.sortDirection,
            currentSortedField: this.currentSortedField,
            userName: this.currentUserName
        };
        if (!this.users) {
            await this.getUsers();
        }
        const result = await this.sessionService.searchSessions(request, this.users);

        runInAction(() => {
            if (!result?.lines) {
                this.autocompleteSource = [];
                return;
            }

            const uniqueLines = _.uniqBy(result.lines, 'packageId');
            this.autocompleteSource = uniqueLines.map(l => ({
                packageName: l.packageName
            }));
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async searchSessions(term: string | null): Promise<any> {
        const request = {
            projectId: this.rootStore.currentProject!.id,
            search: term,
            page: this.pager.currentPage,
            pageSize: PAGE_SIZE,
            dates: this.currentDates,
            appName: this.currentAppName,
            sortDirection: this.sortDirection,
            currentSortedField: this.currentSortedField,
            userName: this.currentUserName
        };

        if (!this.users) {
            await this.getUsers();
        }

        return this.sessionService.searchSessions(request, this.users);
    }
}
