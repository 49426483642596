import { Button, Layout, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import LayoutHeader from '../../../components/LayoutHeader';
import UsersManagerStore from '../stores/UsersManagerStore';
import CustomRoleDialog from './CustomRoleDialog';
import CustomRolesList from './CustomRolesList';

type Props = {
    store: UsersManagerStore;
};

export const RolesManagement: React.FC<Props> = ({ store }) => {
    React.useEffect(() => {
        store.getCustomRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openNewRoleDialog = () => {
        store.setCustomRoleDialogModel(undefined);
        store.setCustomRoleDialogVisible(true);
    };

    const loader = (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <Spin style={{ flex: '1' }} size="large" />
        </div>
    );
    const layout = (
        <Layout
            className="screen-size"
            style={{ ...{ height: '100%', background: 'white', overflow: 'auto', paddingBottom: 32 } }}
        >
            <LayoutHeader
                title="Roles management"
                helpMessage="You can manage roles here."
                buttons={[
                    <HasPermission key="role-manager-add-role-button" permissionClaim={AppPermissions.CanManageUsers}>
                        <Button
                            data-id="role-manager-add-role-button"
                            type="primary"
                            size="large"
                            onClick={openNewRoleDialog}
                        >
                            Create new role
                        </Button>
                    </HasPermission>
                ]}
            />
            <Layout.Content>
                <CustomRoleDialog store={store} />
                <CustomRolesList store={store} />
            </Layout.Content>
        </Layout>
    );

    if (store.loadingCustomRoles) {
        return loader;
    } else {
        return layout;
    }
};

export default observer(RolesManagement);
