import * as React from 'react';
import { Modal, Button, Input, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import FormItem from 'antd/lib/form/FormItem';
import { ReferenceDataVisualStore } from '../stores';
import { STORE_REFERENCE_DATA } from '../constant';

type Props = {
    [STORE_REFERENCE_DATA]: ReferenceDataVisualStore;
};

export const NewRecordModal: React.FC<Props> = ({ ReferenceDataUI: store }) => {
    const [form] = Form.useForm();
    const handleSubmit = (e: React.FormEvent<object>) => {
        e.preventDefault();
        form.validateFields()
            .then(values => {
                store!.handleNewRecordFormSubmit(values);
                form.resetFields();
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleCancel = () => {
        store!.setAddNewRecordDialogVisible(false);
        form.resetFields();
    };

    return (
        <Modal
            visible={store!.addNewRecordDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            className="alpha-portal-dialog"
            title="Add new record"
            centered
            getContainer={false}
            footer={[
                <Button
                    data-id="new-reference-data-record-dialog-cancel"
                    key="back"
                    onClick={handleCancel}
                    size="large"
                    className="light"
                >
                    Cancel
                </Button>,
                <Button
                    data-id="new-reference-data-record-dialog-submit"
                    key="submit"
                    type="primary"
                    size="large"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            ]}
        >
            <Form form={form} layout="vertical" data-id="new-reference-data-record-dialog-form">
                {store.currentTableFields.map(fieldName => (
                    <FormItem
                        key={fieldName}
                        label={fieldName}
                        name={fieldName}
                        rules={[{ required: true, message: 'Please input field value!', whitespace: true }]}
                    >
                        <Input key={fieldName} />
                    </FormItem>
                ))}
            </Form>
        </Modal>
    );
};

export default observer(NewRecordModal);
