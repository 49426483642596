import { Button, Modal, Steps, Form, Input, Spin, Collapse, Alert } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ProjectApplicationDefinitionsVisualStore } from '../../stores';
import { ApiApplicationCreateModel } from '../../types';

type Props = {
    store: ProjectApplicationDefinitionsVisualStore;
};

const ApiApplicationDialog: React.FC<Props> = ({ store }) => {
    const [currentStep, setCurrentStep] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [selectedOperation, setSelectedOperation] = React.useState<string | undefined>(undefined);
    const [form] = Form.useForm();

    const handleCancel = () => {
        store.setIsApiAppDialogVisible(false);
        store.setOperationSearchText('');
        setCurrentStep(0);
        form.resetFields();
    };

    const handleSubmit = () => {
        if (currentStep === 0) {
            form.validateFields().then(() => {
                setCurrentStep(1);
                store.getExternalApiOperations(form.getFieldValue('url'));
            });
        } else if (currentStep === 1) {
            if (!selectedOperation) {
                setErrorMessage('Please select operation!');
                return;
            }

            let model: ApiApplicationCreateModel = {
                apiMethod: selectedOperation,
                name: form.getFieldValue('name'),
                apiUrl: form.getFieldValue('url'),
                applicationId: store.EXTERNAL_API_APPLICATION_NAME,
                extension: store.EXTERNAL_API_EXTENSION_NAME,
                workflowId: store.EXTERNAL_API_WORKFLOW_ID,
                parameters: store.getOperationParameters(selectedOperation) ?? [],
                requestBodyParameters: store.getOperationBodyParameters(selectedOperation) ?? [],
                projectId: store.currentProject?.id ?? '',
                settings: store.externalApiAppSettings ? JSON.stringify(store.externalApiAppSettings) : '',
                state: 'Draft'
            };
            store.createApiApplicationDefinition(model);
        }
    };

    const steps = [
        { key: 0, title: 'Application settings' },
        { key: 1, title: 'Select operation' }
    ]; // , {key: 2, title: 'Select auth operation'}

    const appSettingsForm = () => {
        return (
            <Form form={form} layout="vertical">
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please enter application name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="OpenAPI definition URL"
                    name="url"
                    rules={[{ required: true, message: 'Please enter OpenAPI definition URL' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        );
    };

    const operationChange = (e: React.FormEvent<HTMLFieldSetElement>) => {
        const input = e.target as HTMLInputElement;
        setSelectedOperation(input.value);
    };

    const getOperationPanels = (disabledOperation?: string) => {
        return (
            <>
                <Input
                    onChange={e => store.setOperationSearchText(e.target.value)}
                    placeholder="Search..."
                    style={{ marginBottom: 12 }}
                />
                <Collapse>
                    {store.filteredOperations.map((operation, i) => {
                        return (
                            <Collapse.Panel
                                key={operation.operationId + i}
                                header={operation.operationId}
                                extra={
                                    <input
                                        type="radio"
                                        name="operation"
                                        value={operation.operationId}
                                        onClick={e => e.stopPropagation()}
                                        disabled={operation.operationId === disabledOperation}
                                    />
                                }
                            >
                                {operation?.parameters.length > 0 && <strong>Parameters:</strong>}
                                {operation.parameters.map(parameter => {
                                    return <div key={parameter.name}>{`${parameter.name} (${parameter.type})`}</div>;
                                })}
                                <br />
                                {operation?.requestBodyParameters.length > 0 && <strong>Body parameters:</strong>}
                                {operation.requestBodyParameters.map(parameter => {
                                    return <div key={parameter.name}>{`${parameter.name} (${parameter.type})`}</div>;
                                })}
                            </Collapse.Panel>
                        );
                    })}
                </Collapse>
            </>
        );
    };

    const operationSelection = () => {
        if (store.isLoadingApiOperations || store.isSavingApiApplication) {
            const loadingMessage = store.isLoadingApiOperations
                ? 'Fetching available operations...'
                : 'Saving application...';
            return (
                <div style={{ textAlign: 'center' }}>
                    <Spin tip={loadingMessage} />
                </div>
            );
        }

        return (
            <fieldset onChange={operationChange}>
                {errorMessage && <Alert message={errorMessage} style={{ marginBottom: 12 }} type="error" />}
                {getOperationPanels()}
            </fieldset>
        );
    };

    const getDialogContent = () => {
        switch (currentStep) {
            case 0:
                return appSettingsForm();
            case 1:
                return operationSelection();
            default:
                return null;
        }
    };

    return (
        <Modal
            className="alpha-portal-dialog"
            title="New API application"
            visible={store!.isApiAppDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button
                    data-id="iota-add-api-application-dialog-cancel"
                    className="light"
                    key="back"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="iota-add-api-application-dialog-submit"
                    key="submit"
                    size="large"
                    type="primary"
                    onClick={handleSubmit}
                    disabled={
                        store.isLoadingApiOperations ||
                        store.isSavingApiApplication ||
                        (currentStep === 1 && !selectedOperation)
                    }
                >
                    {currentStep !== 1 ? 'Next' : 'Create'}
                </Button>
            ]}
        >
            <Steps current={currentStep} style={{ marginBottom: 24 }}>
                {steps.map(item => (
                    <Steps.Step key={item.key} title={item.title} />
                ))}
            </Steps>
            {getDialogContent()}
        </Modal>
    );
};

export default observer(ApiApplicationDialog);
