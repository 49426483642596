import * as React from 'react';
import { Card, Select, Input, Tooltip, Switch } from 'antd';
import PipelineStep from '../models/PipelineStep';
import PipelineVisualStore from '../../rules/stores/PipelineVisualStore';
import { PredefinedParametersCard } from './PredefinedParametersCard';
import UserDefinedParametersCard from './UserDefinedParametersCard';
import { observer } from 'mobx-react-lite';
import { STORE_RULE_EDIT } from '../../rules/constants';
import { inject } from 'mobx-react';
import { RulesStores } from '../../rules/stores';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = RulesStores & {
    store: T;
    step: PipelineStep<TPipelineStepType>;
    stepKey: number;
};

type GroupItem = {
    friendlyName: string;
    name: string;
};

type TypeGroup = {
    groupName: string;
    groupItems: GroupItem[];
};

// eslint-disable-next-line max-len
export const PipelineEditStep: <T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string>(
    props: Props<T, TPipelineStepType>
) => React.ReactElement<Props<T, TPipelineStepType>> = ({ step, store, stepKey, RuleEditUI }) => {
    // eslint-disable-next-line max-len
    const header = (
        <div className="pipeline-step-header">
            <i className="alpha-icon sm drag-handle" />
            <Input
                className="pipeline-step-title"
                data-id={`Pipeline-edit-step-name-${stepKey}`}
                value={step.name}
                onChange={({ target }) => store.setName(step, target.value)}
            />
            <i
                data-id={`pipeline-edit-step-delete-${stepKey}`}
                className="alpha-icon md delete-icon"
                style={{ position: 'relative', zIndex: 1000 }}
                onClick={() => {
                    store.removeStep(step);
                    RuleEditUI?.setIsRuleEdited(true);
                }}
            />
        </div>
    );
    const stepModel = store.editorModel[step.stepType]!;
    store.setDescription(stepKey, step.type);

    const renderSwitch = (type: string) => {
        switch (type) {
            case 'predefined':
                return <PredefinedParametersCard store={store} step={step} stepKey={stepKey} stepModel={stepModel} />;
            case 'userdefined':
                return <UserDefinedParametersCard store={store} step={step} stepKey={stepKey} stepModel={stepModel} />;
            default:
                return;
        }
    };

    const populateTypeSelect = () => {
        if (store.editorModel) {
            let groups: TypeGroup[] = [];
            for (let key in store.editorModel) {
                if (store.editorModel[key]) {
                    let s = store.editorModel[key]!;
                    if (!groups.find(g => g.groupName === s.group)) {
                        groups.push({ groupItems: [], groupName: s.group });
                    }

                    let groupToUpdate = groups.find(g => g.groupName === s.group);
                    groupToUpdate!.groupItems.push({ name: key, friendlyName: s.friendlyName });
                }
            }

            return groups.map(g => ({
                label: g.groupName,
                options: g.groupItems.map(t => ({ value: t.name, label: t.friendlyName, filterKey: t.friendlyName }))
            }));
        } else {
            return undefined;
        }
    };

    const getTypeName = (type: string) => {
        if (store.editorModel) {
            return store.editorModel[type]!.friendlyName;
        } else {
            return null;
        }
    };

    const disableStep = (val: boolean) => {
        step.disableStep(!val);
        RuleEditUI?.setIsRuleEdited(true);

        if (RuleEditUI?.isPreviewPoppedOut) {
            RuleEditUI?.refreshRuleInStorage();
        }
    };

    return (
        <>
            <Card title={header}>
                <div className="pipeline-step-type">
                    Type
                    <Tooltip style={{}} title={store.description[stepKey]}>
                        <i className="alpha-icon md question-icon" style={{ verticalAlign: 'middle' }} />
                    </Tooltip>
                </div>
                <Select
                    dropdownClassName="alpha-grouped-select-dropdown"
                    showSearch
                    data-id={`pipeline-edit-step-type-${stepKey}`}
                    placeholder="Select type"
                    value={step.type}
                    getPopupContainer={trigger => trigger.parentNode as HTMLElement}
                    style={{ width: 250, marginBottom: 18, marginTop: 4 }}
                    virtual={false}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(v: any) => {
                        store.setType(step, v);
                        store.setDescription(stepKey, step.type);
                        store.setName(step, getTypeName(v));
                        store.removeFormSubmission(stepKey);
                        step.deleteAllParameters();
                    }}
                    options={populateTypeSelect()}
                    filterOption={(input, option) =>
                        (option?.filterKey?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                />
                {renderSwitch(stepModel.type)}
                <div>
                    <span style={{ fontSize: 11, color: '#9BA0AA' }}>Active</span>
                    <Switch
                        data-id={`pipeline-edit-step-disabled-${stepKey}`}
                        checked={!step.isDisabled}
                        onChange={disableStep}
                        style={{ float: 'right', zIndex: 150 }}
                        className="sm-switch"
                    />
                </div>
            </Card>
            <div
                className="disabled-step-overlay"
                style={{ opacity: step.isDisabled ? 0.5 : 0, zIndex: !step.isDisabled ? -1 : 100 }}
            />
        </>
    );
};

export default inject(STORE_RULE_EDIT)(observer(PipelineEditStep));
