import * as React from 'react';
import { observer, inject } from 'mobx-react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import ProjectApplicationDefinitionsList from '../components/ProjectApplicationDefinitionsList';
import { IotaAppStores } from '../stores';
import { STORE_PROJECT_APPLICATION_DEFINITIONS } from '../stores/constants';
import { STORE_RULES_IMPORT } from '../../rules/constants';
import { RulesStores } from '../../rules/stores';

type Props = IotaAppStores & RulesStores;

export const ProjectApplicationDefinitionsPage: React.FC<Props> = ({
    projectApplicationDefinitionsUI: store,
    RulesImportUI: rulesImportStore
}) => {
    return (
        <FlexContentContainer
            content={
                <ProjectApplicationDefinitionsList
                    projectApplicationDefinitionsUI={store}
                    RulesImportUI={rulesImportStore}
                />
            }
        />
    );
};

export default inject(
    STORE_PROJECT_APPLICATION_DEFINITIONS,
    STORE_RULES_IMPORT
)(observer(ProjectApplicationDefinitionsPage));
