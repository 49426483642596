import * as React from 'react';
import { Col, Row, Card, Spin, Radio, Button } from 'antd';
import { Bar } from '@ant-design/plots';
import { observer } from 'mobx-react-lite';
import { Layout, Typography } from 'antd';
import { TechnicalInformationStore, timeOptions, defaultIntervalTime } from '../stores/TechnicalInformationStore';
import LayoutHeader from '../../../components/LayoutHeader';

const cardFlexBody: React.CSSProperties = { display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' };

interface Props {
    store: TechnicalInformationStore;
}

const TechnicalInformation: React.FC<Props> = ({ store }) => {
    React.useEffect(() => {
        store.initialize();
        return () => store.dispose();
    }, [store]);

    const barConfig = React.useMemo(
        () => ({
            data: store.projectsDocumentCount,
            xField: 'documentCount',
            yField: 'projectName',
            seriesField: 'projectName'
        }),
        [store.projectsDocumentCount]
    );

    return (
        <Layout>
            <LayoutHeader title="Technical Information" />

            <Layout.Content>
                <Row style={{ marginBottom: 32 }}>
                    <Col className="gutter-row">
                        <Typography.Paragraph style={{ fontWeight: 500, fontSize: 16, marginBottom: 8 }}>
                            Refresh interval
                        </Typography.Paragraph>

                        <Radio.Group
                            onChange={e => {
                                store.setFetchIntervalTime(e.target.value);
                            }}
                            defaultValue={defaultIntervalTime}
                            buttonStyle="solid"
                        >
                            {timeOptions.map(option => (
                                <Radio.Button key={option.value} value={option.value}>
                                    {option.label}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col className="gutter-row">
                        <Card
                            title="Document count in projects"
                            style={{ display: 'flex', flexDirection: 'column', width: 600, height: 507 }}
                            bodyStyle={store.documentCountLoading ? cardFlexBody : undefined}
                        >
                            {store.documentCountLoading ? <Spin size="large" /> : <Bar {...barConfig} />}
                        </Card>
                    </Col>

                    <Col className="gutter-row">
                        <Card
                            title="Document count in queue"
                            style={{ display: 'flex', flexDirection: 'column' }}
                            bodyStyle={cardFlexBody}
                            actions={[
                                <Button
                                    key="clear-queue"
                                    type="primary"
                                    disabled={store.clearingQueue}
                                    onClick={store.clearQueue}
                                >
                                    Clear queue
                                </Button>
                            ]}
                        >
                            {store.jobsCountLoading ? (
                                <Spin size="large" />
                            ) : (
                                <Typography.Title level={1} style={{ margin: 0 }}>
                                    {store.jobsCount}
                                </Typography.Title>
                            )}
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
};

export default observer(TechnicalInformation);
