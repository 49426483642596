import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography } from 'antd';
import { DocumentViewVisualStore } from '../stores';

type Props = {
    store: DocumentViewVisualStore;
};

const TextViewer: React.FC<Props> = ({ store }) => {
    const [text, setText] = React.useState('');

    React.useEffect(() => {
        if (store.file) {
            store.file.text().then(setText);
        }
    }, [store.file]);

    return (
        <div className="document-viewer-container">
            <div className="document-viewer-text-container">
                <Typography.Paragraph className="document-viewer-text-paragraph">{text}</Typography.Paragraph>
            </div>
        </div>
    );
};

export default observer(TextViewer);
