import { action, computed, observable } from 'mobx';
import { TestRunResultPreviewModel, TestRunTopicMetaField } from '../types';

export default class TestProjectRunTopicPreviewStore {
    @observable
    packageId: string;

    @observable
    topicId: string;

    @observable
    blocks: TestRunTopicMetaField[] = [];

    @observable
    currentBlockIndex: number = 0;

    @computed
    get currentBlock() {
        if (!this.blocks.length || this.currentBlockIndex >= this.blocks.length) {
            return null;
        }

        return this.blocks[this.currentBlockIndex];
    }

    @action.bound
    setPreviewData(data: TestRunResultPreviewModel) {
        this.setPackageId(data.packageId);
        this.setTopicId(data.topicId);

        this.blocks = data.blocks;
    }

    @action.bound
    setPackageId(packageId: string) {
        // Check if it's necessary
        if (this.packageId === packageId) {
            return;
        }

        this.packageId = packageId;
    }

    @action.bound
    setTopicId(topicId: string) {
        if (this.topicId === topicId) {
            this.toggleCurrentBlockIndex();
            return;
        }

        this.currentBlockIndex = 0;
        this.topicId = topicId;
    }

    @action.bound
    toggleCurrentBlockIndex() {
        this.currentBlockIndex = (this.currentBlockIndex + 1) % this.blocks.length;
    }

    @action.bound
    readPreviewDataFromStorage() {
        const data = localStorage.getItem('test-project-preview');

        if (!data) {
            return;
        }

        this.setPreviewData(JSON.parse(data));
    }
}
