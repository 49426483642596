import { Button, Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Document, Page } from 'react-pdf';
import { SecurityService } from '../../common/services';
import RegionSelect from 'react-region-select';
import { FormTypesStore, FormTypesVisualStore } from '../stores';
import { SelectValue } from 'antd/lib/select';
import { FormBlock, FormRegionBlock } from '../types';
import { CheckOutlined, CloseOutlined, FontSizeOutlined, MoreOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { Utils } from '../../common/services/Utils';
import { Subscription } from 'rxjs';
import BlockChangeHistoryModel from '../types/BlockChangeHistoryModel';
import { FormTypeEvent } from '../types';

type Props = {
    documentId: string;
    store: FormTypesStore;
    visualStore: FormTypesVisualStore;
    pages: number;
    setPages: (pages: number) => void;
    scale: number;
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    setDocumentLoaded: (loading: boolean) => void;
    documentLoaded: boolean;
};

type RegionBlockOption = {
    title: string;
    value: string;
};

const PdfDocumentPreview: React.FC<Props> = ({
    documentId,
    store,
    scale,
    setPages,
    pages,
    pageNumber,
    setPageNumber,
    visualStore,
    documentLoaded,
    setDocumentLoaded
}) => {
    const wrapperId = 'form-type-document-preview-wrapper';
    const controlsId = 'form';
    const dataIsNewKey = 'isNew';
    const dataRegionStyleKey = 'regionStyle';
    const dataBlockIdKey = 'blockId';
    const dataIndexKey = 'index';

    const [regions, setRegions] = React.useState<FormBlock[]>([]);
    const [lastSelectedBlock, setLastSelectedBlock] = React.useState<string | undefined>(undefined);
    const [pageWidth, setPageWidth] = React.useState<number>(1000);
    const [currentRegionOptions, setCurrentRegionOptions] = React.useState<RegionBlockOption[]>([]);
    const [prevPackageId, setPrevPackageId] = React.useState('');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const debouncedSaveHandler =
        React.useRef<(blockId: string, blockTitle: string, blocks: FormBlock[]) => Promise<void>>();
    let newBlockIdsSub = React.useRef<Subscription>();
    let triggerSaveSub = React.useRef<Subscription>();
    let rmbIsPressed = React.useRef(false);

    React.useEffect(() => {
        newBlockIdsSub.current = store.newBlockIdsSubject.subscribe(setLastSelectedBlock);
        window.addEventListener('resize', handleResize);
        document.body.addEventListener('mousedown', handleWindowClick);
        window.addEventListener('keydown', handleCtrlZKeyDown);
        window.addEventListener('keydown', handleArrowKeyDown);
        window.addEventListener('keydown', preventScrollingWithKeys);
        window.addEventListener('keyup', handleArrowKeyUp);
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('mousedown', handleMouseDown);

        return () => {
            newBlockIdsSub?.current?.unsubscribe();
            window.removeEventListener('resize', handleResize);
            document.body.removeEventListener('mousedown', handleWindowClick);
            window.removeEventListener('keydown', handleCtrlZKeyDown);
            window.removeEventListener('keydown', handleArrowKeyDown);
            window.removeEventListener('keyup', handleArrowKeyUp);
            window.removeEventListener('keydown', preventScrollingWithKeys);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('mousedown', handleMouseDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        store.setSelectedBlockInfo(undefined);
        if (store.selectedFormTemplateRegion && !store.isLoadingBlocks) {
            const regionBlocks = store.currentRegionBlocks[store.selectedFormTemplateRegion]?.[pageNumber - 1] || [];
            regionBlocks.forEach(r => {
                r.data = { ...r.data, regionStyle: store.getRegionStyle(r.blockId, r.useContours) };
            });
            pollCanvasChanges(() => convertCoordinatesToRegionBboxes(regionBlocks));

            triggerSaveSub.current = store.saveTriggerSubject.subscribe(saveBlocksAfterUpdate);
        } else {
            setRegions([]);
            triggerSaveSub?.current?.unsubscribe();
        }
        refreshBlockOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, store.selectedFormTemplateRegion, store.isLoadingBlocks]);

    React.useEffect(() => {
        if (store.selectedFormTemplateRegion) {
            const regionBlocks =
                store.formParts
                    .find(fp => fp.regions.some(r => r.regionId === store.selectedFormTemplateRegion))
                    ?.regions.find(r => r.regionId === store.selectedFormTemplateRegion)?.regionBlocks || [];
            const firstPageOfBlocks = regionBlocks.sort((r1, r2) => r1.blockPage - r2.blockPage)[0]?.blockPage + 1 || 1;
            setTimeout(() => {
                setPageNumber(firstPageOfBlocks);
            }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.selectedFormTemplateRegion, documentLoaded]);

    React.useEffect(() => {
        if (store.selectedFormPart) {
            const formPart = store.formParts.find(fp => fp.id === store.selectedFormPart);
            setTimeout(() => {
                if (formPart && formPart.coordinatesReference) {
                    setPageNumber(formPart.coordinatesReference.page + 1 || 1);
                }
            }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.selectedFormPart]);

    const saveBlocksAfterUpdate = () => {
        if (store.selectedFormTemplateRegion) {
            const regionBlocks = store.currentRegionBlocks[store.selectedFormTemplateRegion][pageNumber - 1] || [];
            regionBlocks.forEach(r => {
                r.data = { ...r.data, regionStyle: store.getRegionStyle(r.blockId, r.useContours) };
            });
            convertCoordinatesToRegionBboxes(regionBlocks);
        }
    };

    const handleMouseUp = (e: MouseEvent) => {
        if (e.button === 2) {
            rmbIsPressed.current = false;
        }
    };

    const handleMouseDown = (e: MouseEvent) => {
        const eventTarget = e.target as HTMLElement;
        const targetIsCanvas = eventTarget.classList.contains('react-pdf__Page__canvas');
        if (e.button === 2 && targetIsCanvas) {
            rmbIsPressed.current = true;
        }
    };

    const pollCanvasChanges = (callback: () => void) => {
        const canvas = document.querySelector('.react-pdf__Page__canvas');
        if (canvas) {
            callback();
        } else {
            setTimeout(() => {
                pollCanvasChanges(callback);
            }, 200);
        }
    };

    React.useEffect(() => {
        setLastSelectedBlock(undefined);
        debouncedSaveHandler.current = _.debounce(handleRegionPositionChange, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentRegionPackageId, store.selectedFormTemplateRegion, pageNumber]);

    React.useEffect(() => {
        setPageWidth(getDocumentContainerWidth);
    }, [store.currentRegionPackageId, documentLoaded]);

    React.useEffect(() => {
        if (!store.selectedBlockInfo) {
            clearBlockHighlighting();
        }
    }, [store.selectedBlockInfo]);

    React.useEffect(() => {
        if (!prevPackageId || prevPackageId !== documentId) {
            setDocumentLoaded(false);
            setPrevPackageId(documentId);
        }
    }, [documentId, prevPackageId, setDocumentLoaded]);

    React.useEffect(() => {
        const supscription = visualStore.highlightedBlocksSubject.subscribe(blocks => {
            document
                .querySelectorAll('div[data-wrapper]')
                .forEach(block => block.classList.remove('highlighted-block-warning'));

            blocks.forEach(blockInfo => {
                const block = document.querySelectorAll('div[data-wrapper]')?.[blockInfo.index];

                if (block) {
                    block.classList.add('highlighted-block-warning');
                }
            });
        });

        return () => supscription.unsubscribe();
    }, [store, visualStore]);

    const safePageNumber = React.useMemo(() => {
        if (!documentLoaded || pageNumber > pages) {
            return 1;
        }

        return pageNumber;
    }, [documentLoaded, pageNumber, pages]);

    const highlightPreviouslySelectedBlock = () => {
        if (store.selectedBlockInfo) {
            const block = document.querySelector('div[data-wrapper]')?.[store.selectedBlockInfo.index];
            if (block) {
                block.classList.add('highlighted-block');
            }
        }
    };

    const handleWindowClick = (event: MouseEvent) => {
        let target = event.target as HTMLElement;

        if (target.closest('div[data-wrapper]') != null) {
            target = target.closest('div[data-wrapper]')!;
        }

        if (target.hasAttribute('data-wrapper') && store.selectedFormTemplateRegion) {
            clearBlockHighlighting();
            target.classList.add('highlighted-block');

            const selectedPageItem = document.getElementById(controlsId)?.querySelector('.ant-pagination-item-active');
            const selectedPage = Number.parseInt(selectedPageItem?.getAttribute('title') ?? '1', 10);
            const parentNode = target.parentElement;
            const index = Array.prototype.indexOf.call(parentNode?.children, target);
            if (store.currentRegionBlocks[store.selectedFormTemplateRegion]?.[selectedPage - 1]?.[index]) {
                store.setSelectedBlockInfo({ index, page: selectedPage - 1 });
            } else {
                store.setSelectedBlockInfo(undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const clearBlockHighlighting = () => {
        const highlightedBlocks = document.querySelectorAll('.highlighted-block');
        highlightedBlocks.forEach(b => {
            b.classList.remove('highlighted-block');
        });
    };

    const refreshBlockOptions = () => {
        if (store.selectedFormTemplateRegion) {
            const regionBlocks = store.currentRegionBlocks[store.selectedFormTemplateRegion]?.[pageNumber - 1] ?? [];

            if (!regionBlocks?.length) {
                setCurrentRegionOptions([{ value: 'new', title: '+ Add New' }]);
                return;
            }

            let options: RegionBlockOption[] = [];

            const blockOptions =
                [...new Map(regionBlocks.map(item => [item[dataBlockIdKey], item])).values()]?.map(b => ({
                    title: store.getBlockTitleById(b.blockId),
                    value: b.blockId
                })) ?? [];
            options = [...blockOptions];
            options.push({ value: 'new', title: '+ Add New' });
            setCurrentRegionOptions(options.filter(o => o.title));
        } else {
            setCurrentRegionOptions([{ value: 'new', title: '+ Add New' }]);
        }
    };

    const handleResize = () => {
        setPageWidth(getDocumentContainerWidth);
    };

    const getDocumentContainerWidth = () => {
        const wrapper = document.getElementById(wrapperId);

        if (!wrapper) {
            return 0;
        }

        const sidePaddings = 25;
        return wrapper.clientWidth - sidePaddings;
    };

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setPages(numPages);
        setDocumentLoaded(true);
    }

    const mapBboxToBlockCoordinates = (bbox: number[], block: FormBlock) => {
        block.x1 = bbox[0];
        block.y1 = bbox[1];
        block.x2 = bbox[2];
        block.y2 = bbox[3];

        return block;
    };

    const convertCoordinatesToRegionBboxes = (regionBlocks: FormBlock[], save: boolean = false) => {
        const canvas = document.getElementsByClassName('react-pdf__Page__canvas')[0] as HTMLCanvasElement;
        if (!canvas) {
            return;
        }

        const { clientWidth, clientHeight } = canvas;
        // Need to clone arrays to avoid multiple conversions
        const arrayString = JSON.stringify(regionBlocks);
        const newRegionBlocks: FormBlock[] = JSON.parse(arrayString).map(FormBlock.fromJson);

        newRegionBlocks.forEach(r => {
            const bboxCoordinates = Utils.convertCoordinatesToRegionSelectBbox(
                [r.x1, r.y1, r.x2, r.y2],
                clientWidth,
                clientHeight,
                store.currentFormPart?.coordinatesReference
            );

            r = mapBboxToBlockCoordinates(
                [
                    bboxCoordinates[0],
                    bboxCoordinates[1],
                    bboxCoordinates[2] + bboxCoordinates[0],
                    bboxCoordinates[3] + bboxCoordinates[1]
                ],
                r
            );
        });
        setRegions(newRegionBlocks);

        if (save) {
            onRegionChange(newRegionBlocks);
            highlightPreviouslySelectedBlock();
        }
    };

    const onRegionChange = (newRegions: FormBlock[]) => {
        if (!store.selectedFormTemplateRegion || rmbIsPressed.current) {
            // newRegions = newRegions.filter(nr => nr.blockId && nr.blockId !== 'temp-block-id');
            newRegions.splice(newRegions.length - 1, 1);
            setRegions(newRegions);
            return;
        }

        let currentBlocks = { ...store.currentRegionBlocks };
        newRegions = newRegions.map(nr => {
            let block = FormBlock.fromRegionBlock(nr, store.selectedFormTemplateRegion ?? '');
            return block.x1 != null ? block : FormBlock.fromJson(nr);
        });

        if (!currentBlocks[store.selectedFormTemplateRegion]) {
            currentBlocks[store.selectedFormTemplateRegion] = {};
        }

        const tempBlockId = 'temp-block-id';
        newRegions
            .filter(nr => !nr.data[dataBlockIdKey])
            .forEach(nr => {
                const blockId = (nr.data[dataBlockIdKey] ?? nr.isChanging) ? tempBlockId : lastSelectedBlock;
                nr.data = {
                    ...nr.data,
                    regionStyle: store.getRegionStyle(blockId, nr.useContours),
                    blockPage: pageNumber - 1,
                    isNew: true
                };

                if (blockId && blockId !== tempBlockId && store.selectedFormTemplateRegion) {
                    nr.data[dataIndexKey] = currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1].filter(
                        b => b.blockId === blockId
                    ).length;
                    nr.blockId = blockId;
                    nr.data = {
                        ...nr.data,
                        blockId,
                        regionStyle: store.getRegionStyle(blockId, nr.useContours),
                        isNew: true
                    };
                }
            });

        if (newRegions.every(b => !b.isChanging)) {
            newRegions = newRegions
                .map(b => {
                    if (
                        b.data[dataIsNewKey] &&
                        !formBlockIsAboveSizeThreshold(b) &&
                        store.currentFormPart?.coordinatesReference
                    ) {
                        const { height, width } = store.currentFormPart.coordinatesReference;
                        const minimalSize = 30;
                        b.height = (minimalSize * 100) / height;
                        b.width = (minimalSize * 100) / width;
                    }
                    return b;
                })
                .filter(b => formBlockIsAboveSizeThreshold(b) || !b.data[dataIsNewKey]);

            const formTypeEvent = detectRegionChanges(newRegions);

            if (formTypeEvent) {
                visualStore.logFormTypeEvent(formTypeEvent);
                saveHistoryState();
                saveCurrentBlocksInStore(newRegions);
            }
        }

        setRegions(newRegions);
    };

    const detectRegionChanges = (newRegions: FormBlock[]): FormTypeEvent | undefined => {
        if (!store.selectedFormTemplateRegion) {
            return;
        }

        const canvas = document.getElementsByClassName('react-pdf__Page__canvas')[0] as HTMLCanvasElement;

        if (!canvas) {
            return;
        }

        const oldRegions = store.currentRegionBlocks[store.selectedFormTemplateRegion]?.[pageNumber - 1] ?? [];

        if (newRegions.length !== oldRegions.length) {
            return 'region_block_added';
        }

        const newRegionsWithConvertedCoordinates = _.cloneDeep(newRegions).map(r => {
            if (canvas) {
                const { clientWidth, clientHeight } = canvas;
                const coords = Utils.convertRegionSelectBboxToCoordinates(
                    [r.x, r.y, r.width, r.height],
                    clientWidth,
                    clientHeight,
                    store.currentFormPart?.coordinatesReference
                );
                r = mapBboxToBlockCoordinates(coords, r);
            }
            return r;
        });

        const newRegionsCoodinatesSum = newRegionsWithConvertedCoordinates.reduce(
            (sum, r) => sum + r.x1 + r.y1 + r.x2 + r.y2,
            0
        );
        const oldRegionsCoordinatesSum = oldRegions.reduce((sum, r) => sum + r.x1 + r.y1 + r.x2 + r.y2, 0);

        return newRegionsCoodinatesSum !== oldRegionsCoordinatesSum ? 'region_block_updated' : undefined;
    };

    const saveCurrentBlocksInStore = (newRegions?: FormBlock[] | undefined) => {
        if (!store.selectedFormTemplateRegion) {
            return;
        }

        let currentBlocks = { ...store.currentRegionBlocks };

        if (!currentBlocks[store.selectedFormTemplateRegion]) {
            currentBlocks[store.selectedFormTemplateRegion] = {};
        }

        const canvas = document.getElementsByClassName('react-pdf__Page__canvas')[0] as HTMLCanvasElement;
        const arrayJson = JSON.stringify(newRegions ?? regions);
        let newBlocks: FormBlock[] = JSON.parse(arrayJson).map(FormBlock.fromJson);

        currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1] = newBlocks.map(r => {
            if (canvas) {
                const { clientWidth, clientHeight } = canvas;
                const coords = Utils.convertRegionSelectBboxToCoordinates(
                    [r.x, r.y, r.width, r.height],
                    clientWidth,
                    clientHeight,
                    store.currentFormPart?.coordinatesReference
                );
                r = mapBboxToBlockCoordinates(coords, r);
            }
            return r;
        });
        store.setCurrentRegionBlocks(currentBlocks);
    };

    const saveHistoryState = () => {
        if (!store.selectedFormTemplateRegion) {
            return;
        }
        const historyItem = new BlockChangeHistoryModel();

        if (!store.currentRegionBlocks[store.selectedFormTemplateRegion]) {
            const currentBlocks = { ...store.currentRegionBlocks };
            currentBlocks[store.selectedFormTemplateRegion] = {};
            store.setCurrentRegionBlocks(currentBlocks);
        }

        historyItem.blocksState = store.currentRegionBlocks[store.selectedFormTemplateRegion][pageNumber - 1]
            ? _.cloneDeep(store.currentRegionBlocks[store.selectedFormTemplateRegion][pageNumber - 1])
            : [];
        visualStore.addBlockChangeHistory(historyItem);
    };

    const handleCtrlZKeyDown = (e: KeyboardEvent) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'z') {
            revertToPreviousBlockState();
        }
    };

    const handleArrowKeyDown = (e: KeyboardEvent) => {
        if (!store.selectedBlock || !store.selectedBlockInfo || !store.selectedFormTemplateRegion) {
            return;
        }

        if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            e.stopPropagation();
            let newBlock = _.cloneDeep(store.selectedBlock);
            const step = visualStore.arrowkeysStep;

            if (e.shiftKey) {
                newBlock = handleBlockResizing(newBlock, e);
            } else {
                newBlock = handleBlockMovement(newBlock, e.key);
            }

            setTimeout(() => {
                let currentBlocks = { ...store.currentRegionBlocks };
                currentBlocks[store.selectedFormTemplateRegion!][store.selectedBlockInfo!.page][
                    store.selectedBlockInfo!.index
                ] = newBlock;
                store.setCurrentRegionBlocks(currentBlocks, true);
                visualStore.setCoordinateDelta(visualStore.coordinateDelta + step);
            }, 0);

            visualStore.logFormTypeEvent('region_block_updated');
        }
    };

    const handleBlockMovement = (newBlock: FormBlock, keyCode: string) => {
        const step = visualStore.arrowkeysStep;
        switch (keyCode) {
            case 'ArrowUp':
                newBlock.y1 -= step;
                newBlock.y2 -= step;
                break;
            case 'ArrowDown':
                newBlock.y1 += step;
                newBlock.y2 += step;
                break;
            case 'ArrowLeft':
                newBlock.x1 -= step;
                newBlock.x2 -= step;
                break;
            case 'ArrowRight':
                newBlock.x1 += step;
                newBlock.x2 += step;
                break;
            default:
                break;
        }

        return newBlock;
    };

    const handleBlockResizing = (newBlock: FormBlock, e: KeyboardEvent) => {
        const step = visualStore.arrowkeysStep;

        if (e.key === 'ArrowUp') {
            newBlock.height = Math.max(newBlock.height - step, 1);
        }

        if (e.key === 'ArrowDown') {
            newBlock.height += step;
        }

        if (e.key === 'ArrowLeft') {
            newBlock.width = Math.max(newBlock.width - step, 1);
        }

        if (e.key === 'ArrowRight') {
            newBlock.width += step;
        }

        return newBlock;
    };

    const handleArrowKeyUp = (e: KeyboardEvent) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            visualStore.setCoordinateDelta(0);
        }
    };

    const revertToPreviousBlockState = () => {
        if (!store.selectedFormTemplateRegion) {
            return;
        }
        const historyItem = visualStore.getPreviousHistoryItem();
        if (historyItem) {
            const currentBlocks = { ...store.currentRegionBlocks };
            currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1] = historyItem.blocksState;
            store.setCurrentRegionBlocks(currentBlocks);
            convertCoordinatesToRegionBboxes(historyItem.blocksState);
        }
    };

    const formBlockIsAboveSizeThreshold = (block: FormBlock) => {
        if (block.data[dataIsNewKey]) {
            // New not converted block
            return block.width > 0 && block.height > 0;
        }

        const blockArea = block.width * block.height;
        const areaThreshold = 25;
        return blockArea > areaThreshold;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSelectBlock = async (val: SelectValue, regionProps: any) => {
        if (!store.selectedFormTemplateRegion) {
            return;
        }

        const canvas = document.getElementsByClassName('react-pdf__Page__canvas')[0] as HTMLCanvasElement;

        if (!canvas) {
            return;
        }

        const { clientWidth, clientHeight } = canvas;

        if (val === 'new') {
            const confirmedSave = await promptSaveConfirmation();
            if (!confirmedSave) {
                return;
            }

            const region = regions[regionProps.index];

            store.newBlockInitialValues = {
                blockPage: pageNumber - 1,
                previousBlockId: regionProps.data?.blockId,
                previousBlockIndex: regionProps.data?.index,
                previousBlockPage: regionProps.data?.blockPage,
                bBox: [
                    {
                        useContours: false,
                        bBox: Utils.convertRegionSelectBboxToCoordinates(
                            [region.x, region.y, region.width, region.height],
                            clientWidth,
                            clientHeight,
                            store.currentFormPart?.coordinatesReference
                        )
                    }
                ]
            };
            visualStore.setIsFormBlockCreateDialogVisible(true);
        } else {
            let currentBlocks = { ...store.currentRegionBlocks };

            if (!currentBlocks[store.selectedFormTemplateRegion]) {
                currentBlocks[store.selectedFormTemplateRegion] = {};
            }

            currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1][regionProps.index].blockId = val as string;

            if (
                !currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1][regionProps.index].data[dataBlockIdKey]
            ) {
                const blockBoxesCount = currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1].filter(
                    b => b.blockId === val
                ).length;
                currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1][regionProps.index].data[dataIndexKey] =
                    blockBoxesCount - 1;
            }

            currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1][regionProps.index].data = {
                ...currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1][regionProps.index].data,
                regionStyle: store.getRegionStyle(val as string, regionProps.data.useContours),
                blockId: val as string,
                index:
                    currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1].filter(b => b.blockId === val)
                        .length - 1
            };

            store.setCurrentRegionBlocks(currentBlocks);
            convertCoordinatesToRegionBboxes(currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1]);
            setLastSelectedBlock(val as string);
            visualStore.logFormTypeEvent('region_block_updated');
        }
        refreshBlockOptions();
    };

    const promptSaveConfirmation = async () => {
        let result = true;

        if (visualStore.hasFormTypeChanges && confirm('Save changes before creating new block?')) {
            result = await store.saveAllBlocksOnPage(pageNumber - 1);
            visualStore.clearFormTypeEvents();
        } else if (visualStore.hasFormTypeChanges) {
            result = false;
        }

        return result;
    };

    const handleRegionPositionChange = async (blockId: string, blockTitle: string, blocks: FormBlock[]) => {
        await store.updateFormRegionBlock(
            blockId,
            blockTitle,
            pageNumber - 1,
            blocks.map(b => ({
                useContours: b.useContours,
                bbox: [b.x1, b.y1, b.x2, b.y2]
            }))
        );
    };

    const removeRegion = async (index: number) => {
        const newRegions = [...regions];
        const block = newRegions[index];

        if (!block) {
            return;
        }

        newRegions.splice(index, 1);

        const formPart = store.formParts.find(
            p => p.regions.find(r => r.regionId === store.selectedFormTemplateRegion) != null
        );
        const region = formPart?.regions.find(r => r.regionId === store.selectedFormTemplateRegion);

        if (!region) {
            return;
        }

        const regionBlock = region?.regionBlocks.find(b => b.blockId === block.blockId);

        if (!regionBlock) {
            onRegionChange(newRegions);
            return;
        }

        setRegions(newRegions);
        if (store.selectedFormTemplateRegion) {
            saveHistoryState();
            const currentBlocks = { ...store.currentRegionBlocks };
            let pageBlocks = currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1];
            pageBlocks.splice(index, 1);
            currentBlocks[store.selectedFormTemplateRegion][pageNumber - 1] = pageBlocks;
            store.setCurrentRegionBlocks(currentBlocks);
        }
        store.setSelectedBlockInfo(undefined);
        clearBlockHighlighting();
        refreshBlockOptions();
        visualStore.logFormTypeEvent('region_block_deleted');
    };

    const updateUseContoursOnBbox = async (block: FormRegionBlock | undefined, index: number, useContours: boolean) => {
        if (!block) {
            return;
        }

        const newRegions = [...regions];
        const newBlock = newRegions.find(b => b.blockId === block.blockId && b.data[dataIndexKey] === index);
        const blockIndex = newBlock ? newRegions.indexOf(newBlock) : -1;

        if (blockIndex !== -1) {
            newRegions[blockIndex].useContours = useContours;
            newRegions[blockIndex].data[dataRegionStyleKey] = {
                ...newRegions[blockIndex].data[dataRegionStyleKey],
                backgroundImage: useContours ? store.crossedBg : undefined
            };
            setRegions(newRegions);
            saveHistoryState();
            saveCurrentBlocksInStore(newRegions);
            visualStore.logFormTypeEvent('region_block_updated');
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getBlockDropdownMenu = (block: FormRegionBlock | undefined, regionProps: any, usesContours: boolean) => {
        const { index } = regionProps.data;

        return (
            <Menu>
                <Menu.Item
                    key="1"
                    onClick={() => updateUseContoursOnBbox(block, index, !usesContours)}
                    onMouseDown={e => e.stopPropagation()}
                >
                    {usesContours ? <CheckOutlined /> : null} Use contours
                </Menu.Item>

                <Menu.Divider />

                {currentRegionOptions.map((o, i) => (
                    <Menu.Item
                        key={o.value ?? `option-${i}`}
                        onClick={async () => await handleSelectBlock(o.value, regionProps)}
                        onMouseDown={e => e.stopPropagation()}
                    >
                        {block?.blockId === o.value ? <CheckOutlined /> : null} {o.title}
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const regionRenderer = (regionProps: any) => {
        const formPart = store.formParts.find(
            p => p.regions.find(r => r.regionId === store.selectedFormTemplateRegion) != null
        );
        const region = formPart?.regions.find(r => r.regionId === store.selectedFormTemplateRegion);
        if (!regionProps.isChanging && region) {
            const currentRegion = regions[regionProps.index];
            const block = region?.regionBlocks.find(b => b.blockId === currentRegion.blockId);
            const usesContours = regionProps.data?.regionStyle?.backgroundImage != null;

            return (
                <div data-id={`block-${block ? (block.blockTitle ?? block.blockId) : 'new'}`}>
                    <div className={`region-select-controls ${usesContours ? 'crossed' : ''}`}>
                        <div className="region-controls-wrapper">
                            <Dropdown
                                overlay={getBlockDropdownMenu(block, regionProps, usesContours)}
                                trigger={['click']}
                            >
                                <Button className="region-more-btn" shape="circle" type="link" size="small">
                                    <MoreOutlined />
                                </Button>
                            </Dropdown>
                            <Button
                                className="region-remove-btn"
                                shape="circle"
                                type="link"
                                danger
                                size="small"
                                onClick={() => removeRegion(regionProps.index)}
                            >
                                <CloseOutlined />
                            </Button>
                        </div>
                        {block && <div className="region-block-label">{block.blockTitle ?? block.blockId}</div>}
                    </div>
                </div>
            );
        }

        return null;
    };

    const moreActionsDropdownMenu = () => (
        <Menu>
            <Menu.Item
                key="annotations"
                icon={<FontSizeOutlined />}
                onClick={visualStore.toggleAnnotations}
                onMouseDown={e => e.stopPropagation()}
            >
                {visualStore.showAnnotations ? 'Hide annotations' : 'Show annotations'}
            </Menu.Item>
        </Menu>
    );

    const preventScrollingWithKeys = (e: KeyboardEvent) => {
        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(e.code) > -1) {
            e.preventDefault();
        }
    };

    return (
        <div className="pdf-with-regions-wrapper" id={wrapperId}>
            <div className="pdf-viewer-content-wrapper">
                <Document
                    file={{
                        url: `${process.env.REACT_APP_MANAGE_URL}document/${encodeURIComponent(documentId)}${'?access_token=' + encodeURIComponent(SecurityService.token)}`
                    }}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {store.selectedFormTemplateRegion ? (
                        <RegionSelect
                            regions={regions}
                            onChange={onRegionChange}
                            regionStyle={{
                                zIndex: 1000
                            }}
                            regionRenderer={regionRenderer}
                        >
                            <Dropdown overlay={moreActionsDropdownMenu} trigger={['contextMenu']}>
                                <Page
                                    width={pageWidth * scale}
                                    pageNumber={safePageNumber}
                                    renderTextLayer={false}
                                    renderAnnotations={false}
                                />
                            </Dropdown>
                        </RegionSelect>
                    ) : (
                        <Dropdown overlay={moreActionsDropdownMenu} trigger={['contextMenu']}>
                            <Page
                                width={pageWidth * scale}
                                pageNumber={safePageNumber}
                                renderTextLayer={false}
                                renderAnnotations={false}
                            />
                        </Dropdown>
                    )}
                </Document>
            </div>
        </div>
    );
};

export default observer(PdfDocumentPreview);
