import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { Table, Button, Popconfirm, Modal, Layout, Tooltip, Upload, message } from 'antd';
import WrappedAddConnectionsDefinition from './AddConnectionsDefinition';
import WrappedConnectionsDefinitionEdit from './ConnectionsDefinitionEdit';
import { ConnectionsDefinition } from '../types';
import { ConnectionDescription } from '../../iota_applications/types';
import { IotaConnStores } from '../stores';
import ReactJson from 'react-json-view';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import EditConnectionDefinitionDialog from './EditConnectionDefinitionDialog';
import LayoutHeader from '../../../components/LayoutHeader';
import { Utils } from '../../common/services/Utils';
import { UploadChangeParam } from 'antd/lib/upload';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
const { Content } = Layout;

type Props = IotaConnStores;

export const ProjectConnectionsDefinitionsList: React.FC<Props> = ({ projectConnectionsDefinitionsUI: store }) => {
    const connDefStore = store!;
    const [expandedKeys, setExpandedKeys] = React.useState([] as string[]);
    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (appDef: ConnectionsDefinition) => (
                <span>
                    <span data-id="Connection-Name">{appDef.name}</span>
                </span>
            )
        },
        {
            width: '600px',
            title: 'Actions',
            key: 'actions',
            render: (appDef: ConnectionsDefinition) => (
                <HasPermission
                    entityId={store?.currentProject?.id}
                    permissionClaim={AppPermissions.CanEditIotaConnections}
                >
                    <div className="table-operation">
                        <Tooltip placement="top" title="Edit">
                            <Button
                                data-id={`project-connections-definitions-list-edit-button-${appDef.id}`}
                                onClick={e => {
                                    showEditDialog(appDef);
                                    e.stopPropagation();
                                }}
                                size="small"
                                type="link"
                            >
                                <i className="alpha-icon md edit-icon" />
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Edit settings">
                            <Button
                                data-id={`project-connections-definitions-list-edit-settings-button-${appDef.id}`}
                                onClick={e => {
                                    navigateToSettings(appDef);
                                    e.stopPropagation();
                                }}
                                size="small"
                                type="link"
                            >
                                <i className="alpha-icon md settings" />
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Export">
                            <Button
                                data-id={`project-connections-export-button-${appDef.id}`}
                                onClick={e => {
                                    store!.exportConnection(appDef.id);
                                    e.stopPropagation();
                                }}
                                size="small"
                                type="link"
                            >
                                <i className="alpha-icon md arrow-up" />
                            </Button>
                        </Tooltip>
                        <Popconfirm
                            id="data-id-popconfirm-box-DeleteWFConnection"
                            title="Are you sure that you want to delete this definition?"
                            okText={
                                <span data-id={`project-connections-definitions-list-delete-confirm-yes-${appDef.id}`}>
                                    Yes
                                </span>
                            }
                            onConfirm={e => {
                                deleteAppDefinition(appDef.id);
                                e!.stopPropagation();
                            }}
                            cancelText={
                                <span data-id={`project-connections-definitions-list-delete-confirm-no-${appDef.id}`}>
                                    No
                                </span>
                            }
                            onCancel={e => e!.stopPropagation()}
                        >
                            <Tooltip placement="top" title="Delete">
                                <Button
                                    data-id={`project-connections-definitions-list-delete-button-${appDef.id}`}
                                    size="small"
                                    type="link"
                                    onClick={e => e.stopPropagation()}
                                >
                                    <i className="alpha-icon md delete-icon" />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </HasPermission>
            )
        }
    ];

    React.useEffect(() => {
        connDefStore.loadDefinitions();
    }, [connDefStore.currentProject, connDefStore]);

    const showAddDialog = () => {
        store!.setCurrent(undefined);
        store!.setAddDefinitionDialogVisible(true);
    };

    const showEditDialog = (appDef: ConnectionsDefinition) => {
        store!.setCurrent(appDef);
        store!.setEditDefinitionDialogVisible(true);
    };

    const navigateToSettings = (appDef: ConnectionsDefinition) => {
        store!.goToSettingsPage(appDef);
    };

    const deleteAppDefinition = async (appDefId: string) => {
        await store!.delete(appDefId);
    };

    const onModalClose = () => {
        store!.setIsModalVisible(false);
    };

    const jsonModal = () => {
        return (
            <Modal
                className="alpha-portal-dialog two-columns scrollable"
                visible={store!.isModalVisible}
                title={'Execution Response'}
                width={800}
                onCancel={onModalClose}
                centered
                closable={false}
                maskClosable={false}
                footer={[
                    <Button
                        data-id="execution-response-close"
                        key="back"
                        onClick={onModalClose}
                        size="large"
                        className="light"
                    >
                        Close
                    </Button>
                ]}
            >
                <div data-id="execution-response-content">
                    <ReactJson
                        style={{ overflow: 'auto' }}
                        displayDataTypes={false}
                        src={store!.connExecutionResponse}
                    />
                </div>
            </Modal>
        );
    };

    const expandedRowRender = (record: ConnectionsDefinition) => {
        const nestedColums = [
            {
                title: 'Name',
                key: 'name',
                dataIndex: 'name',
                render: (name: string) => <span data-id="Field-Name">{name}</span>
            },
            {
                title: 'Description',
                key: 'description',
                dataIndex: 'description',
                render: (description: string) => <span data-id="Field-Description">{description}</span>
            },
            {
                width: '250px',
                title: 'Fields',
                key: 'fields',
                render: (conn: ConnectionDescription) =>
                    conn.fields.map(c => (
                        <span key={c.name} data-id="Field-Fields">
                            {c.name} ({c.dataType});{' '}
                        </span>
                    ))
            },
            {
                key: 'execute',
                render: (conn: ConnectionDescription) => (
                    <Tooltip placement="top" title="Execute">
                        <Button
                            className="wf-connection-execute-button"
                            data-id={`project-connections-definitions-list-edit-button-${conn.name}`}
                            style={{ width: 70, height: 20 }}
                            size="small"
                            type="link"
                            onClick={() => store!.executeConnection(record.id, conn.id)}
                        >
                            <i className="alpha-icon md play-icon" style={{ verticalAlign: 'middle' }} />
                        </Button>
                    </Tooltip>
                )
            }
        ];

        return (
            <Observer>
                {() => (
                    <Table
                        columns={nestedColums}
                        dataSource={record.connections}
                        rowKey={r => `${r.id}`}
                        pagination={false}
                        rowClassName="nested-row"
                    />
                )}
            </Observer>
        );
    };

    const onFileUploadChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            store!.loadDefinitions();
        } else if (status === 'error') {
            if (info.file?.response?.status === 409) {
                message.warning(info.file.response.title);
                return;
            }
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const renderElement = (
        <>
            <EditConnectionDefinitionDialog store={store} />
            <WrappedAddConnectionsDefinition projectConnectionsDefinitionsUI={store} />
            <WrappedConnectionsDefinitionEdit projectConnectionsDefinitionsUI={store} />
            <Layout className="screen-size" style={{ ...{ height: '100%', background: 'white' } }}>
                <LayoutHeader
                    subtitle={Utils.getSubtitle(store!.currentProject)}
                    title="WF Connections"
                    buttons={[
                        <HasPermission
                            entityId={store?.currentProject?.id}
                            key="1"
                            permissionClaim={AppPermissions.CanEditIotaConnections}
                        >
                            <Upload
                                showUploadList={false}
                                className="headerButton"
                                key="file-uploader"
                                name="file"
                                onChange={onFileUploadChange}
                                action={`${process.env.REACT_APP_MANAGE_URL}projects/${store!.currentProject?.id}/iota_connections/import`}
                                headers={store!.fileImportActionHeaders}
                                beforeUpload={() => store!.setHeaders()}
                            >
                                <span data-id="button-Import" key="import-button-container">
                                    <i className="alpha-icon xs arrow-down" style={{ verticalAlign: 'middle' }} />
                                    <span style={{ marginLeft: 11 }}>Import</span>
                                </span>
                            </Upload>
                        </HasPermission>,
                        <span key="help-message" className="tooltip-container">
                            <Tooltip title="Workflow data connection Management">
                                <i className="alpha-icon lg question-icon" style={{ verticalAlign: 'middle' }} />
                            </Tooltip>
                        </span>,
                        <HasPermission
                            entityId={store?.currentProject?.id}
                            key="2"
                            permissionClaim={AppPermissions.CanEditIotaConnections}
                        >
                            <Button
                                data-id={'project-connections-definitions-list-add-button'}
                                type="primary"
                                onClick={showAddDialog}
                                size="large"
                            >
                                Add Connections Definition
                            </Button>
                        </HasPermission>
                    ]}
                />
                {jsonModal()}
                {!store!.isLoading ? (
                    <Layout>
                        <Content style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                            <Observer>
                                {() => (
                                    <Table
                                        data-id="table-WF-Connections-list"
                                        expandedRowKeys={expandedKeys}
                                        className="alpha-portal-table nested wf-connections"
                                        dataSource={store!.definitions}
                                        columns={columns}
                                        rowKey={m => m.id!}
                                        rowClassName={record =>
                                            expandedKeys.includes(record.id!)
                                                ? 'expanded parent-nested-row'
                                                : ' parent-nested-row'
                                        }
                                        pagination={false}
                                        expandedRowRender={expandedRowRender}
                                        expandIcon={props => (
                                            <i
                                                className="alpha-icon xs expand-row arrow-expand"
                                                onClick={e => {
                                                    props.onExpand(props.record, e);
                                                }}
                                            />
                                        )}
                                        expandRowByClick
                                        onExpandedRowsChange={(expanded: string[]) => setExpandedKeys(expanded)}
                                    />
                                )}
                            </Observer>
                        </Content>
                    </Layout>
                ) : (
                    <LoadingIndicator />
                )}
            </Layout>
        </>
    );

    return renderElement;
};

export default observer(ProjectConnectionsDefinitionsList);
