import * as React from 'react';
import { ProtectedRoute } from '../../common/components';
import LabelsPage from '../screens/LabelsPage';

export const ListLabelsPagesNavigation = {
    ProjectLabelsPage: '/projects/:id/labels'
};

export default () => {
    return <ProtectedRoute component={LabelsPage} path={ListLabelsPagesNavigation.ProjectLabelsPage} />;
};
