import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input } from 'antd';
import InstructWorkflowModel from '../../model/InstructWorkflowModel';

interface Props {
    workflow: InstructWorkflowModel;
}

const WorkflowName: React.FC<Props> = ({ workflow }) => {
    return (
        <Form.Item
            label="Workflow name"
            name="name"
            initialValue={workflow ? workflow.name : null}
            rules={[{ required: true, message: 'Please enter workflow name!' }]}
        >
            <Input />
        </Form.Item>
    );
};

export default observer(WorkflowName);
