import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormPartPreviewVisualStore } from '../stores';
import { Document, Page } from 'react-pdf';
import { Button, Pagination, Select, Spin } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { SecurityService } from '../../common/services';
import { Utils } from '../../common/services/Utils';

type Props = {
    projectId: string;
    formPartId: string;
    store: FormPartPreviewVisualStore;
};

const FormPartPreview: React.FC<Props> = ({ formPartId, store }) => {
    const crossedBg =
        // eslint-disable-next-line max-len
        'linear-gradient(to top left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1.2px), rgba(0, 0, 255, 0.6) 50%, rgba(0, 0, 0, 0) calc(50% + 1.2px), rgba(0, 0, 0, 0) 100%), linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1.2px), rgba(0, 0, 255, 0.6) 50%, rgba(0, 0, 0, 0) calc(50% + 1.2px), rgba(0, 0, 0, 0) 100%)';

    const pdfScalingLevels = [0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2.5, 3];
    const defaultScaleLevel = 5;
    const [pages, setPages] = React.useState(1);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pdfScaleLevel, setPdfScaleLevel] = React.useState<number>(defaultScaleLevel);
    const [pageWidth, setPageWidth] = React.useState<number>(1000);
    const [showAnnotations, setShowAnnotations] = React.useState<boolean>(true);

    React.useEffect(() => {
        // To hide menu on the side of page
        document.body.classList.add('rule-preview-page');
        setPageWidth(getDocumentContainerWidth);

        window.addEventListener('resize', handleResize);

        store.loadFormPartData(formPartId).then(() => {
            // TODO: Switch page
        });

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (store.currentProject) {
            store.loadAllProjectPackages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject]);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setPages(numPages);
    }

    const handleResize = () => {
        setPageWidth(getDocumentContainerWidth);
    };

    const getDocumentContainerWidth = () => {
        const sidePaddings = 60;
        return window.innerWidth - sidePaddings;
    };

    const changeDocumentScaling = (levelDelta: number) => {
        const newLevel = pdfScaleLevel + levelDelta;
        if (newLevel >= 0 && newLevel < pdfScalingLevels.length) {
            setPdfScaleLevel(newLevel);
        }
    };

    const getDocumentUrl = () => {
        if (!store.formPartDetails || !store.packageId) {
            return null;
        }

        const manageServiceUrl = process.env.REACT_APP_MANAGE_URL;
        const encodedPkgId = encodeURIComponent(store.packageId);
        const accessToken = encodeURIComponent(SecurityService.token);

        return `${manageServiceUrl}document/${encodedPkgId}${'?access_token=' + accessToken}`;
    };

    if (!store.formPartDetails || !store.packageId) {
        return null;
    }

    const getRegionStyle = (blockNameString: string, useContours: boolean = false) => {
        let color: string = Utils.stringToColourRgb(blockNameString);

        const style = {
            border: `1px solid rgb(${color})`,
            backgroundColor: `rgba(${color}, 0.1)`,
            backgroundImage: useContours ? crossedBg : undefined
        };

        return style;
    };

    const renderBlocks = () => {
        if (!store.displayBlocks?.length) {
            return null;
        }

        const blocksOnPage = store.displayBlocks.filter(b => b.page === pageNumber);

        if (!blocksOnPage.length) {
            return [];
        }

        return blocksOnPage.map(b => (
            <div
                className="form-part-block"
                key={`${b.regionId}${b.x1}${b.y1}${b.x2}${b.y2}`}
                style={{
                    left: `${b.x1}%`,
                    top: `${b.y1}%`,
                    height: `${b.height}%`,
                    width: `${b.width}%`,
                    ...getRegionStyle(b.regionId + b.blockName, b.useContours)
                }}
            >
                <div className="form-part-block-name">
                    {b.regionName} / {b.blockName}
                </div>
            </div>
        ));
    };

    const loadingOverlay = (
        <div className="pdf-loading-overlay">
            <Spin size="large" />
        </div>
    );

    return (
        <div className="pdf-with-regions-wrapper">
            {store.isLoadingAdjustments && loadingOverlay}
            <div className="pdf-viewer-controls" style={{ textAlign: 'center', paddingBottom: 12, paddingTop: 12 }}>
                <div>
                    <Select
                        options={store.validPackages.map(p => ({ value: p.id, label: p.name, title: p.name }))}
                        filterOption={(input, option) => option?.title.toLowerCase().includes(input.toLowerCase())}
                        onChange={async packageId => {
                            const page = await store.setPackageId(packageId);
                            setPageNumber(page + 1);
                        }}
                        value={store.packageId}
                        style={{ minWidth: 200, textAlign: 'left' }}
                        showSearch
                        loading={store.isLoadingPackages}
                    />
                </div>
                {pages > 1 ? (
                    <Pagination
                        defaultCurrent={1}
                        current={pageNumber}
                        showQuickJumper
                        showLessItems
                        pageSize={1}
                        total={pages}
                        onChange={setPageNumber}
                        style={{ paddingBottom: 0 }}
                    />
                ) : null}

                <div>
                    <Button onClick={() => changeDocumentScaling(-1)} icon={<MinusOutlined />} />
                    <span style={{ cursor: 'pointer' }} onClick={() => setPdfScaleLevel(defaultScaleLevel)}>
                        {Math.round(pdfScalingLevels[pdfScaleLevel] * 100)}%
                    </span>
                    <Button onClick={() => changeDocumentScaling(+1)} icon={<PlusOutlined />} />
                </div>
                <div>
                    <Button onClick={() => setShowAnnotations(!showAnnotations)}>
                        {showAnnotations ? 'Hide' : 'Show'} annotations
                    </Button>
                </div>
            </div>

            <div className={`pdf-viewer-content-wrapper ${!showAnnotations ? 'hide-annotations' : ''}`}>
                <Document
                    file={{
                        url: getDocumentUrl()
                    }}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <div className="pdf-viewer-page-content" style={{ position: 'relative' }}>
                        {renderBlocks()}
                        <Page
                            width={pageWidth * pdfScalingLevels[pdfScaleLevel]}
                            pageNumber={pageNumber}
                            renderTextLayer={false}
                            renderAnnotations={false}
                        />
                    </div>
                </Document>
            </div>
        </div>
    );
};

export default observer(FormPartPreview);
