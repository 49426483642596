import { Checkbox, Select } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import { Observer, observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormTypesStore } from '../stores';
import { FormTypeImportMapperData } from '../types';

type Props = {
    store: FormTypesStore;
};

const FormTypeMapperTable: React.FC<Props> = ({ store }) => {
    if (!store.formTypeImportData) {
        return null;
    }

    const getTriggerResponseHeader = () => {
        return (
            <Checkbox
                style={{ color: 'inherit' }}
                checked={store.formTypeImportData?.mapperTableData?.every(d => d.triggerPackageReparse)}
                onChange={e => store.handleSelectAllPackagesForReparse(e.target.checked)}
            >
                Trigger package reparse
            </Checkbox>
        );
    };

    const columns: ColumnProps<FormTypeImportMapperData>[] = [
        { title: 'Name', key: 'name', dataIndex: 'name' },
        { key: 'changeTo', dataIndex: 'changeTo', render: () => <span>change to</span> },
        {
            title: 'Name',
            key: 'nativeName',
            dataIndex: 'nativeName',
            render: (_: string, record: FormTypeImportMapperData) => {
                return {
                    children: (
                        <Observer>
                            {() => (
                                <Select
                                    key={`${record.id}_${record.bestValue}`}
                                    defaultValue={record.bestValue || ''}
                                    onChange={val =>
                                        store.updateMapperTableData(val, record.name, record.triggerPackageReparse)
                                    }
                                    style={{ width: 200 }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option?.title && option.title.toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={store.formTypeImportData?.packageSelectionOptions.map(x => ({
                                        value: x.value,
                                        label: x.label,
                                        title: x.label
                                    }))}
                                />
                            )}
                        </Observer>
                    ),
                    props: { style: { paddingTop: 0, paddingBottom: 0 } }
                };
            }
        },
        {
            title: getTriggerResponseHeader(),
            key: 'triggerPackageReparse',
            dataIndex: 'triggerPackageReparse',
            render: (_: string, record: FormTypeImportMapperData) => {
                return {
                    children: (
                        <Observer>
                            {() => (
                                <Checkbox
                                    checked={record.triggerPackageReparse}
                                    onChange={e =>
                                        store.updateMapperTableData(
                                            record.bestValue ?? '',
                                            record.name,
                                            e.target.checked
                                        )
                                    }
                                />
                            )}
                        </Observer>
                    )
                };
            }
        }
    ];

    return (
        <Table
            className="alpha-portal-table"
            rowClassName={record => (record.fuzzyScore < 95 ? 'highlighted-row' : '')}
            key="name"
            columns={columns}
            dataSource={store.formTypeImportData.mapperTableData}
            pagination={false}
        />
    );
};

export default observer(FormTypeMapperTable);
