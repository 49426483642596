import * as React from 'react';
import { Button, Input, AutoComplete } from 'antd';
import autocompleteOptionRenderer from '../../common/components/SearchTextAutocompleteOptionRenderer';
import { AutoCompleteText } from '../../common/types';
import { Package } from '../../common/models';
import { SearchOutlined } from '@ant-design/icons';
import { Observer } from 'mobx-react';

type Props = {
    searchForAutocomplete: (term: string) => Promise<void>;
    changePage: (term?: string) => void;
    autocompleteSource: AutoCompleteText[];
    searchTerm: string;
    selectedPackage: Package | null;
    disabled?: boolean;
};

type State = {
    searchTerm: string;
};

class SearchText extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            searchTerm: ''
        };

        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.selectedPackage !== this.props.selectedPackage) {
            this.setState({ searchTerm: '' });
            console.log('update', 'search term:', this.state.searchTerm);
        }
    }

    handleSearch = (value: string) => {
        this.setState({ searchTerm: value });
        this.props.searchForAutocomplete(value);
    };

    render() {
        const optionRenderer = autocompleteOptionRenderer(this.props.searchTerm);
        return (
            <Observer>
                {() => (
                    <AutoComplete
                        className="global-search"
                        options={this.props.autocompleteSource.map((m, i) => ({
                            value: `${m.text}::${m.page}::${m.position}::${m.pageHeight}`,
                            label: optionRenderer(m, i)
                        }))}
                        onSearch={this.handleSearch}
                        placeholder="Search by keywords..."
                        defaultActiveFirstOption={false}
                        autoClearSearchValue={false}
                        showSearch
                        value={this.props.searchTerm}
                        onSelect={this.props.changePage}
                        allowClear
                        disabled={this.props.disabled}
                    >
                        <Input
                            disabled={this.props.disabled}
                            suffix={
                                <Button className="search-btn" type="ghost">
                                    <SearchOutlined />
                                </Button>
                            }
                        />
                    </AutoComplete>
                )}
            </Observer>
        );
    }
}

export default SearchText;
