import { observer } from 'mobx-react-lite';
import * as React from 'react';

type Props = {
    content: string;
};

const TableBlockPreview: React.FC<Props> = ({ content }) => {
    const [tableHTML, setTableHTML] = React.useState('');

    React.useEffect(() => {
        let tableObj = JSON.parse(content);
        let tableContent = document.createElement('table');
        tableContent.className = 'rule-preview-table';
        let tableHead = tableContent.createTHead();
        let tableBody = tableContent.createTBody();
        for (let i = 0; i < tableObj.rows; i++) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            var rowCells = tableObj.cells.filter((c: any) => c.r === i).sort((c: any) => c.c);
            let row: HTMLTableRowElement;
            if (i === 0) {
                row = tableHead.insertRow();
            } else {
                row = tableBody.insertRow();
            }
            for (let j = 0; j < tableObj.columns; j++) {
                let cell = row.insertCell();
                cell.innerHTML = rowCells[j]?.n || '';
            }
        }
        setTableHTML(tableContent.outerHTML);
    }, [content]);

    return <div dangerouslySetInnerHTML={{ __html: tableHTML }} />;
};

export default observer(TableBlockPreview);
