import * as React from 'react';
import { observer, inject } from 'mobx-react';
import PackageLinesLabelsGrid from '../components/PackageLinesLabelsGrid';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { ListLabelsStores } from '../stores';
import { STORE_PROJECT_LABELS } from '../constants';

type Props = ListLabelsStores;

@inject(STORE_PROJECT_LABELS)
@observer
export default class LabelsPage extends React.Component<Props, object> {
    render() {
        return <FlexContentContainer content={<PackageLinesLabelsGrid />} />;
    }
}
