import { action, observable } from 'mobx';
import RuleEditVisualStore from './RuleEditVisualStore';
import type { RuleTypes } from '../models';
import { RouterStore } from '../../common/stores';
import RulesStore from './RulesStore';
import RuleNewVisuaStoreBase from './RuleNewVisuaStoreBase';
import type RuleType from '../types/RuleType';

export default class RulesNewVisualStore extends RuleNewVisuaStoreBase {
    @observable
    editableRule?: RuleTypes;

    @observable
    type: RuleType | undefined;

    constructor(store: RulesStore, routerStore: RouterStore, editStore: RuleEditVisualStore) {
        super(store, routerStore, editStore);
    }

    @action.bound
    selectType(v?: RuleType) {
        this.type = v;
        this.selectNewType(this.copiedPipeline, v);
    }

    get currentGroupId() {
        return this.editStore.currentGroupId!;
    }

    setCurrentGroupId(id: string) {
        this.editStore.setCurrentGroupId({ groupId: id });
    }

    get currentTagId() {
        return this.editStore.currentTagId;
    }

    setPreselectedData(groupId: string, tagId: string) {
        this.editStore.setPreselectedData({ groupId, tagId });
    }
}
