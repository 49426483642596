import { RootStores } from '../../common/stores';
import UserProfileStore from './UserProfileStore';
import { STORE_USER_PROFILE } from '../constants';
import { STORE_ROUTER, STORE_ERRORS } from '../../common/constants';
import UserProfileService from '../services/UserProfileService';
import { GlobalAdministrationService } from '../../administration/service/GlobalAdministrationService';

export { default as UserProfileStore } from './UserProfileStore';

export function injectUserProfileStores(obj: RootStores) {
    const service = new UserProfileService();
    const adminService = new GlobalAdministrationService();
    obj[STORE_USER_PROFILE] = new UserProfileStore(obj[STORE_ROUTER], service, adminService, obj[STORE_ERRORS]);
}

export type UserProfileStores = {
    [STORE_USER_PROFILE]?: UserProfileStore;
};
