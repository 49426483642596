import { Button, Dropdown, Menu } from 'antd';
import * as React from 'react';
import { SecurityPagesNavigation } from '../modules/administration/routes';
import { ProjectPagesNavigation } from '../modules/project_management/routes';
import { ProjectsStore, RouterStore } from '../modules/common/stores';
import UserProfileControls from '../modules/user_profile/components/UserProfileControls';
import { HasPermission } from '../modules/authorization/components/HasPermission';
import { AppPermissions } from '../modules/authorization/Permissions';

type Props = {
    routerStore: RouterStore;
    projectsStore: ProjectsStore;
};

const SideMenuHeaderControls: React.FC<Props> = ({ routerStore, projectsStore }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const switchMenu = ({ key }: any) => {
        if (key === 'Administration') {
            routerStore.push(ProjectPagesNavigation.IndexingPage);
        } else if (key === 'Members') {
            routerStore.push(SecurityPagesNavigation.UserManagement);
        } else {
            routerStore.push(ProjectPagesNavigation.ProjectsPage);
        }
    };

    // Menu.Item icon = %put icons here%
    const navigationMenu = (
        <Menu onClick={switchMenu}>
            <Menu.Item key="Projects" icon={<i className="alpha-icon lg projects-icon" />}>
                Projects
            </Menu.Item>
            <Menu.Item key="Members" icon={<i className="alpha-icon lg members-icon" />}>
                Members
            </Menu.Item>
            <Menu.Item key="Administration" icon={<i className="alpha-icon lg wrench-icon" />}>
                Administration
            </Menu.Item>
            {/* <Menu.Item key="ProblemReports" icon={<i className="alpha-icon lg problem-icon" />}>
              Problem reports
            </Menu.Item> */}
        </Menu>
    );

    const handleLogoClick = () => {
        routerStore.push(ProjectPagesNavigation.ProjectsPage);
        projectsStore.loadProjects();
    };

    return (
        <div className="side-menu-header">
            <div className="side-menu-logo" onClick={handleLogoClick} />
            <HasPermission
                permissionClaim={AppPermissions.CanAccessAdministration}
                yes={() => (
                    <div className="menu-container">
                        <Dropdown overlay={navigationMenu} trigger={['click']} placement="bottomRight">
                            <Button type="link">
                                <i className="alpha-icon lg menu-icon" />
                            </Button>
                        </Dropdown>
                    </div>
                )}
                no={() => <div className="menu-container" />}
            />
            <UserProfileControls />
        </div>
    );
};

export default SideMenuHeaderControls;
