/* eslint-disable @typescript-eslint/member-ordering */
import { ProjectsRootVisualStore } from '../../common/stores';
import { Project, PackageLine, PackageState } from '../../common/models';
import { computed, reaction, observable, action, runInAction } from 'mobx';
import { ProjectsService } from '../../common/services';
import { BLOCK_TYPE, PackageChange } from '../../../modules/common/types';
import { PreviewVisualStore } from '../../pipeline_base/stores';
import { Subscription } from 'rxjs';

export const SEARCH_DEBOUNCE_TIME = 200;

export class Block {
    constructor(
        public packageFieldId: string,
        public width: number,
        public height: number,
        public x: number,
        // eslint-disable-next-line no-empty,no-empty-function,@typescript-eslint/no-empty-function
        public y: number,
        public text: string,
        public normalizedText: string,
        public line?: PackageLine,
        public blockType?: BLOCK_TYPE
    ) {}
}

export class TocNode {
    constructor(
        public topic: string,
        public subItems: string[]
    ) {}
}

export const NONE_LABEL = 'None';

export class InteractiveLabelsVisualStore extends PreviewVisualStore {
    @observable
    isPackageSourceChecked: boolean = false;

    @observable
    loading: boolean = false;

    @observable
    blockToHighlight: string = '';

    @observable
    externalWindowMode: boolean = false;

    @observable
    preSelectedPackageId: string = '';

    highlightBlockTimeout: ReturnType<typeof setTimeout> | null = null;

    subscription: Subscription | null = null;

    @computed
    get project(): Project | null {
        return this.projectsVisualStore.currentProject;
    }

    @action.bound
    setBlockToHighlight(blockToHighlight: string) {
        this.blockToHighlight = blockToHighlight;
    }

    @action.bound
    handlePackageSourceCheck(val: boolean) {
        this.isPackageSourceChecked = val;
    }

    @action.bound
    setExternalWindowMode(externalWindowMode: boolean) {
        this.externalWindowMode = externalWindowMode;
    }

    @action.bound
    setPreSelectedPackageId(preSelectedPackageId: string) {
        this.preSelectedPackageId = preSelectedPackageId;
    }

    @action.bound
    setLoading(loading: boolean) {
        this.loading = loading;
    }

    handleDownload(id: string, fileType: 'pdf' | 'apkg') {
        this.projectsService.handleDownload(id, fileType);
    }
    @action.bound
    clearFilters() {
        this.setCurrentPage(-1);
        this.selectedPackage = null;
        this.totalPages = 0;
        this.setTags([]);
        this.filterMarkedPackages(false);
        this.handlePackageSourceCheck(false);
        this.setSearchTerm('');
        this.loadProjectPackages();
    }

    @action.bound
    clearExternalWindowFilters() {
        this.setSearchTerm('');
        this.setTags([]);
        this.changeBlockTypes(['LINE_BLOCK']);
    }

    constructor(
        projectsVisualStore: ProjectsRootVisualStore,
        public projectsService: ProjectsService
    ) {
        super(projectsVisualStore);
        this.projectsService = projectsService;
        reaction(
            () => projectsVisualStore.currentProject,
            project => {
                if (this.selectedPackage && this.selectedPackage.project.id === project?.id) {
                    this.setPackage(this.selectedPackage);
                    return;
                }

                this.lines = [];
                this.setCurrentPage(-1);
                this.selectedPackage = null;
                this.totalPages = 0;
            }
        );
    }

    setUrl(): void {
        const path = encodeURIComponent(this.selectedPackage!.id);
        this.pdfServiceUrl = process.env.REACT_APP_MANAGE_URL + `document/${path}`;
    }

    subscribeToPackageChanges() {
        this.subscription = this.projectsVisualStore.projectsStore.packageChanges.subscribe(p =>
            this.handlePackageChanges(p)
        );
    }

    unsubscribeFromPackageChanges() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    clearHighlightedBlock(timeout: number = 1000) {
        if (this.highlightBlockTimeout) {
            clearTimeout(this.highlightBlockTimeout);
        }

        this.highlightBlockTimeout = setTimeout(() => {
            this.setBlockToHighlight('');
        }, timeout);
    }

    scrollAndHighlightBlock(fieldId: string, blockType: BLOCK_TYPE) {
        const scrollAndHighlight = (packageLines: PackageLine[], currentPage: number) => {
            const packageLine = packageLines.find(l => l.id === fieldId);

            if (!packageLine || !packageLine.coordinates || this.blockToHighlight === fieldId) {
                return;
            }

            const page = packageLine.coordinates.page;

            setTimeout(
                () => {
                    this.setCurrentPage(page);
                    this.setBlockToHighlight(fieldId);
                },
                page === currentPage ? 0 : 500
            );
        };

        if (!this.blockTypes.includes(blockType)) {
            this.changeBlockTypes([blockType], (packageLines: PackageLine[], currentPage: number) =>
                scrollAndHighlight(packageLines, currentPage)
            );
            return;
        }

        scrollAndHighlight(this.lines, this.currentPage);
    }

    @action.bound
    handlePackageChanges(packageChange: PackageChange) {
        if (!this.project) {
            return;
        }

        if (this.project.id === packageChange.projectId && packageChange.state === PackageState.Ready) {
            this.loadProjectPackages();
        }
    }

    @action.bound
    async loadProjectPackages() {
        if (this.project) {
            try {
                this.setLoading(true);

                const packages = await this.loadPackages(
                    undefined,
                    !this.isPackageSourceChecked,
                    100,
                    null,
                    this.preSelectedPackageId ? [this.preSelectedPackageId] : undefined
                );

                runInAction(() => {
                    this.packages = packages.lines;
                });

                const preSelectedPackage = this.packages.find(p => p.id === this.preSelectedPackageId);

                if (preSelectedPackage) {
                    this.setPackage(preSelectedPackage);
                }
            } catch (err) {
                console.error(err);
            } finally {
                this.setLoading(false);
            }
        }
    }
}

export default InteractiveLabelsVisualStore;
