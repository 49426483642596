import { Button, Collapse, Input, Space, Tooltip } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { InstructWorkflowStores } from '../../instruct_workflows/stores';
import { STORE_INSTRUCT_WORKFLOW } from '../../instruct_workflows/stores/constants';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import { Parameter } from '../types';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PipelineVisualStore } from '../stores';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';

type Props<
    T extends PipelineVisualStore<TPipelineStepType>,
    TPipelineStepType extends string
> = InstructWorkflowStores & {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};
export const OverridableWorkflowParametersEditor: <T extends PipelineVisualStore<S>, S extends string>(
    props: Props<T, S>
) => React.ReactElement<Props<T, S>> = ({ step, instructWorkflowUI, form }) => {
    const workflowStore = instructWorkflowUI!;

    React.useEffect(() => {
        const fetchData = async () => {
            if (workflowStore.project && workflowStore.workflows.length === 0 && !workflowStore.isLoading) {
                await workflowStore.getData();
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const workflow = workflowStore.workflows.find(f => f.id === step.parameters.get('instruct_workflow_id'));

    const [openPanelKeys, setOpenPanelKeys] = React.useState<string[]>([]);
    const [featureFlagsOverride, setFeatureFlagsOverride] = React.useState<boolean>(false);

    const getOverriddenWorkflowParameters = () => {
        const overriddenParams = step.parameters.get('overridden_workflow_parameters') ?? [];
        if (workflow?.workflowType?.textInputs) {
            const workflowParameters = workflow.workflowType.textInputs;

            const params = [
                ...workflowParameters.map(f => {
                    const inputValue = workflow!.inputValues?.find(input => input.inputId === f.id)?.value;
                    const paramValue = inputValue ?? overriddenParams[f.id];

                    return {
                        key: f.id,
                        name: f.name,
                        value: paramValue ?? f.defaultValue ?? null,
                        isCustom: false,
                        description: f.description
                    };
                }),
                ...Object.keys(overriddenParams)
                    .filter(key => !workflowParameters.some(f => f.id === key))
                    .map(key => ({
                        key: key,
                        name: '',
                        value: overriddenParams[key],
                        isCustom: true,
                        description: ''
                    }))
            ];
            return params;
        }
        return [];
    };

    const [overriddenWorkflowParameters, setOverriddenWorkflowParameters] = React.useState(
        getOverriddenWorkflowParameters()
    );

    React.useEffect(() => {
        form.setFieldsValue({ overridden_workflow_parameters: overriddenWorkflowParameters });
    }, [workflow, step, form, overriddenWorkflowParameters]);

    React.useEffect(() => {
        setOverriddenWorkflowParameters(getOverriddenWorkflowParameters());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflow, step, form]);

    const handleAddField = async () => {
        const newField = { key: '', name: '', value: '', isCustom: true, description: '' };
        const newParams = [...getOverriddenWorkflowParameters(), newField];
        form.setFieldsValue({ overridden_workflow_parameters: newParams });
        setOverriddenWorkflowParameters(newParams);
    };

    const handleRemoveField = (index: number) => {
        const newData = (overriddenWorkflowParameters ?? []).filter((_, idx) => idx !== index);
        setOverriddenWorkflowParameters(newData);
    };

    const featureFlagsPanelExtra = (onCheckboxChange: (e: CheckboxChangeEvent) => void, checked: boolean) => {
        return (
            <div
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <Checkbox style={{ marginLeft: 12 }} checked={checked} onChange={onCheckboxChange} />
            </div>
        );
    };

    return (
        <Collapse
            activeKey={openPanelKeys}
            onChange={key => setOpenPanelKeys(key as string[])}
            style={{ marginBottom: '18px' }}
        >
            <Collapse.Panel
                key="1"
                header="Workflow parameters"
                forceRender
                extra={featureFlagsPanelExtra(e => {
                    setFeatureFlagsOverride(e.target.checked);
                }, featureFlagsOverride)}
            >
                <Form.List name="overridden_workflow_parameters">
                    {(fields, { remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey }, index) => (
                                <Space key={key} align="center" style={{ marginBottom: '18px' }}>
                                    <Form.Item
                                        name={[name, 'key']}
                                        fieldKey={[fieldKey, 'key']}
                                        rules={[{ required: true, message: 'Key is required' }]}
                                        style={{ marginBottom: 0, width: '100%' }}
                                    >
                                        <Input
                                            placeholder="Enter key"
                                            disabled={
                                                !featureFlagsOverride || !overriddenWorkflowParameters[index]?.isCustom
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, 'value']}
                                        fieldKey={[fieldKey, 'value']}
                                        rules={[{ required: true, message: 'Value is required' }]}
                                        style={{ marginBottom: 0, width: '100%' }}
                                    >
                                        <Input
                                            placeholder="Enter value"
                                            style={{ width: '100%' }}
                                            disabled={!featureFlagsOverride}
                                        />
                                    </Form.Item>
                                    <div style={{ textAlign: 'center', paddingLeft: '0px', minWidth: '20px' }}>
                                        {overriddenWorkflowParameters[index]?.isCustom && (
                                            <i
                                                onClick={() => {
                                                    remove(name);
                                                    handleRemoveField(index);
                                                }}
                                                className="alpha-icon md delete-icon"
                                            />
                                        )}
                                        {!overriddenWorkflowParameters[index]?.isCustom &&
                                            overriddenWorkflowParameters[index]?.description && (
                                                <Tooltip title={overriddenWorkflowParameters[index].description}>
                                                    <InfoCircleOutlined style={{ color: '#9ba0aa' }} />
                                                </Tooltip>
                                            )}
                                    </div>
                                </Space>
                            ))}
                            <Button onClick={handleAddField} block>
                                + Add Custom Parameter
                            </Button>
                        </>
                    )}
                </Form.List>
            </Collapse.Panel>
        </Collapse>
    );
};

export default inject(STORE_INSTRUCT_WORKFLOW)(observer(OverridableWorkflowParametersEditor));
