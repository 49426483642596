import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Layout } from 'antd';
import { Utils } from '../../../common/services/Utils';
import { PydanticEditor, JsonSchemaViewer } from '..';
import PydanticSchemaGeneratorStore from '../../stores/PydanticSchemaGeneratorStore';
import LayoutHeader from '../../../../components/LayoutHeader';
import './PydanticSchemaGenerator.less';

interface Props {
    store: PydanticSchemaGeneratorStore;
}

const PydanticSchemaGenerator: React.FC<Props> = ({ store }) => {
    return (
        <Layout>
            <LayoutHeader title="Pydantic Schema Generator" subtitle={Utils.getSubtitle(store.project)} />
            <Layout.Content>
                <div className="pydantic-schema-generator">
                    <PydanticEditor store={store} />
                    <JsonSchemaViewer store={store} />
                </div>
            </Layout.Content>
        </Layout>
    );
};

export default observer(PydanticSchemaGenerator);
