import { observable, action, computed, runInAction } from 'mobx';
import { message as antMessage } from 'antd';
import InstructWorkflowStore from '../stores/InstructWorkflowStore';
import InstructWorkflowService from '../services/InstructWorkflowService';
import {
    WorkflowMessagesTemplateDto,
    WorkflowOutputSchemeDto,
    WorkflowInputValueDto,
    CreateEditWorkflowFormValues,
    InstructWorkflowDto
} from '../types';

interface FormValues extends CreateEditWorkflowFormValues {}

type ModifiedWorkflowOutputScheme = WorkflowOutputSchemeDto & { isNew?: boolean };

export default class InstructWorkflowModel {
    @observable
    id?: string;

    @observable
    name: string;

    @observable
    messagesTemplates: WorkflowMessagesTemplateDto[];

    @observable
    inputValues: WorkflowInputValueDto[];

    @observable
    originalOutputSchemes: WorkflowOutputSchemeDto[];

    @observable
    modifiedOutputSchemes: ModifiedWorkflowOutputScheme[];

    @observable
    createDate?: Date;

    @observable
    updateDate?: Date;

    @observable
    isEditable: boolean = false;

    projectId: string;

    workflowTypeId: string;

    @computed
    get workflowType() {
        return this.store.workflowTypes.find(t => t.id === this.workflowTypeId);
    }

    @computed
    get isNew() {
        return !this.id;
    }

    constructor(
        private readonly store: InstructWorkflowStore,
        private readonly service: InstructWorkflowService,
        data: Partial<InstructWorkflowDto> & Pick<InstructWorkflowDto, 'projectId' | 'workflowTypeId'>
    ) {
        this.id = data.id;
        this.projectId = data.projectId;
        this.workflowTypeId = data.workflowTypeId;
        this.name = data.name ?? '';
        this.messagesTemplates = data.messagesTemplates ?? [];
        this.inputValues = data.inputValues ?? [];
        this.originalOutputSchemes = data.outputSchemes ?? [];
        this.modifiedOutputSchemes = data.outputSchemes ?? [];
        this.createDate = data.createDate;
        this.updateDate = data.updateDate;
    }

    @action.bound
    setIsEditable(isEditable: boolean) {
        this.isEditable = isEditable;
    }

    @action.bound
    addOuputScheme(outputScheme: ModifiedWorkflowOutputScheme) {
        outputScheme.isNew = true;
        this.modifiedOutputSchemes.push(outputScheme);
    }

    @action.bound
    updateOuputScheme(outputScheme: ModifiedWorkflowOutputScheme, index: number) {
        this.modifiedOutputSchemes[index] = outputScheme;
    }

    @action.bound
    removeOuputScheme(index: number) {
        this.modifiedOutputSchemes = this.modifiedOutputSchemes.filter((_, i) => i !== index);
    }

    @action.bound
    resetModifiedOutputSchemes() {
        this.modifiedOutputSchemes = [...this.originalOutputSchemes];
    }

    async create(formValues: FormValues) {
        const resp = await this.service.createWorkflow({
            workflowTypeId: this.workflowTypeId,
            ...this.prepareData(formValues)
        });

        if (!resp.isOk()) {
            antMessage.error('Failed to create instruct workflow');
            return false;
        }

        resp.map(data => this.updateData(data));

        return true;
    }

    async update(formValues: FormValues) {
        if (!this.id) {
            return;
        }

        const resp = await this.service.updateWorkflow({ id: this.id, ...this.prepareData(formValues) });

        if (!resp.isOk()) {
            antMessage.error('Failed to update instruct workflow');
            return false;
        }

        resp.map(data => this.updateData(data));

        return true;
    }

    async delete() {
        if (!this.id) {
            return;
        }

        const resp = await this.service.deleteWorkflow(this.projectId, this.id);

        if (!resp.isOk()) {
            antMessage.error('Failed to delete instruct workflow');
            return;
        }

        this.store.removeWorkflow(this);
    }

    updateData(data: InstructWorkflowDto) {
        runInAction(() => {
            this.id = data.id;
            this.name = data.name;
            this.messagesTemplates = data.messagesTemplates;
            this.inputValues = data.inputValues;
            this.originalOutputSchemes = data.outputSchemes;
            this.modifiedOutputSchemes = data.outputSchemes;
            this.createDate = data.createDate;
            this.updateDate = data.updateDate;
        });
    }

    prepareData(formValues: FormValues) {
        return {
            name: formValues.name,
            messagesTemplates: formValues.messagesTemplates
                ? Object.entries(formValues.messagesTemplates).map(([type, message]) => ({ type, message }))
                : [],
            inputValues: formValues.inputValues
                ? Object.entries(formValues.inputValues).map(([inputId, value]) => ({ inputId, value }))
                : [],
            outputSchemes: this.modifiedOutputSchemes,
            projectId: this.projectId
        };
    }

    getInputValueById(inputId: string) {
        return this.inputValues.find(i => i.inputId === inputId);
    }

    getMessagesTemplateByType(type: string) {
        return this.messagesTemplates.find(t => t.type === type);
    }
}
