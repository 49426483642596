import { gql } from 'apollo-boost';
import appClient from '../../common/services/AppClient';
import { execQuery } from '../../common/services/DataService';
import { EnvironmentVariable, EnvironmentVariablesResult } from '../types';

export default class EnvironmentVariablesService {
    async getAllEnvironmentVariables(): Promise<EnvironmentVariable[]> {
        const result = await execQuery<EnvironmentVariablesResult>({
            query: gql`
                query getEnvironmentVariables {
                    getEnvironmentVariables {
                        id
                        name
                        handlerType
                    }
                }
            `,
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getEnvironmentVariables || [];
    }

    async getEnvironmentVariableValues(
        projectId: string,
        envVarIds: string[]
    ): Promise<{ [id: string]: string | null }> {
        let idsQuery: string = '?varIds=';

        idsQuery += envVarIds.join('&varIds=');

        const url =
            process.env.REACT_APP_MANAGE_URL +
            `projects/${projectId}/iota/applications/environment-variables${idsQuery}`;
        const resp = await appClient.get<{ [id: string]: string | null }>(url);

        return resp.unwrapOr({});
    }
}
