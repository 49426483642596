import { RootStores } from '../../common/stores';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import { STORE_INSTRUCT_WORKFLOW, STORE_INSTRUCT_WORKFLOW_IMPORT_EXPORT } from './constants';
import InstructWorkflowStore from './InstructWorkflowStore';
import InstructWorkflowImportExportStore from './InstructWorkflowImportExportStore';
import InstructWorkflowService from '../services/InstructWorkflowService';

export function injectInstructWorkflowStores(obj: RootStores) {
    const service = new InstructWorkflowService();

    obj[STORE_INSTRUCT_WORKFLOW] = new InstructWorkflowStore(obj[STORE_PROJECTS_ROOT_UI], service);
    obj[STORE_INSTRUCT_WORKFLOW_IMPORT_EXPORT] = new InstructWorkflowImportExportStore(
        obj[STORE_PROJECTS_ROOT_UI],
        obj[STORE_INSTRUCT_WORKFLOW],
        service
    );
}

export type InstructWorkflowStores = {
    [STORE_INSTRUCT_WORKFLOW]?: InstructWorkflowStore;
    [STORE_INSTRUCT_WORKFLOW_IMPORT_EXPORT]?: InstructWorkflowImportExportStore;
};
