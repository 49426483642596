import * as React from 'react';
import { observer, inject } from 'mobx-react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { IotaAppStores } from '../stores';
import { STORE_PROJECT_APPLICATION_DEFINITIONS } from '../stores/constants';
import WrappedApplicationDefinitionEdit from '../components/ProjectApplicationDefinitionEdit';

type Props = IotaAppStores;

export const EditIotaApplicationPage: React.FC<Props> = ({ projectApplicationDefinitionsUI: store }) => {
    return (
        <FlexContentContainer content={<WrappedApplicationDefinitionEdit projectApplicationDefinitionsUI={store} />} />
    );
};

export default inject(STORE_PROJECT_APPLICATION_DEFINITIONS)(observer(EditIotaApplicationPage));
