export class Constants {
    public static readonly PACKAGE_SORT_DIRECTION_STORAGE_KEY = 'test-projects-packages-sorting';
    public static readonly PACKAGE_SORT_FIELD_NAME = 'Name';
    public static readonly PACKAGE_SORT_FIELD_DATE_ADDED = 'Date added';

    public static readonly PACKAGE_SORT_FIELDS = [
        Constants.PACKAGE_SORT_FIELD_NAME,
        Constants.PACKAGE_SORT_FIELD_DATE_ADDED
    ];
}
