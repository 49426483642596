import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Checkbox, Row, Col } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FieldBindingsStore } from '../stores';

interface Props {
    store: FieldBindingsStore;
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
}

const FieldBindingGroupExportDialog: React.FC<Props> = ({ store, isVisible, setIsVisible }) => {
    const [selectAll, setSelectAll] = React.useState(true);
    const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);
    const [defaultOptionsSelected, setDefaultOptionsSelected] = React.useState(false);

    const options = React.useMemo(
        () => store.bindingGroups.map(g => ({ label: g.name, value: g.id })),
        [store.bindingGroups]
    );

    const optionValues = React.useMemo(() => options.map(o => o.value), [options]);

    const onSelectAllChange = (e: CheckboxChangeEvent) => {
        setSelectedOptions(e.target.checked ? optionValues : []);
        setSelectAll(e.target.checked);
    };

    const onChange = (checkedOptions: string[]) => {
        setSelectedOptions(checkedOptions);
        setSelectAll(checkedOptions.length === options.length);
    };

    const onClose = () => {
        setIsVisible(false);
        setSelectAll(true);
        setDefaultOptionsSelected(false);
        setSelectedOptions([...optionValues]);
    };

    const onExport = () => {
        store.exportProjectFieldBindings(selectAll ? undefined : selectedOptions);
        onClose();
    };

    React.useEffect(() => {
        if (optionValues.length && !defaultOptionsSelected) {
            setSelectedOptions([...optionValues]);
            setDefaultOptionsSelected(true);
        }
    }, [optionValues, defaultOptionsSelected]);

    return (
        <Modal
            centered
            width={600}
            title="Export Field Bindings"
            destroyOnClose
            closable={false}
            maskClosable={false}
            visible={isVisible}
            className="alpha-portal-dialog"
            footer={[
                <Button
                    data-id="binding-export-cancel"
                    key="binding-export-cancel"
                    size="large"
                    className="light"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="binding-export"
                    key="binding-export"
                    size="large"
                    type="primary"
                    disabled={!selectedOptions.length}
                    onClick={onExport}
                >
                    Export
                </Button>
            ]}
        >
            <Row style={{ marginBottom: 12 }}>
                <Col>
                    <Checkbox onChange={onSelectAllChange} checked={selectAll}>
                        Select all
                    </Checkbox>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Checkbox.Group
                        className="vertical-checkbox-group"
                        options={options}
                        value={selectedOptions}
                        onChange={onChange}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default observer(FieldBindingGroupExportDialog);
