import appClient from '../../common/services/AppClient';
import { InstructWorkflowDto, InstructWorkflowTypeDto } from '../types';
import { Utils } from '../../common/services/Utils';
import security from '../../common/services/SecurityService';

export default class InstructWorkflowService {
    async getWorkflowTypes(projectId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/instruct-workflows/types`;

        const resp = await appClient.get<InstructWorkflowTypeDto[]>(url);

        return resp.unwrapOr([]);
    }

    async getWorkflows(projectId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/instruct-workflows`;

        const resp = await appClient.get<InstructWorkflowDto[]>(url);

        return resp.unwrapOr([]);
    }

    async createWorkflow(data: Omit<InstructWorkflowDto, 'id' | 'createDate' | 'updateDate'>) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${data.projectId}/instruct-workflows`;

        return appClient.post<InstructWorkflowDto>(url, data);
    }

    async updateWorkflow(data: Omit<InstructWorkflowDto, 'workflowTypeId' | 'createDate' | 'updateDate'>) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${data.projectId}/instruct-workflows`;

        return appClient.update<InstructWorkflowDto>(url, data);
    }

    async deleteWorkflow(projectId: string, id: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/instruct-workflows/${id}`;

        return appClient.delete(url);
    }

    async exportWorkflow(projectId: string, workflowId: string) {
        const mapForm = document.createElement('form');

        mapForm.setAttribute('id', 'exportInstructWorkflowForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';

        await security.invoke(token => {
            const t = '?access_token=' + encodeURIComponent(token);
            const url =
                process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/instruct-workflows/${workflowId}/export${t}`;
            Utils.downloadFile(url, mapForm, 'exportInstructWorkflowForm');
            return Promise.resolve();
        });
    }

    async commitImportedWorkflow(projectId: string, filePath: string, mlModels: Record<string, string> | null) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/instruct-workflows/commit`;

        return appClient.post<InstructWorkflowDto>(url, { filePath, mlModels });
    }
}
