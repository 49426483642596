import appClient from './AppClient';

export type DecriptionResponse = {
    value: string;
};

class EncryptionService {
    async decryptData(value: string) {
        const request = {
            value
        };

        const url = process.env.REACT_APP_MANAGE_URL + 'security/actions/decrypt';
        return appClient.post<DecriptionResponse>(url, request);
    }
}

export default EncryptionService;
