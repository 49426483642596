/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action, computed, reaction } from 'mobx';
import ErrorStore from '../../common/stores/ErrorStore';
import _ from 'lodash';
import { ProjectAssignment } from '../models/ProjectAssignment';
import { ProjectResult } from '../../../modules/common/types/types';
import { ProjectsService } from '../../../modules/common/services';
import { GlobalAdministrationService, Group } from '../service/GlobalAdministrationService';
import { message } from 'antd';
import { NodeDragEventParams } from 'rc-tree/lib/contextTypes';
import { EventDataNode } from 'antd/lib/tree';
import { UserModel } from '../types/UserModel';

type ProjectsDict = {
    [key: string]: string[];
};

type DefaultProjectDict = {
    [key: string]: string | null;
};

export class ProjectAssignmentVisualStore {
    @observable
    isLoading: boolean = false;

    @observable
    users: UserModel[] = [];

    @observable
    groupUsersDict: ProjectsDict = {};

    groups: Group[];

    projects: ProjectResult[];

    @observable
    userProjectsDict: ProjectsDict = {};

    @observable
    groupsProjectsDict: ProjectsDict = {};

    @observable
    groupsDefaultProjectDict: DefaultProjectDict = {};

    @observable
    usersDefaultProjectDict: DefaultProjectDict = {};

    @observable
    currentSelectedNode: string;

    @observable
    currentDefaultProject: string | null;

    @observable
    isAddGroupDialogVisible: boolean = false;

    @observable
    usernameFilter: string = '';

    @observable
    treeExpandedKeys: string[] = [];

    projectAssignments: ProjectAssignment[] | null;

    groupToRename: string | null = null;

    optionsValue: string = 'All';

    constructor(
        private errorStore: ErrorStore,
        private service: GlobalAdministrationService,
        private projectService: ProjectsService
    ) {
        this.saveProjectAssignments = this.saveProjectAssignments.bind(this);
        this.setupUsernameFilterReaction();
    }

    setupUsernameFilterReaction() {
        reaction(
            () => this.applyUsernameFilter,
            () => {
                this.currentSelectedNode = '';

                if (this.applyUsernameFilter) {
                    const treeExpandedKeys = Object.keys(this.filteredGroupUsersDict);

                    if (this.ungroupedUsernames.length) {
                        treeExpandedKeys.push('ungGrouped');
                    }

                    this.setTreeExpandedKeys(treeExpandedKeys);
                } else {
                    this.setTreeExpandedKeys([]);
                }
            }
        );
    }

    @action.bound
    setIsAddGroupDialogVisible(isVisible: boolean) {
        this.isAddGroupDialogVisible = isVisible;
    }

    @action.bound
    setUsernameFilter(usernameFilter: string) {
        this.usernameFilter = usernameFilter;
    }

    @action.bound
    setTreeExpandedKeys(treeExpandedKeys: string[]) {
        this.treeExpandedKeys = treeExpandedKeys;
    }

    @action
    async createUpdateGroup(name: string) {
        this.isLoading = true;
        const resp = await this.service.createUpdateGroup(name, this.groupToRename);
        if (resp.status === 200) {
            const isActionSuccessfull = resp.data;
            if (!isActionSuccessfull) {
                message.error('There were problems creating a group user, please report to administrator');
                return;
            }
            await this.getGroups();
            if (this.groupToRename) {
                message.success('Group has been updated');
            } else {
                message.success('New group has been created');
            }
        }
        this.groupToRename = null;
        this.setIsAddGroupDialogVisible(false);
        this.isLoading = false;
    }

    async handleRenameGroupClick(id: string) {
        this.groupToRename = id;
        this.setIsAddGroupDialogVisible(true);
    }

    async deleteGroup(id: string) {
        const resp = await this.service.deleteGroup(id);
        const isActionSuccessfull = resp;
        if (!isActionSuccessfull) {
            message.error('There were problems deleting a group, please report to administrator');
            return;
        }

        delete this.groupUsersDict[id];
        await this.getGroups();
        message.success('Group has been successfully deleted');
    }

    @action.bound
    async handleNodeDrop(data: NodeDragEventParams & { dragNode: EventDataNode }) {
        const draggedUser = this.users.find(x => x.id === data.dragNode.key);
        const dropGroup = this.groups.find(x => x.usersGroup.id === data.node.key);
        if ((dropGroup || data.node.key === 'ungGrouped') && draggedUser) {
            let resp = null;
            const groupId = Object.keys(this.groupUsersDict).find(key =>
                this.groupUsersDict[key].find(u => u === draggedUser.id)
            );
            if (groupId === (dropGroup && dropGroup!.usersGroup.id)) {
                return Promise.resolve();
            }
            resp = await this.service.changeUserGroup(draggedUser.id!, groupId, dropGroup && dropGroup.usersGroup);
            if (resp.status === 200) {
                const isActionSuccessfull = resp.data;
                if (!isActionSuccessfull) {
                    message.error('There were problems updating user group, please report to administrator');
                    return;
                }

                await this.getGroups();
            }
        }
    }

    @computed
    get checkedDefaultProject() {
        return this.usersDefaultProjectDict[this.currentSelectedNode] || this.defaultProjectsFromCurrentGroup;
    }

    get defaultProjectsFromCurrentGroup() {
        const groupId = Object.keys(this.groupUsersDict).find(key =>
            this.groupUsersDict[key].find(u => u === this.currentSelectedNode)
        );
        return (
            (groupId && this.groupsDefaultProjectDict[groupId]) ||
            this.groupsDefaultProjectDict[this.currentSelectedNode]
        );
    }

    @computed
    get selectedKeys() {
        return (
            (this.userProjectsDict[this.currentSelectedNode] && this.userGroupKeys) ||
            this.groupsProjectsDict[this.currentSelectedNode]
        );
    }

    get userGroupKeys() {
        const groupIds = Object.keys(this.groupUsersDict).filter(key =>
            this.groupUsersDict[key].find(u => u === this.currentSelectedNode)
        );
        return ((groupIds.length && _.flatten(groupIds.map(g => this.groupsProjectsDict[g]))) || []).concat(
            this.userProjectsDict[this.currentSelectedNode]
        );
    }

    @computed
    get selectedNodeIsUser() {
        return !!this.currentSelectedNode && !this.groups.find(g => g.usersGroup.id === this.currentSelectedNode);
    }

    @computed
    get applyUsernameFilter() {
        return this.usernameFilter.trim().length >= 2;
    }

    @computed
    get filteredUsers() {
        if (!this.applyUsernameFilter) {
            return this.users;
        }

        return this.users.filter(u => u.userName.toLocaleLowerCase().includes(this.usernameFilter.toLocaleLowerCase()));
    }

    @computed
    get filteredUngroupedUsers() {
        return _.difference(
            this.filteredUsers.map(u => u.id),
            _.flatten(Object.values(this.filteredGroupUsersDict))
        );
    }

    @computed
    get ungroupedUsernames() {
        return this.filteredUngroupedUsers.map(x => this.getUserName(x)).sort();
    }

    @computed
    get filteredGroupUsersDict() {
        if (!this.applyUsernameFilter) {
            return this.groupUsersDict;
        }

        return _.pickBy(this.groupUsersDict, ids => ids.some(id => this.filteredUsers.some(u => u.id === id)));
    }

    @computed
    get selectedUser() {
        return this.users.find(u => u.id === this.currentSelectedNode);
    }

    @computed
    get selectedGroup() {
        return this.groups.find(g => g.usersGroup.id === this.currentSelectedNode);
    }

    @action.bound
    async handleNodeSelection(selectedKey: string | null) {
        if (!selectedKey) {
            return;
        }
        this.currentSelectedNode = selectedKey;
    }

    isProjectCheckboxDisabled(rowId: string) {
        if (this.users.map(x => x.id).includes(this.currentSelectedNode)) {
            const groupId = this.getGroupByUser(this.currentSelectedNode);
            if (groupId) {
                if (this.groupsProjectsDict[groupId].includes(rowId)) {
                    return true;
                }
            }
        }
        return false;
    }

    isProjectDefaultCheckDisabled(rowId: string) {
        if (this.users.map(x => x.id).includes(this.currentSelectedNode)) {
            const groupId = this.getGroupByUser(this.currentSelectedNode);
            if (groupId) {
                return this.groupsDefaultProjectDict[groupId] === rowId;
            }
        }
        return false;
    }

    getUserName(id: string) {
        return this.users.find(g => g.id === id)?.userName ?? id;
    }

    getUserIdByUsername(userName: string) {
        return this.users.find(g => g.userName === userName)?.id ?? userName;
    }

    @action.bound
    handleDefautProjectCheck(isChecked: boolean, projId: string) {
        const group = this.groups.find(g => g.usersGroup.id === this.currentSelectedNode);
        if (group) {
            if (isChecked) {
                this.currentDefaultProject = projId;
                this.groupsDefaultProjectDict[this.currentSelectedNode] = projId;
            } else {
                this.currentDefaultProject = null;
                this.groupsDefaultProjectDict[this.currentSelectedNode] = null;
            }
        } else {
            if (isChecked) {
                this.currentDefaultProject = projId;
                this.usersDefaultProjectDict[this.currentSelectedNode] = projId;
            } else {
                this.currentDefaultProject = null;
                this.usersDefaultProjectDict[this.currentSelectedNode] = null;
            }
        }
        this.saveProjectAssignments();
    }

    @action
    setSelectedKey(selectedProject: string, isSelected: boolean) {
        const group = this.groups.find(g => g.usersGroup.id === this.currentSelectedNode);
        if (group) {
            if (isSelected) {
                this.groupsProjectsDict[this.currentSelectedNode] = [
                    ...this.groupsProjectsDict[this.currentSelectedNode],
                    selectedProject
                ];
            } else {
                const index = this.groupsProjectsDict[this.currentSelectedNode].indexOf(selectedProject);
                const projects = this.groupsProjectsDict[this.currentSelectedNode].slice();
                projects.splice(index, 1);
                this.groupsProjectsDict[this.currentSelectedNode] = projects;
            }
        } else {
            if (isSelected) {
                this.userProjectsDict[this.currentSelectedNode].push(selectedProject);
            } else {
                const index = this.userProjectsDict[this.currentSelectedNode].indexOf(selectedProject);
                const projects = this.userProjectsDict[this.currentSelectedNode].slice();
                projects.splice(index, 1);
                this.userProjectsDict[this.currentSelectedNode] = projects;
            }
        }
    }

    @action
    setAllKeys(isChecked: boolean) {
        const group = this.groups.find(g => g.usersGroup.id === this.currentSelectedNode);
        if (group) {
            if (isChecked) {
                this.groupsProjectsDict[this.currentSelectedNode] = this.projects.map(p => p.id);
            } else {
                this.groupsProjectsDict[this.currentSelectedNode] = [];
            }
        } else {
            if (isChecked) {
                this.userProjectsDict[this.currentSelectedNode] = this.projects.map(p => p.id);
            } else {
                this.userProjectsDict[this.currentSelectedNode] = [];
            }
        }
    }

    @action
    async getUsersAndGroups() {
        this.isLoading = true;
        this.groupUsersDict = {};
        await this.getProjects();
        await this.getProjectAssignments();
        await this.getUsers().catch(err => {
            return Promise.reject(err);
        });
        await this.getGroups();
        this.isLoading = false;
    }

    async getProjectAssignments() {
        this.projectAssignments = await this.service.getProjectAssignments();
    }

    @action.bound
    async getUsers() {
        const users = (await this.service.getAppUsers(false)) as UserModel[] | { error: string };
        if ((users as { error: string }).error) {
            this.isLoading = false;
            return Promise.reject('No perimission for current resource');
        } else {
            this.users = users as UserModel[];
            for (const user of this.users) {
                this.userProjectsDict[user.id] = this.getProjectsById(user.id);
                this.usersDefaultProjectDict[user.id] = this.getDefaultProjectById(user.id);
            }
            return Promise.resolve();
        }
    }

    @action
    async getGroups() {
        try {
            this.groups = (await this.service.getGroups()) as Group[];
            this.groups.forEach(async g => {
                const users = g.users;
                const groupId = g.usersGroup.id;
                this.groupUsersDict[groupId] = users.sort((a, b) => (a.userName > b.userName ? 1 : -1)).map(u => u.id);
                const userGroupProjects = this.getProjectsById(groupId);
                this.groupsProjectsDict[groupId] = userGroupProjects;
                this.groupsDefaultProjectDict[groupId] = this.getDefaultProjectById(groupId);
            });
        } catch (err) {
            this.errorStore.addError(err);
        }
    }

    get unGroupedUsers() {
        return _.difference(this.users && this.users.map(u => u.id), _.flatten(Object.values(this.groupUsersDict)));
    }

    async getProjects() {
        const projects = await this.projectService.getProjects();
        this.projects = projects;
    }

    @action.bound
    async saveGroupProjectAssignments() {
        if (!this.selectedGroup) {
            return;
        }

        const groupId = this.selectedGroup.usersGroup.id;
        const projects = this.groupsProjectsDict[groupId];
        const defaultProject = this.groupsDefaultProjectDict[groupId];
        const usersWithinGroup = this.groupUsersDict[groupId];
        const users = usersWithinGroup.filter(this.filterValidUsers).map(u => {
            return {
                userId: u,
                userName: this.users.find(user => user.id === u)!.userName
            };
        });

        await this.service.createUpdateGroupProjectAssignments({
            groupId,
            projects,
            users,
            defaultProject
        });
    }

    @action.bound
    async saveUserProjectAssignments() {
        if (!this.selectedUser) {
            return;
        }

        const userId = this.selectedUser.id;
        const userName = this.selectedUser.userName;
        const projects = this.userProjectsDict[userId];
        const defaultProject = this.usersDefaultProjectDict[userId];

        await this.service.createUpdateUserProjectAssignments({
            userId,
            userName,
            projects,
            defaultProject
        });
    }

    @action
    async saveProjectAssignments() {
        if (this.selectedUser) {
            await this.saveUserProjectAssignments();
        } else {
            await this.saveGroupProjectAssignments();
        }

        await this.getProjectAssignments();
    }

    @action
    getObjectRoleAssignments(userId: string, objectId: string) {
        const user = this.users.find(u => u.id === userId);

        if (!user || !user.objectRoleAssignments) {
            return null;
        }

        return user.objectRoleAssignments[objectId];
    }

    @action.bound
    private filterValidUsers(userId: string) {
        if (!this.users) {
            return false;
        }

        if (this.users.find(user => user.id === userId)) {
            return true;
        }

        console.warn(`User '${userId}' not found in users list`);
        return false;
    }

    private getProjectsById(id: string) {
        if (this.projectAssignments) {
            const item = this.projectAssignments.find(p => p.userId === id);
            return (item && item.projects) || [];
        } else {
            return [];
        }
    }

    private getDefaultProjectById(id: string) {
        if (this.projectAssignments) {
            const item = this.projectAssignments.find(p => p.userId === id);
            return (item && item.defaultProject) || null;
        } else {
            return null;
        }
    }

    private getGroupByUser(userId: string) {
        return Object.keys(this.groupUsersDict).find(key => this.groupUsersDict[key].find(u => u === userId));
    }
}

export default ProjectAssignmentVisualStore;
