import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip, Input } from 'antd';
import Form from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { TagsGroupModel } from '../models/TagsGroupModel';
import { RulesListVisualStore } from '../stores';
import TagsGroupVisualStore from '../stores/TagsGroupVisualStore';

type Props = {
    store: TagsGroupVisualStore | undefined;
    rulesListStore: RulesListVisualStore | undefined;
};

export const NewGroupForm: React.FC<Props> = ({ store, rulesListStore }) => {
    const [form] = Form.useForm();
    const editableGroup = store!.editableTagsGroup;

    React.useEffect(() => {
        form.setFieldsValue({
            name: editableGroup && editableGroup.name,
            description: editableGroup && editableGroup.description
        });
    }, [editableGroup, form]);

    const handleSubmit = () => {
        form.validateFields()
            .then((values: TagsGroupModel) => {
                if (editableGroup) {
                    values.id = editableGroup.id;
                    store!
                        .update(values)
                        .then(() => {
                            const row = document.querySelector(`[data-row-key="${values.id}"]`);

                            if (row) {
                                const highlightedRows = document.getElementsByClassName('highlight-selected');
                                for (let i = 0; i < highlightedRows.length; i++) {
                                    highlightedRows[i].classList.remove('highlight-selected');
                                }

                                rulesListStore?.clearRuleHighlight();

                                row.scrollIntoView({ behavior: 'auto', block: 'start' });
                                row.classList.add('highlight-selected');
                            }
                        })
                        .catch(err => {
                            console.error(err);
                        });
                } else {
                    store!.createGroup(values.name, values.description);
                }
                store!.setAddGroupDialogVisible(false);
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <Form form={form} onFinish={handleSubmit} layout="vertical" id="new-rule-group-form" data-id="rule-group-form">
            <FormItem
                label={
                    <span>
                        Group name&nbsp;
                        <span style={{ margin: '0 6px 0 2px', color: 'red' }}>*</span>
                        <Tooltip title="Tags group name for alpha">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </span>
                }
                requiredMark="optional"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Please input group group name!',
                        whitespace: true
                    }
                ]}
            >
                <Input />
            </FormItem>
            <FormItem
                label={
                    <span>
                        Description&nbsp;
                        <span style={{ margin: '0 6px 0 2px', color: 'red' }}>*</span>
                        <Tooltip title="Description for tags group">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </span>
                }
                requiredMark="optional"
                name="description"
                rules={[{ required: true, message: 'Please input tag group description!', whitespace: true }]}
            >
                <TextArea rows={4} />
            </FormItem>
        </Form>
    );
};

export default observer(NewGroupForm);
