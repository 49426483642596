import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Form } from 'antd';
import WrappedApplicationSettingsForm from '../../iota_applications/components/ApplicationSettingsForm';
import { IotaAppStores } from '../stores';
import { STORE_PROJECT_APPLICATION_DEFINITIONS } from '../stores/constants';

type Props = IotaAppStores;

export const ProjectEditApplicationSettingsPage: React.FC<Props> = ({ projectApplicationDefinitionsUI: store }) => {
    const [settingsForm] = Form.useForm();
    return (
        <div style={{ padding: '24px' }}>
            <Row>
                <Col span={12}>
                    {store!.currentAppDef ? (
                        <WrappedApplicationSettingsForm
                            settingsForm={settingsForm}
                            settingsJson={store!.currentAppDef!.settings!}
                            settingsValues={store!.currentAppDef!.settingValues!}
                            back={() => store!.goToApplicationsList()}
                            id={store!.currentAppDef!.id}
                            update={(id, settings) => store!.updateApplicationSettings(id, settings)}
                            // eslint-disable-next-line
                        />
                    ) : (
                        <div />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default inject(STORE_PROJECT_APPLICATION_DEFINITIONS)(observer(ProjectEditApplicationSettingsPage));
