export default class CodeSnippetGroup {
    constructor(
        public id: string | undefined,
        public name: string,
        public description?: string,
        public parent?: string
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.parent = parent;
    }
}
