import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Table, Divider } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { WorkflowTypeMessagesTemplateDto, CreateEditWorkflowFormValues } from '../../types';
import InstructWorkflowModel from '../../model/InstructWorkflowModel';
import WorkflowMessageTemplateCell from './WorkflowMessageTemplateCell';

interface Props {
    form: FormInstance<CreateEditWorkflowFormValues>;
    workflow: InstructWorkflowModel;
}

const WorkflowMessagesTemplates: React.FC<Props> = ({ form, workflow }) => {
    if (!workflow.workflowType || !workflow.workflowType.messagesTemplates.length) {
        return null;
    }

    const defaultColumns = [
        {
            title: 'Type',
            dataIndex: 'type',
            width: 150
        },
        {
            title: 'Message',
            dataIndex: 'message',
            editable: true,
            width: 400
        }
    ];

    const columns = defaultColumns.map(column => {
        if (!column.editable) {
            return column;
        }

        return {
            ...column,
            onCell: (messagesTemplate: WorkflowTypeMessagesTemplateDto) => ({
                messagesTemplate,
                editable: column.editable,
                dataIndex: column.dataIndex,
                title: column.title,
                workflow,
                form
            })
        };
    });

    const components = {
        body: {
            cell: WorkflowMessageTemplateCell
        }
    };

    return (
        <div>
            <Divider orientation="left">Messages Templates</Divider>
            <Table
                className="workflow-messages-templates-table"
                rowClassName={() => 'editable-row'}
                rowKey={(messagesTemplate: WorkflowTypeMessagesTemplateDto) => messagesTemplate.type}
                dataSource={workflow.workflowType.messagesTemplates}
                components={components}
                columns={columns}
                pagination={false}
                bordered
            />
        </div>
    );
};

export default observer(WorkflowMessagesTemplates);
