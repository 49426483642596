import { PackageResult, ProjectResult, SessionResult, TestProjectResult } from '../types';
import { Observer, Observable, Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';

export default class PushClient {
    static RECONNECT_INTERVAL: number = 5000;
    static ConnectionAttempted: boolean = false;
    static ConnectionStatusSubject: Subject<boolean> = new Subject<boolean>();
    static Connection = new signalR.HubConnectionBuilder().withUrl(process.env.REACT_APP_PUSH_URL!).build();

    constructor() {
        PushClient.Connection.onclose(() => {
            PushClient.connect();
        });
    }

    static connect() {
        PushClient.ConnectionAttempted = true;

        PushClient.Connection.start()
            .then(() => {
                PushClient.ConnectionStatusSubject.next(true);
            })
            .catch(() => {
                PushClient.ConnectionStatusSubject.next(false);
                console.info("Wasn't able to connect to SignalR service");
                setTimeout(() => {
                    this.connect();
                }, PushClient.RECONNECT_INTERVAL);
            });
    }

    createPackageListener(): Observable<PackageResult> {
        return Observable.create((obs: Observer<PackageResult>) => {
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            PushClient.Connection.on('PackageMessage', (data: string) => {
                console.info('Received data from push service on packages...');
                obs.next(JSON.parse(data));
            });
        });
    }

    createProjectListener(): Observable<ProjectResult> {
        return Observable.create((obs: Observer<ProjectResult>) => {
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            PushClient.Connection.on('ProjectMessage', (data: string) => {
                console.info('Received data from push service on projects...');
                obs.next(JSON.parse(data));
            });
        });
    }

    // TODO: Split code into modules.
    createSessionListener(): Observable<SessionResult> {
        return Observable.create((obs: Observer<SessionResult>) => {
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            PushClient.Connection.on('SessionMessage', (data: string) => {
                console.info('Received data from push service on sessions...');
                obs.next(JSON.parse(data));
            });
        });
    }

    createTestProjectsListener(): Observable<TestProjectResult> {
        return Observable.create((obs: Observer<TestProjectResult>) => {
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            PushClient.Connection.on('TestProjectMessage', (data: string) => {
                console.info('Received data from push service on test projects...');
                obs.next(JSON.parse(data));
            });
        });
    }
}

if (process.env.NODE_ENV !== 'test') {
    if (!PushClient.ConnectionAttempted) {
        PushClient.connect();
    }
}
