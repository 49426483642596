export default class ApplicationDefinitionsGroupModel {
    constructor(
        public id: string,
        public name: string,
        public projectId: string,
        public applicationDefinitionIds: string[] = [],
        public subGroups: ApplicationDefinitionsGroupModel[] = [],
        public rootGroupId?: string
    ) {}
}
