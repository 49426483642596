import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { TestProjectStores } from '../stores';
import { STORE_TEST_PROJECT_DASHBOARD } from '../stores/constants';
import TestProjectRunResultList from '../components/TestProjectRunResultsList';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';

const TestProjectRunResultPage: React.FC<TestProjectStores> = ({ projectTestProjectsDashboardUI }) => {
    if (!projectTestProjectsDashboardUI) {
        return null;
    }

    return (
        <HasPermission permissionClaim={AppPermissions.CanManageTestProjects}>
            <TestProjectRunResultList store={projectTestProjectsDashboardUI} />
        </HasPermission>
    );
};

export default inject(STORE_TEST_PROJECT_DASHBOARD)(observer(TestProjectRunResultPage));
