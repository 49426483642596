import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RootStores } from '../modules/common/stores';
import { STORE_PROJECTS, STORE_ROUTER } from '../modules/common/constants';
import { AuthConsumer } from '../modules/authorization/AuthContext';
import SideMenuHeaderControls from './SideMenuHeaderControls';
import { Divider, Menu, Tooltip } from 'antd';
import { ProjectPagesNavigation } from '../modules/project_management/routes';
import { hasPermission } from '../modules/authorization/components/HasPermission';
import { AppPermissions } from '../modules/authorization/Permissions';
import { BarChartOutlined } from '@ant-design/icons';
import { AlphaVersionLabel } from '../modules/common/components';

type Props = RootStores;

type State = {
    collapsed: boolean;
    selectedItemKey: string;
    selectedKeys: string[];
};

@inject(STORE_ROUTER, STORE_PROJECTS)
@observer
export default class AdministrationSideMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            collapsed: this.props.router!.sideCollapsed,
            selectedItemKey: '',
            selectedKeys: ['']
        };
    }

    componentDidMount = () => {
        const location = window.location.href;
        if (location.includes('indexing')) {
            this.setState({ selectedKeys: ['indexing'] });
        } else if (location.includes('alpha-configs')) {
            this.setState({ selectedKeys: ['alphaConfig'] });
        } else if (location.includes('codeSnippets')) {
            this.setState({ selectedKeys: ['codeSnippets'] });
        } else {
            this.setState({ selectedKeys: ['technicalInformation'] });
        }
    };

    render() {
        const routerStore = this.props.router!;
        const projectsStore = this.props.projects!;

        const onSelectMenuItem = (key: string, type: string) => {
            this.setState(s => ({ ...s, selectedKeys: [key], selectedItemKey: key }));
            if (type != null) {
                switch (type) {
                    case 'indexing':
                        routerStore.pushToHistory(ProjectPagesNavigation.IndexingPage);
                        return;
                    case 'codeSnippets':
                        routerStore.pushToHistory(ProjectPagesNavigation.CodeSnippetsPage);
                        return;
                    case 'alphaConfig':
                        routerStore.pushToHistory(ProjectPagesNavigation.AlphaConfigPage);
                        return;
                    case 'technicalInformation':
                        routerStore.pushToHistory(ProjectPagesNavigation.TechnicalInformationPage);
                        return;
                    default:
                        return;
                }
            }
        };

        // eslint-disable-next-line max-len
        const menuItem = (
            type: string,
            icon: React.ReactElement,
            title: string,
            enabled: boolean,
            style: object | undefined = undefined,
            appRoles?: string[],
            permissionClaim?: AppPermissions
        ) => {
            if (!appRoles || hasPermission(appRoles, permissionClaim!)) {
                return (
                    <Menu.Item
                        className={this.state.selectedItemKey === type ? 'selected' : ''}
                        key={type}
                        disabled={!enabled}
                        onClick={() => onSelectMenuItem(type, type)}
                    >
                        <span style={style}>
                            <Tooltip title={title} placement="right">
                                {icon}
                            </Tooltip>
                            <span>{title}</span>
                        </span>
                    </Menu.Item>
                );
            } else {
                return <></>;
            }
        };

        return (
            <>
                <AuthConsumer>
                    {() => (
                        <>
                            <SideMenuHeaderControls routerStore={routerStore} projectsStore={projectsStore} />
                            <Divider style={{ marginTop: 0 }} />
                            <Menu
                                mode="inline"
                                defaultOpenKeys={['sub1']}
                                selectedKeys={this.state.selectedKeys}
                                style={{ overflow: 'hidden auto', borderRight: 0, maxHeight: 'calc(100vh - 195px)' }}
                                inlineIndent={18}
                            >
                                {menuItem('indexing', <i className="alpha-icon md list-icon" />, 'Indexing', true)}
                                {menuItem(
                                    'codeSnippets',
                                    <i className="alpha-icon md code-icon" />,
                                    'Code snippets',
                                    true
                                )}
                                {menuItem(
                                    'alphaConfig',
                                    <i className="alpha-icon md settings" />,
                                    'Alpha configs',
                                    true
                                )}
                                {menuItem(
                                    'technicalInformation',
                                    <BarChartOutlined style={{ marginLeft: 3 }} />,
                                    'Technical information',
                                    true
                                )}
                            </Menu>
                            <div className="projects-side-menu-footer">
                                <AlphaVersionLabel />
                            </div>
                        </>
                    )}
                </AuthConsumer>
            </>
        );
    }
}
