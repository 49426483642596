import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DiffOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
    UploadOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import {
    Button,
    Collapse,
    Input,
    InputNumber,
    Layout,
    message,
    Skeleton,
    Spin,
    Table,
    Tooltip,
    Upload,
    Popconfirm,
    Tag
} from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import LayoutHeader from '../../../components/LayoutHeader';
import { TestProjectBaselinesUpdateDialog } from '.';
import { TestProjectDashboardStore, TestProjectWizardStore } from '../stores';
import { TestProjectTopic } from '../types';
import _ from 'lodash';
import { Constants, PackageListModel } from '../misc';
import TestProjectSortingActions from './TestProjectSortingActions/TestProjectSortingActions';

type Props = {
    store: TestProjectDashboardStore;
    wizardStore: TestProjectWizardStore;
};

const TestProjectBaselinesList: React.FC<Props> = ({ store, wizardStore }) => {
    const [openTopics, setOpenTopics] = React.useState<string[]>([]);
    const [action, setAction] = React.useState('');
    const [isUploading, setIsUploading] = React.useState(false);
    const [unsavedBaselines, setUnsavedBaselines] = React.useState<string[]>([]);
    const [savingBaselines, setSavingBaselines] = React.useState<string[]>([]);
    const [editedBaselines, setEditedBaselines] = React.useState<string[]>([]);
    const [baselineResults, setBaselineResults] = React.useState<{ [key: string]: boolean }>({});
    const [packageSortDirection, setPackageSortDirection] = React.useState<'ascend' | 'descend'>('ascend');
    const [packageSortField, setPackageSortField] = React.useState<string>(Constants.PACKAGE_SORT_FIELD_NAME);
    const debouncedResultsReset = React.useRef(_.debounce(() => cleanUpResults(), 3000));

    React.useEffect(() => {
        store.initAccessToken();
        if (store.accessToken && store.currentProject && store.testProject) {
            const uploadUrl = `${process.env.REACT_APP_MANAGE_URL}projects/${store.currentProject.id}/test-projects/${store.testProject.id}/baselines/import`;
            let t = '?access_token=' + encodeURIComponent(store.accessToken);
            setAction(uploadUrl + t);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject, store.testProject]);

    React.useEffect(() => {
        if (debouncedResultsReset.current) {
            debouncedResultsReset.current();
        }
    }, [baselineResults]);

    React.useEffect(() => {
        const readyToLoad = store.currentProject && store.currentTestProjectId;
        const shouldLoadTestProject = !store.testProject || store.testProject.id !== store.currentTestProjectId;
        const shouldLoadPackages =
            !store.packages ||
            store.packages.length === 0 ||
            (store.currentProject && store.packages.some(p => p.project.id !== store.currentProject?.id));

        if (readyToLoad && shouldLoadTestProject) {
            store.loadTestProject();
        }

        if (readyToLoad && shouldLoadPackages) {
            store.loadAllProjectPackages();
        }

        if (readyToLoad) {
            store.loadTestProjectBaselines();
        }

        if (!store.users || store.users.length === 0) {
            store.loadUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject, store.currentTestProjectId]);

    const packageListItems = React.useMemo(() => {
        let items = store.packageListItems.slice();

        if (packageSortField === Constants.PACKAGE_SORT_FIELD_NAME) {
            items = items.sort((a, b) => a.name.localeCompare(b.name));
        }

        // Don't sort in case sort by date is needed, because package ids are stored in the same order as they were loaded

        if (packageSortDirection === 'descend') {
            items = items.reverse();
        }

        return items;
    }, [store.packageListItems, packageSortField, packageSortDirection]);

    const cleanUpResults = () => {
        let newResults = { ...baselineResults };
        for (const key in newResults) {
            if (newResults[key] === true) {
                delete newResults[key];
            }
        }
        setBaselineResults(newResults);
    };

    const onFileChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        setIsUploading(status === 'uploading');
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            store.loadTestProjectBaselines();
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed. Error: ${info.file.response.title}`, 5);
        }
    };

    const loadingContent = <Skeleton active paragraph={{ rows: 4 }} title={{ style: { display: 'none' } }} />;

    const getBaselineResultContent = (topicId: string, packageId: string) => {
        const baseline = store.testProjectBaselines.find(b => b.packageId === packageId && b.topicId === topicId);

        return (
            <Input.TextArea
                id={`${packageId}-${topicId}-baseline-result`}
                autoSize={{ minRows: 1 }}
                defaultValue={baseline?.value}
                onBlur={() => handleBlur(packageId, topicId)}
                onChange={() => setEditedBaselines([...editedBaselines, `${packageId}-${topicId}`])}
            />
        );
    };

    const handleRowSave = async (topicId: string, packageId: string, result: string, fuzzy: number) => {
        setSavingBaselines([...savingBaselines, `${packageId}-${topicId}`]);
        const baseline = store.testProjectBaselines.find(b => b.packageId === packageId && b.topicId === topicId);
        let saveResult: boolean | undefined;
        if (baseline) {
            saveResult = await store.updateBaselineById(baseline.id, result, fuzzy ?? 0);
        } else {
            saveResult = await store.createBaselineForTopic(topicId, packageId, result, fuzzy);
        }
        handleRowSaveResponse(topicId, packageId, saveResult);
        setSavingBaselines(savingBaselines.filter(b => b !== `${packageId}-${topicId}`));
        setUnsavedBaselines(unsavedBaselines.filter(b => b !== `${packageId}-${topicId}`));
        setEditedBaselines(editedBaselines.filter(b => b !== `${packageId}-${topicId}`));
    };

    const handleRowSaveResponse = (topicId: string, packageId: string, isSuccessful: boolean | undefined) => {
        if (isSuccessful == null) {
            return;
        }

        store.removePackageFromNewPackages(packageId);
        setBaselineResults({ ...baselineResults, [`${packageId}-${topicId}`]: isSuccessful });
    };

    const getBaselineFuzzyContent = (topicId: string, packageId: string) => {
        const baseline = store.testProjectBaselines.find(b => b.packageId === packageId && b.topicId === topicId);

        let extra: React.ReactNode = null;

        if (savingBaselines.includes(`${packageId}-${topicId}`)) {
            extra = <Spin indicator={<LoadingOutlined spin style={{ fontSize: 14 }} />} />;
        } else if (unsavedBaselines.includes(`${packageId}-${topicId}`)) {
            extra = (
                <Tooltip title="Not saved due to missing data. Please, fill in blank fields." placement="topLeft">
                    <ExclamationCircleOutlined style={{ color: '#FFCC00' }} />
                </Tooltip>
            );
        } else if (baselineResults[`${packageId}-${topicId}`] === true) {
            extra = (
                <Tooltip title="Saved successfully" placement="topLeft">
                    <CheckCircleOutlined style={{ color: '#00CC00' }} />
                </Tooltip>
            );
        } else if (baselineResults[`${packageId}-${topicId}`] === false) {
            extra = (
                <Tooltip title="Save failed. Please, try again." placement="topLeft">
                    <CloseCircleOutlined style={{ color: '#FF0000' }} />
                </Tooltip>
            );
        }

        return (
            <div className="fuzzy-container">
                <span className="fuzzy-percent-label">
                    <InputNumber
                        id={`${packageId}-${topicId}-baseline-fuzzy`}
                        min={0}
                        max={100}
                        defaultValue={baseline?.fuzzy ?? store.testProject?.defaultBaselineFuzzy}
                        onBlur={() => handleBlur(packageId, topicId)}
                        onChange={() => setEditedBaselines([...editedBaselines, `${packageId}-${topicId}`])}
                    />
                </span>
                {extra}
            </div>
        );
    };

    const handleBlur = (packageId: string, topicId: string) => {
        const result = document.getElementById(`${packageId}-${topicId}-baseline-result`) as HTMLTextAreaElement;
        const fuzzy = document.getElementById(`${packageId}-${topicId}-baseline-fuzzy`) as HTMLInputElement;

        if (!result || !fuzzy || !editedBaselines.includes(`${packageId}-${topicId}`)) {
            return;
        }

        if (fuzzy.value === '') {
            setUnsavedBaselines([...unsavedBaselines, `${packageId}-${topicId}`]);
            return;
        }

        let validFuzzy = Math.min(100, Math.max(0, Number.parseFloat(fuzzy.value)));
        validFuzzy = Number.isNaN(validFuzzy) ? 100 : validFuzzy;
        handleRowSave(topicId, packageId, result.value, validFuzzy);
    };

    const baselineColumns = (packageId: string) => {
        return [
            {
                title: 'Topic',
                dataIndex: 'topic',
                render: (__: unknown, record: TestProjectTopic) => record.name
            },
            {
                title: 'Result',
                dataIndex: 'result',
                render: (__: unknown, record: TestProjectTopic) => getBaselineResultContent(record.topicId, packageId)
            },
            {
                title: 'Fuzzy',
                dataIndex: 'fuzzy',
                width: 150,
                render: (__: unknown, record: TestProjectTopic) => getBaselineFuzzyContent(record.topicId, packageId)
            }
        ];
    };

    const updateBaselinesForPackage = (packageId: string) => {
        if (!store.testProject) {
            return;
        }

        wizardStore.setSourcePackageForBaselineSwitch(packageId);
        wizardStore.setNewPackagesForBaselineSwitch([packageId]);
        wizardStore.setUpTestProject(store.testProject.id);
        wizardStore.setBaselinesEditDialogVisible(true);
    };

    const baselinesContent = () => {
        if (!store.testProject) {
            return null;
        }

        const extra = (packageId: string) => {
            const packageName = store.getPackageById(packageId)?.name ?? packageId;

            return (
                <>
                    <Tooltip title="Assign documents to this baseline" placement="topLeft">
                        <Button
                            data-id={`${packageId}-test-project-baselines-edit-button`}
                            type="link"
                            size="small"
                            onClick={e => {
                                e.stopPropagation();
                                updateBaselinesForPackage(packageId);
                            }}
                            icon={<DiffOutlined />}
                        />
                    </Tooltip>
                    <span onClick={e => e.stopPropagation()}>
                        <Popconfirm
                            placement="left"
                            title={`Are you sure you want to remove document "${packageName}" from the test project?`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                                store.deletePackageFromTestProject(packageId, packageName);
                            }}
                        >
                            <Tooltip title="Remove document from the test project" placement="topLeft">
                                <Button
                                    className="baseline-delete-document-button"
                                    data-id={`${packageId}-test-project-baselines-delete-document-button`}
                                    type="link"
                                    size="small"
                                    loading={packageId === store.deletingPackageId}
                                    onClick={e => e.stopPropagation()}
                                    icon={<DeleteOutlined style={{ color: '#ff4d4f' }} />}
                                />
                            </Tooltip>
                        </Popconfirm>
                    </span>
                </>
            );
        };

        const panelHeader = (pkg: PackageListModel) => {
            if (store.testProject?.newPackageIds?.includes(pkg.packageId)) {
                return (
                    <span style={{ fontWeight: 500 }}>
                        {pkg.name}
                        <Tag color="#52c41a" style={{ marginLeft: 8, marginRight: 0 }}>
                            New
                        </Tag>
                    </span>
                );
            }

            return <span style={{ fontWeight: 500 }}>{pkg.name}</span>;
        };

        return (
            <Collapse
                className="table-content-collapse darker"
                activeKey={openTopics}
                onChange={key => setOpenTopics([...key])}
            >
                {packageListItems.map(item => (
                    <Collapse.Panel
                        className="table-collapse-panel"
                        key={item.packageId}
                        header={panelHeader(item)}
                        extra={extra(item.packageId)}
                    >
                        <Table
                            data-id={`${item.packageId}-test-project-baselines-table`}
                            className="alpha-portal-table"
                            dataSource={store.orderedTestProjectTopics}
                            columns={baselineColumns(item.packageId)}
                            pagination={false}
                            rowKey={record => `${item.packageId}-${record.topicId}`}
                            loading={store.isLoadingBaselines}
                        />
                    </Collapse.Panel>
                ))}
            </Collapse>
        );
    };

    return (
        <Layout className="screen-size test-project-dashboard" style={{ ...{ height: '100%', background: 'white' } }}>
            <LayoutHeader
                title={
                    store.testProject?.name ? (
                        `${store.testProject?.name} baselines`
                    ) : (
                        <Skeleton active paragraph={{ rows: 0 }} />
                    )
                }
                buttons={[
                    <Button
                        key="test-projects-baselines-go-to-list"
                        data-id="button-go-to-list"
                        className="light"
                        size="large"
                        onClick={store.goBackToList}
                    >
                        Go to list
                    </Button>,
                    <Button
                        key="test-projects-baselines-go-back"
                        data-id="button-go-to-dashboard"
                        className="light"
                        size="large"
                        onClick={store.goToDashboard}
                    >
                        Go to dashboard
                    </Button>,
                    <Button
                        key="test-projects-baselines-download"
                        data-id="button-go-download-baselines"
                        size="large"
                        onClick={store.exportTestProjectBaselines}
                        icon={<DownloadOutlined />}
                        type="primary"
                    >
                        Download baselines
                    </Button>,
                    <Upload
                        key="test-projects-baselines-upload"
                        disabled={isUploading}
                        name="file"
                        maxCount={1}
                        action={action}
                        onChange={onFileChange}
                        showUploadList={false}
                        progress={undefined}
                    >
                        <Button
                            loading={isUploading}
                            data-id="button-go-to-upload-baselines"
                            size="large"
                            icon={<UploadOutlined />}
                            type="primary"
                        >
                            Upload baselines
                        </Button>
                    </Upload>
                ]}
            />
            <Layout>
                <Layout.Content style={{ maxHeight: 'calc(100vh - 100px)', overflow: 'auto' }}>
                    <TestProjectSortingActions
                        fields={Constants.PACKAGE_SORT_FIELDS}
                        fieldName={packageSortField}
                        setFieldName={setPackageSortField}
                        setSortingDirection={setPackageSortDirection}
                        sortingDirection={packageSortDirection}
                        storageKey={Constants.PACKAGE_SORT_DIRECTION_STORAGE_KEY}
                    />
                    <TestProjectBaselinesUpdateDialog wizardStore={wizardStore} dashboardStore={store} />
                    {store.isLoadingBaselinesScreenData ? loadingContent : baselinesContent()}
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default observer(TestProjectBaselinesList);
