import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { MLModelList } from '../components/MLModelList';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { MLStorageStores } from '../stores';
import { STORE_PROJECT_ML_MODELS } from '../constants';
import MLModelForm from '../components/MLModelForm';
import MLModelRevisionEditor from '../components/MLModelRevisionEditor';

type Props = MLStorageStores;

export const MLStoragePage: React.FC<Props> = ({ projectMLModelsUI: store }) => {
    return (
        <FlexContentContainer
            content={
                <>
                    <MLModelForm />
                    <MLModelRevisionEditor />
                    <MLModelList projectMLModelsUI={store} />
                </>
            }
        />
    );
};

export default inject(STORE_PROJECT_ML_MODELS)(observer(MLStoragePage));
