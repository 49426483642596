/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action, computed, runInAction } from 'mobx';

// @ts-ignore
import uuid from 'uuid/v1';

export default class PipelineStep<TPipelineStepType> {
    @observable
    stepId: string | undefined;

    @observable
    name: string;

    @observable
    type: TPipelineStepType;

    @observable
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parameters: Map<string, any> = observable.map<string, any>();

    @observable
    checkBoxLabel: string = 'Enabled';

    @observable
    isDisabled: boolean = false;

    internalId: string;

    @computed
    get parameterKeys() {
        return Array.from(this.parameters.keys());
    }

    constructor(
        name: string,
        type: TPipelineStepType,
        parameters: {},
        id?: string,
        internalId?: string,
        isDisabled: boolean = false
    ) {
        this.stepId = id;
        this.name = name;
        this.type = type;
        this.internalId = internalId || uuid();
        this.isDisabled = isDisabled || false;
        Object.getOwnPropertyNames(parameters || {}).forEach(p => this.parameters.set(p, parameters[p]));
    }

    @action
    setType(type: TPipelineStepType) {
        this.type = type;
    }

    @computed
    get stepType() {
        return this.type;
    }

    @action
    setName(name: string) {
        this.name = name;
    }

    @action
    addParameter(name: string, value: string | object) {
        if (Array.isArray(value) && value.every(item => item.key && item.value)) {
            const valueObj = value.reduce<Record<string, string>>(
                (acc, item) => {
                    acc[item.key] = item.value;
                    return acc;
                },
                {} as Record<string, string>
            );

            this.parameters.set(name, valueObj);
        } else {
            this.parameters.set(name, value);
        }
    }

    getParameterValues(name: string) {
        return this.parameters.get(name);
    }

    @action
    deleteParameter(key: string) {
        this.parameters.delete(key);
    }

    @action
    deleteAllParameters() {
        let keys = this.parameterKeys;
        runInAction(() => keys.forEach(k => this.deleteParameter(k)));
    }

    @action
    disableStep(isChecked: boolean) {
        this.isDisabled = isChecked;
    }

    validate(): string[] {
        const errors: string[] = [];

        if (!this.name) {
            errors.push('Name is required');
        }

        if (!this.type) {
            errors.push('Type is required');
        }

        // Array.from(this.parameters.keys()).map(k => {
        //     const val = this.parameters.get(k);
        //     if (!val) {
        //         return `Parameter ${k} is empty!`;
        //     }

        //     return null;
        // }).filter(x => x).reduce((p, c) => { p.push(c!); return p; }, errors);

        return errors;
    }
}
