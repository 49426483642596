import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal } from 'antd';
import { RulesImportVisualStore } from '../stores';

interface Props {
    store: RulesImportVisualStore;
    importCallback: () => Promise<void>;
}

const RulesImportConfirmationDialog: React.FC<Props> = ({ store, importCallback }: Props) => {
    const onClose = () => {
        store.setImportConfirmationDialogVisible(false);
    };

    const onImport = () => {
        store.importRules(importCallback);
        onClose();
    };

    return (
        <Modal
            title="Import rules"
            className="alpha-portal-dialog"
            visible={store.importConfirmationDialogVisible}
            onCancel={onClose}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button
                    data-id="rules-import-dialog-cancel"
                    className="light"
                    key="back"
                    size="large"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="rules-import-dialog-proceed"
                    key="submit"
                    size="large"
                    type="primary"
                    onClick={onImport}
                >
                    Proceed
                </Button>
            ]}
        >
            You are about to overwrite all existing rules. Do you really want to proceed?
        </Modal>
    );
};

export default observer(RulesImportConfirmationDialog);
