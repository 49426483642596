import * as React from 'react';
import { observer } from 'mobx-react-lite';
import ApplicationSettingsStringInput from './ApplicationSettingsStringInput';
import ApplicationSettingsNumberInput from './ApplicationSettingsNumberInput';
import { Card, Form, FormInstance } from 'antd';
import { IotaMetadataInput, SettingsMetadataSection, SettingsMetadata } from '../types';
import ApplicationSettingsPasswordInput from './ApplicationSettingsPasswordInput';
import ApplicationSettingsObjectInput from './ApplicationSettingsObjectInput';
import ApplicationSettingsCheckboxInput from './ApplicationSettingsCheckboxInput';
interface Props {
    settingsForm: FormInstance;
    settingsJson: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    settingsValues: { [id: string]: any };
    id: string;
    back: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    update: (id: string, settings: { [id: string]: any }) => void;
    disabled?: boolean;
}

interface Hash {
    [details: string]: string & string[] & number & number[];
}

export const ApplicationSettingsForm: React.FC<Props> = ({
    id,
    settingsForm,
    settingsJson,
    settingsValues,
    update,
    disabled = false
}) => {
    // const tailFormItemLayout = {
    //     wrapperCol: {
    //         xs: {
    //             span: 24,
    //             offset: 0,
    //         },
    //     }
    // };

    const handleSubmit = async (values: Hash) => {
        if (settingsJson) {
            await update(id, values);
        }
    };

    const renderInput = (input: IotaMetadataInput) => {
        switch (input.type) {
            case 'string':
                return (
                    <ApplicationSettingsStringInput
                        disabled={disabled}
                        form={settingsForm}
                        input={input}
                        key={input.id}
                        initialValue={getInputValue(input)}
                    />
                );
            case 'number':
                return (
                    <ApplicationSettingsNumberInput
                        disabled={disabled}
                        form={settingsForm}
                        input={input}
                        key={input.id}
                        initialValue={getInputValue(input)}
                    />
                );
            case 'password':
                return (
                    <ApplicationSettingsPasswordInput
                        disabled={disabled}
                        form={settingsForm}
                        input={input}
                        key={input.id}
                        initialValue={getInputValue(input)}
                    />
                );
            case 'object':
                return (
                    <ApplicationSettingsObjectInput
                        disabled={disabled}
                        form={settingsForm}
                        input={input}
                        key={input.id}
                        initialValue={getInputValue(input)}
                    />
                );
            case 'checkbox':
                return (
                    <ApplicationSettingsCheckboxInput
                        disabled={disabled}
                        form={settingsForm}
                        input={input}
                        key={input.id}
                        initialValue={getInputValue(input)}
                    />
                );
            default:
                return;
        }
    };

    const getInputValue = (input: IotaMetadataInput) => {
        if (settingsValues) {
            if (settingsValues[input.id]) {
                return settingsValues[input.id];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    };

    const renderSection = (section: SettingsMetadataSection) => {
        return (
            <Card type="inner" title={section.name} key={section.name}>
                {section.inputs.map(i => renderInput(i))}
                {section.sections && section.sections.map(s => renderSection(s))}
            </Card>
        );
    };

    const renderAllSections = () => {
        if (settingsJson) {
            let settingsMetadata: SettingsMetadata = JSON.parse(settingsJson);
            return <>{settingsMetadata.sections && settingsMetadata.sections.map(s => renderSection(s))}</>;
        } else {
            return <div>No settings found</div>;
        }
    };

    return (
        <Form
            id="iota-app-settings-form"
            data-id="iota-edit-application-settings-form"
            form={settingsForm}
            layout="vertical"
            onFinish={handleSubmit}
            preserve={false}
        >
            {/* <Card title="Settings" bordered={false}> */}
            {renderAllSections()}
            {/* <FormItem {...tailFormItemLayout} style={{ textAlign: 'right', margin: 0, padding: '20px 0 0' }}>
                    <Button data-id="iota-edit-application-settings-submit" type="primary" htmlType="submit" style={{marginRight: 20}}>Submit</Button>
                    <Button data-id="iota-edit-application-settings-cancel" htmlType="button" onClick={goToApplicationsList}>Cancel</Button>
                </FormItem> */}
            {/* </Card> */}
        </Form>
    );
};

export default observer(ApplicationSettingsForm);
