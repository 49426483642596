import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';

const provider = new WebTracerProvider({
    resource: new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: 'web_portal'
    })
});

provider.addSpanProcessor(
    new BatchSpanProcessor(
        new OTLPTraceExporter({
            url: process.env.REACT_APP_OTLP_HTTP_URL + '/v1/traces',
            headers: {}
        })
    )
);

// Have to conditionally import, because even just importing causes UI tp freeze in some scenarios when running locally
if (process.env.NODE_ENV !== 'development') {
    import('@opentelemetry/context-zone').then(({ ZoneContextManager }) => {
        provider.register({
            contextManager: new ZoneContextManager()
        });
    });
}

registerInstrumentations({
    instrumentations: []
});
