import {
    RuleResult,
    RefDataRuleResult,
    ElasticSearchMatchRuleResult,
    ElasticSearchMatchPhraseRuleResult,
    ElasticSearchQueryRuleResult,
    ElasticSearchRawRuleResult,
    InferenceRuleResult,
    NamedEntitiesRecognitionRuleResult,
    SmartIndexRuleResult,
    ElasticSearchScriptQueryRuleResult,
    ElasticSearchFuzzyQueryRuleResult,
    ElasticSearchRegexpQueryRuleResult,
    ElasticSearchWildcardQueryRuleResult
} from '../types';

export function isRefData(rule: RuleResult): rule is RefDataRuleResult {
    return rule.ruleType === 'RefDataRule';
}

export function isElasticMatch(rule: RuleResult): rule is ElasticSearchMatchRuleResult {
    return rule.ruleType === 'ElasticSearchMatchRule';
}

export function isElasticMatchPhrase(rule: RuleResult): rule is ElasticSearchMatchPhraseRuleResult {
    return rule.ruleType === 'ElasticSearchMatchPhraseRule';
}

export function isElasticQuery(rule: RuleResult): rule is ElasticSearchQueryRuleResult {
    return rule.ruleType === 'ElasticSearchQueryRule';
}

export function isElasticRaw(rule: RuleResult): rule is ElasticSearchRawRuleResult {
    return rule.ruleType === 'ElasticSearchRawRule';
}

export function isInferenceRule(rule: RuleResult): rule is InferenceRuleResult {
    return rule.ruleType === 'InferenceRule';
}

export function isNamedEntitiesRecognitionRule(rule: RuleResult): rule is NamedEntitiesRecognitionRuleResult {
    return rule.ruleType === 'NamedEntitiesRecognitionRule';
}

export function isSmartIndexRule(rule: RuleResult): rule is SmartIndexRuleResult {
    return rule.ruleType === 'SmartIndexRule';
}

export function isElasticScriptQueryRule(rule: RuleResult): rule is ElasticSearchScriptQueryRuleResult {
    return rule.ruleType === 'ElasticSearchScriptQueryRule';
}

export function isElasticFuzzyQueryRule(rule: RuleResult): rule is ElasticSearchFuzzyQueryRuleResult {
    return rule.ruleType === 'ElasticSearchFuzzyQueryRule';
}

export function isElasticRegexpQueryRule(rule: RuleResult): rule is ElasticSearchRegexpQueryRuleResult {
    return rule.ruleType === 'ElasticSearchRegexpQueryRule';
}

export function isElasticWildcardQueryRule(rule: RuleResult): rule is ElasticSearchWildcardQueryRuleResult {
    return rule.ruleType === 'ElasticSearchWildcardQueryRule';
}
