import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { FlexContentContainer } from '../../common/components';
import { RulesStores } from '../stores';
import { STORE_RULES_COPIED_PIPELINE, STORE_RULES_NEW, STORE_RULE_EDIT } from '../constants';
import { RuleEditPage } from '../components';

type Props = RulesStores;

const RulesCopiedPipelinePage: React.SFC<Props> = ({ RuleCopiedPipelineUI: store, RuleEditUI: editStore }) => {
    store!.selectType('ElasticSearchMatchRule');

    function getContent() {
        return <RuleEditPage RuleEditUI={editStore} />;
    }

    return <FlexContentContainer content={getContent()} />;
};

export default inject(STORE_RULES_COPIED_PIPELINE, STORE_RULES_NEW, STORE_RULE_EDIT)(observer(RulesCopiedPipelinePage));
