import * as React from 'react';
import { inject, Observer } from 'mobx-react';
import { Row, Col, Button, Input, AutoComplete, DatePicker, Form } from 'antd';
import { IotaAuditVisualStore } from '../stores';
import { STORE_IOTA_AUDIT } from '../constants';
import { observer } from 'mobx-react-lite';

const FormItem = Form.Item;
// const Option = Select.Option;
const { RangePicker } = DatePicker;

type Props = {
    store: IotaAuditVisualStore;
};

export const IotaAuditFilter: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();

    const searchForAutocomplete = store.searchForAutocomplete;
    const performSearch = store!.performSearch;

    const formItemLayout = {
        labelCol: {
            style: {
                width: '120px'
            }
        },
        wrapperCol: {
            style: {
                width: 'calc(100% - 120px)',
                minWidth: '140px'
            }
        }
    };

    const handleClear = () => {
        form.resetFields();
        store.clearFilter();
    };

    return (
        <Form className="ant-advanced-search-form" form={form}>
            <Row gutter={24}>
                <Col span={8}>
                    <FormItem {...formItemLayout} name="search">
                        <Observer>
                            {() => (
                                <div className="global-search-wrapper" style={{ width: '100%' }}>
                                    <AutoComplete
                                        className="global-search alpha-autocomplete"
                                        style={{ width: '100%' }}
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        options={store.autocompleteSource.map(m => ({
                                            value: m.packageName,
                                            label: m.packageName
                                        }))}
                                        onSearch={searchForAutocomplete}
                                        placeholder="Search for package"
                                        defaultActiveFirstOption={false}
                                        showSearch
                                        value={store.searchTerm || ''}
                                        onSelect={v => {
                                            const value = v as string;
                                            store.performSearch(value);
                                        }}
                                    >
                                        <Input
                                            suffix={
                                                <Button className="search-btn" type="ghost">
                                                    <i
                                                        className="alpha-icon md search-icon"
                                                        style={{ verticalAlign: 'middle' }}
                                                    />
                                                </Button>
                                            }
                                            onPressEnter={e => {
                                                e.currentTarget.blur();
                                                performSearch(e.currentTarget.value);
                                            }}
                                        />
                                    </AutoComplete>
                                </div>
                            )}
                        </Observer>
                    </FormItem>
                </Col>
                <Col span={5}>
                    <FormItem name="dates" {...formItemLayout} initialValue={''}>
                        <RangePicker style={{ minWidth: 210 }} onChange={store.setDates} />
                    </FormItem>
                </Col>
                <Col offset={7} span={4} style={{ textAlign: 'right', display: 'block', float: 'right' }}>
                    <Button
                        className="clear-button"
                        style={{ width: 'calc(100% - 100px)', minWidth: 60, marginRight: 6 }}
                        onClick={handleClear}
                    >
                        Clear
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default inject(STORE_IOTA_AUDIT)(observer(IotaAuditFilter));
