import { CodeSnippet, CodeSnippetGroup } from '../../administration/models';
import { CodeSnippetsResult, CodeSnippetGroupsResult } from '../../administration/types';
import { execQuery } from '../../common/services/DataService';
import { gql } from 'apollo-boost';
import { CompileDummyCodeType } from '../../rules/types';
import appClient from '../../common/services/AppClient';
import { ResultApi } from '../../common/services/AppClient';

export default class BusinessRulesService {
    executeBusinessRuleCode(code: string, compileDummyCodeType?: CompileDummyCodeType): Promise<ResultApi<unknown>> {
        const request = {
            code: code
        };
        const url = `${process.env.REACT_APP_MANAGE_URL}business_rules/compile/${compileDummyCodeType}`;
        return appClient.post(url, request);
    }

    executeBusinessRuleQuery(connectionId: string, query: string): Promise<ResultApi<unknown>> {
        const request = {
            connectionId: connectionId,
            query: query
        };

        const url = process.env.REACT_APP_MANAGE_URL + 'business_rules/execute-query';
        return appClient.post(url, request);
    }

    async getCodeSnippets(): Promise<CodeSnippet[] | null> {
        const result = await execQuery<CodeSnippetsResult>({
            /* eslint-disable max-len */
            query: gql`
                query getCodeSnippets {
                    getCodeSnippets {
                        id
                        name
                        type
                        code
                        updateDate
                        description
                        groupId
                    }
                }
            `,
            fetchPolicy: 'network-only'
        });
        return result.data.getCodeSnippets;
    }

    async getCodeSnippetGroups(): Promise<CodeSnippetGroup[] | null> {
        const result = await execQuery<CodeSnippetGroupsResult>({
            query: gql`
                query getCodeSnippetGroups {
                    getCodeSnippetGroups {
                        id
                        name
                        description
                        parent
                    }
                }
            `,

            fetchPolicy: 'network-only'
        });
        return result.data.getCodeSnippetGroups;
    }
}
