import { AutoComplete, Form } from 'antd';
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { RulesStores } from '../stores';
import { STORE_RULES_LIST, STORE_RULE_EDIT } from '../constants';

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataId?: string;
} & RulesStores;

export class RuleTagInput extends React.Component<Props, object> {
    get filteredTagNames() {
        const { currentGroupId } = this.props.RuleEditUI!;
        return this.props
            .RulesListUI!.tags.filter(x => x.groupId === currentGroupId)
            .map(t => t.name)
            .sort((a, b) => a.localeCompare(b));
    }
    render() {
        return (
            <Form.Item
                label="Tag"
                name="tag"
                rules={[
                    {
                        required: true,
                        message: 'Please input rule tag!'
                    }
                ]}
                data-id="rule-tag-select"
            >
                <AutoComplete
                    dropdownClassName={this.props.dataId ? `${this.props.dataId}-dropdown` : 'rule-tag-input-dropdown'}
                    style={{ width: '100%' }}
                    options={this.filteredTagNames.map(x => ({ value: x, label: x }))}
                    placeholder="Select tag"
                    filterOption={(inputValue, option) =>
                        option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                />
            </Form.Item>
        );
    }
}

export default inject(STORE_RULES_LIST, STORE_RULE_EDIT)(observer(RuleTagInput));
