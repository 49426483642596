import * as React from 'react';
import { Row, Form, FormItemProps } from 'antd';
import { observer } from 'mobx-react';
import { RulesStores } from '../stores';
import RuleSearch from './RuleSearch';
import TagSearch from './TagSearch';

type Props = RulesStores;

class RulesListFilter extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const formItemLayout: FormItemProps = {
            labelCol: {
                style: {
                    width: '50px'
                }
            },
            wrapperCol: {
                style: {
                    width: 'calc(100% - 50px)',
                    minWidth: '100px'
                }
            }
        };

        return (
            <Form className="ant-advanced-search-form">
                <Row gutter={24}>
                    <RuleSearch RulesListUI={this.props.RulesListUI!} formItemLayout={formItemLayout} />
                    <TagSearch RulesListUI={this.props.RulesListUI!} formItemLayout={formItemLayout} />
                </Row>
            </Form>
        );
    }
}

export default observer(RulesListFilter);
