import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormTypesStore, FormTypesVisualStore } from '../stores';

type Props = {
    store: FormTypesStore;
    visualStore: FormTypesVisualStore;
};

const FormTypeCreateDialog: React.FC<Props> = ({ store, visualStore }) => {
    const [form] = Form.useForm();

    const handleCancel = () => {
        visualStore.setIsFormTypeCreateDialogVisible(false);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(async values => {
                const createdSuccessfully = await store.createFormType(values.name);

                if (createdSuccessfully) {
                    handleCancel();
                }
            })
            .catch(() => {
                // do nothing
            });
    };

    const validateName = (name: string) => {
        const currentGroup = store.formTypes.find(type => type.name === name);

        if (currentGroup) {
            return Promise.reject('Form type with such name already exists');
        }

        return Promise.resolve();
    };

    return (
        <Modal
            wrapProps={{ 'data-id': 'New form type' }}
            className="alpha-portal-dialog"
            title="New form type"
            visible={visualStore.isFormTypeCreateDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button
                    data-id="form-types-add-dialog-cancel"
                    className="light"
                    key="back"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="form-types-add-dialog-submit"
                    key="submit"
                    size="large"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Add form type
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        { required: true, message: 'Provide name for the form type, please' },
                        { validator: (_, name) => validateName(name) }
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(FormTypeCreateDialog);
