import { observable, action, computed } from 'mobx';
import { message } from 'antd';
import { ProjectsRootVisualStore } from '../../common/stores';
import { Utils } from '../../common/services/Utils';
import SchemaGeneratorService from '../services/SchemaGeneratorService';

const defaultImports = ['from datetime import datetime', 'from typing import Tuple', 'from pydantic import BaseModel'];

const defaultPydanticEditortValue = defaultImports.join('\n') + '\n\n';

export default class PydanticSchemaGeneratorStore {
    @observable
    pydanticEditorValue: string = defaultPydanticEditortValue;

    @observable
    generatedJsonSchema: Record<string, unknown> | null = null;

    @observable
    error: string | null = null;

    @computed
    get project() {
        return this.projectsRootStore.currentProject;
    }

    @computed
    get pydanticEditorDisabled() {
        return !this.pydanticEditorValue || this.pydanticEditorValue === defaultPydanticEditortValue;
    }

    constructor(
        readonly projectsRootStore: ProjectsRootVisualStore,
        readonly service: SchemaGeneratorService
    ) {}

    @action.bound
    setPydanticEditorValue(pydanticEditorValue: string) {
        this.pydanticEditorValue = pydanticEditorValue;
    }

    @action.bound
    setGeneratedJsonSchema(generatedJsonSchema: Record<string, unknown> | null) {
        this.generatedJsonSchema = generatedJsonSchema;
    }

    @action.bound
    setError(error: string | null) {
        this.error = error;
    }

    @action.bound
    async generateJsonSchema() {
        if (this.error) {
            this.setError(null);
        }

        const resp = await this.service.generateJsonSchemaFromPydantic(this.pydanticEditorValue);

        if (!resp.isOk()) {
            message.error(`Failed to generate JSON schema. Error: ${resp.error.text}`);
            this.setError(resp.error.data ? resp.error.data.title : resp.error.text);
            this.setGeneratedJsonSchema(null);
            return;
        }

        resp.map(data => this.setGeneratedJsonSchema(JSON.parse(data.jsonSchema)));
    }

    copyGeneratedJsonSchema() {
        if (this.generatedJsonSchema) {
            Utils.copyValToClipboard(JSON.stringify(this.generatedJsonSchema));
        }
    }

    dispose() {
        this.setPydanticEditorValue('');
        this.setGeneratedJsonSchema(null);
        this.setError(null);
    }
}
