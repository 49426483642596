import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Form, Input, Button, Checkbox, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ApplicationDefinitionConditionalEditStore } from '../../stores';
import { CreateEditInputGroupFormValues } from '../../types';
import './CreateEditInputGroupDialog.less';

interface Props {
    store: ApplicationDefinitionConditionalEditStore;
}

const CreateEditInputGroupDialog: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm<CreateEditInputGroupFormValues>();

    const onFinish = (formValues: CreateEditInputGroupFormValues) => {
        if (store.editableInputGroup) {
            store.editableInputGroup.update(formValues);
        } else {
            const inputGroup = store.addNewInputGroup(formValues);
            store.setActiveTab(inputGroup.id);
        }
        store.setIsDataChanged(true);
        onClose();
    };

    const onClose = () => {
        store.setCreateEditInputGroupDialogVisible(false);

        if (store.editableInputGroup) {
            store.editableInputGroup.setEditable(false);
        }
    };

    const validateInputGroupName = (_: unknown, value?: string) => {
        const inputGroupName = value?.trim();

        if (!inputGroupName || !inputGroupName.length) {
            return Promise.resolve();
        }

        const inputGroupExists = store.inputGroups.some(g => {
            if (store.editableInputGroup) {
                return g.id !== store.editableInputGroup.id && g.name.toLowerCase() === inputGroupName.toLowerCase();
            }

            return g.name === inputGroupName;
        });

        if (inputGroupExists) {
            return Promise.reject('Input group with such name already exists');
        }

        return Promise.resolve();
    };

    return (
        <Modal
            className="create-edit-input-group-dialog"
            title={store.editableInputGroup ? 'Edit Input Group' : 'Add Input Group'}
            visible={store.createEditInputGroupDialogVisible}
            width={600}
            onCancel={onClose}
            maskClosable={false}
            closable={false}
            destroyOnClose
            centered
            footer={[
                <Button
                    form="create-edit-input-group-form"
                    data-id="create-edit-input-group-cancel"
                    key="create-edit-input-group-cancel"
                    className="light"
                    size="large"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button
                    form="create-edit-input-group-form"
                    data-id="create-edit-input-group"
                    key="create-edit-input-group"
                    size="large"
                    type="primary"
                    htmlType="submit"
                >
                    {store.editableInputGroup ? 'Update' : 'Create'}
                </Button>
            ]}
        >
            <Form
                id="create-edit-input-group-form"
                className="create-edit-input-group-form"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                preserve={false}
            >
                <Form.Item
                    name="name"
                    label="Input group name"
                    initialValue={store.editableInputGroup ? store.editableInputGroup.name : ''}
                    rules={[
                        { required: true, message: 'Please enter group name!' },
                        { validator: validateInputGroupName }
                    ]}
                >
                    <Input />
                </Form.Item>

                <div className="complex-structure">
                    <span className="complex-structure-label">
                        Complex structure:
                        <Tooltip title="Simple by default. Select if layers and/or sections are needed.">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </span>

                    <Form.Item
                        name="hasLayers"
                        valuePropName="checked"
                        initialValue={store.editableInputGroup ? store.editableInputGroup.hasLayers : false}
                    >
                        <Checkbox>Layers</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="hasSections"
                        valuePropName="checked"
                        initialValue={store.editableInputGroup ? store.editableInputGroup.hasSections : false}
                    >
                        <Checkbox>Sections</Checkbox>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
};

export default observer(CreateEditInputGroupDialog);
