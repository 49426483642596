import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Layout, Button } from 'antd';
import { Utils } from '../../common/services/Utils';
import { ProjectsRootVisualStore } from '../../common/stores';
import { ProjectFormVisualStore } from '../../project_management/stores';
import LayoutHeader from '../../../components/LayoutHeader';
import ProjectDeleteDialog from '../../../components/ProjectDeleteDialog';
import EditProjectForm from '../../project_management/components/EditProjectForm';

type Props = {
    store: ProjectsRootVisualStore;
    formStore: ProjectFormVisualStore;
};

const ProjectSettingsForm: React.FC<Props> = ({ store, formStore }) => {
    React.useEffect(() => {
        return () => formStore.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout>
            <ProjectDeleteDialog store={store} />

            <LayoutHeader
                title="Project Settings"
                subtitle={Utils.getSubtitle(store.currentProject, true)}
                helpMessage="Customization of application preferences to suit specific requirements"
                buttons={[
                    <React.Fragment key="edit-project-form-buttons">
                        <Button
                            loading={store.isLoading}
                            style={{ marginLeft: 8 }}
                            data-id="edit-project-submit"
                            key="edit-project-submit"
                            size="large"
                            type="primary"
                            htmlType="submit"
                            form="edit-project-form"
                        >
                            Save changes
                        </Button>
                    </React.Fragment>
                ]}
            />

            <Layout.Content className="edit-project-setting-layout-content">
                <EditProjectForm store={formStore} />
            </Layout.Content>
        </Layout>
    );
};

export default observer(ProjectSettingsForm);
