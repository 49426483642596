import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormTypesStore, FormTypesVisualStore } from '../stores';

type Props = {
    store: FormTypesStore;
    visualStore: FormTypesVisualStore;
};

const FormTemplateRegionCreateDialog: React.FC<Props> = ({ store, visualStore }) => {
    const [form] = Form.useForm();

    const handleCancel = () => {
        visualStore.setIsFormTemplateRegionCreateDialogVisible(false);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(async values => {
                const createdSuccessfully = await store.createFormTemplateRegion(values.name);

                if (createdSuccessfully) {
                    handleCancel();
                }
            })
            .catch(() => {
                // do nothing
            });
    };

    const validateName = (name: string) => {
        if (!store.selectedFormPart) {
            return Promise.reject('Select form part, please');
        }

        const regions = store.formParts.find(part => part.id === store.selectedFormPart)?.regions;

        if (regions?.length && regions.find(region => region.name === name)) {
            return Promise.reject('Form region with such name already exists');
        }

        return Promise.resolve();
    };

    return (
        <Modal
            wrapProps={{ 'data-id': 'New form template region' }}
            className="alpha-portal-dialog"
            title="New form template region"
            visible={visualStore.isFormTemplateRegionCreateDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button
                    data-id="form-types-add-region-dialog-cancel"
                    className="light"
                    key="back"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="form-types-add-region-dialog-submit"
                    key="submit"
                    size="large"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Add form region
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        { required: true, message: 'Provide name for the form template region, please' },
                        { validator: (__, value) => validateName(value) }
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(FormTemplateRegionCreateDialog);
