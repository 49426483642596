import {
    Button,
    Dropdown,
    Form,
    Input,
    InputNumber,
    Layout,
    Menu,
    message,
    Pagination,
    Select,
    Tooltip,
    Upload
} from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../../common/services/Utils';
import {
    FormLabelingContent,
    FormPartCreateDialog,
    FormPartEditDialog,
    FormTemplateRegionCreateDialog,
    FormTemplateRegionEditDialog,
    FormTypeCreateDialog,
    FormTypeEditDialog,
    FormTypeImportDialog,
    FormTypesTree,
    FormTypeVariationCreateDialog,
    FormTypeVariationEditDialog,
    PackagesLoadingIndicator,
    FormTypeSearch
} from '.';
import LayoutHeader from '../../../components/LayoutHeader';
import FormRegionBlockCreateDialog from './FormRegionBlockCreateDialog';
import {
    FileTextOutlined,
    FontSizeOutlined,
    ImportOutlined,
    MinusOutlined,
    MoreOutlined,
    PicRightOutlined,
    PlusOutlined,
    SaveOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { FormTypesStore, FormTypesVisualStore } from '../stores';
import { getDetectorAutocompleteOptions } from '../misc/FormTypesUtils';
import _ from 'lodash';
import { FormBlock } from '../types';
import { Subscription } from 'rxjs';
import { ProjectsStore } from '../../common/stores';
import { reaction } from 'mobx';
import { HelpMessage } from '../../common/components';

type Props = {
    store: FormTypesStore;
    visualStore: FormTypesVisualStore;
    projectsStore: ProjectsStore;
};

const FormTypes: React.FC<Props> = ({ store, visualStore, projectsStore }) => {
    const controlsId = 'form';
    const pdfScalingLevels = [0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2.5, 3];
    const defaultScaleLevel = 5;
    const [form] = Form.useForm();
    const [detectorForm] = Form.useForm();
    const [pdfScaleLevel, setPdfScaleLevel] = React.useState<number>(defaultScaleLevel);
    const [pages, setPages] = React.useState(1);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [autocompleteOptions, setAutocompleteOptions] = React.useState<{ value: string; label: string }[]>([]);
    const [file, setFile] = React.useState<UploadFile | null>(null);
    const [documentLoaded, setDocumentLoaded] = React.useState(false);

    let pageChangeSub = React.useRef<Subscription>();

    React.useEffect(() => {
        pageChangeSub.current = visualStore.pageChangeSubject.subscribe(pageNum => {
            handlePageChange(pageNum, true);
        });

        return () => {
            visualStore.clearFormTypeEvents();

            if (pageChangeSub.current) {
                pageChangeSub.current.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (store.currentProject) {
            store
                .getFormPartsForProject()
                .then(() => {
                    store.getFormTypesForProject();
                })
                .catch(error => {
                    console.error(error);
                });

            store.setHeaders();
            store.loadAllProjectPackages();
            store.getFormTypePackageLines();
            store.subscribeToPackageChanges();
        }

        return () => store.unsubscribeFromPackageChanges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject]);

    React.useEffect(() => {
        if (store.selectedBlock) {
            const obj = {
                top: store.selectedBlock.y1,
                left: store.selectedBlock.x1,
                width: store.selectedBlock.width,
                height: store.selectedBlock.height
            };
            form.setFieldsValue(obj);
        } else {
            form.resetFields();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.selectedBlock]);

    React.useEffect(() => {
        if (store.currentFormPart) {
            const obj = {
                detectorParts: store.currentFormPart.detector?.detectorParts ?? [],
                ignoreParts: store.currentFormPart.detector?.ignoreParts ?? []
            };
            detectorForm.setFieldsValue(obj);
        } else {
            detectorForm.resetFields();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentFormPart]);

    React.useEffect(() => {
        if (store.currentRegionPackageId && !store.linesForPackageAreLoaded(store.currentRegionPackageId)) {
            if (store.loadingPackages) {
                reaction(
                    () => store.loadingPackages,
                    (loading, r) => {
                        if (!loading) {
                            loadPackageLinesForAutocomplete();
                            r.dispose();
                        }
                    }
                );
            } else {
                loadPackageLinesForAutocomplete();
            }
        } else if (store.linesForPackageAreLoaded(store.currentRegionPackageId)) {
            populateAutocompleteOptions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentRegionPackageId]);

    const loadPackageLinesForAutocomplete = () => {
        store
            .getLineBlocksForPackage()
            .then(() => {
                populateAutocompleteOptions();
            })
            .catch(err => {
                console.error(err);
            });
    };

    const populateAutocompleteOptions = () => {
        const options = store.packageLines.map(getDetectorAutocompleteOptions);
        setAutocompleteOptions(_.uniqBy(options, 'value'));
    };

    const addFormType = () => {
        visualStore.setIsFormTypeCreateDialogVisible(true);
    };

    const addFormTypeVariation = () => {
        visualStore.setIsFormVariationCreateDialogVisible(true);
    };

    const addFormPart = () => {
        visualStore.setIsFormPartCreateDialogVisible(true);
    };

    const addFormTemplateRegion = () => {
        visualStore.setIsFormTemplateRegionCreateDialogVisible(true);
    };

    const getCurrentCoordinateReference = () => {
        if (!store.currentFormPart?.coordinatesReference) {
            return null;
        }

        const { x1, y1, x2, y2 } = store.currentFormPart.coordinatesReference.phrase;
        return `[${x1}, ${y1}], [${x2}, ${y2}]`;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFormBlockPropsUpdate = (values: any) => {
        form.validateFields()
            .then(() => {
                if (store.selectedBlockInfo && store.selectedBlock && store.selectedFormTemplateRegion) {
                    let currentBlocks = { ...store.currentRegionBlocks };
                    const { regionId, blockId, data, useContours } =
                        currentBlocks[store.selectedFormTemplateRegion][store.selectedBlockInfo.page][
                            store.selectedBlockInfo.index
                        ];
                    let newBlock = new FormBlock(
                        values.left,
                        values.top,
                        values.left + values.width,
                        values.top + values.height,
                        regionId,
                        blockId,
                        data,
                        useContours,
                        true
                    );
                    currentBlocks[store.selectedFormTemplateRegion][store.selectedBlockInfo.page][
                        store.selectedBlockInfo.index
                    ] = newBlock;

                    store.setCurrentRegionBlocks(currentBlocks, true);
                    visualStore.logFormTypeEvent('region_block_updated');
                }
            })
            .catch(() => {
                // do nothing
            });
    };

    const changeDocumentScaling = (levelDelta: number) => {
        const newLevel = pdfScaleLevel + levelDelta;
        if (newLevel >= 0 && newLevel < pdfScalingLevels.length) {
            setPdfScaleLevel(newLevel);
        }
    };

    const onFileImport = (info: UploadChangeParam) => {
        const status = info.file.status;
        if (status === 'done') {
            const formTypeImportResponse = info.file.response;
            store.setFormTypeImportData(formTypeImportResponse);
            store.setIsFormTypeImportDialogVisible(true);
            message.success(`${info.file.name} file uploaded successfully.`);
            setFile(info.file);
        } else if (status === 'error') {
            if (info.file?.response?.status === 409) {
                message.warning(info.file.response.title);
                return;
            }
            message.error(`${info.file.name} file upload failed. Error: ${info.file.response.title}`);
        }
    };

    const reuploadFile = async () => {
        if (!store.currentProject || !file) {
            return;
        }

        const formData = new FormData();

        formData.append('file', file.originFileObj as File);
        formData.append('projectId', store.currentProject.id);
        formData.append('userTags', JSON.stringify(store.selectedTags));

        const resp = await store.importFormType(formData);

        if (resp.isOk()) {
            resp.map(formTypeImportTableData => store.setFormTypeImportData(formTypeImportTableData));
        } else {
            store.setIsFormTypeImportDialogVisible(false);
            message.error(
                `${file.name} file upload failed. Error: ${resp.error.data ? resp.error.data.title : resp.error.text}`
            );
        }
    };

    const openFormPartPreview = () => {
        if (!store.currentFormPart || !store.currentProject) {
            return;
        }

        const url = `${window.location.origin}/projects/${store.currentProject.id}/form-parts/${store.currentFormPart.id}/preview`;
        const previewWindow = window.open(url, `${store.currentFormPart.id}-preview`, 'height=768,width=1024');

        if (previewWindow) {
            previewWindow.focus();
        }
    };

    const moreActionsDropdownMenu = () => (
        <Menu data-id="more-menu">
            <Menu.Item key="1" onClick={visualStore.toggleAnnotations} icon={<FontSizeOutlined />}>
                {visualStore.showAnnotations ? 'Hide annotations' : 'Show annotations'}
            </Menu.Item>

            <Upload
                showUploadList={false}
                data={{
                    projectId: store.currentProject?.id,
                    userTags: JSON.stringify(store.selectedTags)
                }}
                name="file"
                headers={store.fileImportActionHeaders}
                action={`${process.env.REACT_APP_MANAGE_URL}formTypes/types/import`}
                onChange={onFileImport}
            >
                <Menu.Item key="2" icon={<ImportOutlined />} style={{ width: 160 }}>
                    Import
                </Menu.Item>
            </Upload>
            {store.currentFormPart && (
                <Menu.Item key="3" icon={<FileTextOutlined />} onClick={openFormPartPreview}>
                    Preview form part
                </Menu.Item>
            )}
            {store.currentRegionPackageId && (
                <>
                    <Menu.Item
                        key="4"
                        onClick={() => store.reparsePackage(store.currentRegionPackageId!)}
                        icon={<PicRightOutlined />}
                    >
                        Re-parse package
                    </Menu.Item>

                    <Menu.SubMenu key="5" title="Download package" icon={<DownloadOutlined />}>
                        <Menu.Item
                            key="5-1"
                            onClick={() => projectsStore.handleDownload(store.currentRegionPackageId!, 'pdf')}
                        >
                            Download Pdf
                        </Menu.Item>
                        <Menu.Item
                            key="5-2"
                            onClick={() => projectsStore.handleDownload(store.currentRegionPackageId!, 'apkg')}
                        >
                            Download apkg
                        </Menu.Item>
                    </Menu.SubMenu>
                </>
            )}
        </Menu>
    );

    const addActionsDropdownMenu = () => (
        <Menu data-id="add-menu">
            <Menu.Item key="0" data-id="button-type" onClick={addFormType} icon={<PlusOutlined />}>
                Type
            </Menu.Item>
            {store.selectedFormType && (
                <Menu.Item key="1" data-id="button-variation" onClick={addFormTypeVariation} icon={<PlusOutlined />}>
                    Variation
                </Menu.Item>
            )}

            {store.selectedFormVariation && (
                <Menu.Item key="2" data-id="button-part" onClick={addFormPart} icon={<PlusOutlined />}>
                    Part
                </Menu.Item>
            )}

            {store.selectedFormPart && (
                <Menu.Item key="3" data-id="button-region" onClick={addFormTemplateRegion} icon={<PlusOutlined />}>
                    Region
                </Menu.Item>
            )}
        </Menu>
    );

    function handleKeyUp(event: React.KeyboardEvent) {
        if (event.key === 'Enter') {
            onFormBlockPropsUpdate(form.getFieldsValue(true));
        }
    }

    function handleDetectorFormKeyUp(event: React.KeyboardEvent) {
        if (event.key === 'Enter') {
            handleDetectorSubmit();
        }
    }

    const handleDetectorSubmit = () => {
        detectorForm
            .validateFields()
            .then(async values => {
                if (!store.currentFormPart) {
                    return;
                }
                const { name } = store.currentFormPart;

                await store.updateFormPart(name, values.detectorParts, values.ignoreParts, undefined);
            })
            .catch(() => {
                // do nothing
            });
    };

    const handleBlocksSave = async () => {
        if (await visualStore.confirmUnsavedRegionBlocks(pageNumber, 'Save changes anyway?')) {
            const savedSuccessfully = await store.saveAllBlocksOnPage(pageNumber - 1);

            if (savedSuccessfully) {
                visualStore.clearFormTypeEvents();
                store.setLastSavedBlockState(_.cloneDeep(store.currentRegionBlocks));
            }
        }
    };

    const handlePageChange = async (page: number, unsafe: boolean = false) => {
        if (await visualStore.confirmFormTypeChanges(pageNumber, 'Discard changes and navigate to another page?')) {
            store.setCurrentRegionBlocks(_.cloneDeep(store.lastSavedBlocksState));
            visualStore.clearFormTypeEvents();
            if (unsafe) {
                setPageNumber(page);
            } else {
                safeSetPageNumber(page);
            }
        }
    };

    const safeSetPageNumber = (page: number) => {
        if (page > 0) {
            setPageNumber(Math.min(page, pages));
        }
    };

    return (
        <Layout className="screen-size" style={{ ...{ height: '100%', background: 'white' } }}>
            <LayoutHeader
                subtitle={Utils.getSubtitle(store.currentProject)}
                title="Forms"
                buttons={[
                    <React.Fragment key="form-types-buttons">
                        {store.currentRegionPackageId && (
                            <div
                                className="pdf-viewer-controls"
                                id={controlsId}
                                style={{ width: 'calc(100vw - 910px)', textAlign: 'center' }}
                            >
                                {pages > 1 ? (
                                    <Pagination
                                        defaultCurrent={1}
                                        current={pageNumber}
                                        showQuickJumper
                                        showLessItems
                                        pageSize={1}
                                        total={pages}
                                        onChange={page => handlePageChange(page)}
                                    />
                                ) : null}

                                <div>
                                    <Button onClick={() => changeDocumentScaling(-1)} icon={<MinusOutlined />} />
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setPdfScaleLevel(defaultScaleLevel)}
                                    >
                                        {Math.round(pdfScalingLevels[pdfScaleLevel] * 100)}%
                                    </span>
                                    <Button onClick={() => changeDocumentScaling(+1)} icon={<PlusOutlined />} />
                                </div>
                            </div>
                        )}

                        <div style={{ display: 'inline-block', width: 264, textAlign: 'right' }}>
                            <PackagesLoadingIndicator store={store} />
                            <HelpMessage message="Pre-made structures for consistent data gathering" />
                            {visualStore.hasFormTypeChanges && (
                                <Tooltip title="Save changes">
                                    <Button
                                        type="primary"
                                        size="large"
                                        data-id="button-save"
                                        icon={<SaveOutlined />}
                                        onClick={handleBlocksSave}
                                    />
                                </Tooltip>
                            )}
                            <Dropdown trigger={['click']} placement="bottomRight" overlay={moreActionsDropdownMenu}>
                                <Button size="large" data-id="button-more" icon={<MoreOutlined rotate={90} />} />
                            </Dropdown>
                            <Dropdown trigger={['click']} placement="bottomRight" overlay={addActionsDropdownMenu}>
                                <Button size="large" data-id="button-add" icon={<PlusOutlined />} />
                            </Dropdown>
                        </div>
                    </React.Fragment>
                ]}
            />
            <Layout>
                {visualStore.isFormTypeEditDialogVisible && (
                    <FormTypeEditDialog store={store} visualStore={visualStore} />
                )}
                {visualStore.isFormVariationEditDialogVisible && (
                    <FormTypeVariationEditDialog store={store} visualStore={visualStore} />
                )}
                {visualStore.isFormRegionEditDialogVisible && (
                    <FormTemplateRegionEditDialog store={store} visualStore={visualStore} />
                )}
                <FormTypeImportDialog store={store} reuploadFile={reuploadFile} />
                <FormTemplateRegionCreateDialog store={store} visualStore={visualStore} />
                <FormTypeVariationCreateDialog store={store} visualStore={visualStore} />
                <FormTypeCreateDialog store={store} visualStore={visualStore} />
                <FormPartCreateDialog store={store} visualStore={visualStore} />
                <FormRegionBlockCreateDialog store={store} visualStore={visualStore} />
                {store.isFormPartEditDialogVisible && <FormPartEditDialog store={store} />}
                <Layout.Sider
                    data-id="form-types-tree-container"
                    width={300}
                    style={{ background: 'white', overflow: 'auto', paddingRight: 15 }}
                >
                    <FormTypeSearch store={store} />
                    <FormTypesTree
                        store={store}
                        visualStore={visualStore}
                        documentLoaded={documentLoaded}
                        pageNumber={pageNumber}
                    />
                </Layout.Sider>
                <Layout.Content
                    id="form-types-labeling-content"
                    data-id="form-types-content-container"
                    style={{ background: 'white' }}
                    className={`${!visualStore.showAnnotations ? 'hide-annotations' : ''}`}
                >
                    <FormLabelingContent
                        visualStore={visualStore}
                        store={store}
                        pages={pages}
                        setPages={setPages}
                        scale={pdfScalingLevels[pdfScaleLevel]}
                        pageNumber={pageNumber}
                        setPageNumber={safeSetPageNumber}
                        documentLoaded={documentLoaded}
                        setDocumentLoaded={setDocumentLoaded}
                    />
                </Layout.Content>
                <Layout.Sider
                    data-id="form-part-info-container"
                    width={200}
                    style={{ background: 'white', overflow: 'auto', paddingRight: 15 }}
                >
                    <div style={{ paddingLeft: 12 }}>
                        {store.currentRegionPackageName && (
                            <>
                                <h3 style={{ fontWeight: 600 }}>Package</h3>
                                <p>{store.currentRegionPackageName}</p>
                            </>
                        )}

                        {store.currentFormPart && (
                            <>
                                <h3 style={{ fontWeight: 600 }}>Form Part Info</h3>

                                <Form form={detectorForm} layout="vertical" onKeyUp={handleDetectorFormKeyUp}>
                                    <h4 style={{ fontWeight: 600 }}>Detector</h4>
                                    <Form.Item
                                        name="detectorParts"
                                        label="Detector Parts"
                                        rules={[{ required: true, message: 'Please select a detector parts' }]}
                                    >
                                        <Select
                                            options={autocompleteOptions}
                                            mode="tags"
                                            showSearch
                                            onDeselect={handleDetectorSubmit}
                                            onSelect={handleDetectorSubmit}
                                        />
                                    </Form.Item>
                                    <Form.Item name="ignoreParts" label="Ignore Parts">
                                        <Select
                                            options={autocompleteOptions}
                                            mode="tags"
                                            showSearch
                                            onDeselect={handleDetectorSubmit}
                                            onSelect={handleDetectorSubmit}
                                        />
                                    </Form.Item>
                                </Form>

                                <h4 style={{ fontWeight: 600 }}>Coordinates reference</h4>
                                <div style={{ marginBottom: 24 }}>
                                    Width
                                    <div style={{ marginTop: 8 }}>
                                        <Input
                                            style={{ background: '#F5F6F8' }}
                                            readOnly
                                            value={store.currentFormPart.coordinatesReference?.width}
                                        />
                                    </div>
                                </div>

                                <div style={{ marginBottom: 24 }}>
                                    Height
                                    <div style={{ marginTop: 8 }}>
                                        <Input
                                            style={{ background: '#F5F6F8' }}
                                            readOnly
                                            value={store.currentFormPart.coordinatesReference?.height}
                                        />
                                    </div>
                                </div>

                                <div style={{ marginBottom: 24 }}>
                                    Text
                                    <div style={{ marginTop: 8 }}>
                                        <Input
                                            style={{ background: '#F5F6F8' }}
                                            readOnly
                                            value={store.currentFormPart.coordinatesReference?.phrase?.text}
                                            title={store.currentFormPart.coordinatesReference?.phrase?.text}
                                        />
                                    </div>
                                </div>

                                <div style={{ marginBottom: 24 }}>
                                    Coordinates
                                    <div style={{ marginTop: 8 }}>
                                        <Input
                                            style={{ background: '#F5F6F8' }}
                                            readOnly
                                            value={getCurrentCoordinateReference() ?? ''}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <Form form={form} layout="horizontal" onKeyUp={handleKeyUp} className="coordinate-reference-form">
                        {store.selectedBlock && (
                            <div style={{ paddingLeft: 12, paddingBottom: 64 }}>
                                <h4 style={{ fontWeight: 600 }}>Block properties</h4>
                                <p>Block: {store.getBlockTitleById(store.selectedBlock.blockId)}</p>
                                <p>Uses contours: {store.selectedBlock.useContours?.toString() ?? 'false'}</p>
                                <Form.Item
                                    label="Left"
                                    name="left"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Left is required'
                                        }
                                    ]}
                                >
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item
                                    label="Top"
                                    name="top"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Top is required'
                                        }
                                    ]}
                                >
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item
                                    label="Width"
                                    name="width"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Width is required'
                                        }
                                    ]}
                                >
                                    <InputNumber min={1} />
                                </Form.Item>
                                <Form.Item
                                    label="Height"
                                    name="height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Height is required'
                                        }
                                    ]}
                                >
                                    <InputNumber min={1} />
                                </Form.Item>
                            </div>
                        )}
                    </Form>
                </Layout.Sider>
            </Layout>
        </Layout>
    );
};

export default observer(FormTypes);
