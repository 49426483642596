import FlexContentContainer from '../../common/components/FlexContentContainer';
import * as React from 'react';
import { Layout } from 'antd';
import { inject, observer } from 'mobx-react';
import { ProjectStores } from '../stores';
import { STORE_PACKAGE_PROBLEM_REPORTS_UI } from '../constants';
import PackageProblemReportsTable from '../components/PackageProblemReportsTable';
import PackageProblemReportsFilter from '../components/PackageProblemReportsFilter';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import LayoutHeader from '../../../components/LayoutHeader';
import { Utils } from '../../common/services/Utils';

type Props = ProjectStores;

@inject(STORE_PACKAGE_PROBLEM_REPORTS_UI)
@observer
export default class PackageProblemReportsPage extends React.Component<Props, object> {
    componentDidMount() {
        const store = this.props.packageProblemsReportsUI!;
        store.loadProblemsReports();
    }
    render() {
        const store = this.props.packageProblemsReportsUI!;
        return (
            <FlexContentContainer
                content={
                    <>
                        <Layout>
                            <LayoutHeader
                                title="Problem Reports"
                                subtitle={Utils.getSubtitle(store.currentProject)}
                                helpMessage="Documentation and resolution of identified issues"
                                helpMessageTooltipPlacement="leftBottom"
                            />
                            <Layout.Content>
                                <PackageProblemReportsFilter />
                                {store.isLoading ? <LoadingIndicator /> : <PackageProblemReportsTable />}
                            </Layout.Content>
                        </Layout>
                    </>
                }
            />
        );
    }
}
