export default class MLModelRevision {
    constructor(
        public storagePath: string,
        public isActive: boolean,
        public createDate: string,
        public fileSizeKilobytes: number | null,
        public manifestJson: string | null
    ) {
        this.storagePath = storagePath;
        this.isActive = isActive;
        this.createDate = createDate;
        this.fileSizeKilobytes = fileSizeKilobytes;
        this.manifestJson = manifestJson;
    }
}
