import * as React from 'react';
import { observer, inject } from 'mobx-react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import Users from '../../../modules/administration/components/Users';
import { STORE_PROJECT_ASSIGNMENT, STORE_USERS_MANAGER_UI } from '../../../modules/administration/constants';
import { AdministrationStores } from '../../../modules/administration/stores';
import { AuthConsumer } from '../../authorization/AuthContext';

type Props = AdministrationStores;
@inject(STORE_PROJECT_ASSIGNMENT, STORE_USERS_MANAGER_UI)
@observer
export default class ProjectAssignmentPage extends React.Component<Props, object> {
    render() {
        const projectAssignmentStore = this.props.ProjectAssignment!;
        const usersManagerStore = this.props.UsersManager!;

        const content = (
            <AuthConsumer>
                {() => <Users ProjectAssignment={projectAssignmentStore} UsersManager={usersManagerStore} />}
            </AuthConsumer>
        );

        return <FlexContentContainer content={content} />;
    }
}
