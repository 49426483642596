import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Tree, Input, Checkbox, Form } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { FeatureFlagsTreeStore } from '../../../project_management/stores';
import { FeatureFlagTreeNodeModel, TreeFeatureFlagModel } from '../../../project_management/models';
import './FeatureFlagsTreeRenderer.less';

interface Props {
    featureFlagKeyPrefix?: string;
    disableInputs?: boolean;
}

const FeatureFlagsTreeRenderer: React.FC<Props> = ({ featureFlagKeyPrefix = '', disableInputs = false }: Props) => {
    const store = useLocalStore(() => ({
        featureFlagsTreeStore: new FeatureFlagsTreeStore()
    }));

    const renderFeatureFlagFormItem = (featureFlag: TreeFeatureFlagModel) => {
        const name = featureFlagKeyPrefix + featureFlag.key;
        switch (featureFlag.controlType) {
            case 'checkbox':
                return (
                    <Form.Item name={name} valuePropName="checked">
                        <Checkbox disabled={disableInputs} />
                    </Form.Item>
                );
            case 'text':
                return (
                    <Form.Item name={name}>
                        <Input disabled={disableInputs} />
                    </Form.Item>
                );
            case 'textarea':
                return (
                    <Form.Item name={name}>
                        <Input.TextArea autoSize={{ minRows: 4, maxRows: 12 }} disabled={disableInputs} />
                    </Form.Item>
                );
            default:
                console.error(`Unknown feature flag control type: ${featureFlag.controlType}`);
                return null;
        }
    };

    const renderFeatureFlag = (featureFlag: TreeFeatureFlagModel) => (
        <div
            className={`feature-flag-container ${featureFlag.controlType}`}
            key={featureFlag.key}
            onClick={e => e.stopPropagation()}
        >
            <span className="feature-flag-label">{featureFlag.keyPart}</span>
            {renderFeatureFlagFormItem(featureFlag)}
        </div>
    );

    const renderNode = (node: FeatureFlagTreeNodeModel) => (
        <div className="feature-flags-tree-node-container">
            {node.featureFlag ? (
                renderFeatureFlag(node.featureFlag)
            ) : (
                <span className="node-label" onClick={() => store.featureFlagsTreeStore.toggleExpanded(node.key)}>
                    {node.keyPart}
                </span>
            )}
        </div>
    );

    const mapNodesToTreeData = (nodes: FeatureFlagTreeNodeModel[]): DataNode[] => {
        return nodes.map(node => ({
            key: node.key,
            children: mapNodesToTreeData(node.children),
            title: renderNode(node)
        }));
    };

    return (
        <Tree
            className="feature-flags-tree"
            treeData={mapNodesToTreeData(store.featureFlagsTreeStore.nodes)}
            expandedKeys={store.featureFlagsTreeStore.expandedKeys}
            onExpand={(expandedKeys: string[]) => store.featureFlagsTreeStore.setExpandedKeys(expandedKeys)}
        />
    );
};

export default observer(FeatureFlagsTreeRenderer);
