import * as React from 'react';
import { Modal, Button, Input, Form } from 'antd';
import { STORE_RULES_LIST } from '../constants';
import { RulesListVisualStore } from '../stores';
import FormItem from 'antd/lib/form/FormItem';
import { observer } from 'mobx-react-lite';

type Props = {
    [STORE_RULES_LIST]: RulesListVisualStore | undefined;
};
const TagRenameDialog: React.FC<Props> = ({ RulesListUI: store }) => {
    const [form] = Form.useForm();
    const handleSubmit = (e: React.FormEvent<object>) => {
        e.preventDefault();
        form.validateFields()
            .then((values: { tagRename: string }) => {
                if (store!.tags.map(x => x.name).includes(values.tagRename)) {
                    form.setFields([
                        {
                            name: 'tagRename',
                            value: values.tagRename,
                            errors: ['Tag name already in use']
                        }
                    ]);
                    return;
                }

                const tagId = store!.tags.find(r => r.name === store!.tagToRename)!.id!;
                store!.renameTag(values).then(() => {
                    const highlightedRows = document.getElementsByClassName('highlight-selected');
                    for (let i = 0; i < highlightedRows.length; i++) {
                        highlightedRows[i].classList.remove('highlight-selected');
                    }

                    store?.clearRuleHighlight();

                    const row = document.querySelector(`[data-row-key="${tagId}"]`);

                    if (row) {
                        row.scrollIntoView({ behavior: 'auto', block: 'start' });
                        row.classList.add('highlight-selected');
                    }
                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    const getFormContent = (
        <Form form={form} layout="vertical" data-id="edit-tag-dialog-content">
            <FormItem
                key="2"
                label="Tag Name"
                name="tagRename"
                initialValue={store!.tagToRename}
                rules={[{ required: true, message: 'Please input tag name!', whitespace: true }]}
            >
                <Input />
            </FormItem>
        </Form>
    );

    return (
        <Modal
            key={'tag-rename-modal'}
            title="New Tag Name"
            visible={store!.tagRenameDialog}
            onCancel={() => store!.setNewTagFormVisible(false)}
            className="alpha-portal-dialog"
            maskClosable={false}
            destroyOnClose
            closable={false}
            centered
            footer={[
                <>
                    <Button
                        data-id="edit-tag-dialog-cancel"
                        key="tag-rename-back"
                        size="large"
                        className="light"
                        onClick={() => store!.setTagRenameDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        data-id="edit-tag-dialog-submit"
                        key="tag-rename-submit"
                        size="large"
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </>
            ]}
        >
            {getFormContent}
        </Modal>
    );
};

export default observer(TagRenameDialog);
