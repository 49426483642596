/* eslint-disable @typescript-eslint/member-ordering */
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Route } from 'react-router';
import { RootStores } from '../stores';
import { STORE_PROJECTS_ROOT_UI } from '../constants';

interface ProtectedRouteProps {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    component: React.ComponentClass | React.FunctionComponent | React.NamedExoticComponent;
    [key: string]: any;
}

export type Props = Partial<RootStores> & ProtectedRouteProps;

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
    const { component: Component, ...p } = props;

    // let comp = (pr: any) => <Redirect to="/projects" />;
    // if (projectsRootUI && projectsRootUI.currentProject) {
    let comp = (pr: any) => <Component {...pr} />;
    // }

    return <Route {...p} render={comp} />;
};

export default inject(STORE_PROJECTS_ROOT_UI)(observer(ProtectedRoute));
