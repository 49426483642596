import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Input, List, Radio, Spin, Tabs, Row, Col, AutoComplete, Divider } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { TestProjectWizardStore } from '../../stores';
import { InputBindingAppInput, AppDefInputBindingMetadata, AppDefConditionalInputBindingMetadata } from '../../types';

type Props = {
    store: TestProjectWizardStore;
};

const TopicSelectionStep: React.FC<Props> = ({ store }) => {
    const [hasValidTopcs, setHasValidTopcs] = React.useState(store.currentTestProject?.hasValidTopics ?? true);
    const [selectedTopicsAutocomplete, setSelectedTopicsAutocomplete] = React.useState('');
    const [selectedTopicsFilter, setSelectedTopicsFilter] = React.useState('');

    React.useEffect(() => {
        if (store.currentProject) {
            store.loadProjectRuleData();
            store.loadProjectInputBindingsMetadata();
            store.loadProjectFieldBindingsGroups();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject]);

    React.useEffect(() => {
        if (store.currentTestProject) {
            setHasValidTopcs(store.currentTestProject.hasValidTopics);
        }
    }, [store.currentTestProject]);

    const getTagsContent = () => {
        return (
            <Collapse>
                {store.currentProjectTagGroups.map(tg => {
                    const filteredTags = store.currentProjectTags.filter(
                        t => t.groupId === tg.id && t.name.toLowerCase().includes(store.topicsSearchTerm.toLowerCase())
                    );

                    if (filteredTags.length === 0) {
                        return null; // Don't render empty tag groups (no tags match the search term)
                    }

                    return (
                        <Collapse.Panel header={tg.name} key={tg.id!}>
                            {filteredTags.map(t => (
                                <div key={t.id}>
                                    <Checkbox
                                        onChange={e => store.toggleTopicTag(t.id!, e.target.checked)}
                                        checked={store.selectedTopicTags.includes(t.id!)}
                                    >
                                        {t.name}
                                    </Checkbox>
                                </div>
                            ))}
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        );
    };

    const cleanUpFaultyBindings = () => {
        const faultyBindings = store.topics.filter(b => getTopicName(b) == null);
        faultyBindings.forEach(b => handleTopicDeselect(b));
    };

    const handleTopicChange = (e: CheckboxChangeEvent, inputId: string, appId: string, inputGroupId?: string) => {
        store.toggleInputBinding(inputId, e.target.checked, appId, inputGroupId);

        if (!hasValidTopcs) {
            cleanUpFaultyBindings();
        }

        setHasValidTopcs(true);
    };

    const getFilteredInputs = (inputs: InputBindingAppInput[]) =>
        inputs.filter(input => input.name.toLowerCase().includes(store.topicsSearchTerm.toLowerCase()));

    const getBindingsContent = () => {
        if (store.isLoadingInputBindingsMetadata) {
            return <Spin />;
        }

        return (
            <Collapse>
                {store.inputBindingsMetadata.map(app => {
                    if (app instanceof AppDefInputBindingMetadata) {
                        const filteredInputs = getFilteredInputs(app.inputs);

                        if (filteredInputs.length === 0) {
                            return null; // Don't render empty apps (no inputs match the search term)
                        }

                        return (
                            <Collapse.Panel header={app.applicationName} key={app.applicationId!}>
                                <Collapse>
                                    {getAppSections(app.inputs).map(section => {
                                        const filteredBySections = filteredInputs.filter(
                                            input => input.section === section
                                        );
                                        if (filteredBySections.length === 0) {
                                            return null; // Don't render empty sections (no inputs match the search term)
                                        }

                                        return (
                                            <Collapse.Panel header={section} key={section}>
                                                {filteredBySections.map(input => (
                                                    <div key={input.inputId}>
                                                        <Checkbox
                                                            onChange={e =>
                                                                handleTopicChange(e, input.inputId, app.applicationId)
                                                            }
                                                            checked={
                                                                store.selectedInputBindings.includes(input.inputId) &&
                                                                app.applicationId === store.selectedAppForBindings
                                                            }
                                                            disabled={
                                                                store.selectedAppForBindings !== '' &&
                                                                store.selectedAppForBindings !== app.applicationId &&
                                                                hasValidTopcs
                                                            }
                                                        >
                                                            {input.name}
                                                        </Checkbox>
                                                    </div>
                                                ))}
                                            </Collapse.Panel>
                                        );
                                    })}
                                </Collapse>
                            </Collapse.Panel>
                        );
                    }

                    if (app instanceof AppDefConditionalInputBindingMetadata) {
                        return (
                            <Collapse.Panel header={app.applicationName} key={app.applicationId!}>
                                <Collapse>
                                    {app.inputGroups.map(inputGroup => (
                                        <Collapse.Panel
                                            header={inputGroup.inputGroupName}
                                            key={inputGroup.inputGroupId}
                                        >
                                            <Collapse>
                                                {getAppSections(inputGroup.inputs).map(section => {
                                                    const filteredInputs = getFilteredInputs(inputGroup.inputs);

                                                    const filteredBySections = filteredInputs.filter(
                                                        input => input.section === section
                                                    );

                                                    if (filteredBySections.length === 0) {
                                                        return null; // Don't render empty sections (no inputs match the search term)
                                                    }

                                                    return (
                                                        <Collapse.Panel
                                                            header={section}
                                                            key={`${inputGroup.inputGroupId}_${section}`}
                                                        >
                                                            {filteredBySections.map(input => (
                                                                <div key={input.inputId}>
                                                                    <Checkbox
                                                                        onChange={e =>
                                                                            handleTopicChange(
                                                                                e,
                                                                                input.inputId,
                                                                                app.applicationId,
                                                                                inputGroup.inputGroupId
                                                                            )
                                                                        }
                                                                        checked={
                                                                            store.selectedInputBindings.includes(
                                                                                input.inputId
                                                                            ) &&
                                                                            store.selectedInputGroupForBindings ===
                                                                                inputGroup.inputGroupId
                                                                        }
                                                                        disabled={
                                                                            store.selectedInputGroupForBindings !==
                                                                                '' &&
                                                                            store.selectedInputGroupForBindings !==
                                                                                inputGroup.inputGroupId &&
                                                                            hasValidTopcs
                                                                        }
                                                                    >
                                                                        {input.name}
                                                                    </Checkbox>
                                                                </div>
                                                            ))}
                                                        </Collapse.Panel>
                                                    );
                                                })}
                                            </Collapse>
                                        </Collapse.Panel>
                                    ))}
                                </Collapse>
                            </Collapse.Panel>
                        );
                    }

                    return null;
                })}
            </Collapse>
        );
    };

    const getFieldBindingContent = () => {
        if (store.isLoadingFieldBindingGroups) {
            return <Spin />;
        }

        return (
            <Collapse>
                {store.fieldBindingGroups.map(group => {
                    const filteredInputs = group.fields.filter(input =>
                        input.name.toLowerCase().includes(store.topicsSearchTerm.toLowerCase())
                    );

                    if (filteredInputs.length === 0) {
                        return null; // Don't render empty apps (no inputs match the search term)
                    }

                    return (
                        <Collapse.Panel header={group.name} key={group.id!}>
                            <Collapse>
                                {group.sections.map(section => {
                                    const filteredBySections = _.uniqBy(
                                        filteredInputs.filter(input => input.sectionId === section.sectionId),
                                        'inputId'
                                    );
                                    if (filteredBySections.length === 0) {
                                        return null; // Don't render empty sections (no inputs match the search term)
                                    }

                                    return (
                                        <Collapse.Panel header={section.name} key={section.sectionId}>
                                            {filteredBySections.map(input => (
                                                <div key={input.inputId}>
                                                    <Checkbox
                                                        onChange={e =>
                                                            store.toggleTopicProjectField(
                                                                input.inputId,
                                                                e.target.checked
                                                            )
                                                        }
                                                        checked={store.selectedProjectFields.includes(input.inputId)}
                                                    >
                                                        {input.name}
                                                    </Checkbox>
                                                </div>
                                            ))}
                                        </Collapse.Panel>
                                    );
                                })}
                            </Collapse>
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        );
    };

    const getContent = () => {
        switch (store.topicInputsType) {
            case 'Tags':
                return getTagsContent();
            case 'Bindings':
                return getBindingsContent();
            case 'ProjectFields':
                return getFieldBindingContent();
            default:
                return null;
        }
    };

    const getAppSections = (inputs: InputBindingAppInput[]) => [...new Set(inputs.map(i => i.section))];

    const handleTopicDeselect = (id: string) => {
        switch (store.topicInputsType) {
            case 'Tags':
                store.toggleTopicTag(id, false);
                break;
            case 'Bindings':
                store.toggleInputBinding(id, false, store.selectedAppForBindings);
                break;
            case 'ProjectFields':
                store.toggleTopicProjectField(id, false);
                break;
            default:
                break;
        }
    };

    const getBindingTopicName = (id: string) => {
        const app = store.inputBindingsMetadata.find(a => a.applicationId === store.selectedAppForBindings);

        if (app instanceof AppDefInputBindingMetadata) {
            return app.inputs.find(i => i.inputId === id)?.name;
        }

        if (app instanceof AppDefConditionalInputBindingMetadata) {
            const inputGroup = app.inputGroups.find(g => g.inputGroupId === store.selectedInputGroupForBindings);
            return inputGroup?.inputs.find(i => i.inputId === id)?.name;
        }

        return null;
    };

    const getTopicName = (id: string) => {
        switch (store.topicInputsType) {
            case 'Tags':
                return store.currentProjectTags.find(t => t.id === id)?.name;
            case 'Bindings':
                return getBindingTopicName(id);
            case 'ProjectFields':
                return _.flatten(store.fieldBindingGroups.map(g => g.fields)).find(f => f.inputId === id)?.name;
            default:
                return null;
        }
    };

    const getTopicTitle = (item: string) => {
        const name = getTopicName(item);

        if (name) {
            return name;
        }

        return <span style={{ color: 'red' }}>{item}</span>;
    };

    const onSelectedTopicsAutocompleteSearch = (value: string) => {
        setSelectedTopicsAutocomplete(value);
        setSelectedTopicsFilter(value);
    };

    return (
        <Tabs>
            <Tabs.TabPane tab="Search topics" key="1">
                <div>
                    <Radio
                        name="topicInputType"
                        value="Bindings"
                        checked={store.topicInputsType === 'Bindings'}
                        onChange={e => store.setTopicInputsType(e.target.value)}
                    >
                        IOTA App Bindings
                    </Radio>
                    <Radio
                        name="topicInputType"
                        value="Tags"
                        checked={store.topicInputsType === 'Tags'}
                        onChange={e => store.setTopicInputsType(e.target.value)}
                    >
                        Tags
                    </Radio>
                    <Radio
                        name="topicInputType"
                        value="ProjectFields"
                        checked={store.topicInputsType === 'ProjectFields'}
                        onChange={e => store.setTopicInputsType(e.target.value)}
                    >
                        Project Fields
                    </Radio>
                </div>
                <br />
                <div>
                    <Input.Search
                        placeholder="Search topics..."
                        onChange={e => store.setTopicsSearchTerm(e.target.value)}
                    />
                </div>
                <br />
                <div style={{ maxHeight: 'calc(100vh - 380px)', overflow: 'auto' }}>{getContent()}</div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Selected topics" key="2">
                <Row gutter={16} style={{ marginTop: 5 }}>
                    <Col span={12}>
                        <AutoComplete
                            style={{ width: '100%' }}
                            options={store.topics
                                .map(t => getTopicName(t) ?? '')
                                .filter(t => t.toLowerCase().includes(selectedTopicsAutocomplete.toLowerCase()))
                                .map(topicName => ({
                                    value: topicName,
                                    label: <span style={{ whiteSpace: 'pre' }}>{topicName}</span>
                                }))}
                            value={selectedTopicsAutocomplete}
                            onSearch={setSelectedTopicsAutocomplete}
                            placeholder="Search topics..."
                            onSelect={onSelectedTopicsAutocompleteSearch}
                            onClear={() => onSelectedTopicsAutocompleteSearch('')}
                            allowClear
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    onSelectedTopicsAutocompleteSearch(selectedTopicsAutocomplete);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 0 }} />
                <List
                    style={{ maxHeight: 'calc(100vh - 400px)', overflowY: 'auto' }}
                    dataSource={
                        selectedTopicsFilter
                            ? store.topics.filter(t =>
                                  getTopicName(t)?.toLowerCase().includes(selectedTopicsFilter.toLowerCase())
                              )
                            : store.topics
                    }
                    renderItem={item => (
                        <List.Item
                            actions={[
                                <Button
                                    key={item + '-delete'}
                                    type="link"
                                    icon={<CloseOutlined />}
                                    onClick={() => handleTopicDeselect(item)}
                                />
                            ]}
                        >
                            <List.Item.Meta title={getTopicTitle(item)} />
                        </List.Item>
                    )}
                />
            </Tabs.TabPane>
        </Tabs>
    );
};

export default observer(TopicSelectionStep);
