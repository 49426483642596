import appClient from '../../common/services/AppClient';
import { BindingGroup, BindingGroupSection, FieldBinding } from '../types';
import security from '../../common/services/SecurityService';
import { Utils } from '../../common/services/Utils';
import { EvaluateInputOnPackageResponse } from '../types/EvaluateInputOnPackageResponse';

export default class FieldBindingsService {
    async getProjectFieldGroups(projectId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projectFields/groups?projectId=${projectId}`;
        const resp = await appClient.get<BindingGroup[]>(url);
        return resp.unwrapOr(Array<BindingGroup>());
    }

    async createProjectFieldsGroup(projectId: string, name: string, alias: string) {
        const request = {
            projectId,
            name,
            alias
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'projectFields/groups';
        return await appClient.post(url, request);
    }

    async updateProjectFieldsGroup(
        groupId: string,
        name: string,
        alias: string,
        fields?: FieldBinding[],
        sections?: BindingGroupSection[]
    ) {
        const request = {
            id: groupId,
            name,
            fields,
            alias,
            sections
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'projectFields/groups';
        return await appClient.update(url, request);
    }

    async deleteProjectFieldGroup(groupId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projectFields/groups?groupId=${groupId}`;
        return await appClient.delete(url);
    }

    async createProjectFieldsSection(groupId: string, name: string) {
        const request = {
            groupId,
            name
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'projectFields/sections';
        return await appClient.post(url, request);
    }

    async createField(groupId: string, name: string, sectionId?: string) {
        const request = {
            sectionId,
            groupId,
            name
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'projectFields';
        return await appClient.post(url, request);
    }

    async deleteProjectFieldGroupSection(groupId: string, sectionId: string) {
        const url =
            process.env.REACT_APP_MANAGE_URL + `projectFields/sections?groupId=${groupId}&sectionId=${sectionId}`;
        return await appClient.delete(url);
    }

    async updateProjectFieldsGroupSection(id: string, groupId: string, name: string) {
        const request = {
            id,
            name,
            groupId
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'projectFields/sections';
        return await appClient.update(url, request);
    }

    async deleteProjectField(fieldId: string, groupId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projectFields?fieldId=${fieldId}&groupId=${groupId}`;
        return await appClient.delete(url);
    }

    async updateProjectField(
        inputId: string,
        groupId: string,
        sectionId: string,
        name: string,
        type: string,
        value?: string
    ) {
        const request = {
            inputId,
            name,
            groupId,
            sectionId,
            type,
            value
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'projectFields';
        return await appClient.update(url, request);
    }

    async updateProjectFieldGroupsOrder(projectId: string, groupOrders: { [groupId: string]: number }) {
        const request = {
            projectId,
            groupOrders
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'projectFields/groups-order';
        return await appClient.post(url, request);
    }

    async exportProjectFields(projectId: string, projectFieldGroupIds?: string[]) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'projectFieldsPostForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';

        if (projectFieldGroupIds && projectFieldGroupIds.length) {
            projectFieldGroupIds.forEach(id => {
                const idInput = document.createElement('input');
                idInput.type = 'text';
                idInput.name = 'ids[]';
                idInput.value = id;
                mapForm.appendChild(idInput);
            });
        }

        await security.invoke(token => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url = process.env.REACT_APP_MANAGE_URL + `projectFields/export${t}&projectId=${projectId}`;
            Utils.downloadFile(url, mapForm, 'projectFieldsPostForm');
            return Promise.resolve();
        });
    }

    async getFieldsBindingResults(groupId: string, inputId: string, packageId: string) {
        const url = `${process.env.REACT_APP_MANAGE_URL}field-bindings/evaluate-field`;
        const request = {
            groupId,
            inputId,
            packageId
        };
        const resp = await appClient.post<EvaluateInputOnPackageResponse>(url, request);
        if (resp.isOk()) {
            return resp.unwrapOr({} as EvaluateInputOnPackageResponse);
        }
        const errMsg = `${resp.error?.text}: ${resp.error?.data?.title}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return resp.unwrapOr({ values: [{ value: errMsg, bindingIndex: 0 }], packageId } as any);
    }
}
