import { Input, Modal, Button, Upload, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { UserProfileStore } from '../stores';
import * as React from 'react';
import { RcFile } from 'antd/lib/upload';
import UserProfileModel from '../models/UserProfileModel';
import { PlusOutlined } from '@ant-design/icons';
import { EMAIL_REGEX_PATTERN } from '../../../modules/common/Utils';

type Props = {
    store: UserProfileStore;
};

const UserProfileEditForm: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();
    const [imageUrl, setimageUrl] = React.useState('');

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                const model = new UserProfileModel(
                    values.firstName,
                    values.lastName,
                    values.email,
                    values.timezone,
                    values.avatarIcon?.length ? values.avatarIcon[0].originFileObj : null
                );
                store!.updateUserProfile(model);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleCancel = () => {
        store!.setEditUserProfileVisible(false);
        setimageUrl('');
    };

    const beforeUpload = (file: RcFile) => {
        var reader = new FileReader();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reader.onload = function (e: any) {
            setimageUrl(e.target.result);
        };

        reader.readAsDataURL(file);
        return false;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const isFieldDisabled = !!store.userProfile && store.userProfile.isDomainUser;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <Modal
            title="My profile"
            className="alpha-portal-dialog"
            centered
            closable={false}
            visible={store!.editUserProfileVisible}
            onCancel={handleCancel}
            maskClosable={false}
            forceRender
            footer={[
                <Button
                    data-id="iota-add-connection-dialog-cancel"
                    size="large"
                    key="back"
                    onClick={handleCancel}
                    className="light"
                >
                    Cancel
                </Button>,
                <Button
                    data-id="iota-add-connection-dialog-submit"
                    size="large"
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    timezone: store!.userProfile.timezone,
                    firstName: store!.userProfile.firstName,
                    lastName: store!.userProfile.lastName,
                    email: store!.userProfile.email
                }}
            >
                <Form.Item
                    label={<span>Avatar</span>}
                    name="avatarIcon"
                    getValueFromEvent={getFile}
                    valuePropName="fileList"
                >
                    <Upload
                        name="application-icon"
                        accept=".jpeg,.jpg,.png"
                        listType="picture-card"
                        className="icon-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        maxCount={1}
                    >
                        {imageUrl || store.imageUrl ? (
                            <img src={imageUrl || store.imageUrl} alt="application-icon" />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: 'Please input First Name!'
                        }
                    ]}
                >
                    <Input size="large" disabled={isFieldDisabled} />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                        {
                            required: true,
                            message: 'Please input Last Name!'
                        }
                    ]}
                >
                    <Input size="large" disabled={isFieldDisabled} />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input email!'
                        },
                        {
                            pattern: EMAIL_REGEX_PATTERN,
                            message: 'Input valid email address please.'
                        }
                    ]}
                >
                    <Input size="large" disabled={isFieldDisabled} />
                </Form.Item>
                {/* <Form.Item
                {...formItemLayout}
                label="Timezone"
            >
            {getFieldDecorator('timezone', {
                rules: [{
                    required: false, message: 'Please input timezone!',
                }],
            })(
                <Input />
            )}
            </Form.Item> */}
            </Form>
        </Modal>
    );
};

export default observer(UserProfileEditForm);
