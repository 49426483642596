import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { FlexContentContainer } from '../../common/components';
import { RulesStores } from '../stores';
import { STORE_RULES_NEW } from '../constants';

type Props = RulesStores;

const RuleNewPage: React.SFC<Props> = () => {
    function getContent() {
        return null;
    }

    return <FlexContentContainer content={getContent()} />;
};

export default inject(STORE_RULES_NEW)(observer(RuleNewPage));
