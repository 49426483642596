import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from 'antd';
import { WorkflowTypeInputDto } from '../../types';
import WorkflowTextInputCell from './WorkflowTextInputCell';
import InstructWorkflowModel from '../../model/InstructWorkflowModel';

interface Props {
    workflow: InstructWorkflowModel;
}

const WorkflowTextInputs: React.FC<Props> = ({ workflow }) => {
    if (!workflow.workflowType || !workflow.workflowType.textInputs.length) {
        return null;
    }

    const defaultColumns = [
        {
            title: 'Label',
            dataIndex: 'name',
            width: 200
        },
        {
            title: 'Value',
            dataIndex: 'value',
            editable: true,
            width: 350
        }
    ];

    const columns = defaultColumns.map(column => {
        if (!column.editable) {
            return column;
        }

        return {
            ...column,
            onCell: (input: WorkflowTypeInputDto) => ({
                input,
                editable: column.editable,
                dataIndex: column.dataIndex,
                title: column.title,
                workflow
            })
        };
    });

    const components = {
        body: {
            cell: WorkflowTextInputCell
        }
    };

    return (
        <div>
            <Table
                rowClassName={() => 'editable-row'}
                rowKey={(input: WorkflowTypeInputDto) => input.id}
                dataSource={workflow.workflowType.textInputs}
                components={components}
                columns={columns}
                pagination={false}
                bordered
            />
        </div>
    );
};

export default observer(WorkflowTextInputs);
