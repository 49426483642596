import * as React from 'react';
import { Popover } from 'antd';

export interface TextPopoverProps {
    text: string;
    popoverText: string;
}

const initialState = {
    isPopoverVisible: false
};

type State = Readonly<typeof initialState>;

export class TextPopover extends React.Component<TextPopoverProps, State> {
    readonly state: State = initialState;

    render() {
        return (
            // eslint-disable-next-line max-len
            <Popover
                overlayStyle={{ width: '20%', fontSize: 11 }}
                placement="left"
                content={<div style={{ maxHeight: 400, overflow: 'auto' }}>{this.props.popoverText}</div>}
            >
                <span style={{ whiteSpace: 'nowrap' }}>{this.props.text}</span>
            </Popover>
        );
    }
}
export default TextPopover;
