import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Table, Collapse, Select, Form } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { ColumnProps } from 'antd/lib/table';
import { InstructWorkflowMapperTableDto } from '../../types';
import { WorkflowImportFormValues } from '../../types';
import InstructWorkflowImportExportStore from '../../stores/InstructWorkflowImportExportStore';
import './WorkflowImportMapperTable.less';

interface Props {
    importExportStore: InstructWorkflowImportExportStore;
    form: FormInstance<WorkflowImportFormValues>;
}

const WorkflowImportMapperTable: React.FC<Props> = ({ importExportStore }) => {
    if (!importExportStore.workflowImportData) {
        return null;
    }

    const columns: ColumnProps<InstructWorkflowMapperTableDto>[] = [
        { title: 'Name', key: 'name', dataIndex: 'name', width: '30%' },
        { title: 'Type', key: 'type', dataIndex: 'type', width: '20%' },
        { key: 'changeTo', dataIndex: 'changeTo', width: '20%', render: () => <span>change to</span> },
        {
            title: 'Name',
            key: 'modelId',
            dataIndex: 'modelId',
            width: '30%',
            render: (_: string, record: InstructWorkflowMapperTableDto) => {
                return {
                    children: (
                        <Form.Item
                            name={['mlModels', record.id]}
                            initialValue={record.bestValue}
                            rules={[{ required: true, message: 'Please select model!' }]}
                        >
                            <Select
                                options={record.altOptions.map(o => ({ value: o.id, label: o.name, title: o.name }))}
                                filterOption={(input, option) =>
                                    option?.title && option.title.toLowerCase().includes(input.toLowerCase())
                                }
                                showSearch
                            />
                        </Form.Item>
                    )
                };
            }
        }
    ];

    return (
        <Collapse
            bordered={false}
            expandIconPosition="right"
            className="workflow-import-mapper-table-collapse"
            defaultActiveKey={['mlModels']}
        >
            <Collapse.Panel key="mlModels" header="ML Models">
                <Table
                    className="alpha-portal-table workflow-import-mapper-table"
                    columns={columns}
                    rowKey={(record: InstructWorkflowMapperTableDto) => record.id}
                    dataSource={importExportStore.workflowImportData.mapperTableData.filter(
                        d => d.tableType === 'MlModel'
                    )}
                    pagination={false}
                />
            </Collapse.Panel>
        </Collapse>
    );
};

export default observer(WorkflowImportMapperTable);
