import { WorkflowOutputSchemeType } from '../types';

type WorkflowOutputSchemeTypeOption = { value: WorkflowOutputSchemeType; label: string };

export const workflowOutputSchemeTypeOptions: WorkflowOutputSchemeTypeOption[] = [
    {
        value: 'JsonScheme',
        label: 'Json Scheme'
    },
    {
        value: 'PythonDict',
        label: 'Python Dict'
    }
];
