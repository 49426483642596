import * as React from 'react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { STORE_REFERENCE_DATA, STORE_REFERENCE_DATA_USAGE } from '../constant';
import { IotaReferenceDataStores } from '../stores';
import ReferenceDataTable from '../components/ReferenceDataTable';
type Props = IotaReferenceDataStores;

const ReferenceDataPage: React.FC<Props> = ({ ReferenceDataUI: store, ReferenceDataUsageUI }) => {
    React.useEffect(() => {
        store!.getConnections();
    }, [store]);

    return (
        <FlexContentContainer
            style={{ overflow: 'hidden' }}
            content={<ReferenceDataTable ReferenceDataUI={store!} ReferenceDataUsageUI={ReferenceDataUsageUI!} />}
        />
    );
};

export default inject(STORE_REFERENCE_DATA, STORE_REFERENCE_DATA_USAGE)(observer(ReferenceDataPage));
