import * as React from 'react';
import { ProtectedRoute } from '../../common/components';
import UploadedPackagesPage from '../screens/UploadedPackagesPage';
import AdministrationProjectPage from '../screens/AdministrationProjectPage';
import { PackageProblemReportsPage } from '../screens';

export const ProjectPagesNavigation = {
    AddProjectPage: '/projects/add-project',
    ProjectsPage: '/projects',
    GlobalAdministrationPage: '/admin',
    IndexingPage: '/admin/indexing',
    CodeSnippetsPage: '/admin/code-snippets',
    AlphaConfigPage: '/admin/alpha-configs',
    TechnicalInformationPage: '/admin/technical-information',
    ProjectMetadataPackagesPage: '/projects/:id/metadata/uploadedPackages',
    ProjectAdministrationPage: '/projects/:id/metadata/administration',
    ProjectMetadataPage: '/projects/:id/metadata',
    PackageProblemReportsPage: '/projects/:projectId/problemReports'
};

export default () => {
    return (
        <React.Fragment>
            <ProtectedRoute
                component={UploadedPackagesPage}
                path={ProjectPagesNavigation.ProjectMetadataPackagesPage}
            />
            <ProtectedRoute
                component={AdministrationProjectPage}
                path={ProjectPagesNavigation.ProjectAdministrationPage}
            />
            <ProtectedRoute
                component={PackageProblemReportsPage}
                path={ProjectPagesNavigation.PackageProblemReportsPage}
            />
        </React.Fragment>
    );
};
