import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal, Form, Input, Upload, Select, Checkbox, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { ProjectsStore, ProjectsRootVisualStore } from '../../common/stores';
import ProjectImportStore from '../stores/ProjectImportStore';

interface Props {
    store: ProjectImportStore;
    projectsStore: ProjectsStore;
    projectsVisualStore: ProjectsRootVisualStore;
}

interface FormValues {
    projectFile?: File;
    projectName?: string;
    projectId?: string;
}

const ProjectImportDialog: React.FC<Props> = ({ store, projectsStore, projectsVisualStore }) => {
    const [form] = useForm<FormValues>();

    const importProject = async (values: FormValues) => {
        if (!values.projectFile) {
            return;
        }

        const formData = new FormData();

        formData.append('file', values.projectFile);

        if (values.projectName) {
            formData.append('projectName', values.projectName);
        }

        if (values.projectId) {
            formData.append('projectId', values.projectId);
        }

        onClose();

        store.setImportInProgress(true);

        try {
            const resp = await store.importProject(formData);

            if (!resp.isOk()) {
                message.error('Project import failed');
                return;
            }

            resp.map(async data => {
                message.success(`Project ${data.importProject.name} imported successfully`);

                await projectsStore.loadProjects();

                if (!projectsVisualStore.currentProject) {
                    return;
                }

                if (projectsVisualStore.currentProject.id === data.importProject.id) {
                    const project = projectsStore.projects.find(p => p.id === data.importProject.id) || null;
                    projectsVisualStore.setCurrentProject(project);
                }
            });
        } finally {
            store.setImportInProgress(false);
        }
    };

    const onClose = () => {
        store.setDialogVisible(false);
        store.setCustomizeProjectName(false);
        store.setImportIntoExistingProject(false);
        form.resetFields();
    };

    React.useEffect(() => {
        if (!store.customizeProjectName) {
            form.resetFields(['projectName']);
        }

        if (!store.importIntoExistingProject) {
            form.resetFields(['projectId']);
        }
    }, [form, store, store.customizeProjectName, store.importIntoExistingProject]);

    return (
        <>
            <Button
                type="link"
                className="import-project-button"
                data-id="import-project-button"
                disabled={store.importInProgress}
                onClick={() => store.setDialogVisible(true)}
            >
                {store.importInProgress ? (
                    <Spin indicator={<LoadingOutlined />} size="small" style={{ marginLeft: 4, marginRight: 10 }} />
                ) : (
                    <i
                        className="alpha-icon xs arrow-down"
                        style={{ marginLeft: 4, marginRight: 8, verticalAlign: 'middle' }}
                    />
                )}

                <span style={{ verticalAlign: 'middle', color: 'white' }}>Import project</span>
            </Button>

            <Modal
                centered
                forceRender
                width={600}
                title="Import Project"
                destroyOnClose
                closable={false}
                maskClosable={false}
                visible={store.dialogVisible}
                className="alpha-portal-dialog"
                footer={[
                    <Button
                        data-id="project-import-cancel"
                        key="project-import-cancel"
                        size="large"
                        className="light"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>,
                    <Button
                        data-id="project-import"
                        key="project-import"
                        size="large"
                        type="primary"
                        htmlType="submit"
                        form="import-project-form"
                    >
                        Import
                    </Button>
                ]}
            >
                <Form
                    id="import-project-form"
                    data-id="import-project-form"
                    layout="vertical"
                    form={form}
                    onFinish={importProject}
                >
                    <Form.Item style={{ marginBottom: 12 }}>
                        <Checkbox
                            checked={store.customizeProjectName}
                            onChange={() => store.setCustomizeProjectName(!store.customizeProjectName)}
                        >
                            Customize project name
                        </Checkbox>

                        {store.customizeProjectName && (
                            <Form.Item
                                style={{ marginTop: 6, marginBottom: 6 }}
                                name="projectName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter project name!',
                                        whitespace: true
                                    }
                                ]}
                            >
                                <Input placeholder="Enter project name" size="large" />
                            </Form.Item>
                        )}
                    </Form.Item>

                    <Form.Item style={{ marginBottom: 18 }}>
                        <Checkbox
                            checked={store.importIntoExistingProject}
                            onChange={() => store.setImportIntoExistingProject(!store.importIntoExistingProject)}
                        >
                            Import into existing project
                        </Checkbox>

                        {store.importIntoExistingProject && (
                            <Form.Item
                                style={{ marginTop: 6, marginBottom: 6 }}
                                name="projectId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select project!'
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select project..."
                                    options={projectsStore.projects.map(p => ({
                                        value: p.id,
                                        label: p.title,
                                        title: p.title
                                    }))}
                                    filterOption={(input, option) =>
                                        option &&
                                        option.title &&
                                        option.title.toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        )}
                    </Form.Item>

                    <Form.Item
                        name="projectFile"
                        valuePropName="file"
                        getValueFromEvent={e => {
                            if (!e.file || !e.fileList || !e.fileList.length) {
                                return;
                            }

                            return e.file;
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Please upload project file!'
                            }
                        ]}
                    >
                        <Upload.Dragger
                            name="file"
                            className="alpha-portal-upload-dragger"
                            beforeUpload={() => false}
                            maxCount={1}
                        >
                            <p className="ant-upload-hint">
                                <i className="alpha-icon lg upload-icon" />
                                Drag and drop file here to upload...
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default observer(ProjectImportDialog);
