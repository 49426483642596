import { Button, message } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import EncryptionService from '../services/EncryptionService';

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changeHandler: (value: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialValue?: any;
};

const EncyptedInput: React.FC<Props> = ({ children, changeHandler, initialValue }) => {
    const [showInput, setShowInput] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const service = new EncryptionService();

    const handleClick = () => {
        if (initialValue) {
            setIsLoading(true);

            service
                .decryptData(initialValue)
                .then(r => {
                    if (r.isOk()) {
                        r.map(v => {
                            changeHandler(v.value);
                            setIsLoading(false);
                            setShowInput(true);
                        });
                    }
                })
                .catch(err => {
                    setIsLoading(false);
                    message.error('Error during decryption');
                    console.error(err);
                });
        } else {
            setShowInput(true);
        }
    };

    return showInput ? (
        <>{children}</>
    ) : (
        <Button loading={isLoading} onClick={handleClick} style={{ width: '100%' }}>
            <span style={{ color: '#273C75', fontSize: 12 }}>Show</span>
        </Button>
    );
};

export default observer(EncyptedInput);
