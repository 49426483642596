import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { SectionModel } from '../../models';
import './SectionExtraRenderer.less';

interface Props {
    section: SectionModel;
}

const SectionExtraRenderer: React.FC<Props> = ({ section }) => {
    return (
        <div className="section-extra-renderer">
            <Button
                type="link"
                size="small"
                onClick={e => {
                    e.stopPropagation();
                    section.setEditable(!section.editable);
                }}
            >
                <EditOutlined />
            </Button>

            <div onClick={e => e.stopPropagation()}>
                <Switch size="small" checked={section.enabled} onChange={section.setEnabled} />
            </div>
        </div>
    );
};

export default observer(SectionExtraRenderer);
