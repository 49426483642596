import { observable, action, runInAction } from 'mobx';
import ErrorStore from '../../common/stores/ErrorStore';
import { GlobalAdministrationService } from '../service/GlobalAdministrationService';
import { ProcessType } from '../../../modules/common/types';

export class GlobalAdministrationStore {
    @observable
    isLoading: boolean = false;

    optionsValue: string = 'All';
    constructor(
        private errorStore: ErrorStore,
        private service: GlobalAdministrationService
    ) {}

    @action
    async processByStatus(processType: ProcessType) {
        try {
            this.isLoading = true;
            await this.service.processByStatus(this.optionsValue, processType);
        } catch (err) {
            this.errorStore.addError(err);
        }

        runInAction(() => (this.isLoading = false));
    }

    handleOptionsChange(value: string) {
        this.optionsValue = value;
    }
}

export default GlobalAdministrationStore;
