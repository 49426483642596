/* eslint-disable react/no-deprecated */
import { Table, Button, Popover, Popconfirm, Layout, Upload, message, Tooltip, List } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import * as React from 'react';
import { TagsGroupModel } from '../models/TagsGroupModel';
import NewGroupModal from './NewGroupModal';
import { RulesStores } from '../stores';
import RulesList from './RulesList';
import { Observer } from 'mobx-react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragableBodyRow } from './ItemDraggableRow';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { TagModel } from '../models/TagModel';
import LayoutHeader from '../../../components/LayoutHeader';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import RulesListFilter from './RulesListFilter';
import RulesImportDialog from '../screens/RulesImportDialog';
import { Utils } from '../../common/services/Utils';
import TagsVersionEditDialog from './TagsVersionEditDialog';
import { RuleCreateDialog } from '.';

const { Content } = Layout;
type Props = RulesStores;

const initialState = {
    expandedKeys: [],
    borderStyle: 'unset',
    showImportConfirmationDialog: false
};

const groupComponents = {
    body: {
        row: DragableBodyRow
    }
};

type State = Readonly<typeof initialState>;

export class TagsGroupList extends React.Component<Props, object> {
    readonly groupsStore = this.props.TagsGroupUI!;
    readonly rulesListStore = this.props.RulesListUI!;
    readonly ruleNewStore = this.props.RuleNewUI!;
    readonly state: State = initialState;

    constructor(props: Props) {
        super(props);
        this.handleOnExpand = this.handleOnExpand.bind(this);
        this.moveRow = this.moveRow.bind(this);
        this.getGroupTagsCountLabel = this.getGroupTagsCountLabel.bind(this);
        this.rulesListStore = this.props.RulesListUI!;
    }

    UNSAFE_componentWillMount() {
        this.setState({ expandedKeys: this.groupsStore.expandedKeys });
    }

    componentWillUnMount() {
        this.props.TagsGroupUI!.disposer();
    }

    componentDidMount() {
        const highlightedRows = document.getElementsByClassName('highlight-selected');
        if (highlightedRows.length && highlightedRows.item(0)) {
            highlightedRows.item(0)!.scrollIntoView();
        }

        if (this.rulesListStore?.previewedTag) {
            const row = document.querySelector(`[data-row-key="${this.rulesListStore.previewedTag}"]`);

            if (row) {
                row.scrollIntoView({ behavior: 'smooth', block: 'start' });
                this.rulesListStore.setPreviewedTag(undefined);
            }
        }
    }

    cutText(text: string) {
        let cutText = text;
        if (text.length > 150) {
            cutText = `${text.substring(0, 150)}...`;
        }
        return (
            <Popover content={text} autoAdjustOverflow placement="topRight">
                <span>{cutText}</span>
            </Popover>
        );
    }

    handleOnExpand(expanded: boolean, record: TagsGroupModel) {
        this.groupsStore.setExpandedKeys(expanded, record);
        this.setState({ expandedKeys: this.groupsStore.expandedKeys });
    }

    moveRow(dragIndex: number, dropIndex: number, record: TagModel | TagsGroupModel) {
        if (record && !this.isTagsGroupModel(record)) {
            const { tagsGroups } = this.groupsStore;
            const destGroupId = tagsGroups.find(g => g.position === dropIndex)!.id!;
            if (destGroupId === record.groupId) {
                return;
            }
            this.rulesListStore.moveTagToAnotherGroup(record, destGroupId);
        } else {
            this.groupsStore.updateGroupRowPosition(dragIndex, dropIndex);
        }
    }

    getGroupTagsCountLabel(tagsGroup: TagsGroupModel) {
        const tagsCount = this.rulesListStore.filteredTags.filter(t => t.groupId === tagsGroup.id).length;

        if (tagsCount === 0) {
            return 'No tags';
        }

        return `${tagsCount} tag${tagsCount > 1 ? 's' : ''}`;
    }

    render() {
        const projectId = this.rulesListStore.currentProject && this.rulesListStore.currentProject.id;
        const columns: ColumnProps<TagsGroupModel>[] = [
            {
                title: 'Group',
                key: 'name',
                dataIndex: 'name',
                render: (_, record: TagsGroupModel) => {
                    return {
                        children: (
                            <div className="group-title-wrapper">
                                <div className="group-title">{record.name}</div>
                                <div className="group-subtitle">{this.cutText(record.description)}</div>
                            </div>
                        ),
                        props: {
                            'data-id-value': record.name,
                            'data-id-type': 'group-list',
                            'data-id-cells': 'Group'
                        }
                    };
                }
            },
            {
                key: 'operation',
                render: (_, record: TagsGroupModel) => (
                    <div
                        key={`${record.id}-operations-container`}
                        className="operation-label-container"
                        style={{ textAlign: 'right' }}
                    >
                        <HasPermission
                            entityId={this.rulesListStore?.currentProject?.id}
                            permissionClaim={AppPermissions.CanEditGroupsTags}
                        >
                            <Observer>
                                {() => (
                                    <span key={`${record.id}-operations`} className="table-operation">
                                        <Tooltip title="Add rule">
                                            <Button
                                                data-id-cells="Add rule"
                                                data-id-value=""
                                                data-id-type="group-list"
                                                type="link"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    this.groupsStore.setExpandedKeys(true, record);
                                                    this.ruleNewStore.setIsCreateDialogVisible(true);
                                                    this.ruleNewStore.setCurrentGroupId(record.id!);
                                                }}
                                                size="small"
                                            >
                                                <i className="alpha-icon lg plus-icon" />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Edit group">
                                            <Button
                                                data-id-cells="Edit group"
                                                data-id-value=""
                                                data-id-type="group-list"
                                                type="link"
                                                onClick={event => {
                                                    this.groupsStore.edit(record);
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                }}
                                                size="small"
                                            >
                                                <i className="alpha-icon lg edit-icon" />
                                            </Button>
                                        </Tooltip>
                                        <Popconfirm
                                            id={`data-id-popconfirm-box-${record.name}`}
                                            title={
                                                <div style={{ width: 500 }}>
                                                    {this.groupsStore.deleteConfirmationText[record.id!]}
                                                </div>
                                            }
                                            okText="Yes"
                                            onConfirm={() => this.groupsStore.delete(record.id!)}
                                            cancelText="No"
                                            onCancel={e => e!.stopPropagation()}
                                        >
                                            <Tooltip title="Delete group">
                                                <Button
                                                    onClick={e => e.stopPropagation()}
                                                    data-id-cells="Delete group"
                                                    data-id-value=""
                                                    data-id-type="group-list"
                                                    type="link"
                                                    size="small"
                                                >
                                                    <i className="alpha-icon lg delete-icon" />
                                                </Button>
                                            </Tooltip>
                                        </Popconfirm>
                                        <i
                                            className="alpha-icon md rule-problem-icon"
                                            style={{
                                                display: this.groupsStore!.groupHasBrokenRules(record.id!)
                                                    ? 'visible'
                                                    : 'none',
                                                marginBottom: 2,
                                                marginLeft: 10
                                            }}
                                        />
                                    </span>
                                )}
                            </Observer>
                        </HasPermission>
                        <span className="operation-info-label gray">
                            {this.getGroupTagsCountLabel(record)}
                            <i
                                className="alpha-icon md rule-problem-icon"
                                style={{
                                    display: this.groupsStore!.groupHasBrokenRules(record.id!) ? 'visible' : 'none',
                                    marginTop: 2,
                                    verticalAlign: 'middle',
                                    marginLeft: 10
                                }}
                            />
                        </span>
                    </div>
                )
            }
        ];

        const onChange = (info: UploadChangeParam) => {
            const status = info.file.status;
            const store = this.props.RulesImportUI!;
            if (status === 'done') {
                const rulesResponse = info.file.response;
                store.setTableData(rulesResponse);
                message.success(`${info.file.name} file uploaded successfully.`);
                const extension = info.file.name.split('.').pop()?.toLowerCase();
                this.setState({ showImportConfirmationDialog: extension === 'tags' });
            } else if (status === 'error') {
                if (info.file?.response?.status === 409) {
                    message.warning(info.file.response.title);
                    return;
                }
                message.error(`${info.file.name} file upload failed.`);
            }
        };

        const getTitle = () => {
            return (
                <div className="page-header-with-controls">
                    <span>Tags </span>
                    <span className="secondary-info highlight-green">
                        {this.rulesListStore!.currentProject?.tagsVersion
                            ? `${this.rulesListStore!.currentProject?.tagsVersion?.version} (${this.rulesListStore!.currentProject?.tagsVersion?.versionHash})`
                            : null}
                    </span>
                    <HasPermission
                        entityId={this.rulesListStore?.currentProject?.id}
                        key="rules-export"
                        permissionClaim={AppPermissions.CanEditImportExportRules}
                    >
                        <Tooltip title="Edit version">
                            <Button type="link" size="small">
                                <i
                                    className="alpha-icon md edit-icon"
                                    onClick={() => this.rulesListStore!.setTagsVersionDialogVisible(true)}
                                />
                            </Button>
                        </Tooltip>
                    </HasPermission>
                </div>
            );
        };

        const handleBeforeUpload = async (file: RcFile) => {
            if (file.name.endsWith('.rules') && !this.props.RulesImportUI!.handleBeforeUpload()) {
                return Promise.reject();
            }
            return this.rulesListStore.setHeaders();
        };

        const getPinnedRulesTitle = () => {
            if (!this.rulesListStore.pinnedRulesInProject.length) {
                return null;
            }

            return `${this.rulesListStore.pinnedRulesInProject.length} pinned rule${this.rulesListStore.pinnedRulesInProject.length > 1 ? 's' : ''}`;
        };

        const getPinnedRulesPopoverContent = () => {
            if (!this.rulesListStore.pinnedRulesInProject?.length) {
                return null;
            }

            return (
                <List className="pinned-rules-list" style={{ width: 320 }}>
                    {this.rulesListStore.pinnedRulesInProject.map(ruleId => {
                        const rule = this.rulesListStore.rules.find(r => r.id === ruleId);

                        if (!rule) {
                            return null;
                        }

                        return (
                            <List.Item
                                key={rule.id}
                                actions={[
                                    <i
                                        style={{ cursor: 'pointer' }}
                                        key={`${rule.id}-unpin`}
                                        className="alpha-icon lg unpin-icon"
                                        onClick={() => this.rulesListStore.unpinRule(rule.id!)}
                                    />
                                ]}
                            >
                                <List.Item.Meta
                                    title={
                                        <>
                                            <div className="meta-subtitle">
                                                {this.groupsStore.getTagGroupById(rule.groupId ?? '')?.name} /{' '}
                                                {rule.tag}
                                            </div>
                                            <div
                                                className="hyperlink-title"
                                                onClick={() => this.rulesListStore.edit(rule.id!)}
                                            >
                                                {rule.name}
                                            </div>
                                        </>
                                    }
                                    description={rule.description}
                                />
                            </List.Item>
                        );
                    })}
                </List>
            );
        };

        /* eslint-disable max-len */
        const getTable = (hasPermission: boolean) => {
            const action = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/rules/import`;

            return (
                <Observer>
                    {() => (
                        <Layout
                            className="screen-size"
                            style={{ ...{ height: '100%', background: 'white', overflow: 'hidden' } }}
                        >
                            <LayoutHeader
                                subtitle={Utils.getSubtitle(this.rulesListStore!.currentProject)}
                                title={getTitle()}
                                buttons={[
                                    <React.Fragment key="pinned-rules-popover">
                                        {this.rulesListStore.pinnedRulesInProject?.length ? (
                                            <Popover content={getPinnedRulesPopoverContent()} trigger={['click']}>
                                                <Button
                                                    data-id="Pinned rules"
                                                    type="link"
                                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                                    icon={
                                                        <i
                                                            className="alpha-icon lg pin-icon"
                                                            style={{ verticalAlign: 'middle', marginRight: 6 }}
                                                        />
                                                    }
                                                >
                                                    {getPinnedRulesTitle()}
                                                </Button>
                                            </Popover>
                                        ) : null}
                                    </React.Fragment>,
                                    <HasPermission
                                        entityId={this.rulesListStore?.currentProject?.id}
                                        key="rules-export"
                                        permissionClaim={AppPermissions.CanEditImportExportRules}
                                    >
                                        <span
                                            data-id="button-Export"
                                            key="export-button-container"
                                            className={'headerButton rule-export'}
                                            onClick={() => this.rulesListStore.handleExportClick()}
                                        >
                                            <i className="alpha-icon xs arrow-up" style={{ verticalAlign: 'middle' }} />
                                            <span className={'rule-export-label'}>Export</span>
                                        </span>
                                    </HasPermission>,
                                    <HasPermission
                                        entityId={this.rulesListStore?.currentProject?.id}
                                        key="rules-import"
                                        permissionClaim={AppPermissions.CanEditImportExportRules}
                                    >
                                        <Upload
                                            showUploadList={false}
                                            className="headerButton"
                                            key="file-uploader"
                                            name="file"
                                            onChange={onChange}
                                            action={`${action}`}
                                            headers={this.rulesListStore.fileImportActionHeaders}
                                            beforeUpload={file => handleBeforeUpload(file)}
                                        >
                                            <span data-id="button-Import" key="import-button-container">
                                                <i
                                                    className="alpha-icon xs arrow-down"
                                                    style={{ verticalAlign: 'middle' }}
                                                />
                                                <span className={'rule-import-label'} style={{ marginLeft: 11 }}>
                                                    Import
                                                </span>
                                            </span>
                                        </Upload>
                                    </HasPermission>,
                                    <span key="help-message" className="tooltip-container">
                                        <Tooltip title={'Rule Management'}>
                                            <i
                                                className="alpha-icon lg question-icon"
                                                style={{ verticalAlign: 'middle' }}
                                            />
                                        </Tooltip>
                                    </span>,
                                    <HasPermission
                                        entityId={this.rulesListStore?.currentProject?.id}
                                        key="rules-add-group"
                                        permissionClaim={AppPermissions.CanEditGroupsTags}
                                    >
                                        <Button
                                            data-id="button-Add Group"
                                            type="primary"
                                            size="large"
                                            onClick={() => this.groupsStore.setAddGroupDialogVisible(true)}
                                            style={{ marginBottom: 10 }}
                                        >
                                            Add Group
                                        </Button>
                                    </HasPermission>
                                ]}
                            />
                            <Layout>
                                <RulesListFilter RulesListUI={this.rulesListStore} />
                                <Content style={{ overflowY: 'auto', overflowX: 'auto' }}>
                                    <RulesImportDialog
                                        showImportConfirmationDialog={this.state.showImportConfirmationDialog}
                                    />
                                    <RuleCreateDialog store={this.ruleNewStore!} />
                                    {this.rulesListStore.tagsVersionDialogVisible && (
                                        <TagsVersionEditDialog store={this.rulesListStore} />
                                    )}
                                    <Table
                                        data-id="tags-group-list-table"
                                        style={{ border: this.state.borderStyle }}
                                        loading={this.rulesListStore.isTableLoding || this.groupsStore.isTableLoding}
                                        className="alpha-portal-table tags-group-list-table"
                                        rowClassName={record =>
                                            this.groupsStore.expandedKeys.includes(record.id!)
                                                ? 'expanded tags-group-table-row'
                                                : ' tags-group-table-row'
                                        }
                                        columns={columns}
                                        rowKey="id"
                                        pagination={false}
                                        expandRowByClick
                                        dataSource={this.groupsStore.tagsGroups
                                            .slice()
                                            .filter(g => !this.groupsStore.emptyGroupIds.find(gId => gId === g.id))
                                            .sort((a, b) => a.position - b.position)}
                                        expandedRowRender={this.expandedRowRender}
                                        expandIcon={props => (
                                            <i
                                                className="alpha-icon xs expand-row arrow-expand"
                                                onClick={e => {
                                                    props.onExpand(props.record, e);
                                                }}
                                            />
                                        )}
                                        expandedRowKeys={this.groupsStore.expandedKeys}
                                        onExpand={this.handleOnExpand}
                                        onRow={
                                            hasPermission
                                                ? (record, index) =>
                                                      ({
                                                          index,
                                                          record,
                                                          moveRow: this.moveRow,
                                                          handleFileDrop: this.rulesListStore.handleDropedFile
                                                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                      }) as any
                                                : undefined
                                        }
                                        components={hasPermission ? groupComponents : undefined}
                                    />
                                </Content>
                            </Layout>
                        </Layout>
                    )}
                </Observer>
            );
        };

        return (
            <>
                <NewGroupModal TagsGroupUI={this.groupsStore} RulesListUI={this.rulesListStore} />
                <DndProvider backend={HTML5Backend}>
                    <HasPermission
                        entityId={this.rulesListStore?.currentProject?.id}
                        permissionClaim={AppPermissions.CanEditGroupsTags}
                        yes={() => getTable(true)}
                        no={() => getTable(false)}
                    />
                </DndProvider>
            </>
        );
    }

    private expandedRowRender = (tagsGroup: TagsGroupModel) => {
        return <RulesList RulesListUI={this.rulesListStore} tagsGroup={tagsGroup} RuleNewUI={this.ruleNewStore} />;
    };

    private isTagsGroupModel(value: TagsGroupModel | TagModel): value is TagsGroupModel {
        return (value as TagsGroupModel).description !== undefined;
    }
}
