import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Parameter } from '../../types';
import { WorkflowOutputSchemeDto } from '../../../instruct_workflows/types';
import PipelineStep from '../../../pipeline_base/models/PipelineStep';
import PipelineVisualStore from '../../stores/PipelineVisualStore';
import useDisplayConditions from '../../hooks/useDisplayConditions';

const hasOutputSchemes = (values: unknown): values is { outputSchemes: WorkflowOutputSchemeDto[] } =>
    !!(values && typeof values === 'object' && 'outputSchemes' in values);

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};

const ParametersOutputSchemeSelect: <T extends PipelineVisualStore<S>, S extends string>(
    props: Props<T, S>
) => React.ReactElement<Props<T, S>> = props => {
    const { param, form, toolTip, step, store, stepKey } = props;

    const [outputSchemes, setOutputSchemes] = React.useState<WorkflowOutputSchemeDto[]>([]);

    const handleSubmit = async () => {
        await store.validateForm(form, step, stepKey, false);
    };

    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    React.useEffect(() => {
        const subject = store.pipelineStepValuesSubject.subscribe(values => {
            const stepValues = values[stepKey];

            if (!hasOutputSchemes(stepValues)) {
                return;
            }

            const outputSchemeName = form.getFieldValue(param.name);

            if (!stepValues.outputSchemes.some(s => s.name === outputSchemeName)) {
                form.setFieldsValue({ [param.name]: undefined });
            }

            setOutputSchemes(stepValues.outputSchemes);
        });

        return () => subject.unsubscribe();
    }, [store, param, stepKey, form]);

    const { isVisible } = useDisplayConditions({ param, form, store });

    return (
        <div style={!isVisible ? { display: 'none' } : {}}>
            <Form.Item label={toolTip} name={param.name} initialValue={param.defaultValue}>
                <Select placeholder="Select output scheme" allowClear>
                    {outputSchemes.map((s, i) => (
                        <Select.Option key={i + s.name} value={s.name}>
                            {s.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    );
};

export default observer(ParametersOutputSchemeSelect);
