import { RootStores } from '../../common/stores';
import SessionsVisualStore from './SessionsVisualStore';
import SessionsServce from '../services/SessionsService';
import { ApplicationDefinitionsService } from '../../iota_applications/services';
import { STORE_SESSIONS } from '../constants';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import { GlobalAdministrationService } from '../../administration/service/GlobalAdministrationService';
export { default as SessionsVisualStore } from './SessionsVisualStore';

export function injectIotaSessStores(obj: RootStores) {
    const service = new SessionsServce();
    const appService = new ApplicationDefinitionsService();
    const adminService = new GlobalAdministrationService();

    obj[STORE_SESSIONS] = new SessionsVisualStore(service, appService, obj[STORE_PROJECTS_ROOT_UI], adminService);
}

export type IotaSessionsStores = {
    [STORE_SESSIONS]?: SessionsVisualStore;
};
