import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ReferenceDataVisualStore } from '../../stores';

interface Props {
    store: ReferenceDataVisualStore;
}

interface FormValues {
    name: string;
}

const CreateEditTableGroupDialog: React.FC<Props> = ({ store }) => {
    const [form] = useForm<FormValues>();

    const onFinish = async (values: FormValues) => {
        const name = values.name.trim();

        const completed = store.editableTableGroup
            ? await store.editableTableGroup.update({ name })
            : await store.createTableGroup(name);

        if (completed) {
            onClose();
        }
    };

    const onClose = () => {
        if (store.editableTableGroup) {
            store.editableTableGroup.setIsEditable(false);
        } else {
            store.setCreateTableGroupDialogVisible(false);
        }
    };

    return (
        <Modal
            className="alpha-portal-dialog"
            title={store.editableTableGroup ? 'Edit group' : 'Create group'}
            visible={store.createTableGroupDialogVisible || !!store.editableTableGroup}
            width={600}
            onCancel={onClose}
            destroyOnClose
            centered
            footer={[
                <Button
                    form="create-edit-table-group-form"
                    data-id="create-edit-table-group-cancel"
                    key="create-edit-table-group-cancel"
                    className="light"
                    size="large"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button
                    form="create-edit-table-group-form"
                    data-id="create-edit-table-group"
                    key="create-edit-table-group"
                    size="large"
                    type="primary"
                    htmlType="submit"
                >
                    Create
                </Button>
            ]}
        >
            <Form id="create-edit-table-group-form" layout="vertical" form={form} onFinish={onFinish} preserve={false}>
                <Form.Item
                    label="Group name"
                    name="name"
                    initialValue={store.editableTableGroup ? store.editableTableGroup.name : ''}
                    rules={[
                        {
                            required: true,
                            message: 'Please enter group name!',
                            whitespace: true
                        }
                    ]}
                >
                    <Input size="large" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(CreateEditTableGroupDialog);
