import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Button, Modal, message } from 'antd';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineVisualStore from '../stores/PipelineVisualStore';
import { Parameter } from '../types';
import { FormInstance } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { RulePatternEditorContent } from './RulePatternEditorContent';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};
export const ParametersRulePatternEditor: <
    T extends PipelineVisualStore<TPipelineStepType>,
    TPipelineStepType extends string
>(
    props: Props<T, TPipelineStepType>
) => React.ReactElement<Props<T, TPipelineStepType>> = ({ param, form, toolTip, step, store, stepKey }) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const handleSubmit = async () => {
        await store.validateForm(form, step, stepKey, false);
    };

    const handleButtonClick = () => {
        try {
            JSON.parse(form.getFieldValue(param.name) || '[]');
        } catch (error) {
            message.error(
                'There was a problem with parsing your pattern value, please check if it is a valid JSON string'
            );
            return;
        }
        setIsVisible(true);
    };

    const handleCancel = () => {
        setIsVisible(false);
    };

    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    const handleRulePatternSave = (value: string) => {
        const obj = Object.create(null);
        obj[param.name] = value;
        form.setFieldsValue(obj);
        setIsVisible(false);
    };

    const modal = (
        <Modal
            className="rule-patter-editor-modal alpha-portal-dialog two-columns"
            visible={isVisible}
            onCancel={handleCancel}
            maskClosable={false}
            destroyOnClose
            footer={null}
            closable={false}
            centered
        >
            <RulePatternEditorContent
                pattern={form.getFieldValue(param.name)}
                handleRulePatternSave={handleRulePatternSave}
                handleCancel={handleCancel}
            />
        </Modal>
    );
    return (
        <div>
            <Form.Item
                label={toolTip}
                name={param.name}
                rules={[
                    {
                        required: !step.isDisabled && param.required,
                        message: 'Please input parameter value'
                    }
                ]}
                initialValue={param.defaultValue}
            >
                <TextArea autoSize placeholder={param.type === 'number' ? '0,1' : 'value'} />
            </Form.Item>
            <Button style={{ marginBottom: 22 }} onClick={handleButtonClick}>
                Edit
            </Button>
            {modal}
        </div>
    );
};

export default observer(ParametersRulePatternEditor);
