import * as React from 'react';
import { observer, Observer } from 'mobx-react';
import { Page, Pdf, Outline, Document } from 'react-pdf';
import _ from 'lodash';
import { InputNumber } from 'antd';
import { AutoSizer } from 'react-virtualized';
import { findDOMNode } from 'react-dom';
import { DocumentViewVisualStore } from '../stores';

type State = {
    numPages: number;
    pageNumber: number;
};

type Props = {
    store: DocumentViewVisualStore;
};

const setPages = (numPages: number, prevState: State) => ({ ...prevState, numPages: numPages });

export class PdfViewer extends React.Component<Props, State> {
    readonly state: State = {
        numPages: 0,
        pageNumber: 1
    };

    pageRefs: Page[] = [];

    constructor(props: Props) {
        super(props);
        this.handleDocumentLoaded = this.handleDocumentLoaded.bind(this);
    }

    navigateToPage(pageNumber: number) {
        const page = findDOMNode(this.pageRefs[pageNumber]) as HTMLElement;
        if (page) {
            page.parentElement!.parentElement!.scrollTo(0, page.offsetTop);
        }
    }

    render() {
        const { store } = this.props;
        return (
            <div className="document-viewer-container">
                <div className="document-viewer-toolBar">
                    <div className="document-viewer-toolBar-zoom">
                        <label>Scale:</label>
                        <InputNumber
                            defaultValue={store!.scale * 100}
                            min={0}
                            step={10}
                            max={100}
                            formatter={value => `${value}%`}
                            onChange={v => {
                                store!.setScale(v as number);
                            }}
                            parser={value => parseInt(value!.replace('%', ''), 10)}
                        />
                    </div>
                </div>
                <Document file={this.props.store.file} onLoadSuccess={this.handleDocumentLoaded}>
                    <div className="pdf-document-viewr-sidebar">
                        <Outline onItemClick={p => this.navigateToPage(p.pageNumber)} />
                    </div>
                    <div className="pdf-document-viewer">
                        <AutoSizer style={{ height: '100%', width: '100%', textAlign: 'center' }}>
                            {({ width }) =>
                                _.range(this.state.numPages + 1).map(v => (
                                    // eslint-disable-next-line max-len
                                    <Observer key={v}>
                                        {() => (
                                            <Page
                                                // eslint-disable-next-line max-len
                                                key={v}
                                                width={width * store!.scale * 0.8}
                                                renderTextLayer={false}
                                                ref={r => this.pageRefs.push(r!)}
                                                pageNumber={v}
                                                renderAnnotations={false}
                                            />
                                        )}
                                    </Observer>
                                ))
                            }
                        </AutoSizer>
                    </div>
                </Document>
            </div>
        );
    }

    private handleDocumentLoaded = ({ numPages }: Pdf) => this.setState((s: State) => setPages(numPages, s));
}

export default observer(PdfViewer);
