import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Row, Col, Checkbox, Button, Divider } from 'antd';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import './TableFilterDropdown.less';

type Props = {
    filters?: ColumnFilterItem[];
    selectedFilters?: CheckboxValueType[];
    onChange: (selectedFilters: CheckboxValueType[]) => void;
    onFilter: () => void;
    onClear: () => void;
    onClose: () => void;
};

const TableFilterDropdown: React.FC<Props> = ({ filters, selectedFilters, onChange, onFilter, onClear, onClose }) => {
    return (
        <div className="table-filter-dropdown" onKeyDown={e => e.stopPropagation()}>
            <Row className="option-row">
                <Col>
                    <Checkbox.Group
                        className="vertical-checkbox-group"
                        options={filters ? filters.map(f => ({ label: f.text, value: f.value })) : []}
                        value={selectedFilters}
                        onChange={onChange}
                    />
                </Col>
            </Row>

            <Divider className="divider" />

            <Row gutter={4} className="button-row">
                <Col span={8} className="button-col">
                    <Button type="link" size="small" onClick={onClose}>
                        Close
                    </Button>
                </Col>

                <Col span={8} className="button-col">
                    <Button className="light" size="small" onClick={onClear}>
                        Clear
                    </Button>
                </Col>

                <Col span={8} className="button-col">
                    <Button type="primary" size="small" onClick={onFilter}>
                        Filter
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default observer(TableFilterDropdown);
