import { observable, action, computed, reaction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { message } from 'antd';
import { uniq } from 'lodash';
import { ProjectsRootVisualStore } from '../../common/stores';
import { ProjectApplicationDefinitionEditVisualStore } from '../../iota_applications/stores';
import { ConditionModel, InputGroupModel } from '../models';
import { InputMeta } from '../../iota_applications/types';
import { ApplicationDefinitionConditional } from '../../iota_applications/types/ApplicationDefinition';
import { CreateEditConditionFormValues, CreateEditInputGroupFormValues } from '../types';
import ApplicationDefinitionConditionalService from '../services/ApplicationDefinitionConditionalService';
import { RcFile } from 'antd/lib/upload';
import { ImportInputsResponse } from '../../common/types/types';

interface ConditionFormValues extends CreateEditConditionFormValues {}

interface InputGroupFormValues extends CreateEditInputGroupFormValues {}

export default class ApplicationDefinitionConditionalEditStore {
    @observable
    activeTab: string = 'conditions';

    @observable
    createEditConditionDialogVisible: boolean = false;

    @observable
    createEditInputGroupDialogVisible: boolean = false;

    @observable
    conditions: ConditionModel[] = [];

    @observable
    inputGroups: InputGroupModel[] = [];

    @observable
    isUpdating: boolean = false;

    @observable
    isLoading: boolean = false;

    @observable
    currentImportFile: RcFile | undefined = undefined;

    @observable
    isImportConfirmDialogVisible: boolean = false;

    @observable
    isImportingConditions: boolean = false;

    @observable
    isDataChanged: boolean = false;

    @computed
    get project() {
        return this.projectsRootStore.currentProject;
    }

    @computed
    get applicationDefinition() {
        const applicationDefinition = this.applicationDefinitionEditVisualStore.currentAppDef;

        return applicationDefinition instanceof ApplicationDefinitionConditional ? applicationDefinition : null;
    }

    @computed
    get applicationDefinitionInputs(): InputMeta[] {
        if (!this.applicationDefinition) {
            return [];
        }

        return JSON.parse(this.applicationDefinition.meta).inputs;
    }

    @computed
    get applicationDefinitionSectionNames() {
        return uniq(this.applicationDefinitionInputs.filter(i => !!i.section).map(i => i.section!));
    }

    @computed
    get selectedCondition() {
        return this.conditions.find(c => c.selected);
    }

    @computed
    get editableCondition() {
        return this.conditions.find(c => c.editable);
    }

    @computed
    get editableInputGroup() {
        return this.inputGroups.find(g => g.editable);
    }

    @computed
    get importBindingsActionUrl() {
        if (!this.project || !this.applicationDefinition) {
            return '';
        }

        return (
            process.env.REACT_APP_MANAGE_URL +
            `projects/${this.project.id}/iota/applications/${this.applicationDefinition.id}/import-conditions`
        );
    }

    @computed
    get fileImportActionHeaders() {
        return this.applicationDefinitionEditVisualStore.fileImportActionHeaders;
    }

    constructor(
        private readonly projectsRootStore: ProjectsRootVisualStore,
        private readonly applicationDefinitionEditVisualStore: ProjectApplicationDefinitionEditVisualStore,
        private readonly service: ApplicationDefinitionConditionalService
    ) {
        reaction(
            () => this.applicationDefinition,
            applicationDefinition => {
                if (applicationDefinition) {
                    this.setActiveTab('conditions');
                    this.clearEditableData();
                    this.setEditableData(applicationDefinition);
                }
            }
        );
    }

    @action.bound
    setActiveTab(activeTab: string) {
        this.activeTab = activeTab;
    }

    @action.bound
    setCreateEditConditionDialogVisible(createEditConditionDialogVisible: boolean) {
        this.createEditConditionDialogVisible = createEditConditionDialogVisible;
    }

    @action.bound
    setCreateEditInputGroupDialogVisible(createEditInputGroupDialogVisible: boolean) {
        this.createEditInputGroupDialogVisible = createEditInputGroupDialogVisible;
    }

    @action.bound
    addNewCondition(formValues: ConditionFormValues) {
        const condition = new ConditionModel(this, {
            ...formValues,
            conditionId: uuidv4(),
            name: formValues.name.trim(),
            priority: this.conditions.length + 1
        });

        this.conditions.push(condition);
    }

    @action.bound
    addNewInputGroup(formValues: InputGroupFormValues) {
        const inputGroup = new InputGroupModel(this, {
            ...formValues,
            inputGroupId: uuidv4(),
            name: formValues.name.trim(),
            enabled: true,
            isDefault: false,
            sections: []
        });

        this.inputGroups.push(inputGroup);

        return inputGroup;
    }

    @action.bound
    removeCondition(condition: ConditionModel) {
        this.conditions.splice(this.conditions.indexOf(condition), 1);
    }

    @action.bound
    removeInputGroup(inputGroup: InputGroupModel) {
        this.inputGroups.splice(this.inputGroups.indexOf(inputGroup), 1);
    }

    @action.bound
    clearEditableData() {
        this.conditions = [];
        this.inputGroups = [];
    }

    @action.bound
    setEditableData(applicationDefinition: ApplicationDefinitionConditional) {
        this.conditions = applicationDefinition.conditions.map(condition => new ConditionModel(this, condition));

        if (applicationDefinition.inputGroups.length) {
            this.inputGroups = applicationDefinition.inputGroups.map(group => new InputGroupModel(this, group));
        } else {
            this.createDefaultInputGroups();
        }
    }

    @action.bound
    createDefaultInputGroups() {
        this.inputGroups.push(
            new InputGroupModel(this, {
                inputGroupId: uuidv4(),
                name: 'Simple',
                enabled: true,
                isDefault: true,
                hasLayers: false,
                hasSections: false,
                sections: []
            }),
            new InputGroupModel(this, {
                inputGroupId: uuidv4(),
                name: 'Layers',
                enabled: true,
                isDefault: false,
                hasLayers: true,
                hasSections: false,
                sections: []
            }),
            new InputGroupModel(this, {
                inputGroupId: uuidv4(),
                name: 'Sections',
                enabled: true,
                isDefault: false,
                hasLayers: false,
                hasSections: true,
                sections: []
            }),
            new InputGroupModel(this, {
                inputGroupId: uuidv4(),
                name: 'Layers with Sections',
                enabled: true,
                isDefault: false,
                hasLayers: true,
                hasSections: true,
                sections: []
            })
        );
    }

    @action.bound
    async updateData() {
        try {
            if (!this.project || !this.applicationDefinition) {
                return;
            }

            this.isUpdating = true;

            const resp = await this.service.updateData(this.project.id, this.applicationDefinition.id, {
                conditions: this.conditions.map(c => c.dto),
                inputGroups: this.inputGroups.map(i => i.dto)
            });

            if (resp.isOk()) {
                const updatedApp = resp.value;
                this.applicationDefinition.setLastUpdatedInfo(updatedApp.lastUpdatedBy, updatedApp.lastUpdatedTime);
                this.setIsDataChanged(false);
                message.success('Application has been successfully updated');
            } else {
                message.error('Failed to update application');
            }
        } finally {
            this.isUpdating = false;
        }
    }

    @action.bound
    exportAppBindings() {
        if (!this.applicationDefinition || !this.project) {
            console.error('No current app def or project');
            return;
        }

        this.service.exportAppConditions(this.applicationDefinition.id, this.project.id);
    }

    @action.bound
    getUserName(userId: string) {
        return this.applicationDefinitionEditVisualStore.getUserNameById(userId);
    }

    @action.bound
    setHeaders() {
        return this.applicationDefinitionEditVisualStore.setHeaders();
    }

    @action.bound
    setCurrentImportFile(file: RcFile | undefined) {
        this.currentImportFile = file;
    }

    @action.bound
    handleImportResponse(response: ImportInputsResponse) {
        if (response.importedSuccessfully) {
            const appDefId = this.applicationDefinition!.id;
            this.applicationDefinitionEditVisualStore.setCurrentAppDef(undefined);

            // Needed for correct UI update
            setTimeout(() => {
                message.success('You have successfully updated the application Inputs bindings.');
                this.setCurrentImportFile(undefined);
                this.applicationDefinitionEditVisualStore.loadApplication(appDefId);
            }, 0);
            return;
        }

        if (!response.canProceed) {
            message.error(response.responseMessage);
            return;
        }

        if (response.canProceed) {
            this.setIsImportConfirmDialogVisible(true);
        }
    }

    @action.bound
    setIsImportingConditions(isImporting: boolean) {
        this.isImportingConditions = isImporting;
    }

    @action.bound
    setIsImportConfirmDialogVisible(visible: boolean) {
        this.isImportConfirmDialogVisible = visible;
    }

    @action.bound
    handleImportDialogCancel() {
        this.setIsImportConfirmDialogVisible(false);
        this.setCurrentImportFile(undefined);
    }

    @action.bound
    async handleImportDialogConfirm() {
        if (!this.applicationDefinition || !this.project || !this.currentImportFile) {
            return;
        }

        try {
            this.setIsImportingConditions(true);
            const resp = await this.service.commitAppBindings(
                this.applicationDefinition!.id,
                this.project.id,
                this.currentImportFile!
            );

            if (resp.isOk()) {
                const appDefId = this.applicationDefinition!.id;
                this.applicationDefinitionEditVisualStore.setCurrentAppDef(undefined);

                // Needed for correct UI update
                setTimeout(() => {
                    this.applicationDefinitionEditVisualStore.loadApplication(appDefId);
                    this.handleImportDialogCancel();
                    message.success('You have successfully updated the application Inputs bindings.');
                }, 0);
            } else {
                message.error(resp.error.data);
                console.error(resp.error.data);
            }
        } catch (err) {
            message.error(err);
            console.error(err);
        } finally {
            this.setIsImportingConditions(false);
        }
    }

    @action.bound
    setIsDataChanged(isChanged: boolean) {
        this.isDataChanged = isChanged;
    }

    navigateToList() {
        if (this.project) {
            this.projectsRootStore.navigateToApplicationDefinitionsPage(this.project);
        }
    }
}
