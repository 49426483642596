import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { IotaMetadataInput } from '../types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { EncryptedInput } from '../../common/components';
import TextArea from 'antd/lib/input/TextArea';

interface Props {
    form: FormInstance;
    input: IotaMetadataInput;
    initialValue: unknown;
    disabled?: boolean;
}

const Option = Select.Option;

export const ApplicationSettingsStringInput: React.FC<Props> = ({ input, initialValue, form, disabled = false }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [inputVal, setInputVal] = React.useState<any>('');

    const renderSelectValues = () => {
        let options: JSX.Element[] = [];
        for (let key in input.values!) {
            if (input.values![key]) {
                let val = input.values![key];
                options.push(
                    <Option data-id={`iota-edit-application-settings-input-${input.id}-${val}`} value={val} key={val}>
                        {key}
                    </Option>
                );
            }
        }
        return options;
    };

    const renderInput = () => {
        let renderedInput: JSX.Element;
        const isEncrypted = input.behavior && input.behavior.encrypted;

        // Adding handler in case it will be passed to encrypted input component
        const onChangeHandler = isEncrypted
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (val: any) => {
                  let valObj = {};
                  const newVal = Array.isArray(val) || typeof val === 'string' ? val : val.target.value;
                  // @ts-ignore
                  valObj[input.id] = newVal;
                  form.setFieldsValue(valObj);
                  setInputVal(newVal);
              }
            : undefined;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = isEncrypted ? (initialValue as any) : undefined;

        if (input.behavior && input.behavior.multiselect) {
            renderedInput = (
                <Select
                    disabled={disabled}
                    value={isEncrypted ? inputVal : undefined}
                    defaultValue={value}
                    onChange={onChangeHandler}
                    getPopupContainer={trigger => trigger.parentElement!}
                    data-id={`iota-edit-application-settings-input-${input.id}`}
                    mode="tags"
                >
                    {input.values && renderSelectValues()}
                </Select>
            );
        } else {
            renderedInput = (
                <TextArea
                    disabled={disabled}
                    value={isEncrypted ? inputVal : undefined}
                    defaultValue={value}
                    onChange={onChangeHandler}
                    autoSize
                    data-id={`iota-edit-application-settings-input-${input.id}`}
                />
            );
        }

        if (isEncrypted) {
            renderedInput = (
                <EncryptedInput changeHandler={onChangeHandler!} initialValue={initialValue}>
                    {renderedInput}
                </EncryptedInput>
            );
        }

        return renderedInput;
    };

    return (
        <FormItem
            label={
                <span>
                    {input.name}&nbsp;
                    <Tooltip title={input.description}>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </span>
            }
            className="required-with-tooltip"
            name={input.id}
            rules={[
                {
                    ...input.validation,
                    message: 'Please input value!',
                    whitespace: true,
                    type: input.behavior && input.behavior.multiselect ? 'array' : 'string'
                }
            ]}
            initialValue={initialValue}
        >
            {renderInput()}
        </FormItem>
    );
};

export default observer(ApplicationSettingsStringInput);
