import * as React from 'react';
import { STORE_RULES_IMPORT } from '../constants';
import { RulesImportVisualStore } from '../stores';
import { Collapse, Table, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { ColumnProps } from 'antd/lib/table';
import { ImportMapperTableModel } from '../types';
const { Panel } = Collapse;

type Props = {
    [STORE_RULES_IMPORT]: RulesImportVisualStore;
};

export const RulesImportMapperTable: React.FC<Props> = ({ RulesImportUI: store }) => {
    React.useEffect(() => {
        return () => {
            store.setMapperTableIsVisible(false);
        };
    }, [store]);

    const columns: ColumnProps<ImportMapperTableModel>[] = [
        { title: 'Name', key: 'name', dataIndex: 'name' },
        { title: 'Type', key: 'type', dataIndex: 'type' },
        { key: 'changeTo', dataIndex: 'changeTo', render: () => <span>change to</span> },
        {
            title: 'Name',
            key: 'nativeName',
            dataIndex: 'nativeName',
            render: (_: string, record: ImportMapperTableModel) => {
                return {
                    children: (
                        <Select
                            defaultValue={record.bestValue || ''}
                            onChange={val => store.updateMapperTableData(val, record.name)}
                            style={{ width: 200 }}
                            showSearch
                            filterOption={(input, option) =>
                                option?.title && option.title.toLowerCase().includes(input.toLowerCase())
                            }
                            options={record.altOptions.map(x => ({ value: x, label: x, title: x }))}
                        />
                    ),
                    props: { style: { paddingTop: 0, paddingBottom: 0 } }
                };
            }
        }
    ];

    const hasRuleWihtoutMapping = () => {
        return !!store.mapperTableData.find(x => !x.altOptions.length);
    };

    return (
        <>
            <div
                className="validation-message"
                style={{ display: hasRuleWihtoutMapping() ? 'block' : 'none', marginBottom: 10 }}
            >
                <div>
                    <i className="alpha-icon md rule-problem-icon" />
                    <span>Are you sure want to import rules without ML Models/Connections/Instruct Workflows</span>
                </div>
            </div>
            <Collapse
                bordered={false}
                expandIconPosition="right"
                style={{ marginBottom: 20 }}
                defaultActiveKey={['mlModels', 'connections', 'instructWorkflows']}
            >
                <Panel key={'mlModels'} header={'ML Models'}>
                    <Table
                        className="alpha-portal-table"
                        key="name"
                        columns={columns}
                        dataSource={store.mapperTableData.filter(x => x.tableType === 'MlModel')}
                        pagination={false}
                    />
                </Panel>
                <Panel key={'connections'} header={'Connections'}>
                    <Table
                        className="alpha-portal-table"
                        key="name"
                        columns={columns}
                        dataSource={store.mapperTableData.filter(
                            x => x.tableType === 'RefDataConnectionModel' || x.tableType === 'IOTAConnectionModel'
                        )}
                        pagination={false}
                    />
                </Panel>
                <Panel key={'instructWorkflows'} header={'Instruct Workflows'}>
                    <Table
                        className="alpha-portal-table"
                        key="name"
                        columns={columns}
                        dataSource={store.mapperTableData.filter(x => x.tableType === 'InstructWorkflowModel')}
                        pagination={false}
                    />
                </Panel>
            </Collapse>
        </>
    );
};

export default observer(RulesImportMapperTable);
