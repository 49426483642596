import { Layout, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

type Props = {
    title: string | JSX.Element;
    buttons?: JSX.Element[] | undefined;
    helpMessage?: string | undefined;
    helpMessageTooltipPlacement?: TooltipPlacement;
    subtitle?: string | JSX.Element;
};

const LayoutHeader: React.FC<Props> = ({ title, buttons, helpMessage, helpMessageTooltipPlacement, subtitle }) => {
    const actionButtons = () => {
        if (buttons && buttons.length) {
            return <span className="header-actions">{buttons.map(b => b)}</span>;
        }

        return null;
    };

    const renderHelpMessage = () => {
        if (helpMessage) {
            return (
                <span data-id="page-help-tooltip-container" className="tooltip-container">
                    <Tooltip title={helpMessage} placement={helpMessageTooltipPlacement}>
                        <i className="alpha-icon lg question-icon" />
                    </Tooltip>
                </span>
            );
        }

        return null;
    };

    return (
        <Layout.Header className="alpha-layout-header">
            <div className="title-wrapper">
                {subtitle ? <div className="header-subtitle">{subtitle}</div> : null}
                <div className="header-title">{title}</div>
            </div>
            {renderHelpMessage()}
            {actionButtons()}
        </Layout.Header>
    );
};

export default observer(LayoutHeader);
