import { Button, Form, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ConnectionAddPage } from '../screens/ConnectionAddPage';
import { ConnectionEditorStore } from '../stores';

type Props = {
    store: ConnectionEditorStore;
};

const ConnectionAddDialog: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();
    let tempId = React.useRef(Math.random().toString(36).substring(2, 15));

    React.useEffect(() => {
        if (store.connectionAddDialogVisible) {
            tempId.current = Math.random().toString(36).substring(2, 15);
        } else {
            form.resetFields();
        }
    }, [store.connectionAddDialogVisible, form]);

    const handleCancel = () => {
        store.setConnectionAddDialogVisible(false);
        store.cancel(tempId.current);
    };

    return (
        <Modal
            className="alpha-portal-dialog"
            title="Add connection"
            centered
            forceRender
            visible={store!.connectionAddDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            width={600}
            footer={[
                <Button
                    data-id="rd-connection-add-dialog-cancel"
                    className="light"
                    key="back"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="rd-connection-add-dialog-validate"
                    key="validate"
                    style={{ backgroundColor: 'green', borderColor: 'green' }}
                    type="primary"
                    size="large"
                    onClick={() => {
                        store!.validateConnection(
                            tempId.current,
                            form.getFieldValue('connectionString'),
                            form.getFieldValue('connectionType')
                        );
                    }}
                    loading={store!.isLoading}
                >
                    Validate
                </Button>,
                <Button
                    data-id="rd-connection-add-dialog-submit"
                    key="submit"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    form="add-rd-connection-form"
                >
                    Add connection
                </Button>
            ]}
        >
            <ConnectionAddPage RuleConnection={store} form={form} tempId={tempId.current} />
        </Modal>
    );
};

export default observer(ConnectionAddDialog);
