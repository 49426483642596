import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Button } from 'antd';
import PydanticSchemaGeneratorStore from '../../stores/PydanticSchemaGeneratorStore';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-python';
import './PydanticEditor.less';

interface Props {
    store: PydanticSchemaGeneratorStore;
}

const PydanticEditor: React.FC<Props> = ({ store }) => {
    return (
        <div className="pydantic-editor">
            <div className="header">
                <Typography.Title level={5}>Pydantic Model</Typography.Title>
                <Button onClick={store.generateJsonSchema} disabled={store.pydanticEditorDisabled}>
                    Generate JSON
                </Button>
            </div>

            <div className="code-editor-container">
                <Editor
                    className="code-editor"
                    value={store.pydanticEditorValue}
                    onValueChange={store.setPydanticEditorValue}
                    highlight={code => Prism.highlight(code, Prism.languages.python, 'python')}
                />
            </div>
        </div>
    );
};

export default observer(PydanticEditor);
