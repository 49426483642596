import * as React from 'react';
import { ProtectedRoute, monitorRouterMatch } from '../../common/components';
import InteractiveLabelsPage from '../screens/InteractiveLabelsPage';
import { STORE_PROJECT_INTERACTIIVE_LABELS } from '../constants';

export const InteractivePagesNavigation = {
    ProjectInteractiveLabelPage: '/projects/:id/interactivelabels/:packageId/:pageNumber'
};

export default () => {
    return (
        <ProtectedRoute
            component={monitorRouterMatch(STORE_PROJECT_INTERACTIIVE_LABELS, (s, m) =>
                s[STORE_PROJECT_INTERACTIIVE_LABELS].setMatch(m!.params)
            )(InteractiveLabelsPage)}
            path={InteractivePagesNavigation.ProjectInteractiveLabelPage}
        />
    );
};
