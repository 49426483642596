import * as React from 'react';
import { ProtectedRoute } from '../../common/components';
import SecurityPage from '../screens/SecurityPage';

export const SecurityPagesNavigation = {
    SecurityPage: '/members',
    UserManagement: '/members/user-management',
    ProjectAssignment: '/members/project-assignment',
    RolesManagement: '/members/roles-management'
};

export default () => {
    return <ProtectedRoute component={SecurityPage} path={SecurityPagesNavigation.SecurityPage} />;
};
