export default class PageCoordinates {
    constructor(
        public pageWidth: number,
        public pageHeight: number,
        public x: number,
        // eslint-disable-next-line no-empty, no-empty-function, @typescript-eslint/no-empty-function
        public y: number,
        public width: number,
        public height: number,
        public page: number
    ) {}
}
