import permissions, { AppPermissions } from '../../authorization/Permissions';
import * as React from 'react';
import { RoleModel } from '../types/RoleModel';
import { CustomRole } from '../types';

export class UsersHelper {
    static additionalRoles = ['auth_level_1', 'auth_level_2', 'auth_level_3'];

    static isCustomRole = (r: unknown) => {
        const properties = Object.getOwnPropertyNames(r);
        return properties.includes('permissions');
    };

    static getAuthLevelRoleDescription = (roleName: string) => {
        if (!roleName || !roleName.includes('auth_level_')) {
            return '';
        }

        // eslint-disable-next-line max-len
        let template = `Limit user's possibility to submit claims. User can submit 'Paid Loss' amount only under set up limit in Inputs. The limit format should be used in Inputs - %amt%:${roleName}`;

        let amt = 0;
        switch (roleName) {
            case 'auth_level_1':
                amt = 1000;
                break;
            case 'auth_level_2':
                amt = 5000;
                break;
            case 'auth_level_3':
                amt = 7000;
                break;
            default:
                break;
        }

        template = template.replace('%amt%', amt.toString());
        return template;
    };

    static getPermissionsString = (role: string) => {
        if (permissions[role]) {
            const rolePermissions = permissions[role] as AppPermissions[];
            return rolePermissions.map(x => (
                <>
                    {x}
                    <br />
                </>
            ));
        }
        return null;
    };

    static getRolePermissions = (r: RoleModel | CustomRole) => {
        return this.isCustomRole(r)
            ? (r as CustomRole).permissions.map(p => (
                  <>
                      {p}
                      <br />
                  </>
              ))
            : this.getPermissionsString(r.name);
    };

    static getBasicRoles = (defaultRoles?: RoleModel[], customRoles?: CustomRole[]) => {
        if (!defaultRoles && !customRoles) {
            return [];
        }

        const allRoles: (RoleModel | CustomRole)[] = [...(defaultRoles ?? []), ...(customRoles ?? [])];
        return allRoles.filter(r => !this.additionalRoles.includes(r.name.toLowerCase()));
    };

    static getOtherRoles = (defaultRoles?: RoleModel[], customRoles?: CustomRole[]) => {
        if (!defaultRoles && !customRoles) {
            return [];
        }

        const allRoles: (RoleModel | CustomRole)[] = [...(defaultRoles ?? []), ...(customRoles ?? [])];

        return allRoles.filter(r => this.additionalRoles.includes(r.name.toLowerCase()));
    };
}
