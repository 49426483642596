import { Menu, Dropdown } from 'antd';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ProjectsRootVisualStore } from '../modules/common/stores';
import { STORE_PROJECTS_ROOT_UI } from '../modules/common/constants';
import { STORE_PROJECT_FORM } from '../modules/project_management/constants';
import { ProjectFormVisualStore } from '../modules/project_management/stores';
import EditProjectDialog from '../modules/project_management/components/EditProjectDialog';
import ProjectDeleteDialog from './ProjectDeleteDialog';

type Props = {
    projectsRootUI?: ProjectsRootVisualStore;
    projectForm?: ProjectFormVisualStore;
    hideProjectEditOption?: boolean;
};

const ProjectDropdownMenu: React.FC<Props> = ({
    projectsRootUI: store,
    projectForm: formStore,
    hideProjectEditOption
}) => {
    if (!store || !formStore) {
        return null;
    }

    const menu = (
        <Menu>
            {hideProjectEditOption ? null : (
                <Menu.Item key="edit" onClick={() => store.setProjectEditDialogVisible(true)}>
                    Edit
                </Menu.Item>
            )}
            <Menu.Item key="delete" style={{ color: 'red' }} onClick={() => store.setProjectDeleteDialogVisible(true)}>
                Delete
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <ProjectDeleteDialog store={store} />

            <EditProjectDialog
                formStore={formStore}
                store={store}
                showDeleteProjectModal={() => {
                    store.setProjectDeleteDialogVisible(true);
                }}
            />

            <Dropdown overlay={menu} trigger={['click']} overlayClassName="alpha-dropdown-menu">
                <i className="alpha-icon md more-icon" />
            </Dropdown>
        </>
    );
};

export default inject(STORE_PROJECTS_ROOT_UI, STORE_PROJECT_FORM)(observer(ProjectDropdownMenu));
