import { ConnectionDescription } from '../../iota_applications/types';

export default class ConnectionsDefinition {
    constructor(
        public id: string,
        public name: string,
        public applicationId: string,
        public workflowId: string,
        public projectId: string,
        public extension: string,
        public settings?: string,
        public settingValues?: { [id: string]: unknown },
        public connections?: ConnectionDescription[]
    ) {}
}
