/* eslint-disable react/jsx-key */
import * as React from 'react';
import {
    AutoSizer,
    CellMeasurerCache,
    Table,
    Column,
    TableCellProps,
    CellMeasurer,
    Index,
    InfiniteLoader,
    defaultTableRowRenderer,
    TableRowProps
} from 'react-virtualized';
import { Select, Modal, Layout, Button, Tooltip } from 'antd';
import { observer, inject, Observer } from 'mobx-react';
import { reaction } from 'mobx';
// @ts-ignore
import Img from 'react-image';
// @ts-ignore
import VisibilitySensor from 'react-visibility-sensor';
import imageCellRenderer from '../../common/components/ImageCellRenderer';
import textCellRenderer from '../../common/components/TextCellRenderer';
import { HTMLAttributes } from 'react';
import PackageContextMenu from '../../common/components/PackageContextMenu';
import { ProjectLabelsVisualStore } from '../stores';
import { PackageLine, PackageLineState } from '../../common/models';
import { ViewerPagesNavigation } from '../../viewer_base/routes';
import { STORE_PROJECT_LABELS } from '../constants';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { LoadingOutlined } from '@ant-design/icons';
import LayoutHeader from '../../../components/LayoutHeader';
import { Utils } from '../../common/services/Utils';
import LabelsFilter from './LabelsFilter';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

const Option = Select.Option;
const { Content } = Layout;

interface PackageLinesGridProps {}

const COLUMN_HEIGHT = 48;

const initialState = {
    modalContent: '',
    previewVisible: false
};

type State = Readonly<typeof initialState>;

const showModal = (path: string) => ({ modalContent: path, previewVisible: true });
const hideModal = () => ({ previewVisible: false });
@inject(STORE_PROJECT_LABELS)
@observer
class PackageLinesLabelsGrid extends React.Component<PackageLinesGridProps, State> {
    nameRenderer: (props: TableCellProps) => JSX.Element | null;
    textRenderer: (props: TableCellProps) => JSX.Element | null;
    imageRenderer: (props: TableCellProps) => JSX.Element | null;
    anchorRenderer: (props: TableCellProps) => JSX.Element | null;

    readonly state: State = initialState;
    private cache: CellMeasurerCache;
    private promiseResolver: (val: boolean) => void;
    private lastPromise: Promise<boolean>;
    private store: ProjectLabelsVisualStore;
    private disposer: () => void;
    private grid: Table;
    private containerRef: React.RefObject<HTMLDivElement>;

    constructor(props: PackageLinesGridProps) {
        super(props);
        this.store = this.props[STORE_PROJECT_LABELS] as ProjectLabelsVisualStore;

        this.cache = new CellMeasurerCache({
            fixedWidth: true,
            minHeight: 47
        });

        /* eslint-disable max-len */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const anchor = (line: PackageLine, p: HTMLAttributes<any>) =>
            !line.pkg.documentPath ? (
                <div {...p}>{line.packageName}</div>
            ) : (
                <Tooltip title={line.packageName}>
                    <a
                        href={`${process.env.PUBLIC_URL}${ViewerPagesNavigation.DocumentViewerPage}/${encodeURIComponent(line.pkg.id)}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <PackageContextMenu pkg={line.pkg} />
                    </a>
                    ;
                </Tooltip>
            );

        this.containerRef = React.createRef();
        this.initReactions();

        this.nameRenderer = textCellRenderer(anchor, () => this.store.packageLines, this.cache, 0);
    }

    componentDidMount() {
        this.recomputeHeight();
        this.store.subscribeToPackageChanges();
    }

    componentWillUnmount() {
        this.store.unsubscribeFromPackageChanges();
        this.disposer();
    }

    render() {
        const rowHeight: (info: Index) => number = (info: Index) => this.cache.rowHeight(info) || COLUMN_HEIGHT;
        const { hasNextPage, isNextPageLoading } = this.store.pager;
        const packageLinesCount = this.store.packageLines.length;

        const rowCount = hasNextPage ? packageLinesCount + 1 : packageLinesCount;

        if (!isNextPageLoading && this.promiseResolver) {
            this.promiseResolver(true);
        }

        const isRowLoaded = ({ index }: Index) => index < packageLinesCount;
        const loadMoreRows = isNextPageLoading
            ? () => {
                  return this.lastPromise;
              }
            : () => {
                  this.store.loadNextPage();
                  this.lastPromise = new Promise<boolean>(resolve => (this.promiseResolver = resolve));
                  return this.lastPromise;
              };

        const rowRenderer = (props: TableRowProps) => {
            if (!isRowLoaded({ index: props.index })) {
                return (
                    <div key={props.index} style={props.style}>
                        Loading...
                    </div>
                );
            } else {
                const row = this.store.packageLines[props.index];
                if (row.state === PackageLineState.ChangedLabel) {
                    props.className = `${props.className} yellow-highlight-selected`;
                }

                return defaultTableRowRenderer(props);
            }
        };

        const mainColumns = (width: number) => {
            const showPkg = !this.store.selectedPackage;

            const tail = [
                <Column
                    width={90}
                    dataKey="random2"
                    label="Image"
                    className={'tableColumn'}
                    style={{ padding: '10px 10px' }}
                    cellRenderer={this.imageRenderer}
                />,
                // (
                //     <Column
                //         width={210}
                //         dataKey="label.text"
                //         label="Label"
                //         key={'label'}
                //         className={'tableColumn'}
                //         style={{ padding: '7px 5px 10px 5px' }}
                //         cellRenderer={this.anchorRenderer}
                //     />),
                <Column
                    width={width - 200}
                    dataKey="random"
                    label="Text"
                    key={'text'}
                    className={'tableColumn'}
                    cellRenderer={this.textRenderer}
                />
            ];

            if (showPkg) {
                return [
                    <Column
                        width={400}
                        key={'packageName'}
                        dataKey="packageName"
                        label="Package"
                        className={'tableColumn'}
                        cellRenderer={this.nameRenderer}
                    />,
                    ...tail
                ];
            }

            return tail;
        };

        const columns = this.store.visibleProperties.map(v => (
            <Column
                width={200}
                key={v}
                dataKey={v}
                cellDataGetter={p => {
                    return p.rowData.properties[p.dataKey];
                }}
                label={v}
                className={'tableColumn'}
            />
        ));

        return (
            <Layout className="screen-size" style={{ ...{ height: '100%', background: 'white', overflow: 'hidden' } }}>
                <LayoutHeader
                    subtitle={Utils.getSubtitle(this.store.project)}
                    title="Label data"
                    helpMessage="Page shows all parsed documents in the project and extract data with concrete block type"
                    buttons={[
                        <Button
                            key="clear"
                            size="large"
                            className="light"
                            onClick={() => this.store.clearFilter()}
                            data-id="button-clear-filters"
                        >
                            Clear filters
                        </Button>
                    ]}
                />
                <Layout>
                    <Content>
                        <LabelsFilter />
                        {!this.store.isLoading ? (
                            <div data-id="table-result" ref={this.containerRef} style={{ height: '100%' }}>
                                <InfiniteLoader
                                    isRowLoaded={isRowLoaded}
                                    loadMoreRows={loadMoreRows}
                                    rowCount={rowCount}
                                >
                                    {({ onRowsRendered, registerChild }) => (
                                        <AutoSizer>
                                            {({ height, width }) => (
                                                <Table
                                                    ref={x => {
                                                        this.setRef(x as Table);
                                                        registerChild(x);
                                                    }}
                                                    deferredMeasurementCache={this.cache}
                                                    headerHeight={44}
                                                    headerClassName={'tableHeaderColumn'}
                                                    height={height - 106}
                                                    onRowsRendered={onRowsRendered}
                                                    overscanRowCount={2}
                                                    rowClassName={'tableRow'}
                                                    rowHeight={rowHeight}
                                                    rowGetter={this.rowGetter}
                                                    rowCount={rowCount}
                                                    rowRenderer={rowRenderer}
                                                    width={
                                                        width +
                                                        this.store.visibleProperties.reduce(
                                                            (p, c) => p + c.length * 12,
                                                            0
                                                        )
                                                    }
                                                >
                                                    {[mainColumns(width), ...columns]}
                                                </Table>
                                            )}
                                        </AutoSizer>
                                    )}
                                </InfiniteLoader>
                                <Modal
                                    width={750}
                                    footer={[]}
                                    wrapClassName="preview-modal"
                                    visible={this.state.previewVisible}
                                    onCancel={this.handleHideModal}
                                    centered
                                >
                                    <VisibilitySensor>
                                        <Img
                                            className="popup-image"
                                            // eslint-disable-next-line max-len
                                            src={
                                                process.env.REACT_APP_IMAGE_URL +
                                                `?path=${encodeURIComponent(this.state.modalContent)}`
                                            }
                                            loader={<LoadingOutlined />}
                                        />
                                    </VisibilitySensor>
                                </Modal>
                            </div>
                        ) : (
                            <LoadingIndicator />
                        )}
                    </Content>
                </Layout>
            </Layout>
        );
    }

    private setRef(ref: Table) {
        this.grid = ref;
    }

    private anchorCellRendererFactory =
        (idx: number = 2) =>
        ({ dataKey, parent, rowIndex }: TableCellProps) => {
            const { packageLines } = this.store;
            const keywords = this.store.project.labels.map(l => l.text);

            if (rowIndex === packageLines.length) {
                return null;
            }

            const options = ['None', ...keywords.slice()].sort((a, b) => a.localeCompare(b));
            const line = packageLines[rowIndex];
            const setLabel = (label: string) => {
                if (label === 'null') {
                    line.setLabel(null);
                } else {
                    const obj = this.store.project.labels.find(l => l.text === label)!;
                    line.setLabel(obj);
                }
            };

            const menu = (isDisabled: boolean) => {
                return (
                    <Observer>
                        {() => (
                            <Select
                                disabled={isDisabled}
                                data-id-cells="Label"
                                showSearch
                                style={{ width: 150 }}
                                value={line.labelText || 'None'}
                                placeholder="Select a label"
                                optionFilterProp="children"
                                onChange={(value: string) => setLabel(value as string)}
                                // eslint-disable-next-line max-len
                                filterOption={(input, option) =>
                                    (option!.props.children! as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {options.map(a => (
                                    <Option key={a} value={a} data-id={`label-data-list-label-select-${rowIndex}-${a}`}>
                                        {a}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Observer>
                );
            };

            return (
                <CellMeasurer
                    cache={this.cache}
                    columnIndex={idx}
                    key={dataKey}
                    parent={parent}
                    className={'tableColumn'}
                    rowIndex={rowIndex}
                >
                    <HasPermission
                        entityId={this.store?.project?.id}
                        permissionClaim={AppPermissions.CanEditImportExportLabels}
                        yes={() => menu(false)}
                        no={() => menu(true)}
                    />
                </CellMeasurer>
            );
        };

    private rowGetter = ({ index }: Index) => {
        const { packageLines } = this.store;
        return packageLines[index % packageLines.length] || [];
    };

    private handleShowModal = (path: string) => this.setState(() => showModal(path));
    private handleHideModal = () => this.setState(hideModal);

    private initReactions = () => {
        if (!this.store.project) {
            reaction(
                () => this.store.project,
                (p, r) => {
                    this.initReactions();
                    r.dispose();
                }
            );
            return;
        }
        this.store.loadProjectPackages();
        const popOver = (text: string) => {
            return (
                <Tooltip
                    overlayStyle={{ width: 300, fontSize: 12, maxHeight: 400, overflow: 'auto' }}
                    title={text}
                    autoAdjustOverflow
                    placement="topLeft"
                >
                    <div data-id-cells="Text">{text}</div>
                </Tooltip>
            );
        };

        var r0 = reaction(
            () => this.store.selectedPackage,
            () => {
                if (!this.store.selectedPackage) {
                    this.anchorRenderer = this.anchorCellRendererFactory(2);
                    this.imageRenderer = imageCellRenderer(
                        path => this.handleShowModal(path),
                        () => this.store.packageLines,
                        this.cache,
                        1,
                        'Image'
                    );
                    this.textRenderer = textCellRenderer(
                        l => popOver(l.normalizedText),
                        () => this.store.packageLines,
                        this.cache,
                        3
                    );
                } else {
                    this.recomputeHeight();
                    this.imageRenderer = imageCellRenderer(
                        path => this.handleShowModal(path),
                        () => this.store.packageLines,
                        this.cache,
                        0,
                        'Image'
                    );
                    this.anchorRenderer = this.anchorCellRendererFactory(1);
                    this.textRenderer = textCellRenderer(
                        l => popOver(l.normalizedText),
                        () => this.store.packageLines,
                        this.cache,
                        2
                    );
                }

                this.cache.clearAll();
            },
            { fireImmediately: true }
        );

        var r1 = reaction(
            () => this.store.filterQuery,
            () => this.cache.clearAll()
        );
        var r2 = reaction(
            () => this.store.project.dirtyRows.length,
            () => this.grid.forceUpdateGrid()
        );
        let r3 = reaction(
            () => this.store.project,
            () => this.store.loadProjectPackages()
        );
        this.disposer = () => {
            r0();
            r1();
            r2();
            r3();
        };
    };

    private recomputeHeight = () => {
        // Resize container to make virtualized table render visible rows
        if (this.store.packageLines.length > 0 && this.containerRef.current) {
            this.containerRef.current.style.height = '99.9%';

            setTimeout(() => {
                this.containerRef.current!.style.height = '100%';
            }, 0);
        }
    };
}

export default PackageLinesLabelsGrid;
