/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action, computed, runInAction, reaction } from 'mobx';
import ErrorStore from '../../common/stores/ErrorStore';
import _ from 'lodash';
import { Connection, RefDataRule } from '../models';
import RulesStore from './RulesStore';
import { message } from 'antd';

type FormField = {
    value: string;
};

type ConnectionFormFields = Readonly<{
    [P in keyof Connection]?: FormField;
}>;

interface CurrentUiState {
    [key: string]: boolean | null;
}
export default class ConnectionEditorStore {
    @observable
    editableConnection: Connection | null = null;

    @observable
    connectionsList?: Connection[];

    @observable
    validationFailed: CurrentUiState = {};

    @observable
    activeTabKey: string;

    @observable
    isLoading: boolean = false;

    @observable
    connectionEditDialogVisible: boolean = false;

    @observable
    connectionAddDialogVisible: boolean = false;

    @observable
    fileImportActionHeaders: {};

    @computed
    get currentProject() {
        return this.store.currentProject;
    }

    constructor(
        private store: RulesStore,
        public errorStore: ErrorStore
    ) {
        reaction(
            () => this.store.currentProject,
            async c => {
                if (c) {
                    await this.loadConnections();
                }
            }
        );
    }

    async loadConnections() {
        if (!this.store.currentProject) {
            reaction(
                () => this.store.currentProject,
                async (p, r) => {
                    await this.loadConnections();
                    r.dispose();
                }
            );
        } else {
            await this.store.loadConnections();
        }
    }

    @computed
    get connections() {
        // this.connectionsList = this.store.connections;
        return this.store.connections;
    }

    @action.bound
    setConnectionEditDialogVisible(visible: boolean) {
        this.connectionEditDialogVisible = visible;
    }

    @action.bound
    setConnectionAddDialogVisible(visible: boolean) {
        this.connectionAddDialogVisible = visible;
    }

    @action.bound
    setEditableConnection(connection: Connection | null) {
        this.editableConnection = connection;
    }

    @action.bound
    add() {
        // this.routerStore.pushToHistory(RulesPagesNavigation.ConnectionsAddPage.replace(':projectId', this.store.currentProject!.id));
        this.editableConnection = null;
        this.setConnectionAddDialogVisible(true);
    }

    @action
    edit(record: Connection) {
        sessionStorage.setItem('rulesConnection', record.id);
        this.setMatch({ id: record.id });
        this.setConnectionEditDialogVisible(true);
        // this.routerStore.pushToHistory(RulesPagesNavigation.ConnectionsEditPage.replace(':id', record.id!).replace(':projectId', this.store.currentProject!.id));
    }

    @action
    async delete(id: string) {
        let connectionIsUsedList = this.store.rules.map(r => {
            if (r instanceof RefDataRule) {
                return r.connectionId === id;
            } else {
                return false;
            }
        });
        let IsUsed = _.includes(connectionIsUsedList, true);
        if (IsUsed) {
            let error = new Error('Connection is Used');
            this.errorStore.addBasicError(error);
        } else {
            await this.store.deleteConnection(id);
            await this.loadConnections();
        }
    }

    @action
    async validateConnection(id: string, connectionString: string, connectionType: string) {
        this.isLoading = true;
        let key = id;
        let isFailed = false;
        const resp = await this.store.validateConnection(connectionString, connectionType);
        resp.mapErr(() => {
            isFailed = true;
            runInAction(() => (this.validationFailed[key] = true));
        });

        runInAction(() => (this.validationFailed[key] = isFailed));
        runInAction(() => (this.isLoading = false));

        if (isFailed) {
            message.error('Validation failed');
        } else {
            message.success('Validation successful');
        }
    }

    @action
    async save(values: Connection, validationId: string) {
        this.clearValidationError(validationId);
        await this.store.saveConnection(values);
        // this.routerStore.pushToHistory(RulesPagesNavigation.ConnectionsListPage.replace(':projectId', this.store.currentProject!.id));
        this.editableConnection = null;
        this.setConnectionEditDialogVisible(false);
        this.setConnectionAddDialogVisible(false);
    }

    @action.bound
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async setMatch(m: any) {
        console.log('setting match');
        if (!this.connections || this.connections.length === 0) {
            await this.loadConnections();
            reaction(
                () => this.connections,
                (l, r) => {
                    const savedId = sessionStorage.getItem('rulesConnection');
                    if (!this.editableConnection && savedId) {
                        this.setMatch({ id: savedId });
                    } else {
                        this.setMatch(m);
                    }
                    r.dispose();
                }
            );
        } else {
            this.editableConnection = this.connections!.find(x => x.id === m.id) || null;
        }
    }

    @action
    cancel(connId?: string) {
        this.clearValidationError(connId);
        this.activeTabKey = '2';
        this.setEditableConnection(null);
        this.setConnectionEditDialogVisible(false);
        this.setConnectionAddDialogVisible(false);
        // this.routerStore.pushToHistory(RulesPagesNavigation.ConnectionsListPage.replace(':projectId', this.store.currentProject!.id));
    }

    @action
    clearValidationError(connId?: string) {
        let id = connId || this.editableConnection!.id;
        this.validationFailed[id] = null;
    }

    @action
    applyFields(fields: ConnectionFormFields) {
        Object.getOwnPropertyNames(fields).forEach(f => (this.editableConnection![f] = fields[f].value));
    }

    @action
    async exportConnection(id: string) {
        this.isLoading = true;
        await this.store.exportConnection(id);
        this.isLoading = false;
    }

    async setHeaders() {
        const token = await this.store.getAuthToken();
        this.fileImportActionHeaders = {
            Authorization: 'Bearer ' + token
        };
        return Promise.resolve();
    }
}
