import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Row, Col, Tooltip, Input } from 'antd';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineVisualStore from '../stores/PipelineVisualStore';
import { Parameter } from '../types';
import Form, { FormInstance } from 'antd/lib/form';
import PipelineStepType from '../types/PipelineStepType';
import _ from 'lodash';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};

export const ParametersRegexEditor: <
    T extends PipelineVisualStore<TPipelineStepType>,
    TPipelineStepType extends string
>(
    props: Props<T, TPipelineStepType>
) => React.ReactElement<Props<T, TPipelineStepType>> = ({ param, form, toolTip, step, store, stepKey }) => {
    const [internalForm] = Form.useForm();

    const [showModal, setShowModal] = React.useState(false);

    const handleSubmit = async () => {
        await store.validateForm(form, step, stepKey, false);
    };

    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    const cancel = () => {
        setShowModal(false);
        internalForm.resetFields();
    };

    const validate = async () => {
        if (
            internalForm.getFieldValue('testString') == null ||
            internalForm.getFieldValue('testString').trim() === ''
        ) {
            return;
        }

        internalForm.validateFields().then(async values => {
            const result = await store.validateRegexPatternValue(
                values.testString,
                values.regexPattern,
                step.type as PipelineStepType,
                step.parameters
            );
            internalForm.setFieldsValue({ result });
        });
    };

    const save = () => {
        internalForm
            .validateFields()
            .then(() => {
                const val = internalForm.getFieldValue('regexPattern');
                if (val) {
                    if (step.type === 'ReplaceSymbols') {
                        form.setFieldsValue({ search: val, regex: true });
                    } else {
                        form.setFieldsValue({ regex: val });
                    }
                    form.submit(); // submit form to trigger onFieldsChange of the form which updates pipeline steps in store
                }
                internalForm.resetFields();
                setShowModal(false);
            })
            .catch(() => {
                // do nothing
            });
    };

    const getRegexInitialValue = () => {
        return step.type === 'ReplaceSymbols' ? form.getFieldValue('search') : form.getFieldValue('regex');
    };

    return (
        <div>
            <Form.Item
                label={toolTip}
                name={param.name}
                rules={[
                    {
                        required: !step.isDisabled && param.required,
                        message: 'Please input parameter value'
                    }
                ]}
                initialValue={param.defaultValue}
            >
                <>
                    <Tooltip title={'regex editor'} overlayClassName="code-tooltip-overlay">
                        <Button type={'ghost'} onClick={() => setShowModal(true)}>
                            Open
                        </Button>
                    </Tooltip>
                    <Modal
                        visible={showModal}
                        onCancel={cancel}
                        onOk={validate}
                        footer={
                            <Row>
                                <Col span={12} style={{ textAlign: 'left' }}>
                                    <Button data-id="query-editor-dialog-debug" type="link" onClick={validate}>
                                        Validate
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button
                                        size="large"
                                        className="light"
                                        data-id="regex-editor-dialog-cancel"
                                        onClick={cancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        size="large"
                                        data-id="regex-editor-dialog-validate"
                                        type="primary"
                                        onClick={save}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        }
                    >
                        <Form form={internalForm} layout="vertical">
                            <Form.Item
                                name="regexPattern"
                                label="Regular expression"
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input regular expression!'
                                    }
                                ]}
                                initialValue={getRegexInitialValue()}
                            >
                                <Input.TextArea autoSize={{ minRows: 2 }} onChange={_.debounce(validate, 500)} />
                            </Form.Item>
                            <Form.Item name="testString" label="Test value">
                                <Input onChange={_.debounce(validate, 500)} />
                            </Form.Item>
                            <Form.Item name="result" label="Result">
                                <Input />
                            </Form.Item>
                        </Form>
                    </Modal>
                </>
            </Form.Item>
        </div>
    );
};

export default observer(ParametersRegexEditor);
