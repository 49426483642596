import * as React from 'react';
import { CodeSnippetsVisualStore } from '../stores/CodeSnippetsVisualStore';
import { observer } from 'mobx-react-lite';
import CodeSnippetPreview from './CodeSnippetPreview';
import CodeSnippetGroupPreview from './CodeSnippetGroupPreview';

type Props = {
    store: CodeSnippetsVisualStore;
};

const CodeSnippetsTreeItemPreview: React.FC<Props> = ({ store }) => {
    return (
        <>
            {store.previewItem && (
                <>
                    {Object.getOwnPropertyNames(store.previewItem).includes('code') ? (
                        <CodeSnippetPreview store={store} />
                    ) : (
                        <CodeSnippetGroupPreview store={store} />
                    )}
                </>
            )}
        </>
    );
};

export default observer(CodeSnippetsTreeItemPreview);
