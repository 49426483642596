import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Row, Col, Button, Select, Form } from 'antd';
import { ProjectStores } from '../stores';
import { STORE_PACKAGE_PROBLEM_REPORTS_UI } from '../constants';
const FormItem = Form.Item;
const Option = Select.Option;

type Props = ProjectStores;

class PackageProblemReportsFilter extends React.Component<Props, object> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const store = this.props.packageProblemsReportsUI!;
        const packageNames = ['All packages', ...store.packageNames];
        const userNames = ['All users', ...store.userNames];

        const formItemLayout = {
            labelCol: {
                style: {
                    width: '70px'
                }
            },
            wrapperCol: {
                style: {
                    width: 'calc(100% - 70px)',
                    minWidth: '100px'
                }
            }
        };

        return (
            <Form className="ant-advanced-search-form">
                <Row gutter={24}>
                    <Col span={10} style={{ display: 'block' }}>
                        <FormItem {...formItemLayout} label="Users">
                            <Select
                                data-id={'package-problem-reports-user-filter-select'}
                                onChange={store.setCurrentUserName}
                                value={store.currentUserName}
                            >
                                {userNames.map((k, i) => (
                                    <Option
                                        data-id={`package-problem-reports-user-filter-select-${k}`}
                                        key={`kw-${i}`}
                                        value={k}
                                    >
                                        {k}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={10}>
                        <FormItem {...formItemLayout} label="Packages">
                            <Select
                                data-id={'package-problem-reports-package-filter-select'}
                                onChange={store.setCurrentPackageName}
                                value={store.currentPackageName}
                            >
                                {packageNames.map((k, i) => (
                                    <Option
                                        data-id={`package-problem-reports-package-filter-select-${k}`}
                                        key={`kw-${i}`}
                                        value={k}
                                    >
                                        {k}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={4} style={{ textAlign: 'right', display: 'block' }}>
                        <Button
                            data-id={'package-problem-reports-clear-button'}
                            className="clear-button"
                            style={{ marginLeft: 8 }}
                            onClick={() => store.clearFilter()}
                        >
                            Clear
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default inject(STORE_PACKAGE_PROBLEM_REPORTS_UI)(observer(PackageProblemReportsFilter));
