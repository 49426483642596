import { TableCellProps, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import * as React from 'react';
import { Popover, Button } from 'antd';
// @ts-ignore
import Img from 'react-image';
import { PackageLine } from '../models';
import { LoadingOutlined, PictureOutlined } from '@ant-design/icons';

// eslint-disable-next-line max-len
export default function (
    onClick: (path: string) => void,
    packageLinesGetter: () => PackageLine[],
    cache: CellMeasurerCache,
    idx: number = 1,
    dataId = 'image-renderer'
) {
    return ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const packageLines = packageLinesGetter();
        if (rowIndex === packageLines.length) {
            return null;
        }

        var img = <div />;

        const imagePath = packageLines[rowIndex].imagePath;
        if (imagePath) {
            const content = (
                <Img
                    className="preview-image"
                    src={process.env.REACT_APP_IMAGE_URL + `?path=${encodeURIComponent(imagePath)}`}
                    loader={<LoadingOutlined />}
                />
            );

            img = (
                <Popover content={content} title={imagePath} autoAdjustOverflow placement="right">
                    <Button
                        icon={<PictureOutlined />}
                        shape="circle"
                        type="primary"
                        onClick={() => onClick(imagePath!)}
                    />
                </Popover>
            );
        }

        return (
            <CellMeasurer
                cache={cache}
                columnIndex={idx}
                key={dataKey}
                parent={parent}
                className={'tableColumn'}
                rowIndex={rowIndex}
            >
                <div
                    data-id-cells={dataId}
                    style={{
                        whiteSpace: 'normal',
                        padding: '0px'
                    }}
                >
                    {img}
                </div>
            </CellMeasurer>
        );
    };
}
