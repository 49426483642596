import ErrorStore from './ErrorStore';
import ProjectsStore from './ProjectsStore';
import { ProjectsService } from '../services';
import { RouterStore } from './RouterStore';
import { createBrowserHistory } from 'history';
import ProjectsRootVisualStore from './ProjectsRootVisualStore';
import { STORE_PROJECTS, STORE_PROJECTS_ROOT_UI, STORE_ROUTER, STORE_ERRORS, STORE_ALPHA_CONFIG } from '../constants';
import { AlphaConfigVisualStore } from '../../../modules/administration/stores/AlphaConfigVisualStore';
import { GlobalAdministrationService } from '../../../modules/administration/service/GlobalAdministrationService';

export { default as ErrorStore } from './ErrorStore';
export { default as ProjectsRootVisualStore } from './ProjectsRootVisualStore';
export { default as ProjectsStore } from './ProjectsStore';
export { default as RouterStore } from './RouterStore';

export type RootStores = {
    [STORE_PROJECTS]: ProjectsStore;
    [STORE_PROJECTS_ROOT_UI]: ProjectsRootVisualStore;
    [STORE_ROUTER]: RouterStore;
    [STORE_ERRORS]: ErrorStore;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & { [index: string]: any };

export function injectRootStores(obj: RootStores) {
    const history = createBrowserHistory({
        basename: '/'
    });
    const service = new ProjectsService();
    const routerStore = new RouterStore(history);
    const errorStore = new ErrorStore();
    const projectsStore = new ProjectsStore(service, routerStore, errorStore);
    const projectsVisualStore = new ProjectsRootVisualStore(routerStore, projectsStore, errorStore, service);
    const adminService = new GlobalAdministrationService();
    const auditConfigVisualStore = new AlphaConfigVisualStore(adminService, obj[STORE_ERRORS]);
    obj[STORE_PROJECTS] = projectsStore;
    obj[STORE_PROJECTS_ROOT_UI] = projectsVisualStore;
    obj[STORE_ROUTER] = routerStore;
    obj[STORE_ERRORS] = errorStore;
    obj[STORE_ALPHA_CONFIG] = auditConfigVisualStore;

    return { history, projectsStore, projectsVisualStore, errorStore, routerStore };
}
