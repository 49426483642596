import * as Highlight from 'react-highlighter';
import * as React from 'react';
import { AutoCompleteText } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function (searchTerm: string): any {
    return function (item: AutoCompleteText, idx: number) {
        return (
            <div className="global-search-item-container" data-id={`label-search-autocomplete-item-${idx}`}>
                <div className="global-search-item-result">
                    <Highlight search={searchTerm}>{item.text}</Highlight>
                </div>
                <div className="global-search-item-count">{item.page + 1}</div>
            </div>
        );
    };
}
