import * as React from 'react';
import { observer, inject } from 'mobx-react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import ProjectApplicationDefinitionEditInputs from '../components/ProjectApplicationDefinitionEditInputs';
import { IotaAppStores } from '../stores';
import { STORE_PROJECT_APPLICATION_DEFINITION_EDIT } from '../stores/constants';
import { ApplicationDefinitionConditional } from '../types/ApplicationDefinition';
import ApplicationDefinitionConditionalEditPage from '../../application_definition_conditional/screens/ApplicationDefinitionConditionalEditPage';

type Props = IotaAppStores;

export const ProjectApplicationDefinitionEditPage: React.FC<Props> = ({
    projectApplicationDefinitionEditUI: store
}) => {
    if (!store || !store.currentAppDef) {
        return <FlexContentContainer content={<div />} />;
    }

    if (store.currentAppDef instanceof ApplicationDefinitionConditional) {
        return <ApplicationDefinitionConditionalEditPage />;
    }

    return (
        <FlexContentContainer
            id="input-bindings-container"
            content={<ProjectApplicationDefinitionEditInputs projectApplicationDefinitionEditUI={store} />}
            contentCssClass=" "
        />
    );
};

export default inject(STORE_PROJECT_APPLICATION_DEFINITION_EDIT)(observer(ProjectApplicationDefinitionEditPage));
