import { gql } from 'apollo-boost';
import { execQuery } from '../../common/services/DataService';
import InferenceModel, { GetConnectionsResult, AllModelsResult, InferenceModelsForProjectResult } from '../types';
import { Connection } from '../models';

export default class InferenceModelsService {
    async getModels(projectId?: string, type?: string[]): Promise<InferenceModel[]> {
        let result;

        if (projectId) {
            result = await this.executeByProject(projectId, type);
        } else {
            result = await this.execute();
        }

        return result;
    }

    async getConnections(): Promise<Connection[]> {
        const result = await this.executeConnectionQuery();

        if (result.errors) {
            throw result.errors[0];
        }

        return result.data.getConnections.map(x => new Connection(x));
    }

    private async execute() {
        const result = await execQuery<AllModelsResult>({
            query: gql`
                query getModels {
                    allInferenceModels {
                        id
                        type
                        version
                        storagePath
                        labels
                        description
                        name
                    }
                }
            `,
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.allInferenceModels;
    }

    private async executeByProject(projectId: string, type?: string[]) {
        const result = await execQuery<InferenceModelsForProjectResult>({
            query: gql`
                query getModels($projectId: String!, $type: [String]) {
                    inferenceModelsForProject(projectId: $projectId, type: $type) {
                        id
                        type
                        version
                        storagePath
                        labels
                        description
                        name
                    }
                }
            `,
            variables: {
                projectId,
                type
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.inferenceModelsForProject;
    }

    private executeConnectionQuery() {
        return execQuery<GetConnectionsResult>({
            query: gql`
                query getConnections {
                    getConnections {
                        id
                        name
                        connectionType
                        connectionString
                    }
                }
            `,
            fetchPolicy: 'network-only'
        });
    }
}
