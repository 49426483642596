import { RulesService } from '../services';
import { action, runInAction } from 'mobx';
import { ErrorStore, ProjectsRootVisualStore } from '../../common/stores';
import { RulesStore } from '.';
import PreviewVisualStoreBase from './PreviewVisualStoreBase';

export default class TagPreviewVisualStore extends PreviewVisualStoreBase {
    isEditable: boolean = true;

    constructor(
        private rulesService: RulesService,
        public projectStore: ProjectsRootVisualStore,
        public rulesStore: RulesStore,
        private errorStore: ErrorStore
    ) {
        super(projectStore, rulesStore);
    }

    @action.bound
    setPreviewedTag(tagId: string | undefined) {
        this.rulesStore.setPreviewedTagId(tagId);
    }

    @action.bound
    async initUsingProjectId(projectId: string) {
        await this.rootStore.loadProjects();
        const project = this.rootStore.projects.find(p => p.id === projectId);

        if (!project) {
            return;
        }

        await this.projectStore.setupCurrentProject(project);
        await this.rulesStore.getRules();

        this.setSelectedTag(this.selectedItemLabel);
    }

    @action.bound
    editRule(ruleId: string | null) {
        if (ruleId) {
            this.rulesStore.rulesEditSubject.next(ruleId);
        }
    }

    @action.bound
    async loadPreviewResults() {
        if (this.selectedItemLabel && this.selectedPackageIds.length) {
            runInAction(() => {
                this.isExecuting = true;
            });

            try {
                const tagId = this.rulesStore.ruleTags.find(x => x.name === this.selectedItemLabel)!.id!;

                const previewResultsResp = await this.rulesService.getTagRulesPreviewWithPackageId(
                    this.currentProject!.id,
                    tagId,
                    this.selectedPackageIds
                );

                if (previewResultsResp.isErr()) {
                    const errorMessage = previewResultsResp.error.data?.title ?? previewResultsResp.error.text;
                    this.errorStore.addBasicError(new Error(errorMessage));
                }

                if (previewResultsResp.isOk() && previewResultsResp.value.length) {
                    runInAction(() => {
                        this.previewResults = previewResultsResp.value;
                    });
                }
            } catch (error) {
                this.errorStore.addBasicError(error);
            } finally {
                runInAction(() => {
                    this.isExecuting = false;
                });
            }
        }
    }

    @action.bound
    setSelectedTag(tagId: string | undefined) {
        const tag = this.rulesStore.ruleTags.find(t => t.id === tagId);
        this.setPreviewedTag(tagId);
        this.selectedItemLabel = tag ? tag.name : tagId;
    }

    @action.bound
    goToInitialList() {
        if (this.project) {
            this.projectStore.navigateToRulesPage(this.project);
        }
    }

    setUrl() {
        return;
    }

    getPackageNameById(id: string) {
        if (
            this.project &&
            this.loadedPackages[this.project.id] &&
            this.loadedPackages[this.project.id].find(p => p.id === id)
        ) {
            return this.loadedPackages[this.project.id].find(p => p.id === id)!.name;
        }

        return id;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setItem(m: any) {
        this.setSelectedTag(m.tag);
    }
}
