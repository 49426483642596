/* eslint-disable dot-notation */
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { HistoryField } from '../../services/types';
import { formatFieldName, valueGetter } from '../../misc/helpers';
import BaseHistoryRecordModel from '../../models/BaseHistoryRecordModel';
import HistoryFieldModal from './HistorySerializedFieldModal';
import './HistoryGrid.less';

interface Props {
    historyRecord: BaseHistoryRecordModel;
}

const HistoryRecordExpandedRow: React.FC<Props> = ({ historyRecord }) => {
    const [selectedSerializedField, setSelectedSerializedField] = React.useState<HistoryField | null>(null);

    const columns: ColumnsType<HistoryField> = React.useMemo(() => {
        return [
            {
                title: 'Field',
                dataIndex: 'name',
                key: 'name',
                width: '25%',
                render: (value: string) => formatFieldName(value)
            },
            {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',
                width: '25%',
                render: (_: unknown, record: HistoryField) => {
                    if (record.serialized) {
                        return (
                            <div className="expanded-row-value">
                                <a onClick={() => setSelectedSerializedField(record)}>{record.value as string}</a>
                            </div>
                        );
                    }

                    return <div className="expanded-row-value">{valueGetter(record.value)}</div>;
                }
            },
            {
                title: 'Previous Value',
                dataIndex: 'prevValue',
                key: 'prevValue',
                width: '25%',
                render: (_: unknown, record: HistoryField) => {
                    if (record.serialized) {
                        return (
                            <div className="expanded-row-value">
                                <a onClick={() => setSelectedSerializedField(record)}>{record.prevValue as string}</a>
                            </div>
                        );
                    }

                    return <div className="expanded-row-value">{valueGetter(record.prevValue)}</div>;
                }
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                width: '25%'
            }
        ];
    }, []);

    return (
        <>
            <HistoryFieldModal
                historyField={selectedSerializedField}
                onClose={() => setSelectedSerializedField(null)}
            />

            <Table
                className="nested-history-table"
                rowKey={r => r.name}
                dataSource={historyRecord.fields}
                columns={columns}
                pagination={false}
            />
        </>
    );
};

export default observer(HistoryRecordExpandedRow);
