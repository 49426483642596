import { Form, Input, InputNumber } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

const ProjectNameStep: React.FC = () => {
    return (
        <>
            <Form.Item
                label="Project name"
                name="name"
                rules={[{ required: true, message: 'Please input test project name!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Default baseline fuzzy"
                name="defaultBaselineFuzzy"
                initialValue={100}
                rules={[{ required: true, message: 'Please input default baseline fuzzy!' }]}
            >
                <InputNumber min={0} max={100} style={{ width: 200 }} />
            </Form.Item>
        </>
    );
};

export default observer(ProjectNameStep);
