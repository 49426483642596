import { gql } from 'apollo-boost';
import { execQuery } from '../../common/services/DataService';
import { IotaExtension } from '../../iota_applications/types';
import { ConnectionsDefinitionsResult, ConnectionsDefinition, ConnectionsDefinitionCreateModel } from '../types';
import appClient from '../../common/services/AppClient';
import { ResultApi } from '../../common/services/AppClient';
import { Utils } from '../../common/services/Utils';

export default class ConnectionsDefinitionsService {
    async getAll(): Promise<ConnectionsDefinition[]> {
        const result = await execQuery<ConnectionsDefinitionsResult>({
            query: gql`
                query getDefinitions {
                    getConnectionsDefinitions {
                        id
                        name
                        projectId
                        applicationId
                        workflowId
                        settings
                        settingValues
                        connections
                    }
                }
            `,
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getConnectionsDefinitions || [];
    }

    async getForProject(projectId: string): Promise<ConnectionsDefinition[]> {
        const result = await execQuery<ConnectionsDefinitionsResult>({
            query: gql`
                query getDefinitions($projectId: String!) {
                    getConnectionsDefinitions(projectId: $projectId) {
                        id
                        name
                        projectId
                        applicationId
                        workflowId
                        settings
                        settingValues
                        connections
                    }
                }
            `,
            variables: {
                projectId: projectId
            },
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getConnectionsDefinitions || [];
    }

    async get(projectId: string | null, id: string | null): Promise<ConnectionsDefinition | null> {
        const result = await execQuery<ConnectionsDefinitionsResult>({
            query: gql`
                query getDefinitions($projectId: String, $id: String!) {
                    getConnectionsDefinitions(projectId: $projectId, id: $id) {
                        id
                        name
                        projectId
                        applicationId
                        workflowId
                        settings
                        settingValues
                        connections
                    }
                }
            `,
            variables: {
                projectId: projectId,
                id: id
            },
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        const arr = result.data.getConnectionsDefinitions || [];
        if (!arr.length) {
            return null;
        }

        return arr[0];
    }

    async getMetadata(): Promise<IotaExtension[]> {
        const url = process.env.REACT_APP_IOTA_URL + 'metadata';
        const resp = await appClient.get<IotaExtension[]>(url);
        return resp.unwrapOr(Array<IotaExtension>());
    }

    async exportConnection(id: string, projectId: string) {
        return Utils.CreateTabForDownload(id, `projects/${projectId}/iota_connections/export`);
    }

    create(model: ConnectionsDefinitionCreateModel, projectId: string): Promise<ResultApi<string>> {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota_connections`;
        return appClient.post(url, model);
    }

    delete(id: string, projectId: string): Promise<ResultApi<unknown>> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/iota_connections/${id}`;
        return appClient.delete(url);
    }

    edit(formData: FormData, id: string, projectId: string): Promise<ResultApi<unknown>> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/iota_connections/${id}`;
        return appClient.post(url, formData);
    }

    updateSettings(id: string, settings: { [id: string]: unknown }, projectId: string): Promise<ResultApi<unknown>> {
        const request = {
            settingValues: settings
        };

        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/iota_connections/${id}/settings`;
        return appClient.update(url, request);
    }

    executeConnection(projectId: string, definitionId: string, connectionId: string): Promise<ResultApi<JSON>> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/iota_connections/test/${definitionId}/${connectionId}`;
        const requestBody = {
            page: 0,
            pageSize: 10,
            connectionId: connectionId,
            fields: ['text', 'value'],
            filters: undefined
        };

        return appClient.post<JSON>(url, requestBody);
    }
}
