import { AutoComplete, Button, Col, Row, Select, Table, Tabs, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Package } from '../../../common/models';
import { ProjectPackagesLoadingIndicator } from '..';
import { TestProjectWizardStore } from '../../stores';
import { Utils } from '../../../common/services/Utils';
import { ColumnProps } from 'antd/lib/table';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
    store: TestProjectWizardStore;
    selectedPackages?: string[];
    setSelectedPackages?: (packages: string[]) => void;
    skipPackageLoading?: boolean;
};

const DocumentSelectionStep: React.FC<Props> = ({
    store,
    selectedPackages,
    setSelectedPackages,
    skipPackageLoading
}) => {
    const [tablePage, setTablePage] = React.useState(1);
    const [selectedTab, setSelectedTab] = React.useState('1');
    const [selectedPackagesAutocomplete, setSelectedPackagesAutocomplete] = React.useState('');
    const [selectedPackagesFilter, setSelectedPackagesFilter] = React.useState('');

    const selectedPackagesSource = store.getPackagesByIds(selectedPackages ?? store.selectedPackages);

    React.useEffect(() => {
        // Check for which project there are loaded packages (?)
        if (store.currentProject) {
            //  && store.packages.filter(p => p.project.id === store.currentProject!.id).length === 0
            setTablePage(1);
            setSelectedTab('1');

            if (skipPackageLoading !== true) {
                store.loadAllProjectPackages();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject]);

    React.useEffect(() => {
        setTablePage(1);
    }, [store.filteredPackages]);

    React.useEffect(() => {
        if (!store.isCreationWizardVisible) {
            setTablePage(1);
        }
    }, [store.isCreationWizardVisible]);

    const onAutocompleteSearh = (value: string) => {
        store.setAutocompleteValue(value);
        store.setSearchTerm(value);
    };

    const onSelectedPackagesAutocompleteSearch = (value: string) => {
        setSelectedPackagesAutocomplete(value);
        setSelectedPackagesFilter(value);
    };

    const columns: ColumnProps<Package>[] = [
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Name',
            sorter: (a: Package, b: Package) => Utils.safeLocaleCompare(a.name, b.name),
            render: (text: string, record: Package) => (
                <span data-id={`Name-${record.name}`} style={{ whiteSpace: 'pre-wrap' }}>
                    {record.name}
                </span>
            )
        },
        {
            key: 'tags',
            dataIndex: 'tags',
            title: 'Tags',
            sorter: (a: Package, b: Package) => Utils.safeLocaleCompare(a.userTags?.join(', '), b.userTags?.join(', ')),
            render: (_: unknown, record: Package) => (
                <div data-id={`Tags-${record.name}`}>
                    <Tooltip title={record.userTags?.join(', ')}>{record.userTags?.join(', ')}</Tooltip>
                </div>
            )
        },
        {
            key: 'uploadDate',
            dataIndex: 'uploadDate',
            title: 'Upload date',
            sorter: (a: Package, b: Package) => Utils.safeDateCompare(a.uploadDate, b.uploadDate),
            render: (uploadDate: string, record: Package) => (
                <span data-id={`UploadDate-${record.name}`} data-id-timestamp={uploadDate}>
                    {Utils.formatDateStringShort(uploadDate, true)}
                </span>
            )
        }
    ];

    const selectedItemColumns: ColumnProps<Package>[] = [
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Name',
            sorter: (a: Package, b: Package) => Utils.safeLocaleCompare(a.name, b.name),
            render: (_: string, record: Package) => {
                return (
                    <span
                        data-id={`Selected-Name-${record.name}`}
                        style={{ color: getListItemColor(record.id), whiteSpace: 'pre-wrap' }}
                    >
                        {record.name}
                    </span>
                );
            }
        },
        {
            key: 'tags',
            dataIndex: 'tags',
            title: 'Tags',
            sorter: (a: Package, b: Package) => Utils.safeLocaleCompare(a.userTags?.join(', '), b.userTags?.join(', ')),
            render: (_: string, record: Package) => {
                return (
                    <div data-id={`Selected-Tags-${record.name}`}>
                        <Tooltip title={record.userTags?.join(', ')}>{record.userTags?.join(', ')}</Tooltip>
                    </div>
                );
            }
        },
        {
            key: 'uploadDate',
            dataIndex: 'uploadDate',
            title: 'Upload date',
            sorter: (a: Package, b: Package) => Utils.safeDateCompare(a.uploadDate, b.uploadDate),
            render: (_: string, record: Package) => {
                return (
                    <span data-id={`Selected-UploadDate-${record.name}`} data-id-timestamp={record.uploadDate}>
                        {Utils.formatDateStringShort(record.uploadDate?.toString(), true)}
                    </span>
                );
            }
        },
        {
            key: 'actions',
            title: '',
            width: 64,
            render: (_: string, record: Package) => {
                return (
                    <Button
                        key={record.id + '-delete'}
                        type="link"
                        icon={<CloseOutlined />}
                        onClick={() => handleSelection(record.id, false)}
                    />
                );
            }
        }
    ];

    const handleSelectedPackagesUpdate = (newPkgs: string[]) => {
        if (setSelectedPackages) {
            setSelectedPackages(newPkgs);
        } else {
            store.setSelectedPackages(newPkgs);
        }
    };

    const handleSelection = (pkgId: string, selected: boolean) => {
        const actualSelectedPackages = selectedPackages ?? store.selectedPackages;

        if (selected) {
            handleSelectedPackagesUpdate([...actualSelectedPackages, pkgId]);
        } else {
            handleSelectedPackagesUpdate(actualSelectedPackages.filter(p => p !== pkgId));
        }
    };

    const handleMultipleSelection = (selected: boolean, selectedRows: Package[], changeRows: Package[]) => {
        const actualSelectedPackages = selectedPackages ?? store.selectedPackages;
        if (selected) {
            handleSelectedPackagesUpdate([...actualSelectedPackages, ...changeRows.map(r => r.id)]);
        } else {
            handleSelectedPackagesUpdate(actualSelectedPackages.filter(p => !changeRows.map(r => r.id).includes(p)));
        }
    };

    const getListItemColor = (pkgId: string) => {
        return !store.allPackages.find(p => p.id === pkgId) ? 'red' : 'black';
    };

    return (
        <div style={{ maxHeight: 'calc(100vh - 280px)' }}>
            <Tabs defaultActiveKey="1" activeKey={selectedTab} onChange={setSelectedTab}>
                <Tabs.TabPane tab="Search packages" key="1">
                    <ProjectPackagesLoadingIndicator
                        isLoadingPackages={store.isLoadingPackages}
                        loadingPackagesProgress={store.loadingPackagesProgress}
                        loadingPackagesErrorMessage={store.loadingPackagesErrorMessage}
                    />
                    <Row gutter={16}>
                        <Col span={12}>
                            <AutoComplete
                                style={{ width: '100%' }}
                                options={store.autocompleteOptions.map(option => ({
                                    value: option,
                                    label: <span style={{ whiteSpace: 'pre' }}>{option}</span>
                                }))}
                                placeholder="Search for packages"
                                value={store.autocompleteValue}
                                onSearch={store.setAutocompleteValue}
                                onSelect={onAutocompleteSearh}
                                onClear={() => onAutocompleteSearh('')}
                                allowClear
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        onAutocompleteSearh(store.autocompleteValue);
                                    }
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <Select
                                options={store.packageTags.map(t => ({ value: t }))}
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Filter by tags"
                                value={store.selectedTags}
                                onChange={store.setSelectedTags}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Table
                        scroll={{ y: 'calc(100vh - 500px)' }}
                        data-id="test-project-packages-list"
                        className="alpha-portal-table"
                        dataSource={store.filteredPackages}
                        loading={store.isLoadingPackages}
                        columns={columns}
                        pagination={{
                            pageSize: 50,
                            showSizeChanger: false,
                            hideOnSinglePage: true,
                            showLessItems: true,
                            onChange: setTablePage,
                            current: tablePage
                        }}
                        rowKey={(record: Package) => record.id}
                        rowSelection={{
                            selectedRowKeys: selectedPackages ?? store.selectedPackages,
                            onSelect: (record: Package, selected: boolean) => handleSelection(record.id, selected),
                            onSelectMultiple: handleMultipleSelection,
                            onSelectAll: handleMultipleSelection,
                            columnWidth: 48
                        }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Selected packages" key="2">
                    <Row gutter={16}>
                        <Col span={12}>
                            <AutoComplete
                                style={{ width: '100%' }}
                                options={selectedPackagesSource
                                    .filter(s =>
                                        s.name.toLowerCase().includes(selectedPackagesAutocomplete.toLowerCase())
                                    )
                                    .map(source => ({
                                        value: source.name,
                                        label: <span style={{ whiteSpace: 'pre' }}>{source.name}</span>
                                    }))}
                                value={selectedPackagesAutocomplete}
                                onSearch={setSelectedPackagesAutocomplete}
                                placeholder="Search for packages"
                                onSelect={onSelectedPackagesAutocompleteSearch}
                                onClear={() => onSelectedPackagesAutocompleteSearch('')}
                                allowClear
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        onSelectedPackagesAutocompleteSearch(selectedPackagesAutocomplete);
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Table
                        scroll={{ y: 'calc(100vh - 500px)' }}
                        data-id="test-project-selected-packages-list"
                        className="alpha-portal-table"
                        dataSource={
                            selectedPackagesFilter
                                ? selectedPackagesSource.filter(s =>
                                      s.name.toLowerCase().includes(selectedPackagesFilter.toLowerCase())
                                  )
                                : selectedPackagesSource
                        }
                        loading={store.isLoadingPackages}
                        columns={selectedItemColumns}
                        pagination={{
                            pageSize: 50,
                            showSizeChanger: false,
                            hideOnSinglePage: true,
                            showLessItems: true,
                            onChange: setTablePage,
                            current: tablePage
                        }}
                        rowKey={(record: Package) => record.id}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

export default observer(DocumentSelectionStep);
