import * as React from 'react';
import ProjectConnectionsDefinitionsPage from '../screens/ProjectConnectionsDefinitionsPage';
import { ProtectedRoute } from '../../common/components';
import EditConnectionsSettingsPage from '../screens/EditConnectionsSettingsPage';

export const IotaConnPagesNavigation = {
    ProjectConnectionsDefinitionsPage: '/projects/:id/connectionsDefinitions',
    ConnectionsDefinitionEditPage: '/projects/:id/connectionsDefinitions/:appId',
    ProjectConnectionsDefinitionsSettings: '/projects/:id/connectionsSettings/:appId'
};

export default () => {
    return (
        <React.Fragment>
            <ProtectedRoute
                exact
                component={ProjectConnectionsDefinitionsPage}
                path={IotaConnPagesNavigation.ProjectConnectionsDefinitionsPage}
            />
            <ProtectedRoute
                component={EditConnectionsSettingsPage}
                path={IotaConnPagesNavigation.ProjectConnectionsDefinitionsSettings}
            />
        </React.Fragment>
    );
};
