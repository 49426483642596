import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Row, Col, Table, Tooltip } from 'antd'; // Input,
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineVisualStore from '../stores/PipelineVisualStore';
import { Parameter } from '../types';
import AceEditor from 'react-ace';
import 'brace/mode/mysql';
import 'brace/theme/xcode';
import Form, { FormInstance } from 'antd/lib/form';

// const { TextArea } = Input;

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};

type RefDataItem = {
    id: string;
    value: string;
};

type TableColumn = {
    title: string;
    dataIndex: string;
};

export const ParametersSqlQueryEditor: <
    T extends PipelineVisualStore<TPipelineStepType>,
    TPipelineStepType extends string
>(
    props: Props<T, TPipelineStepType>
) => React.ReactElement<Props<T, TPipelineStepType>> = ({ param, form, toolTip, step, store, stepKey }) => {
    const [showModal, setShowModal] = React.useState(false);
    const [value, setValue] = React.useState(form.getFieldValue(param.name) || step.getParameterValues(param.name));
    const [queryResults, setQueryResults] = React.useState<RefDataItem[]>([]);
    const [showResults, setShowResults] = React.useState(false);
    const [error, setError] = React.useState('');
    const [columns, setColumns] = React.useState<TableColumn[]>([]);

    const handleSubmit = async () => {
        await store.validateForm(form, step, stepKey, false);
    };

    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    const hideDialogAndResetState = () => {
        setShowModal(false);
        setError('');
        setQueryResults([]);
        setShowResults(false);
        setColumns([]);
    };

    const save = () => {
        const data = { query: value };
        form.setFieldsValue(data);
        store.addParameters(data, step, false);
        hideDialogAndResetState();
    };

    const cancel = () => {
        setValue(form.getFieldValue(param.name));
        hideDialogAndResetState();
    };

    const allowDebug = () => {
        const conn = form.getFieldValue('connection_id');
        return conn && conn.trim() !== '' && value && value.trim() !== '';
    };

    const getColumns = (data: unknown) => {
        let obj: unknown;
        if (Array.isArray(data)) {
            obj = data[0];
        } else {
            obj = data;
        }
        const keys = Object.getOwnPropertyNames(obj);
        let tableCols: TableColumn[] = [];
        for (let key of keys) {
            tableCols.push({ dataIndex: key, title: key[0].toUpperCase() + key.substr(1) });
        }
        return tableCols;
    };

    const executeQuery = async () => {
        const conn = form.getFieldValue('connection_id');
        const res = await store.executeBusinessRuleQuery(conn, value);
        res.map(data => {
            setColumns(getColumns(data));
            setQueryResults(data as []);
            setError('');
            setShowResults(true);
        }).mapErr(err => {
            setError(err.data?.title ?? '');
            setShowResults(false);
            setColumns([]);
        });
    };

    return (
        <div>
            <Form.Item
                label={toolTip}
                name={param.name}
                rules={[
                    {
                        required: !step.isDisabled && param.required,
                        message: 'Please input parameter value'
                    }
                ]}
                initialValue={param.defaultValue}
            >
                <>
                    <Tooltip title={value} overlayClassName="code-tooltip-overlay">
                        <Button
                            type={value && value.trim() !== '' ? 'primary' : 'ghost'}
                            onClick={() => setShowModal(true)}
                        >
                            {value && value.trim() !== '' ? 'Edit' : 'Add'}
                        </Button>
                    </Tooltip>
                    <Modal
                        visible={showModal}
                        onCancel={cancel}
                        onOk={save}
                        width="70%"
                        centered
                        footer={
                            <Row align="middle">
                                <Col span={12} style={{ textAlign: 'left' }}>
                                    <Button
                                        data-id="query-editor-dialog-debug"
                                        type="link"
                                        disabled={!allowDebug()}
                                        onClick={executeQuery}
                                    >
                                        Debug
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button data-id="query-editor-dialog-cancel" type="ghost" onClick={cancel}>
                                        Cancel
                                    </Button>
                                    <Button data-id="query-editor-dialog-save" type="primary" onClick={save}>
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        }
                    >
                        <div data-id="query-editor-dialog-content">
                            <AceEditor
                                mode="mysql"
                                theme="xcode"
                                name={`${stepKey}-query-editor`}
                                editorProps={{ $blockScrolling: true }}
                                value={value}
                                width="calc(100% - 30px)"
                                onChange={(code: string) => setValue(code)}
                            />
                            {showResults && (
                                <Table
                                    rowKey={(r, i) => `${r.id}-${i}`}
                                    columns={columns}
                                    dataSource={queryResults}
                                    pagination={{ pageSize: 5 }}
                                    size="small"
                                />
                            )}
                            {error && error.trim() !== '' && <div style={{ color: 'red' }}>{error}</div>}
                        </div>
                    </Modal>
                </>
            </Form.Item>
        </div>
    );
};

export default observer(ParametersSqlQueryEditor);
