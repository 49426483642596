/* eslint-disable @typescript-eslint/member-ordering */
import { observable, computed, action } from 'mobx';
import PackageLineProperties from './PackageLineProperties';
import Package from './Package';
import PageCoordinates from './PageCoordinates';
import { BLOCK_TYPE } from '../types/BlockType';
import PackageLineState from './PackageLineState';
import Label from './Label';

export default class PackageLine {
    properties: PackageLineProperties = {};

    readonly pkg: Package;

    readonly packageId: string;

    readonly x: number;

    readonly y: number;

    readonly w: number;

    readonly h: number;

    readonly page: number;

    readonly pageHeight: number;

    @observable
    label: Label | null;

    shortText: string;

    @computed
    get packageName() {
        return this.pkg.name;
    }

    @computed
    get labelText() {
        if (this.label === Label.None) {
            return null;
        }

        const label = this.label || this.initialLabel;
        return (label && label.text) || null;
    }

    @computed
    get state() {
        const labelText = this.label || this.initialLabel;

        if (labelText !== this.initialLabel && !(this.label === Label.None && !this.initialLabel)) {
            return PackageLineState.ChangedLabel;
        }

        return PackageLineState.Unmodified;
    }

    constructor(
        public id: string,
        pkg: Package,
        public rowId: number,
        public text: string,
        public normalizedText: string,
        public readonly initialLabel: Label | null = null,
        props: PackageLineProperties | null = null,
        public imagePath: string | null = null,
        public coordinates: PageCoordinates | null = null,
        public readonly blockType: BLOCK_TYPE = 'TEXTBOX_BLOCK'
    ) {
        this.pkg = pkg;

        const res = pkg.project.additionalProperties;

        if (props) {
            for (let r of res) {
                this.properties[r] = props[r] || 'Empty';
            }
        } else {
            for (let r of res) {
                this.properties[r] = 'Empty';
            }
        }

        if (this.text.length > 50) {
            this.shortText = this.text.substring(0, 50) + '...';
        } else {
            this.shortText = this.text;
        }
    }

    @action
    setLabel(label: Label | null) {
        if (!label) {
            this.label = Label.None;
        } else {
            this.label = label;
        }
    }

    @action
    reset() {
        this.label = this.initialLabel;
    }
}
