import ProjectLabelsVisualStore from './ProjectLabelsVisualStore';
import { RootStores } from '../../common/stores';
import { STORE_PROJECT_LABELS } from '../constants';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';

export { default as ProjectLabelsVisualStore } from './ProjectLabelsVisualStore';

export function injectListLabelsStores(obj: RootStores) {
    obj[STORE_PROJECT_LABELS] = new ProjectLabelsVisualStore(obj[STORE_PROJECTS_ROOT_UI]);
}

export type ListLabelsStores = {
    [STORE_PROJECT_LABELS]?: ProjectLabelsVisualStore;
};
