import { Button, Form, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { RuleEditForm } from '.';
import { RuleTypes } from '../models';
import { RulesStores } from '../stores';
import RuleType from '../types/RuleType';

type Props = RulesStores & {
    saveHandler: () => void;
};

const RuleEditSettingsDialog: React.FC<Props> = ({ RuleEditUI, saveHandler }) => {
    const [isValidationFailed, setIsValidationFailed] = React.useState(false);
    const editStore = RuleEditUI!;
    const [form] = Form.useForm();
    let ruleClone = React.useRef<RuleTypes | undefined>(undefined);

    React.useEffect(() => {
        if (editStore.isEditSettingsDialogVisible && editStore.editableRule) {
            const clone = editStore.editableRule.clone();
            ruleClone.current = clone;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editStore.isEditSettingsDialogVisible]);

    const applyFields = () => {
        const formFields = Object.entries(form.getFieldsValue(true)).map(([key, value]) => ({ name: [key], value }));
        editStore.applyFields(formFields);
    };

    const selectType = (type: RuleType) => {
        const pipeline = editStore.editableRule?.pipeline;
        applyFields();
        editStore.selectNewType(pipeline!, type);
    };

    const handleCancel: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.preventDefault();
        if (ruleClone.current) {
            editStore.setRule(ruleClone.current);
            form.setFieldsValue({ ruleType: ruleClone.current.ruleType });
        }
        editStore.setIsEditSettingsDialogVisible(false);
    };

    const handleSubmit = async () => {
        form.validateFields()
            .then(async () => {
                const formFieldsValue = form.getFieldsValue();

                const transformedFormFieldsValue = Object.entries(formFieldsValue).reduce((acc, [field, value]) => {
                    if (Array.isArray(value) && value.every(item => item.key && item.value)) {
                        acc[field] = value.reduce(
                            (transformed: Record<string, string>, param: { key: string; value: string }) => {
                                if (param.key) {
                                    transformed[param.key] = param.value;
                                }
                                return transformed;
                            },
                            {}
                        );
                    } else {
                        acc[field] = value;
                    }
                    return acc;
                }, {});

                const formFields = Object.entries(transformedFormFieldsValue).map(([key, value]) => ({
                    name: [key],
                    value
                }));

                editStore.applyFields(formFields);
                editStore.setIsEditSettingsDialogVisible(false);
                setIsValidationFailed(false);
                saveHandler();
            })
            .catch(() => {
                setIsValidationFailed(true);
            });
    };

    const getHeaderButtons = () => {
        let buttons = [
            <Button key="cancel" size="large" className="light" htmlType="button" onClick={handleCancel}>
                Cancel
            </Button>,
            <Button loading={editStore.isLoading} key="submit" type="primary" size="large" onClick={handleSubmit}>
                Apply rule settings
            </Button>
        ];
        return buttons;
    };

    return (
        <Modal
            title="Edit Rule Settings"
            visible={editStore.isEditSettingsDialogVisible}
            onCancel={handleCancel}
            footer={getHeaderButtons()}
            destroyOnClose
            centered
            closable={false}
            maskClosable={false}
            className="alpha-portal-dialog two-columns"
            width={600}
        >
            <RuleEditForm form={form} selectType={selectType} isValidationFailed={isValidationFailed} />
        </Modal>
    );
};

export default observer(RuleEditSettingsDialog);
