import * as React from 'react';
import { Moment } from 'moment';
import RuleType from '../rules/types/RuleType';
import { CodeSnippetGroup } from '../administration/models';

export function addNewLinesInCopyBuffer() {
    document.addEventListener('copy', handleCopyBuffer, true);
}

export function removeAddNewLinesInCopyBuffer() {
    document.removeEventListener('copy', handleCopyBuffer, true);
}

function handleCopyBuffer(e: ClipboardEvent) {
    const text = window!.getSelection()!.toString();
    const updatedText = text.replace(/\\n/g, '\n').replace(/\\t/g, '\t');
    e.clipboardData!.setData('text/plain', updatedText);
    e.preventDefault();
}

export function getFriendlyRuleTypeName(t: RuleType) {
    switch (t) {
        case 'RefDataRule':
            return 'Reference data search';
        case 'ElasticSearchMatchRule':
            return 'Words search';
        case 'ElasticSearchMatchPhraseRule':
            return 'Phrase search';
        case 'ElasticSearchQueryRule':
            return 'Query search';
        case 'ElasticSearchRawRule':
            return 'Elastic search';
        case 'ElasticSearchScriptQueryRule':
            return 'Elastic search script query';
        case 'ElasticSearchFuzzyQueryRule':
            return 'Elastic search fuzzy query';
        case 'ElasticSearchRegexpQueryRule':
            return 'Elastic search regexp query';
        case 'ElasticSearchWildcardQueryRule':
            return 'Elastic search wildcard query';
        case 'InferenceRule':
            return 'Inference search';
        case 'NamedEntitiesRecognitionRule':
            return 'Entities search';
        case 'SmartIndexRule':
            return 'Smart index';
        default:
            return 'Undefined';
    }
}

export function getFullSnippetPath(groupId: string, codeSnippetGroups: CodeSnippetGroup[], path: string = ''): string {
    const group = getSnippetGroupById(groupId, codeSnippetGroups);
    path = [group ? group.name + '.' : '', path].join('');
    if (group && group.parent) {
        path = getFullSnippetPath(group.parent, codeSnippetGroups, path);
    }
    return path;
}

function getSnippetGroupById(groupId: string, codeSnippetGroups: CodeSnippetGroup[]) {
    return codeSnippetGroups.find(g => g.id === groupId);
}

export const safeStringLocaleCompare = (a: string | undefined, b: string | undefined) => {
    if (!b && a) {
        return 1;
    } else if (b && !a) {
        return -1;
    } else if (!b && !a) {
        return 0;
    } else {
        return b!.localeCompare(a!);
    }
};

export const handleDropdownItemSelection = (e: React.MouseEvent<HTMLElement, MouseEvent>, sel: Selection) => {
    const getTextNode = (node: ChildNode): ChildNode => {
        return node.lastChild === null ? node : getTextNode(node.lastChild);
    };
    const el = e.target as HTMLElement;
    if (el.className !== 'ant-select-item-option-content') {
        return;
    }
    el.focus();
    const range = document.createRange();
    const textNode = getTextNode(el);
    range.setStart(textNode!, 0);
    range.setEnd(textNode!, (textNode as Text).length);
    sel!.removeAllRanges();
    sel!.addRange(range);
};

export const getFormattedDateRange = (dateRange: [Moment, Moment], dateFormat: string) => [
    dateRange[0].format(dateFormat),
    dateRange[1].format(dateFormat)
];

// eslint-disable-next-line max-len
export const EMAIL_REGEX_PATTERN =
    /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g;
