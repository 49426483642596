export default class Keyword {
    constructor(
        public keyword: string,
        public isInInitialList: boolean,
        public initialValue?: string
    ) {
        this.keyword = keyword;
        this.isInInitialList = isInInitialList;

        if (initialValue) {
            this.initialValue = initialValue;
        }
    }
}
