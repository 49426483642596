import EnvironmentVariable from './EnvironmentVariable';

export { default as ApplicationDefinitionCreateModel } from './ApplicationDefinitionCreateModel';
export { default as ApplicationDefinitionSaveSuccessModel } from './ApplicationDefinitionSaveSuccessModel';
export type { default as InputBinding } from './InputBinding';
export { default as IotaApplication } from './IotaApplication';
export { default as IotaApplicationWorkflow } from './IotaApplicationWorkflow';
export { default as IotaExtension } from './IotaExtension';
export { default as IotaMetadataInput } from './IotaMetadataInput';
export { default as IotaMetadataInputBehavior } from './IotaMetadataInputBehavior';
export type { default as ApplicationDefinitionsResult } from './ApplicationDefinitionsResult';
export type { default as ConnectionDescription } from './ConnectionDescription';
export { default as SettingsMetadata } from './SettingsMetadata';
export { default as EnvironmentVariable } from './EnvironmentVariable';
export { default as SettingsMetadataSection } from './SettingsMetadataSection';
export type { default as IotaInputBindingsMeta } from './IotaInputBindingsMeta';
export { default as ApplicationCapabilities } from './ApplicationCapabilities';
export { default as ApiOperationInfo } from './ApiOperationInfo';
export { default as ApiOperationParameter } from './ApiOperationParameter';
export { default as ApiApplicationCreateModel } from './ApiApplicationCreateModel';

export type EnvironmentVariablesResult = {
    getEnvironmentVariables: EnvironmentVariable[];
};

export type ValidationMeta = {
    [id: string]: unknown;
};

export type InputMeta = {
    id: string;
    name: string;
    description?: string;
    validation?: ValidationMeta;
    type: 'number' | 'string';
    section?: string;
};

export enum BindingType {
    constant = 'constant',
    tag = 'tag',
    variable = 'variable',
    codeRule = 'coderule'
}
