import * as React from 'react';
import { AdministrationStores } from '../stores';
import { observer } from 'mobx-react-lite';
import { Button, Layout } from 'antd';
import WrappedAddUserDialog from './AddUserDialog';
// import UserManagerTree from './UserManagerTree';
// import WrappedUserContent from './UserContent';
import LayoutHeader from '../../../components/LayoutHeader';
import UsersList from './UsersList';
import EditUserDialog from './EditUserDialog';

type Props = AdministrationStores;

export const Users: React.FC<Props> = ({ UsersManager: store }) => {
    React.useEffect(() => {
        store!.getUsers().catch(err => console.error(err));
        store!.getCustomRoles().catch(err => console.error(err));
    }, [store]);

    const layout = (
        // {/* <Sider data-id="user-manager-side-section" width={350} style={{background: 'white', overflow: 'auto'}}>
        //     <WrappedAddUserDialog UsersManager={store}/>
        //     <Button data-id="user-manager-add-user-button" style={{marginLeft: 9}} type="primary"  onClick={() => store!.setIsAddUserDialogVisible(true)}>Add User</Button>
        //     <UserManagerTree UsersManager={store}/>
        // </Sider> */}
        <Layout className="screen-size" style={{ ...{ height: '100%', background: 'white', overflow: 'hidden' } }}>
            <WrappedAddUserDialog UsersManager={store} />
            <EditUserDialog UsersManager={store} />
            <LayoutHeader
                title="User management"
                helpMessage="You can manage users and their roles here."
                buttons={[
                    <Button
                        key="user-manager-add-user-button"
                        data-id="user-manager-add-user-button"
                        type="primary"
                        size="large"
                        onClick={() => store!.setIsAddUserDialogVisible(true)}
                    >
                        Add user
                    </Button>
                ]}
            />
            <Layout.Content>
                {/* {store!.selectedUser ? <WrappedUserContent UsersManager={store}/> : <></>} */}
                <UsersList store={store!} />
            </Layout.Content>
        </Layout>
    );

    return layout;
};

export default observer(Users);
