import * as React from 'react';
import { Tooltip } from 'antd';

interface Props {
    message: string;
}

const HelpMessage: React.FC<Props> = ({ message }) => {
    return (
        <span className="headerButton">
            <Tooltip title={message}>
                <i className="alpha-icon lg question-icon" style={{ verticalAlign: 'middle' }} />
            </Tooltip>
        </span>
    );
};

export default HelpMessage;
