/* eslint-disable max-len */
import * as React from 'react';
import { Card } from 'antd';
import { inject, observer } from 'mobx-react';
import PipelineVisualStore from '../../rules/stores/PipelineVisualStore';
import StepDraggableItem from './StepDraggableItem';
import PipelineEditStep from './PipelineEditStep';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { STORE_RULE_EDIT } from '../../rules/constants';
import { RulesStores } from '../../rules/stores';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = RulesStores & {
    store: T;
};

type State = {
    isLoading: boolean;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
class PipelineEditor<
    T extends PipelineVisualStore<TPipelineStepType>,
    TPipelineStepType extends string
> extends React.Component<Props<T, TPipelineStepType>, State> {
    private store: T;
    constructor(props: Props<T, TPipelineStepType>) {
        super(props);
        this.store = this.props.store;
        this.state = {
            isLoading: false
        };
    }

    async UNSAFE_componentWillMount() {
        this.setState({ isLoading: true });
        await this.store.getEditorModels();
        this.setState({ isLoading: false });
    }

    render() {
        const store = this.store;

        const handleAddStep = () => {
            this.props.RuleEditUI?.setIsRuleEdited(true);
            store.newStep();
        };

        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 20,
                        paddingLeft: 39,
                        paddingBottom: 39
                    }}
                >
                    {!this.state.isLoading && !!store.currentProject ? (
                        store.pipeline!.map((p, i) => (
                            <React.Fragment key={'p' + i}>
                                {store.editorModel && (
                                    <div
                                        className="pipeline-step"
                                        data-id={`pipeline-edit-step-${i}`}
                                        style={{ width: 300 }}
                                    >
                                        <StepDraggableItem index={i} store={store}>
                                            <PipelineEditStep store={store} step={p} stepKey={i} />
                                        </StepDraggableItem>
                                    </div>
                                )}
                                <div>
                                    <i style={{ paddingLeft: 17 }} className="alpha-icon sm arrow-right" />
                                </div>
                            </React.Fragment>
                        ))
                    ) : (
                        <LoadingIndicator />
                    )}
                    <div className="pipeline-edit-add-step" data-id="pipeline-edit-add-step" onClick={handleAddStep}>
                        <Card style={{ height: 170 }} bodyStyle={{ height: '100%', width: '100%' }}>
                            <div className="step-plus-icon">
                                <i className="alpha-icon lg plus-icon" />
                                <span className="add-step-label">Add step</span>
                            </div>
                        </Card>
                    </div>
                </div>
            </>
        );
    }
}

export default inject(STORE_RULE_EDIT)(observer(PipelineEditor));
