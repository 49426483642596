import { action, computed, observable, runInAction } from 'mobx';
import { ProjectsRootVisualStore } from '../../common/stores';
import { OrderBy } from '../../common/types';
import { GlobalAdministrationService } from '../../administration/service/GlobalAdministrationService';
import BaseHistoryRecordModel from '../models/BaseHistoryRecordModel';
import HistoryService from '../services/HistoryService';
import HistoryRecordFactory from '../misc/HistoryRecordFactory';
import { UserModel } from '../../administration/types/UserModel';
import { flatten } from 'lodash';

export const HISTORY_PAGE_SIZE = 20;

const projectHistoryAreaOptions = [
    {
        value: 'projects',
        label: 'Project',
        entities: ['Project']
    },
    {
        value: 'tags',
        label: 'Tags',
        entities: ['RuleTag']
    },
    {
        value: 'rules',
        label: 'Rules',
        entities: ['RuleBase']
    },
    {
        value: 'refDataConnections',
        label: 'RD Connections',
        entities: ['RefDataConnection']
    },
    {
        value: 'testProjects',
        label: 'Test projects',
        entities: ['TestProject', 'TestProjectBaseline']
    },
    {
        value: 'applicationDefinitions',
        label: 'IOTA applications',
        entities: ['ApplicationDefinitionBase']
    },
    {
        value: 'connectionsDefinitions',
        label: 'WF connections',
        entities: ['ConnectionsDefinition']
    },
    {
        value: 'MLModels',
        label: 'ML storage',
        entities: ['MLModel']
    },
    {
        value: 'projectFields',
        label: 'Project Fields',
        entities: ['ProjectFieldGroup']
    },
    {
        value: 'formTemplate',
        label: 'Templates',
        entities: ['FormPart', 'FormType']
    },
    {
        value: 'instructWorkflows',
        label: 'Instruct Workflows',
        entities: ['InstructWorkflow']
    },
    {
        value: 'packages',
        label: 'Packages',
        entities: ['Package']
    }
];

const globalHistoryAreaOptions = [
    {
        value: 'referenceData',
        label: 'Reference Data',
        entities: ['ReferenceDataTable']
    }
];

type FieldOrderBy = OrderBy;

export default class HistoryStore {
    @observable
    historyRecords: BaseHistoryRecordModel[] = [];

    @observable
    total: number = 0;

    @observable
    orderBy?: FieldOrderBy;

    @observable
    selectedAreaOption?: string;

    @observable
    page: number = 0;

    @observable
    loading: boolean = false;

    @observable
    searchOnlyProjectRelated: boolean = false;

    @observable
    searchTerm: string = '';

    @observable
    userId?: string;

    @observable
    users: UserModel[] = [];

    @observable
    usersLoading: boolean = false;

    @observable
    dateFrom?: string;

    @observable
    dateTo?: string;

    @computed
    get project() {
        return this.projectsRootStore.currentProject;
    }

    @computed
    get historyAreaOptions() {
        return this.searchOnlyProjectRelated
            ? projectHistoryAreaOptions
            : [...projectHistoryAreaOptions, ...globalHistoryAreaOptions];
    }

    @computed
    get searchEntities() {
        if (this.searchOnlyProjectRelated) {
            const projectEntities = flatten(
                this.historyAreaOptions.filter(o => o.value !== 'referenceData').map(o => o.entities)
            );
            return this.historyAreaOptions.find(o => o.value === this.selectedAreaOption)?.entities ?? projectEntities;
        }

        return this.historyAreaOptions.find(o => o.value === this.selectedAreaOption)?.entities ?? [];
    }

    constructor(
        readonly projectsRootStore: ProjectsRootVisualStore,
        readonly historyService: HistoryService,
        readonly adminService: GlobalAdministrationService
    ) {}

    @action.bound
    setHistoryRecords(historyRecords: BaseHistoryRecordModel[]) {
        this.historyRecords = historyRecords;
    }

    @action.bound
    setTotal(total: number) {
        this.total = total;
    }

    @action.bound
    setOrderBy(orderBy?: FieldOrderBy) {
        this.orderBy = orderBy;
    }

    @action.bound
    setSelectedAreaOption(selectedAreaOption?: string) {
        this.selectedAreaOption = selectedAreaOption;
    }

    @action.bound
    setPage(page: number) {
        this.page = page;
    }

    @action.bound
    setSearchTerm(searchTerm: string) {
        this.searchTerm = searchTerm;
    }

    @action.bound
    setUserId(userId?: string) {
        this.userId = userId;
    }

    @action.bound
    setLoading(loading: boolean) {
        this.loading = loading;
    }

    @action.bound
    setDateFrom(dateFrom?: string) {
        this.dateFrom = dateFrom;
    }

    @action.bound
    setDateTo(dateTo?: string) {
        this.dateTo = dateTo;
    }

    @action.bound
    async getUsers() {
        try {
            runInAction(() => {
                this.usersLoading = true;
            });

            this.users = await this.adminService.getAppUsers();
        } finally {
            runInAction(() => {
                this.usersLoading = false;
            });
        }
    }

    @action.bound
    performSearch() {
        this.setPage(0);
        this.searchHistory();
    }

    @action.bound
    async searchHistory() {
        if (!this.project) {
            return;
        }

        try {
            this.setLoading(true);

            const response = await this.historyService.searchHistory({
                projectId: this.project.id,
                searchEntities: this.searchEntities,
                orderBy: this.orderBy,
                page: this.page,
                pageSize: HISTORY_PAGE_SIZE,
                searchTerm: this.searchTerm,
                userId: this.userId,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo
            });

            this.setHistoryRecords(response.historyRecords.map(HistoryRecordFactory.createHistoryRecord));
            this.setTotal(response.total);
        } finally {
            this.setLoading(false);
        }
    }

    @action.bound
    setSearchOnlyProjectRelated(searchOnlyProjectRelated: boolean) {
        if (searchOnlyProjectRelated && this.selectedAreaOption === 'referenceData') {
            this.setSelectedAreaOption(undefined);
        }

        this.searchOnlyProjectRelated = searchOnlyProjectRelated;
    }

    @action.bound
    clearFilters() {
        this.setSearchTerm('');
        this.setUserId(undefined);
        this.setSelectedAreaOption(undefined);
        this.setSearchOnlyProjectRelated(true);
        this.setDateFrom(undefined);
        this.setDateTo(undefined);
    }

    dispose() {
        this.clearFilters();
        this.setHistoryRecords([]);
        this.setTotal(0);
        this.setPage(0);
    }
}
