import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, Popconfirm, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FieldBindingInput } from '.';
import { FieldBindingsStore } from '../stores';
import { BindingGroupSection, FieldBinding } from '../types';

type Props = {
    store: FieldBindingsStore;
};

const FieldBindingsGroupContent: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();
    const [displaySections, setDisplaySections] = React.useState<BindingGroupSection[]>([]);

    React.useEffect(() => {
        setDisplaySections(store.selectedGroup?.sections ?? []);
    }, [store.selectedGroup]);

    React.useEffect(() => {
        if (store.selectedGroup && store.isCreateGroupDialogVisible) {
            handleSubmit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.isCreateGroupDialogVisible]);

    React.useEffect(() => {
        if (store.selectedGroup && store.isUpdateGroupDialogVisible) {
            handleSubmit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.isUpdateGroupDialogVisible]);

    const addFieldBindingInput = (event: React.MouseEvent, sectionId: string) => {
        event.stopPropagation();
        handleSubmit(false);
        store.setCurrentSectionId(sectionId);
        store.setIsCreateInputDialogVisible(true);
    };

    const removeSection = async (event: React.MouseEvent | undefined, sectionId: string) => {
        event?.stopPropagation();
        await handleSubmit(false);
        await store.deleteSection(sectionId);
    };

    const editSection = (event: React.MouseEvent, sectionId: string) => {
        event.stopPropagation();
        handleSubmit(false);
        store.setCurrentSectionId(sectionId);
        store.setIsUpdateSectionDialogVisible(true);
    };

    const renderSectionFields = (sectionId: string) => {
        if (store.selectedGroup && store.selectedGroup.fields) {
            const fields = store.selectedGroup?.fields.filter(f => f.sectionId === sectionId);
            const fieldIds = [...new Set(fields.map(f => f.inputId))];

            return fieldIds.map((inputId, i) => (
                <div key={`${inputId}-${i}`} style={{ display: 'flex' }}>
                    <FieldBindingInput form={form} store={store} bindingId={inputId} saveHandler={handleSubmit} />
                </div>
            ));
        }

        return null;
    };

    const panelExtra = (sectionId: string) => {
        return (
            <>
                <Tooltip title="Remove section" placement="bottom">
                    <Popconfirm
                        placement="topLeft"
                        title="Are you sure that you want to delete this section?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={event => removeSection(event, sectionId)}
                        onCancel={event => event?.stopPropagation()}
                    >
                        <Button type="link" size="small" onClick={event => event.stopPropagation()}>
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </Tooltip>
                <Tooltip title="Edit section" placement="bottom">
                    <Button type="link" size="small" onClick={event => editSection(event, sectionId)}>
                        <EditOutlined />
                    </Button>
                </Tooltip>
                <Tooltip title="Add input" placement="bottom">
                    <Button type="link" size="small" onClick={event => addFieldBindingInput(event, sectionId)}>
                        <PlusOutlined />
                    </Button>
                </Tooltip>
            </>
        );
    };

    const handleSubmit = async (showMsg: boolean = true) => {
        let fields: FieldBinding[] | undefined = undefined;
        const formData = form.getFieldsValue();
        for (let key in formData) {
            if (formData[key]) {
                if (!fields) {
                    fields = [];
                }

                fields = [...fields, ...formData[key]];
            }
        }

        if (store.selectedGroup) {
            await store.updateGroup(store.selectedGroup.name, showMsg, fields);
        }
    };

    return (
        <Form
            form={form}
            id="project-field-bindings-form"
            onFinish={handleSubmit}
            className="project-fields-section-form"
        >
            <Collapse
                className="bindings-group-section-collapse"
                activeKey={store.openedSections}
                onChange={store.setOpenedSections}
            >
                {displaySections.map(ds => (
                    <Collapse.Panel forceRender key={ds.sectionId} header={ds.name} extra={panelExtra(ds.sectionId)}>
                        {renderSectionFields(ds.sectionId)}
                    </Collapse.Panel>
                ))}
            </Collapse>
        </Form>
    );
};

export default observer(FieldBindingsGroupContent);
