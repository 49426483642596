import * as React from 'react';
import { Row, Col, Input, Tooltip, Checkbox, Form } from 'antd';
import RuleTypeSelect from './RuleTypeSelect';
import RuleTagInput from './RuleTagInput';
import { RuleEditVisualStore } from '../stores';
import { observer } from 'mobx-react-lite';
import RuleType from '../types/RuleType';
import { FormInstance } from 'antd/lib/form';

type Props = {
    store: RuleEditVisualStore;
    form: FormInstance;
    selectType?: (type: RuleType) => void;
};
const RuleEditGeneralSettings: React.FC<Props> = ({ store, selectType, form }) => {
    const resetFormValues = () => {
        const inputNames = Object.keys(form.getFieldsValue());
        const formVals = Object.create(null);
        inputNames.forEach(x => (formVals[x] = ''));
        const { tag, name, ruleType, description, overridePriority, priority } = store!.editableRule!;
        Object.assign(formVals, { tag, name, ruleType, description, overridePriority });
        formVals.priority = priority ?? 1;
        form!.setFieldsValue(formVals);
    };
    return (
        <div className="general-rule-settings">
            <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                    <RuleTypeSelect
                        selectType={selectType}
                        initialValue={store.editableRule!.ruleType}
                        resetFormValues={resetFormValues}
                    />
                </Col>
                <Col className="gutter-row" span={12}>
                    <RuleTagInput />
                </Col>
                <Col className="gutter-row" span={12}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input rule name!'
                            },
                            {
                                validator: (_, value) => {
                                    if (store.ruleExists(value)) {
                                        return Promise.reject(
                                            new Error('Rule with the same name already exists under the project')
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            }
                        ]}
                        data-id="rule-name-input"
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                    <Form.Item
                        data-id="rule-priority-input"
                        label={
                            <span>
                                <span>Priority</span>
                                <Tooltip title="1 - highest priority, 100 - lowest priority.">
                                    <i className="alpha-icon md question-icon" style={{ verticalAlign: 'middle' }} />
                                </Tooltip>
                            </span>
                        }
                        name="priority"
                        rules={[
                            {
                                required: true,
                                message: 'Please assign priority!'
                            }
                        ]}
                    >
                        <Input min={1} max={100} type="number" />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                    <Form.Item label="Description" name="description" data-id="rule-description-input">
                        <Input />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                    <Form.Item
                        label={null}
                        name="overridePriority"
                        valuePropName="checked"
                        data-id="rule-override-priority-checkbox"
                    >
                        <Checkbox>Override priority</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default observer(RuleEditGeneralSettings);
