import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Lambda, reaction } from 'mobx';
import { RootStores, ProjectsRootVisualStore, ProjectsStore, RouterStore } from '../modules/common/stores';
import { ProjectStores } from '../modules/project_management/stores';
import { ProjectState, Project } from '../modules/common/models';
import { STORE_PROJECTS_ROOT_UI, STORE_ROUTER } from '../modules/common/constants';
import { STORE_PROJECT_EXPORT } from '../modules/project_management/constants';
import { AppPermissions } from '../modules/authorization/Permissions';
import { hasPermission } from '../modules/authorization/components/HasPermission';
import { Menu, Tooltip } from 'antd';
import {
    AuditOutlined,
    BugOutlined,
    LoadingOutlined,
    HistoryOutlined,
    BlockOutlined,
    CodeOutlined
} from '@ant-design/icons';
import ProjectExportStore from '../modules/project_management/stores/ProjectExportStore';
import ProjectExportDialog from '../modules/project_management/components/ProjectExportDialog';

const SubMenu = Menu.SubMenu;

const initialState = {
    openKeys: new Array<string>(),
    selectedKeys: new Array<string>(),
    selectedItemKey: ''
};

export type State = Readonly<typeof initialState>;
type Permissions = {
    permissions: string[];
};

type Props = Partial<RootStores> & ProjectStores & Permissions;

export class ProjectsList extends React.Component<Props, State> {
    readonly state: State = initialState;

    private projectsRootStore: ProjectsRootVisualStore;
    private store: ProjectsStore;
    private router: RouterStore;
    private projectExportStore: ProjectExportStore;
    private readonly disposer: Lambda;

    constructor(props: Props) {
        super(props);
        this.projectsRootStore = this.props.projectsRootUI!;
        this.store = this.projectsRootStore.projectsStore;
        this.router = this.props.router!;
        this.projectExportStore = this.props.projectExport!;

        const d1 = this.store.projectsStates?.observe(() => {
            let changed = false;
            this.store.projectsStates?.forEach((v, p) => {
                if (v === ProjectState.Processing) {
                    if (this.projectsRootStore.currentProject) {
                        changed = this.projectsRootStore.currentProject.id !== p || changed;
                    }
                }
            });

            if (changed) {
                this.projectsRootStore.navigateToProjectsPage();
            }
        });

        const d2 = reaction(
            () => this.projectsRootStore.currentProject,
            p => {
                if (!p) {
                    this.setState({ ...this.state, openKeys: new Array<string>() });
                } else if (!sessionStorage.getItem('menuOpenItems')) {
                    this.setState({ ...this.state, openKeys: [p.id] });
                }
            }
        );

        const d3 = reaction(
            () => this.state.openKeys,
            k => {
                sessionStorage.setItem('menuOpenItems', JSON.stringify(k));
            }
        );

        const d4 = reaction(
            () => this.state.selectedItemKey,
            k => {
                sessionStorage.setItem('selectedItem', k);
            }
        );

        if (sessionStorage.getItem('menuOpenItems')) {
            reaction(
                () => this.store.projects,
                (projects, r) => {
                    this.onOpenChange(JSON.parse(sessionStorage.getItem('menuOpenItems')!));
                    r.dispose();
                }
            );
        }

        if (sessionStorage.getItem('selectedItem')) {
            reaction(
                () => this.store.projects,
                (projects, r) => {
                    this.onSelectedChange(sessionStorage.getItem('selectedItem')!);
                    r.dispose();
                }
            );
        }

        this.disposer = () => {
            if (d1) {
                d1();
            }

            d2();
            d3();
            d4();
        };
    }

    componentWillUnmount() {
        this.disposer();
    }

    onOpenChange = (openKeys: string[]) => {
        const submenus = ['Properties', 'Rules', 'IOTA', 'Labels', 'Templates'];
        if (openKeys.some(k => submenus.filter(s => k.endsWith(s)).length === 0)) {
            this.router.setIsFooterHidden(true);
        } else {
            this.router.setIsFooterHidden(false);
        }

        if (openKeys.length && submenus.some(s => openKeys.slice(-1)[0].endsWith(s))) {
            this.setState({ openKeys: openKeys });
            return;
        }

        if (!openKeys || openKeys.length === 0) {
            this.setState({ openKeys: [] });
            return;
        }

        const lastKey = openKeys.slice(-1);
        let newKeys: string[] = [];

        if (openKeys.length >= this.state.openKeys.length) {
            newKeys = submenus.map(s => `${lastKey}${s}`);
            newKeys = [...lastKey, ...newKeys];
        } else {
            newKeys = [...lastKey];
        }

        this.setState({ openKeys: newKeys });
    };

    onSelectedChange = (selectedItem: string) => {
        this.setState({ selectedItemKey: selectedItem });
    };

    onSelectMenuItem = (key: string, type: string, project: Project) => {
        this.setState(s => ({ ...s, selectedKeys: [key], selectedItemKey: project.id + key }));
        if (type != null) {
            switch (type) {
                case 'labels':
                    this.projectsRootStore.navigateToLabelsPage(project);
                    return;
                case 'interactivelabels':
                    this.projectsRootStore.navigateToInteractiveLabelsPage(project);
                    return;
                case 'uploadedPackages':
                    this.projectsRootStore.navigateToUploadedPackagesPage(project);
                    return;
                case 'administration':
                    this.projectsRootStore.navigateToProjectAdministrationPage(project);
                    return;
                case 'mlstorage':
                    this.projectsRootStore.navigateToMlStoragePage(project);
                    return;
                case 'applicationDefinitions':
                    this.projectsRootStore.navigateToApplicationDefinitionsPage(project);
                    return;
                case 'connectionsDefinitions':
                    this.projectsRootStore.navigateToConnectionsDefinitionsPage(project);
                    return;
                case 'sessions':
                    this.projectsRootStore.navigateToSessionsPage(project);
                    return;
                case 'tags':
                    this.projectsRootStore.navigateToTagsPage(project);
                    return;
                case 'connections':
                    this.projectsRootStore.navigateToConnectionsPage(project);
                    return;
                case 'problemReports':
                    this.projectsRootStore.navigateToPackageProblemReportsPage(project);
                    return;
                case 'referenceData':
                    this.projectsRootStore.navigateToReferenceDataPage(project);
                    return;
                case 'iotaAudit':
                    this.projectsRootStore.navigateToIotaAuditPage(project);
                    return;
                case 'fieldBindings':
                    this.projectsRootStore.navigateToFieldBindingsPage(project);
                    return;
                case 'forms':
                    this.projectsRootStore.navigateToFormTypesPage(project);
                    return;
                case 'projectSettings':
                    this.projectsRootStore.navigateToProjectSettingsPage(project);
                    return;
                case 'testProjects':
                    this.projectsRootStore.navigateToTestProjectPage(project);
                    return;
                case 'history':
                    this.projectsRootStore.navigateToHistoryPage(project);
                    return;
                case 'instructWorkflows':
                    this.projectsRootStore.navigateToInstructWorkflowsPage(project);
                    return;
                case 'schemaGenerator':
                    this.projectsRootStore.navigateToSchemaGeneratorPage(project);
                    return;
                default:
                    return;
            }
        }
    };

    render() {
        const projects = this.store.projects;
        const getProjectIcon = (project: Project) => {
            let icon;
            let style;

            if (project.state === ProjectState.Ready) {
                icon = <span className={`project-icon ${project.color}`} />;
                style = {};
            } else {
                icon = <LoadingOutlined spin />;
                style = { color: '#9BA0AA' };
            }

            return (
                <span style={{ ...style }}>
                    <Tooltip placement="right" title={project.title}>
                        {icon}
                    </Tooltip>
                    <span>{project.title}</span>
                </span>
            );
        };

        // eslint-disable-next-line max-len
        const menuItem = (
            type: string,
            p: Project,
            icon: React.ReactElement,
            title: string,
            enabled: boolean,
            style: object | undefined = undefined,
            userPermissions?: string[],
            permissionClaim?: AppPermissions
        ) => {
            if (!userPermissions || hasPermission(userPermissions, permissionClaim!)) {
                return (
                    <Menu.Item
                        className={this.state.selectedItemKey === p.id + type ? 'selected' : ''}
                        key={p.id + type}
                        data-id={`${p.title}_${type}`}
                        disabled={!enabled}
                        onClick={() => this.onSelectMenuItem(type, type, p)}
                    >
                        <span style={style}>
                            <Tooltip placement="right" title={title}>
                                {icon}
                            </Tooltip>
                            <span>{title}</span>
                        </span>
                    </Menu.Item>
                );
            } else {
                return <></>;
            }
        };

        const makeMetaDataItem = (p: Project, enabled: boolean, appRoles: string[]) => {
            /* eslint-disable max-len */
            return (
                <SubMenu
                    key={p.id + 'Properties'}
                    title={
                        <span>
                            <Tooltip placement="right" title="Packages">
                                <i className="alpha-icon md package-menu-icon" />
                            </Tooltip>
                            <span>{'Packages'}</span>
                        </span>
                    }
                >
                    {menuItem('uploadedPackages', p, <i className="alpha-icon md import-icon" />, 'Upload', true)}
                    {menuItem(
                        'administration',
                        p,
                        <i className="alpha-icon md gear-icon" />,
                        'Administration',
                        enabled,
                        undefined,
                        appRoles,
                        AppPermissions.CanAccessAdministration
                    )}
                    {menuItem(
                        'problemReports',
                        p,
                        <BugOutlined style={{ marginLeft: 3 }} />,
                        'Problem reports',
                        enabled
                    )}
                </SubMenu>
            );
        };

        const makeRulesItem = (p: Project) => {
            return (
                <SubMenu
                    key={p.id + 'Rules'}
                    title={
                        <span>
                            <Tooltip placement="right" title="Rules">
                                <i className="alpha-icon md rules-icon" />
                            </Tooltip>
                            <span>{'Rules'}</span>
                        </span>
                    }
                >
                    {menuItem('tags', p, <i className="alpha-icon md label-icon" />, 'Tags', true)}
                    {menuItem(
                        'connections',
                        p,
                        <i className="alpha-icon md connection-icon" />,
                        'RD Connections',
                        true
                    )}
                    {menuItem(
                        'referenceData',
                        p,
                        <i className="alpha-icon md ref-data-icon" />,
                        'Reference data',
                        true
                    )}
                    {menuItem(
                        'testProjects',
                        p,
                        <i className="alpha-icon md gear-icon" />,
                        'Test projects',
                        true,
                        undefined,
                        permissions,
                        AppPermissions.CanManageTestProjects
                    )}
                    {makeInstructWorkflowsItem(p, permissions)}
                    {makeSchemaGeneratorItem(p)}
                </SubMenu>
            );
        };

        const makeLabelsItem = (p: Project) => {
            return (
                <SubMenu
                    key={p.id + 'Labels'}
                    title={
                        <span>
                            <Tooltip placement="right" title="Labels">
                                <i className="alpha-icon md label-menu-icon" />
                            </Tooltip>
                            <span>{'Labels'}</span>
                        </span>
                    }
                >
                    {menuItem('labels', p, <i className="alpha-icon md label-icon" />, 'Label data', true)}
                    {menuItem(
                        'interactivelabels',
                        p,
                        <i className="alpha-icon md interactive-labels-icon" />,
                        'Interactive labels',
                        true
                    )}
                </SubMenu>
            );
        };

        const makeIotaItems = (p: Project) => {
            return (
                <SubMenu
                    key={p.id + 'IOTA'}
                    title={
                        <span>
                            <Tooltip placement="right" title="IOTA">
                                <i className="alpha-icon md iota-icon" />
                            </Tooltip>
                            <span>{'IOTA'}</span>
                        </span>
                    }
                >
                    {menuItem(
                        'applicationDefinitions',
                        p,
                        <i className="alpha-icon md applications-icon" />,
                        'Applications',
                        true
                    )}
                    {menuItem(
                        'connectionsDefinitions',
                        p,
                        <i className="alpha-icon md connection-icon" />,
                        'WF Connections',
                        true
                    )}
                    {menuItem('mlstorage', p, <i className="alpha-icon md ml-storage-icon" />, 'ML storage', true)}
                    {menuItem('sessions', p, <i className="alpha-icon md session-icon" />, 'Sessions', true)}
                    {menuItem('iotaAudit', p, <AuditOutlined />, 'Audit', true)}
                    {menuItem(
                        'fieldBindings',
                        p,
                        <i className="alpha-icon md connection-icon" />,
                        'Project Fields',
                        true,
                        undefined,
                        permissions,
                        AppPermissions.CanManageProjectFields
                    )}
                </SubMenu>
            );
        };

        const makeTemplatesItems = (p: Project) => {
            if (!hasPermission(permissions, AppPermissions.CanManageFormTemplates)) {
                return null;
            }

            return (
                <SubMenu
                    key={p.id + 'Templates'}
                    title={
                        <span>
                            <Tooltip placement="right" title="Templates">
                                <i className="alpha-icon md task-menu-icon" />
                            </Tooltip>
                            <span>{'Templates'}</span>
                        </span>
                    }
                >
                    {menuItem(
                        'forms',
                        p,
                        <AuditOutlined />,
                        'Forms',
                        true,
                        undefined,
                        permissions,
                        AppPermissions.CanManageFormTemplates
                    )}
                </SubMenu>
            );
        };

        const makeProjectSettingsItem = (p: Project, userPermissions: string[]) => {
            if (!userPermissions || !hasPermission(userPermissions, AppPermissions.CanAddEditDeleteAssignProject)) {
                return null;
            }

            return (
                <React.Fragment>
                    <Menu.Divider className="collapsed-divider" />
                    {menuItem('projectSettings', p, <i className="alpha-icon md gear-icon" />, 'Settings', true)}
                </React.Fragment>
            );
        };

        const makeProjectExportItem = (p: Project) => {
            return (
                <Menu.Item
                    key={p.id + 'projectExport'}
                    data-id={`${p.title}_projectExport`}
                    onClick={() => {
                        this.projectExportStore.setProjectId(p.id);
                        this.projectExportStore.setDialogVisible(true);
                    }}
                >
                    <span>
                        <Tooltip placement="right" title="Export">
                            <i
                                className="alpha-icon xs arrow-up-white"
                                style={{ marginLeft: 3, marginRight: 9, height: 15, width: 15 }}
                            />
                        </Tooltip>
                        <span style={{ color: '#fff' }}>Export</span>
                    </span>
                </Menu.Item>
            );
        };

        const makeHistoryItem = (p: Project, userPermissions: string[]) => {
            if (!userPermissions || !hasPermission(userPermissions, AppPermissions.CanAccessAdministration)) {
                return null;
            }

            return (
                <React.Fragment>
                    <Menu.Divider className="collapsed-divider" />
                    {menuItem('history', p, <HistoryOutlined style={{ marginLeft: 3 }} />, 'Change History', true)}
                </React.Fragment>
            );
        };

        const makeInstructWorkflowsItem = (p: Project, userPermissions: string[]) => {
            if (!userPermissions || !hasPermission(userPermissions, AppPermissions.CanEditMlStorage)) {
                return null;
            }

            return (
                <React.Fragment>
                    <Menu.Divider className="collapsed-divider" />
                    {menuItem(
                        'instructWorkflows',
                        p,
                        <BlockOutlined style={{ marginLeft: 3 }} />,
                        'Instruct Workflows',
                        true
                    )}
                </React.Fragment>
            );
        };

        const makeSchemaGeneratorItem = (p: Project) => {
            return (
                <React.Fragment>
                    <Menu.Divider className="collapsed-divider" />
                    {menuItem(
                        'schemaGenerator',
                        p,
                        <CodeOutlined style={{ marginLeft: 3 }} />,
                        'Schema Generator',
                        true
                    )}
                </React.Fragment>
            );
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const getExpandIcon = (props: any) => {
            let style: React.CSSProperties = {
                float: 'right',
                transition: 'transform .3s',
                marginTop: '5px'
            };

            if (!props.isOpen) {
                style.transform = 'rotate(-90deg)';
            }

            return <i style={style} className="alpha-icon md expand-icon" />;
        };

        const { permissions } = this.props;
        this.store.setCurrentUserRoles(permissions);

        return (
            <>
                <Menu
                    onOpenChange={this.onOpenChange}
                    mode="inline"
                    openKeys={this.state.openKeys}
                    selectedKeys={this.state.selectedKeys}
                    style={{ overflow: 'hidden auto', borderRight: 0 }}
                    inlineIndent={18}
                    expandIcon={getExpandIcon}
                >
                    {projects.map(p => (
                        <React.Fragment key={p.id + '-fragment'}>
                            <SubMenu
                                data-id={p.id}
                                data-id-type="MainMenu"
                                data-id-name={p.title}
                                key={p.id}
                                title={getProjectIcon(p)}
                                className="main-menu-item"
                            >
                                {makeRulesItem(p)}
                                <Menu.Divider className="collapsed-divider" />
                                {makeIotaItems(p)}
                                <Menu.Divider className="collapsed-divider" />
                                {makeLabelsItem(p)}
                                <Menu.Divider className="collapsed-divider" />
                                {makeMetaDataItem(p, true, permissions)}
                                <Menu.Divider className="collapsed-divider" />
                                {makeTemplatesItems(p)}
                                {makeHistoryItem(p, permissions)}
                                <Menu.Divider className="collapsed-divider" />
                                {makeProjectSettingsItem(p, permissions)}
                                <Menu.Divider className="collapsed-divider" />
                                {makeProjectExportItem(p)}
                            </SubMenu>
                            <Menu.Divider />
                        </React.Fragment>
                    ))}
                </Menu>

                <ProjectExportDialog store={this.projectExportStore} />
            </>
        );
    }
}

export default inject(STORE_PROJECTS_ROOT_UI, STORE_ROUTER, STORE_PROJECT_EXPORT)(observer(ProjectsList));
