import { DownloadOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Tag, Tooltip, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { TestProjectDashboardStore, TestProjectWizardStore } from '../../stores';

type Props = {
    store: TestProjectDashboardStore;
    wizardStore: TestProjectWizardStore;
};

const TestProjectBaseline: React.FC<Props> = ({ store, wizardStore }) => {
    const [action, setAction] = React.useState('');
    const [isUploading, setIsUploading] = React.useState(false);

    React.useEffect(() => {
        store.initAccessToken();
        if (store.accessToken && store.currentProject && store.testProject) {
            const uploadUrl = `${process.env.REACT_APP_MANAGE_URL}projects/${store.currentProject.id}/test-projects/${store.testProject.id}/baselines/import`;
            let t = '?access_token=' + encodeURIComponent(store.accessToken);
            setAction(uploadUrl + t);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject, store.testProject]);

    const onFileChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        setIsUploading(status === 'uploading');
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            store.loadTestProject();
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed. Error: ${info.file.response.title}`, 5);
        }
    };

    const openDocumentEditDialog = () => {
        if (!store.currentTestProjectId) {
            return;
        }

        wizardStore.setUpTestProject(store.currentTestProjectId!);
        wizardStore.setPackagesEditDialogVisible(true);
    };

    const openTopicsEditDialog = () => {
        if (!store.currentTestProjectId) {
            return;
        }

        wizardStore.setUpTestProject(store.currentTestProjectId!);
        wizardStore.setTopicsEditDialogVisible(true);
    };

    const newDocsTag = () => {
        if (store.testProject?.newPackageIds?.length && !store.isLoadingTestProject) {
            return (
                <Tag color="#52c41a" style={{ marginLeft: 8, marginRight: 0 }}>
                    {store.testProject?.newPackageIds?.length} new
                </Tag>
            );
        }
        return null;
    };

    return (
        <div className="test-project-dashboard-item">
            <div className="item-title">Baseline</div>
            <div className="item-subtitle">
                <div className="item-subtitle-label" style={{ marginLeft: -12 }}>
                    <Button type="link" onClick={openDocumentEditDialog}>
                        {`${store.testProject?.packageIds?.length} documents`}
                        {newDocsTag()}
                    </Button>
                </div>
                <div className="item-subtitle-label">
                    <Button
                        type="link"
                        onClick={openTopicsEditDialog}
                    >{`${store.testProject?.topics?.length} inputs`}</Button>
                </div>
                <div className="item-subtitle-extra">
                    <Tooltip title="Edit baselines">
                        <Button size="small" type="link" icon={<EditOutlined />} onClick={store.goToBaselines} />
                    </Tooltip>
                    <Tooltip title="Upload baselines">
                        <Upload
                            disabled={isUploading}
                            name="file"
                            maxCount={1}
                            action={action}
                            onChange={onFileChange}
                            showUploadList={false}
                            progress={undefined}
                        >
                            <Button size="small" type="link" icon={<UploadOutlined />} loading={isUploading} />
                        </Upload>
                    </Tooltip>
                    <Tooltip title="Download baselines">
                        <Button
                            size="small"
                            type="link"
                            icon={<DownloadOutlined />}
                            onClick={store.exportTestProjectBaselines}
                        />
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default observer(TestProjectBaseline);
