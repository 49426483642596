import { PackageLine, Package, PackageState, FeatureFlags } from '../models';
import type { BLOCK_TYPE } from './BlockType';
import { ReportedProblemState } from '../../../modules/project_management/stores/PackagesProblemsReportVisualStore';
import { PackageSource } from '../models/PackageSource';
export type { BLOCK_TYPE };

export type AnchorResult = {
    id: string;
    keyword: string;
    aliases: string[];
};

export type PackageResult = {
    id: string;
    fileName: string;
    filePath: string;
    projectId: string;
    contentType: string;
    state: PackageStateResult;
    operationState: string[];
    userTags: string[];
    uploadedTime: Date | null;
    indexDate: Date | null;
    source: PackageSource | null;
    sortingDate: Date;
    error: string | null;
    featureFlags: FeatureFlags | null;
    isProtected: boolean | null;
};

export type NamedEntity = {
    start: number;
    end: number;
    text: string;
};

export type SearchResult = {
    searchPackageLines: {
        result: FieldResult[];
        pageInfo: {
            total: number;
        };
    };
};

export type ProjectByIdResult = {
    projectById: ProjectResult | null;
};

export type AllProjectResult = {
    allProjects: ProjectResult[];
};

export type ProjectByUserResult = {
    projectsByUser: ProjectResult[];
};

export type SearchPackagesResult = {
    searchPackages: {
        result: PackageResult[];
        pageInfo: {
            total: number;
        };
    };
};

export type SmartIndexSettings = {
    enabled: boolean;
    instructWorkflowId: string | null;
};

export type ProjectResult = {
    id: string;
    color: string;
    name: string;
    type: string;
    keywords: string[];
    labels: AnchorResult[];
    namedEntities: {
        label: string;
        text: string;
        entities: NamedEntity[];
    }[];
    packages: PackageResult[] | null;
    tagsVersion?: {
        version: string;
        versionHash: string;
    };
    featureFlags: FeatureFlags | null;
    smartIndexSettings: SmartIndexSettings | null;
};

export type FieldResult = {
    fieldId: string;
    rowId: number;
    page: number;
    x: number;
    y: number;
    w: number;
    h: number;
    pageWidth: number;
    pageHeight: number;
    text: string;
    normalizedText: string;
    anchor: string;
    imagePath: string;
    hasImage: boolean;
    packageId: string;
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalProperties: any;
    namedEntities: NamedEntity[];
    blockType: BLOCK_TYPE;
};

export enum PackageStateResult {
    Ready = 'Ready',
    Parsed = 'Parsed',
    Importing = 'Importing',
    Processing = 'Processing',
    Broken = 'Broken'
}

export class PackageLinesResponse {
    total: number = 0;
    lines: PackageLine[] = [];
}

export class PackagesResponse {
    total: number = 0;
    lines: Package[] = [];
}

export type CommitLabelsequest = {
    projectId: string;
    labels?: {
        keyword: string;
        aliases: string[];
    }[];
    namedEntities: {
        label: string;
        text: string;
        entities: NamedEntity[];
    }[];
    packages: {
        id: string;
    }[];
};

export class PackagesRequest {
    projectId: string;
    page: number;
    pageSize: number;
    searchPage?: number | null;
    pkgId?: string | null;
    search?: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fieldsSearch?: any;
    blockTypes?: BLOCK_TYPE[];
    tags?: string[];
}

export type OrderBy = {
    field: string;
    direction: 'ASC' | 'DESC';
};

export class SearchPackagesRequest {
    projectId: string;
    page: number;
    pageSize: number;
    search?: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fieldsSearch?: any;
    tags?: string[];
    allSources?: boolean;
    orderBy?: OrderBy;
    state?: string[];
    distinctBy?: string | null;
    protectedOnly?: boolean;
    uploadedBy?: string | null;
    packageIds?: string[];
}

export class SearchSessionsRequest {
    projectId: string;
    page: number;
    pageSize: number;
    search?: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fieldsSearch?: any;
    dates: string[];
    appName: string;
    sortDirection: 'DESC' | 'ASC';
    currentSortedField: string;
    userName: string;
}

export class SearchIotaAuditRequest {
    userName: string;
    projectId: string;
    applicationId: string;
    workflowId: string;
    page: number;
    pageSize: number;
    search?: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fieldsSearch?: any;
    dates: string[];
    sortDirection: 'DESC' | 'ASC';
}

export type ProjectTag = {
    tag: string;
};

export type ProjectTagsResult = {
    projectTags: ProjectTag[];
};

export class PackageChange {
    projectId: string;
    id: string;
    state: PackageState;
    fileName: string;
    filePath: string;
    userTags: string[];
    uploadDate: Date;
    indexDate: Date;
    source: PackageSource;
    error: string | null;
    featureFlags: FeatureFlags | undefined;
    isProtected: boolean | undefined;
}

export type SessionResult = {
    id: string;
    user: string;
    packageId: string;
    projectId: string;
    runtimeSessionId: string;
    created: string;
    updated: string;
    applicationDefinitionId: string;
    applicationName: string;
    packageName: string;
    error: string;
    userId: string;
};

export type TestProjectResult = {
    id: string;
    projectId: string;
    name: string;
    createdBy: string;
    createdTime: string;
    updatedBy: string;
    updatedTime: string;
    packageIds: string[];
    applicationId?: string;
    hasValidTopics: boolean;
    hasValidPackages: boolean;
    isRunning: boolean;
    errors?: string[];
    lastRunId?: string;
    lastRunTime?: string;
    lastRunFuzzy?: number;
    lastRunError?: string;
    newPackageIds?: string[];
};

export interface IotaSessionChange {
    id: string;
    packageId: string;
    projectId: string;
    runtimeSessionId: string;
    created: string;
    updated: string;
    applicationDefinitionId: string;
}

export class TestProjectChange {
    id: string;
    isRunning: boolean;
    projectId: string;
    lastRunId?: string;
    lastRunTime?: string;
    lastRunFuzzy?: number;
    lastRunError?: string;
    newPackageIds?: string[];
}

export type AutoCompleteText = {
    text: string;
    page: number;
    position: number;
    pageHeight: number;
};

export type AutoCompleteSourceItem = {
    text: string;
    imagePath: string | null;
    packageName: string;
};

export type SearchPackagesAutoCompleteSourceItem = {
    fileName: string;
    filePath: string;
    state: PackageState;
};

export type SearchUsersAutoCompleteSourceItem = {
    userName: string;
    id: string;
};

export type PackageProblemReportResult = {
    getPackageProblemMessages: PackageProblemReport[];
};

export type PackageProblemReport = {
    id: string;
    packageId: string;
    packageName: string;
    lastModified: string;
    reports: ProblemReport[];
};

export type ProblemReport = {
    problemId: string;
    user: string;
    message: string;
    createDate: string;
    page: string;
    status: ReportedProblemState;
};

export enum ProcessType {
    Reindex,
    Reparse
}

export type AlphaConfigurationControlType = 'checkbox' | 'text' | 'textarea';

export type DefaultAlphaConfiguration = {
    key: string;
    defaultValue: string;
    controlType: AlphaConfigurationControlType;
};
