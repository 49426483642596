export const STORE_RULES = 'RulesStore';
export type STORE_RULES = typeof STORE_RULES;

export const STORE_RULES_LIST = 'RulesListUI';
export type STORE_RULES_LIST = typeof STORE_RULES_LIST;

export const STORE_RULES_NEW = 'RuleNewUI';
export type STORE_RULES_NEW = typeof STORE_RULES_NEW;

export const STORE_RULE_EDIT = 'RuleEditUI';
export type STORE_RULE_EDIT = typeof STORE_RULE_EDIT;

export const STORE_RULES_PREVIEW = 'RulePreviewUI';
export type STORE_RULES_PREVIEW = typeof STORE_RULES_PREVIEW;

export const STORE_RULES_CONNECTIONS = 'RuleConnection';
export type STORE_RULES_CONNECTIONS = typeof STORE_RULES_CONNECTIONS;

export const STORE_RULES_COPIED_PIPELINE = 'RuleCopiedPipelineUI';
export type STORE_RULES_COPIED_PIPELINE = typeof STORE_RULES_COPIED_PIPELINE;

export const STORE_RULES_IMPORT = 'RulesImportUI';
export type STORE_RULES_IMPORT = typeof STORE_RULES_IMPORT;

export const STORE_TAGS_GROUP = 'TagsGroupUI';
export type STORE_TAGS_GROUP = typeof STORE_TAGS_GROUP;

export const STORE_TAG_PREVIEW = 'TagsPreviewUI';
export type STORE_TAG_PREVIEW = typeof STORE_TAG_PREVIEW;
