import { Button, Modal, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ProjectApplicationDefinitionsVisualStore } from '../stores';
import WrappedApplicationSettingsForm from '../../iota_applications/components/ApplicationSettingsForm';
type Props = {
    store: ProjectApplicationDefinitionsVisualStore | undefined;
};

const ApplictionSettingsDialog: React.FC<Props> = ({ store }) => {
    const [settingsForm] = Form.useForm();

    const [isValidating, setIsValidating] = React.useState(false);

    if (!store || !store.currentAppDef) {
        return null;
    }

    const handleCancel = () => {
        store.setSettingsDialogVisible(false);
        store.setCurrentAppDef(undefined);
    };

    const validateConnectionAdSchema = async () => {
        const connectionString = settingsForm.getFieldValue('SqlConnection');
        const tableSchema = settingsForm.getFieldValue('TableSchema');

        setIsValidating(true);

        try {
            await store.validateConnectionAndSchema(connectionString, tableSchema);
        } finally {
            setIsValidating(false);
        }
    };

    return (
        <Modal
            width={600}
            visible={store.settingsDialogVisible}
            destroyOnClose
            title={`${store.currentAppDef.name} Settings`}
            maskClosable={false}
            closable={false}
            className="alpha-portal-dialog"
            bodyStyle={{ padding: 24 }}
            centered
            footer={[
                <Button
                    className="light"
                    data-id="iota-edit-application-settings-dialog-cancel"
                    size="large"
                    key="back"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="iota-edit-application-settings-validate"
                    key="validate"
                    style={{
                        backgroundColor: 'green',
                        borderColor: 'green',
                        display: store.appHasConnectionAndSchema ? 'inline-block' : 'none'
                    }}
                    type="primary"
                    size="large"
                    loading={isValidating}
                    onClick={validateConnectionAdSchema}
                >
                    Validate
                </Button>,
                <Button
                    form="iota-app-settings-form"
                    data-id="iota-edit-application-settings-submit"
                    size="large"
                    key="submit"
                    type="primary"
                    htmlType="submit"
                >
                    Save changes
                </Button>
            ]}
        >
            <WrappedApplicationSettingsForm
                settingsForm={settingsForm}
                settingsJson={store!.currentAppDef!.settings!}
                settingsValues={store!.currentAppDef!.settingValues!}
                back={() => store!.goToApplicationsList()}
                id={store!.currentAppDef!.id}
                update={(id, settings) => store!.updateApplicationSettings(id, settings)}
                disabled={store.isSettingsDisabled}
            />
        </Modal>
    );
};

export default observer(ApplictionSettingsDialog);
