import * as React from 'react';
import { ProtectedRoute } from '../../common/components';
import ReferenceDataPage from '../screens/ReferenceDataPage';

export const ReferenceDataNavigation = {
    ReferenceDataPage: '/projects/:projectId/referencedata'
};

export default () => {
    return <ProtectedRoute component={ReferenceDataPage} path={ReferenceDataNavigation.ReferenceDataPage} />;
};
