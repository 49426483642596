import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { ChatbotErrorModel } from '../../models';
import './ChatbotDisplayItems.less';

type Props = {
    error: ChatbotErrorModel;
    addRef: (questionId: string, questionRef: React.RefObject<HTMLDivElement>) => void;
};

const ChatbotErrorMessage: React.FC<Props> = ({ error, addRef }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        addRef(error.id, ref);
    }, [error.id, addRef]);

    return (
        <div className="item-container" ref={ref}>
            <div className="item error">{error.message}</div>
        </div>
    );
};

export default observer(ChatbotErrorMessage);
