import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { match } from 'react-router';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function monitorRouterMatch(storeName: string, apply: (store: { [index: string]: any }, match?: match) => void) {
    type Props = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        match?: match;
    };

    return function injectRouterMatch(WrappedComponent: React.ComponentType) {
        // Try to create a nice displayName for React Dev Tools.
        const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

        // Creating the inner component. The calculated Props type here is the where the magic happens.
        const cls = class ComponentWithTheme extends React.Component<Props> {
            public static displayName = `monitorRouterMatch(${displayName})`;

            UNSAFE_componentWillMount() {
                apply(this.props, this.props.match);
            }

            public render() {
                // this.props comes afterwards so the can override the default ones.
                return <WrappedComponent {...this.props} />;
            }
        };

        return inject(storeName)(observer(cls));
    };
}

export default monitorRouterMatch;
