import * as React from 'react';
import { Form, Card, Input, Select, Space, Button, InputNumber } from 'antd';
import { MinusOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { OnHitEventArgs } from 'react-drag-drop-container';
import DraggableItemWrapper from '../../common/components/DraggableItemWrapper';
const { Option } = Select;

type Props = {
    pattern: string;
    handleRulePatternSave: (value: string) => void;
    handleCancel: () => void;
};
export const RulePatternEditorContent: React.FC<Props> = ({ pattern, handleRulePatternSave, handleCancel }) => {
    const [form] = Form.useForm();
    const res = (pattern && JSON.parse(pattern)) || ([] as {}[]);
    const [patternModel, setPatternModel] = React.useState(res);
    let swapIndex = 0;
    const getInitialValues = () => {
        const obj = Object.create(null);
        patternModel.forEach((x: {}, i: number) => {
            obj[i] = Object.keys(x).map(key => ({ type: key, value: x[key] }));
        });
        return obj;
    };

    const handleChange = (value: string, attributeIndex: number, tokenIndex: number) => {
        const val = form.getFieldValue(tokenIndex);
        const obj = Object.create(null);
        val[attributeIndex].value = null;
        obj[tokenIndex] = val;
        form.setFieldsValue(obj);
    };

    const getValueComponent = (attributeIndex: number, tokenIndex: number) => {
        const val = form.getFieldValue(tokenIndex);
        if (!val[attributeIndex]) {
            return <Input />;
        }
        const type = val[attributeIndex].type as string;
        if (!type) {
            return <Input />;
        }
        if (type.startsWith('IS') || type.startsWith('LIKE')) {
            /* eslint-disable @typescript-eslint/no-explicit-any */
            return (
                <Select>
                    <Option key="true" value={true as any}>
                        yes
                    </Option>
                    <Option key="false" value={false as any}>
                        no
                    </Option>
                </Select>
            );
        }
        if (type === 'OP') {
            return (
                <Select>
                    <Option key="1" value="!">
                        !
                    </Option>
                    <Option key="2" value="?">
                        ?
                    </Option>
                    <Option key="3" value="+">
                        +
                    </Option>
                    <Option key="4" value="*">
                        *
                    </Option>
                </Select>
            );
        }
        if (type === 'LENGTH') {
            return <InputNumber style={{ width: '100%' }} />;
        }
        return <Input />;
    };

    const handleTokenAddition = () => {
        const copy = patternModel.slice();
        copy.push({ type: null, value: null });
        const obj = Object.create(null);
        obj[copy.length - 1] = [{ type: null, value: null }];
        form.setFieldsValue(obj);
        setPatternModel(copy);
    };

    const handleTokenDelete = (tokenIndex: number) => {
        const vals = Object.values(form.getFieldsValue());
        vals.splice(tokenIndex, 1);
        vals.forEach((val, i) => {
            const ob = {};
            ob[i] = val;
            form.setFieldsValue(ob);
        });
        const copy = patternModel.slice();
        copy.splice(tokenIndex, 1);
        setPatternModel(copy);
    };

    const handleFormFinish = (values: {}) => {
        let objToSave = [] as {}[];
        Object.keys(values).forEach(key => {
            const obj = Object.create(null);
            values[key].forEach((x: { type: string; value: string }) => {
                obj[x.type] = x.value;
            });
            objToSave.push(obj);
        });

        const stringified = JSON.stringify(objToSave);
        handleRulePatternSave(stringified);
    };

    const handleOnDrop = (index: number) => {
        swapIndex = index;
    };

    const handleOnHit = (args: OnHitEventArgs, index: number) => {
        const copy = patternModel.slice();
        const draggable = copy[swapIndex];
        copy.splice(swapIndex, 1);
        copy.splice(index, 0, draggable);
        const vals = Object.values(form.getFieldsValue());
        const obj = vals[swapIndex];
        vals.splice(swapIndex, 1);
        vals.splice(index, 0, obj);
        vals.forEach((val, i) => {
            const ob = {};
            ob[i] = val;
            form.setFieldsValue(ob);
        });
        setPatternModel(copy);
    };
    return (
        <Form
            form={form}
            onFinish={handleFormFinish}
            initialValues={getInitialValues()}
            className="rule-patter-editor-form"
        >
            {patternModel.map((x: {}, tokenIndex: number) => (
                <DraggableItemWrapper
                    key={tokenIndex}
                    index={tokenIndex}
                    handleOnDrop={handleOnDrop}
                    handleOnHit={handleOnHit}
                >
                    <Card key={tokenIndex} style={{ marginBottom: 20, width: 450 }}>
                        <Form.List name={tokenIndex}>
                            {(fields, { add, remove }) => {
                                return (
                                    <>
                                        {fields.map((field, attributeIndex) => (
                                            <Space
                                                key={`${field.key}-${attributeIndex}`}
                                                style={{ display: 'flex', marginBottom: 8 }}
                                                align="baseline"
                                            >
                                                <Form.Item
                                                    // key={`${index}-${i}-type`}
                                                    {...field}
                                                    name={[field.name, 'type']}
                                                    rules={[{ required: true, message: 'Input type please' }]}
                                                >
                                                    <Select
                                                        style={{ width: 140 }}
                                                        onChange={val =>
                                                            handleChange(val as string, attributeIndex, tokenIndex)
                                                        }
                                                    >
                                                        {dropdownData.map(v => (
                                                            <Option key={v} value={v}>
                                                                {v}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ width: 200 }}
                                                    // key={`${index}-${i}-value`}
                                                    {...field}
                                                    name={[field.name, 'value']}
                                                    rules={[{ required: true, message: 'Input value please' }]}
                                                >
                                                    {getValueComponent(attributeIndex, tokenIndex)}
                                                </Form.Item>
                                                <Button type="link" onClick={() => remove(field.name)} size="small">
                                                    <MinusOutlined />
                                                </Button>
                                            </Space>
                                        ))}
                                        <Form.Item style={{ marginBottom: 0 }}>
                                            <Button type="link" onClick={() => add()} size="small">
                                                <PlusOutlined />
                                            </Button>
                                        </Form.Item>
                                        <Button
                                            onClick={() => {
                                                handleTokenDelete(tokenIndex);
                                            }}
                                            style={{ position: 'absolute', top: -2, right: -4 }}
                                            type="link"
                                            size="small"
                                        >
                                            <CloseOutlined style={{ color: '#FF0000' }} />
                                        </Button>
                                    </>
                                );
                            }}
                        </Form.List>
                    </Card>
                </DraggableItemWrapper>
            ))}
            <Button onClick={handleTokenAddition}>Add token</Button>
            <div style={{ textAlign: 'right' }}>
                <Button size="large" className="light" onClick={handleCancel} style={{ marginRight: 14 }}>
                    {' '}
                    Cancel{' '}
                </Button>
                <Button size="large" type="primary" htmlType="submit" style={{ marginRight: 16 }}>
                    {' '}
                    Save{' '}
                </Button>
            </div>
        </Form>
    );
};

const dropdownData = [
    'ORTH',
    'LOWER',
    'LENGTH',
    'IS_ALPHA',
    'IS_ASCII',
    'IS_DIGIT',
    'IS_LOWER',
    'IS_UPPER',
    'IS_TITLE',
    'IS_PUNCT',
    'IS_SPACE',
    'IS_STOP',
    'LIKE_NUM',
    'LIKE_URL',
    'LIKE_EMAIL',
    'POS',
    'TAG',
    'DEP',
    'LEMMA',
    'SHAPE',
    'ENT_TYPE',
    'OP'
];

export default RulePatternEditorContent;
