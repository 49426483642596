import * as React from 'react';
import { ProtectedRoute } from '../../common/components';
import SchemaGeneratorPage from '../screens/SchemaGeneratorPage';

export const SchemaGeneratorNavigation = {
    SchemaGeneratorPage: '/projects/:projectId/pydantic-schema-generator'
};

export default () => {
    return (
        <>
            <ProtectedRoute component={SchemaGeneratorPage} path={SchemaGeneratorNavigation.SchemaGeneratorPage} />
        </>
    );
};
