import * as React from 'react';
import { observer } from 'mobx-react-lite';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { SendOutlined } from '@ant-design/icons';
import { ChatbotStore } from '../../stores';
import { ChatbotSettingsValues } from '../../types';
import './ChatbotInput.less';

type Props = {
    store: ChatbotStore;
    settingsForm: FormInstance<ChatbotSettingsValues>;
};

const ChatbotInput: React.FC<Props> = ({ store, settingsForm }) => {
    const [message, setMessage] = React.useState('');

    const ref = React.useRef<HTMLTextAreaElement>(null);

    const isDisabled = React.useMemo(() => store.isBusy || !message.trim().length, [store.isBusy, message]);

    React.useEffect(() => {
        if (store.dialogVisible && ref.current) {
            ref.current.focus();
        }
    }, [store.dialogVisible]);

    const askQuestion = () => {
        if (isDisabled) {
            return;
        }

        if (!store.settings.values.instructWorkflowId) {
            store.setSettingsVisible(true);
            setTimeout(() => settingsForm.validateFields(), 300);
            return;
        }

        store.askQuestion(message.trim());
        setMessage('');
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const enterPressed = event.key === 'Enter';
        const newLinePressed = event.metaKey || event.shiftKey;

        if (enterPressed && !newLinePressed) {
            event.preventDefault();
            askQuestion();
            return;
        }

        if (event.key === 'ArrowUp' && store.lastSentMessage) {
            setMessage(store.lastSentMessage);
        }
    };

    return (
        <div className="chatbot-input-container">
            <TextareaAutosize
                className="chatbot-input"
                placeholder="Ask a question..."
                ref={ref}
                minRows={1}
                maxRows={6}
                value={message}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
            />

            <Tooltip title="Send question">
                <Button type="primary" disabled={isDisabled} onClick={askQuestion}>
                    <SendOutlined />
                </Button>
            </Tooltip>
        </div>
    );
};

export default observer(ChatbotInput);
