import { Button, Collapse, Empty, Modal, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ReferenceDataUsageStore } from '../../stores';

type Props = {
    selectedTable: string;
    store: ReferenceDataUsageStore;
};

const MutipleProjectsRefDataUsageDialog: React.FC<Props> = ({ selectedTable, store }) => {
    React.useEffect(() => {
        if (selectedTable && store.mutipleProjectsDialogVisible) {
            store.getMutipleProjectsRefDataUsage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.mutipleProjectsDialogVisible, selectedTable]);

    const loadingContent = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                <Spin />
            </div>
        );
    };

    const dataContent = () => {
        if (!store.mutipleProjectsDataUsage || !store.mutipleProjectsDataUsage.length) {
            return (
                <Empty
                    style={{ width: '100%' }}
                    image={Empty.PRESENTED_IMAGE_DEFAULT}
                    imageStyle={{ display: 'block' }}
                    description="No usage data found"
                />
            );
        }
        return (
            <Collapse style={{ width: '100%', alignSelf: 'flex-start' }}>
                {store.mutipleProjectsDataUsage.map(item => (
                    <Collapse.Panel header={item.projectName} key={item.projectId}>
                        <Collapse>
                            {item.groups.map(group => (
                                <Collapse.Panel header={group.groupName} key={group.groupId}>
                                    <Collapse>
                                        {item.tags
                                            .filter(tag => tag.groupId === group.groupId)
                                            .map(tag => (
                                                <Collapse.Panel header={tag.tagName} key={tag.tagId}>
                                                    {item.rules
                                                        .filter(rule => rule.tagId === tag.tagId)
                                                        .map(rule => (
                                                            <div key={rule.ruleId}>
                                                                {store.projects
                                                                    .map(p => p.id)
                                                                    .includes(item.projectId) ? (
                                                                    <a
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        href={`${window.location.origin}/projects/${item.projectId}/rules/edit/${rule.ruleId}/false`}
                                                                    >
                                                                        {rule.ruleName}
                                                                    </a>
                                                                ) : (
                                                                    rule.ruleName
                                                                )}
                                                            </div>
                                                        ))}
                                                </Collapse.Panel>
                                            ))}
                                    </Collapse>
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    </Collapse.Panel>
                ))}
            </Collapse>
        );
    };

    const handleCancel = () => {
        store.setMutipleProjectsDialogVisible(false);
    };

    return (
        <Modal
            visible={store.mutipleProjectsDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            className="alpha-portal-dialog"
            title={selectedTable ? `Usage of ${selectedTable}` : 'Reference data usage'}
            centered
            getContainer={false}
            footer={[
                <Button
                    data-id="reference-data-usage-dialog-close"
                    key="back"
                    onClick={handleCancel}
                    size="large"
                    className="light"
                >
                    Close
                </Button>
            ]}
        >
            <div
                style={{
                    minHeight: 300,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxHeight: 'calc(100vh - 220px)',
                    overflow: 'auto'
                }}
            >
                {store.mutipleProjectsDataLoading ? loadingContent() : dataContent()}
            </div>
        </Modal>
    );
};

export default observer(MutipleProjectsRefDataUsageDialog);
