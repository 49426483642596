import ProjectAssignmentVisualStore from './ProjectAssignmentVisualStore';
import { RootStores } from '../../../modules/common/stores';
import { ProjectsService } from '../../../modules/common/services';
import {
    STORE_PROJECT_ASSIGNMENT,
    STORE_GLOBAL_ADMINISTRATION,
    STORE_CODE_SNIPPETS_UI,
    STORE_USERS_MANAGER_UI,
    STORE_TECHNICAL_INFORMATION
} from '../constants';
import { STORE_ERRORS } from '../../../modules/common/constants';
import GlobalAdministrationStore from './GlobalAdministrationStore';
import { GlobalAdministrationService } from '../service/GlobalAdministrationService';
import { CodeSnippetsVisualStore } from './CodeSnippetsVisualStore';
import UsersManagerStore from './UsersManagerStore';
import { TechnicalInformationStore } from './TechnicalInformationStore';

export { default as ProjectAssignmentVisualStore } from './ProjectAssignmentVisualStore';
export { default as GlobalAdministrationStore } from './ProjectAssignmentVisualStore';

export function injectAdministrationStores(obj: RootStores) {
    const adminService = new GlobalAdministrationService();
    const projService = new ProjectsService();

    obj[STORE_PROJECT_ASSIGNMENT] = new ProjectAssignmentVisualStore(obj[STORE_ERRORS], adminService, projService);
    obj[STORE_GLOBAL_ADMINISTRATION] = new GlobalAdministrationStore(obj[STORE_ERRORS], adminService);
    obj[STORE_CODE_SNIPPETS_UI] = new CodeSnippetsVisualStore(adminService);
    obj[STORE_USERS_MANAGER_UI] = new UsersManagerStore(adminService, obj[STORE_ERRORS]);
    obj[STORE_TECHNICAL_INFORMATION] = new TechnicalInformationStore(adminService);
}

export type AdministrationStores = {
    [STORE_PROJECT_ASSIGNMENT]?: ProjectAssignmentVisualStore;
    [STORE_GLOBAL_ADMINISTRATION]?: GlobalAdministrationStore;
    [STORE_CODE_SNIPPETS_UI]?: CodeSnippetsVisualStore;
    [STORE_USERS_MANAGER_UI]?: UsersManagerStore;
    [STORE_TECHNICAL_INFORMATION]?: TechnicalInformationStore;
};
