/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action, computed, reaction } from 'mobx';
import ReferenceDataService from '../services/ReferenceDataService';
import { ProjectsRootVisualStore } from '../../../modules/common/stores';
import { Connection } from '../../../modules/rules/models';
import _ from 'lodash';
import { message } from 'antd';
import type { RefTableData, ValueMapping } from '../services/ReferenceDataService';
import RefDataTableGroupModel from '../models/RefDataTableGroupModel';

export enum RefDataConstants {
    ALPHA_SYSTEM_UPDATED_BY = 'ALPHA_SYSTEM_UPDATED_BY',
    ALPHA_SYSTEM_UPDATE_DATE = 'ALPHA_SYSTEM_UPDATE_DATE'
}
export default class ReferenceDataVisualStore {
    @observable
    isLoading: boolean = false;

    @observable
    refTableData: RefTableData = Object.create({});

    @observable
    connections: Connection[] = [];

    @observable
    selectedTable: string;

    @observable
    editableTable: string;

    @observable
    addNewTableDialogVisible: boolean = false;

    @observable
    addNewRecordDialogVisible: boolean = false;

    @observable
    editTableDialogVisible: boolean = false;

    @observable
    createTableGroupDialogVisible: boolean = false;

    @observable
    refDataFilteredValue: string = '';

    @observable
    newTableFields: string[] = [];

    @observable
    currentFieldInputValue: string;

    @observable
    connectionInputValue: string;

    @observable
    isNewRecordMode: boolean = false;

    @observable
    currentConnectionId: string;

    @observable
    selectedRowKeys: string[];

    @observable
    isCurrentFieldInputValueValid: boolean = false;

    @observable
    isImportedResultDialogVisible: boolean = false;

    @observable
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    importResult: any[] = [];

    @observable
    fileImportActionHeaders: {};

    @observable
    queryError: string;

    @observable
    showResults: boolean = false;

    @observable
    queryResults: {}[] = [];

    @observable
    editorValue: string;

    @observable
    showSqlEditorModal: boolean = false;

    @observable
    tableNames: string[] = [];

    @observable
    isTableLoading: boolean = false;

    @observable
    tableGroups: RefDataTableGroupModel[] = [];

    reservedFieldNames: string[] = ['id'];

    constructor(
        private service: ReferenceDataService,
        private rootStore: ProjectsRootVisualStore
    ) {
        reaction(
            () => this.currentProject,
            () => {
                this.getConnections();
                this.selectedTable = '';
                this.currentConnectionId = '';
                this.connectionInputValue = '';
                this.refTableData = Object.create({});
                this.tableNames = [];
                this.tableGroups = [];
            }
        );

        reaction(
            () => this.selectedTable,
            () => {
                this.setRefDataFilteredValue('');
            }
        );
    }

    @computed
    get projects() {
        return this.rootStore.projects;
    }

    @computed
    get currentProject() {
        return this.rootStore.currentProject!;
    }

    @computed
    get filteredConnections() {
        return (
            (!!this.currentProject &&
                this.currentProject.id &&
                this.connections &&
                this.connections.filter(c => c.projectId === this.currentProject.id)) ||
            []
        );
    }

    @computed
    get filteredValues() {
        const data = this.refTableData?.data ?? [];

        if (!this.refDataFilteredValue) {
            return data;
        }

        return (
            data.filter(
                x =>
                    !!Object.values(x).find(
                        s => s && s.toString().toLowerCase().includes(this.refDataFilteredValue.toLowerCase())
                    )
            ) ||
            (this.selectedTable && data) ||
            []
        );
    }

    @computed
    get currentTableFields() {
        return (
            (this.selectedTable &&
                this.refTableData.fields.filter(
                    t =>
                        t !== 'Id' &&
                        t !== RefDataConstants.ALPHA_SYSTEM_UPDATED_BY &&
                        t !== RefDataConstants.ALPHA_SYSTEM_UPDATE_DATE
                )) ||
            []
        );
    }

    @computed
    get columns() {
        if (this.refTableData && this.selectedTable) {
            return this.refTableData.fields.filter(
                f =>
                    f !== 'Id' &&
                    f !== RefDataConstants.ALPHA_SYSTEM_UPDATED_BY &&
                    f !== RefDataConstants.ALPHA_SYSTEM_UPDATE_DATE
            );
        } else {
            return [];
        }
    }

    @computed
    get searchData() {
        const data = this!.selectedTable ? this!.refTableData.data : null;
        let searchData = [] as string[];
        if (data) {
            data.forEach(d => {
                const keys = Object.keys(d).filter(
                    x =>
                        ![
                            'Id',
                            RefDataConstants.ALPHA_SYSTEM_UPDATED_BY,
                            RefDataConstants.ALPHA_SYSTEM_UPDATE_DATE
                        ].includes(x)
                );
                searchData = searchData.concat(keys.map(k => d[k]));
            });
            return [...new Set(searchData)];
        } else {
            return [];
        }
    }

    @computed
    get hasEmptyFieldInNewFields() {
        return this.newTableFields.find(n => n === '') === '';
    }

    @computed
    get updateInfo() {
        if (!this.currentConnection?.updatedBy) {
            return null;
        }

        return this.currentConnection && this.currentConnection.updateDate
            ? `Updated at: ${new Date(this.currentConnection.updateDate).toLocaleString()} by ${this.currentConnection.updatedBy}`
            : '' || '';
    }

    @computed
    get editableTableGroup() {
        return this.tableGroups.find(g => g.isEditable);
    }

    @computed
    get currentConnection() {
        return this.connections.find(x => x.id === this.currentConnectionId);
    }

    @action
    setSelectedRowKeys(selectedRowKeys: string[]) {
        this.selectedRowKeys = selectedRowKeys;
    }

    async getConnections() {
        this.isLoading = true;
        this.connections = await this.service.getConnections();
        this.isLoading = false;
    }

    async setHeaders() {
        const token = await this.service.getAuthToken();
        this.fileImportActionHeaders = {
            Authorization: 'Bearer ' + token
        };
        return Promise.resolve();
    }

    @action.bound
    async getRefDataByConnectionId(name: string) {
        this.isLoading = true;
        this.connectionInputValue = name;
        const id = this.connections.find(c => c.name === name)!.id;
        this.currentConnectionId = id;
        const resp = await this.service.getRefDataByConnectionId(this.currentProject!.id, id);

        resp.map(r => {
            this.refTableData = r;
        });

        this.isLoading = false;
    }

    @action.bound
    async getRefDataByTableName(name: string) {
        this.isTableLoading = true;
        const resp = await this.service.getRefDataByTableName(this.currentProject!.id, this.currentConnectionId, name);

        resp.map(r => {
            this.refTableData = r;
        });

        this.isTableLoading = false;
    }

    @action.bound
    async getTableData(id: string) {
        this.isLoading = true;

        this.currentConnectionId = id;
        this.resetTableSelection();

        const promises = [this.getTableNames(id), this.getTableGroups(id)];
        await Promise.all(promises);

        this.isLoading = false;
    }

    @action.bound
    async getTableNames(id: string) {
        this.tableNames = await this.service.getRefDataTableNames(this.currentProject!.id, id);
    }

    @action.bound
    async getTableGroups(id: string) {
        const data = await this.service.getTableGroups(this.currentProject!.id, id);
        this.tableGroups = data.map(dto => new RefDataTableGroupModel(dto, this, this.service));
    }

    @action.bound
    async handleTableSelect(tableName: string) {
        await this.getRefDataByTableName(tableName);
        this.selectedTable = tableName;
    }

    @action.bound
    resetTableSelection() {
        this.selectedTable = '';
        this.refTableData = Object.create({});
    }

    @action.bound
    handleTableEdit(tableName: string) {
        this.editableTable = tableName;
        this.setEditTableDialogVisible(true);
    }

    @action
    handleNewRecordFormSubmit(values: {}) {
        this.addNewRecord(values);
        this.setAddNewRecordDialogVisible(false);
    }

    @action
    handleNewTableFormSubmit(name: string) {
        this.createTable(name);
        this.setAddNewTableDialogVisible(false);
    }

    @action
    async handleTableRename(name: string) {
        await this.service.renameTable(this.currentProject!.id, this.currentConnectionId, this.editableTable, name);
        const index = this.tableNames.findIndex(x => x === this.editableTable);
        this.tableNames[index] = name;
        if (this.selectedTable === this.editableTable) {
            this.selectedTable = name;
        }

        const tableGroup = this.tableGroups.find(g => g.tableNames.includes(this.editableTable));

        if (tableGroup) {
            tableGroup.updateTable(name, this.editableTable);
        }

        this.setEditTableDialogVisible(false);
    }

    @action
    async updateTableRecord(id: string, record: any) {
        const resp = await this.service.updateRecords(
            this.currentProject!.id,
            this.currentConnectionId,
            this.selectedTable,
            id,
            record
        );

        resp.map(data => {
            const tableData = this.refTableData;
            const newData = [...tableData.data];
            const index = newData.findIndex(d => id === d.Id);
            const item = newData[index];
            record.Id = data.id;
            record[RefDataConstants.ALPHA_SYSTEM_UPDATE_DATE] = data.updateDate;
            record[RefDataConstants.ALPHA_SYSTEM_UPDATED_BY] = data.updatedBy;
            newData.splice(index, 1, { ...item, ...record });
            tableData.data = newData;
        });
    }

    @action
    async deleteRecord(id: string) {
        const newData = [...this.refTableData.data];
        const index = newData.findIndex(d => id === d.Id);
        newData.splice(index, 1);
        this.refTableData.data = newData;
        await this.service.deleteRecords(this.currentProject!.id, this.currentConnectionId, this.selectedTable, id);
    }

    @action.bound
    async addNewRecord(record: any) {
        const newData = [...this.refTableData.data];
        const resp = await this.service.updateRecords(
            this.currentProject!.id,
            this.currentConnectionId,
            this.selectedTable,
            null,
            record
        );
        resp.map(item => {
            record.Id = item.id;
            record.ALPHA_SYSTEM_UPDATE_DATE = item.updateDate;
            record.ALPHA_SYSTEM_UPDATED_BY = item.updatedBy;
            newData.unshift(record as ValueMapping);
            this.refTableData.data = newData;
        });
    }

    @action
    setAddNewTableDialogVisible(isOpen: boolean) {
        this.addNewTableDialogVisible = isOpen;
    }

    @action
    setAddNewRecordDialogVisible(isOpen: boolean) {
        this.addNewRecordDialogVisible = isOpen;
    }

    @action
    setEditTableDialogVisible(isOpen: boolean) {
        this.editTableDialogVisible = isOpen;
    }

    @action
    setCreateTableGroupDialogVisible(isOpen: boolean) {
        this.createTableGroupDialogVisible = isOpen;
    }

    @action
    async createTable(name: string) {
        this.refTableData = { data: [], fields: this.newTableFields };
        const resp = await this.service.createTable(
            this.currentProject!.id,
            name,
            this.currentConnectionId,
            this.newTableFields
        );
        resp.map(() => (this.newTableFields = []));
        this.tableNames.push(name);
        this.selectedTable = name;
    }

    @action.bound
    async deleteTable(tableToDelete: string) {
        await this.service.deleteTable(this.currentProject!.id, this.currentConnectionId, tableToDelete);
        if (this.selectedTable === tableToDelete) {
            this.selectedTable = '';
            this.refTableData = Object.create({});
        }
        this.tableNames = this.tableNames.filter(x => x !== tableToDelete);

        const tableGroup = this.tableGroups.find(g => g.tableNames.includes(tableToDelete));

        if (tableGroup) {
            tableGroup.removeTable(tableToDelete);
        }
    }

    async exportTable(tableName: string) {
        this.service.exportTable(this.currentProject!.id, this.currentConnectionId, tableName);
    }

    @action.bound
    async saveImportedData() {
        if (!this.importResult) {
            this.setImportedResultDialogVisible(false);
            this.setSqlEditorModalVisible(false);
            return;
        }
        this.isLoading = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const saveModel = this.importResult
            .filter((x: any) => this.selectedRowKeys.indexOf(x.rowId) >= 0)
            .map((x: any) => {
                delete x.rowId;
                return x;
            });
        await this.service.saveImportedData(
            this.currentProject!.id,
            this.currentConnectionId,
            this.selectedTable,
            saveModel
        );
        const resp = await this.service.getRefDataByTableName(
            this.currentProject!.id,
            this.currentConnectionId,
            this.selectedTable
        );
        resp.map(d => (this.refTableData = d));
        this.setImportedResultDialogVisible(false);
        this.setSqlEditorModalVisible(false);
        this.removeImportResults();
        this.isLoading = false;
    }

    @action.bound
    async createTableGroup(name: string) {
        if (!this.currentProject || !this.currentConnectionId) {
            return false;
        }

        try {
            this.isLoading = true;

            const resp = await this.service.createTableGroup({
                name,
                projectId: this.currentProject.id,
                refDataConnectionId: this.currentConnectionId
            });

            if (!resp.isOk()) {
                message.error(`Error: ${resp.error.data ? resp.error.data.title : resp.error.text}`);
                return false;
            }

            resp.map(dto => this.tableGroups.push(new RefDataTableGroupModel(dto, this, this.service)));

            return true;
        } finally {
            this.isLoading = false;
        }
    }

    @action.bound
    async deleteTableGroup(id: string) {
        if (!this.currentProject) {
            return;
        }

        const resp = await this.service.deleteTableGroup({ id, projectId: this.currentProject.id });

        if (!resp.isOk()) {
            message.error('Failed to delete group');
            return;
        }

        this.tableGroups = this.tableGroups.filter(g => g.id !== id);
    }

    @action.bound
    async setRefDataFilteredValue(value: string) {
        this.refDataFilteredValue = value;
    }

    @action.bound
    setCurrentFieldInputValue(event: React.ChangeEvent) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.currentFieldInputValue = (event.currentTarget as any).value;
        let regex = new RegExp(/^[a-zA-Z0-9@#\_]*$/, 'i');
        let isReserved = this.reservedFieldNames.some(s => s === this.currentFieldInputValue.toLowerCase().trim());
        this.isCurrentFieldInputValueValid =
            regex.test(this.currentFieldInputValue) &&
            !isReserved &&
            !this.newTableFields.some(s => s === this.currentFieldInputValue.toLowerCase().trim());
    }

    @action.bound
    setNewTableFields(value: string[]) {
        this.newTableFields = value;
    }

    @action.bound
    addNewFieldValue() {
        const newTableFields = this.newTableFields.slice();
        newTableFields.push(this.currentFieldInputValue);
        this.newTableFields = newTableFields;
        this.currentFieldInputValue = '';
    }

    @action.bound
    renameField(fieldName: string, newFieldName: string) {
        const newTableFields = this.newTableFields.slice();
        const index = this.newTableFields.findIndex(n => n === fieldName);
        newTableFields[index] = newFieldName;
        this.newTableFields = newTableFields;
    }
    @action.bound
    removeItem(fieldName: string) {
        const newTableFields = this.newTableFields.slice();
        const index = this.newTableFields.findIndex(n => n === fieldName);
        newTableFields.splice(index, 1);
        this.newTableFields = newTableFields;
    }

    @action
    setNewRecordMode(value: boolean) {
        this.isNewRecordMode = value;
    }

    @action
    setSqlEditorModalVisible(value: boolean) {
        this.showSqlEditorModal = value;
    }

    @action
    setImportedResultDialogVisible(val: boolean) {
        this.isImportedResultDialogVisible = val;
    }

    @action
    removeImportResults() {
        this.importResult = [];
    }

    @action
    setImportResult(data: {}[]) {
        if (data && data.length) {
            const commonFields = _.intersection(Object.keys(data[0]), this.refTableData.fields);

            if (this.currentConnection?.connectionType === 'MongoDb') {
                this.setUpMongoDbImport(data);
            } else if (commonFields.length && commonFields.length === Object.keys(data[0]).length) {
                this.selectedRowKeys = [...Array(data.length).keys()].map(x => x.toString());
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.importResult = data.map((x: any, i: number) => {
                    const obj = {};
                    Object.assign(obj, { rowId: i.toString() });
                    commonFields.forEach(f => (obj[f] = x[f]));
                    return obj;
                });
            } else {
                message.error("Table schemas don't match, please check imported data");
                this.isLoading = false;
                return;
            }
        }

        this.isLoading = false;
        this.setImportedResultDialogVisible(true);
    }

    @action
    setEditorValue(val: string) {
        this.editorValue = val;
    }

    @action
    setError(val: string) {
        this.queryError = val;
    }

    @action
    setShowResults(val: boolean) {
        this.showResults = val;
    }

    @action
    setQueryResults(queryResults: {}[]) {
        this.queryResults = queryResults;
    }

    @action.bound
    private setUpMongoDbImport(data: {}[]) {
        if (this.currentConnection?.connectionType !== 'MongoDb') {
            return;
        }

        const keys = Object.keys(data[0]);

        if (keys.length > 2) {
            message.error("Table schemas don't match, please check imported data");
            return;
        }

        const valueKey = keys.find(k => k.toLowerCase() === 'value');

        if (!valueKey) {
            message.error("Table schemas don't match, please check imported data");
            return;
        }

        const idKey = keys.find(k => k.toLowerCase() !== 'value')!;
        this.selectedRowKeys = [...Array(data.length).keys()].map(x => x.toString());

        console.log(data);
        this.importResult = data.map((x: any, i: number) => {
            const obj: {} = {};
            Object.assign(obj, { rowId: i.toString(), Code: x[idKey], Value: x[valueKey] });
            return obj;
        });

        console.log(this.importResult);
    }

    async executeQuery(query: string) {
        this.setSqlEditorModalVisible(false);
        this.isLoading = true;
        const resp = await this.service.executeQuery(this.currentProject!.id, this.currentConnectionId, query);

        resp.map(d => {
            this.setImportResult(d as {}[]);
        }).mapErr(() => (this.isLoading = false));
    }

    debug(query: string) {
        return this.service.executeQuery(this.currentProject!.id, this.currentConnectionId, query);
    }

    @computed
    get hasInvalidFieldNameInput() {
        const regex = new RegExp(/^[a-zA-Z0-9@#\_]*$/, 'i');
        const hasInvalidFieldName = !!this.newTableFields.find(f => regex.test(f) === false);
        return !this.newTableFields.length || this.hasEmptyFieldInNewFields || hasInvalidFieldName;
    }
}
