import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu, Dropdown, Button, Tooltip } from 'antd';
import { TestProjectDashboardStore } from '../../stores';
import { ViewerPagesNavigation } from '../../../viewer_base/routes';
import './TestProjectPackageActions.less';

type Props = {
    packageId: string | null;
    packageName: string;
    store: TestProjectDashboardStore;
};

const TestProjectPackageActions: React.FC<Props> = ({ packageId, packageName, store }) => {
    if (!packageId) {
        return null;
    }

    const downloadMenu = (name: string) => {
        const format = name.split('.').pop() ?? '';
        return (
            <Menu>
                <Menu.Item key="1" onClick={() => store.projectsBaseStore.handleDownload(packageId)}>
                    Download {format.toLocaleLowerCase()}
                </Menu.Item>
                <Menu.Item key="2" onClick={() => store.projectsBaseStore.handleDownload(packageId, 'apkg')}>
                    Download apkg
                </Menu.Item>
            </Menu>
        );
    };

    const href = `${process.env.PUBLIC_URL}${ViewerPagesNavigation.DocumentViewerPage}/${encodeURIComponent(packageId)}`;

    return (
        <div className="test-project-package-actions-container" onClick={e => e.stopPropagation()}>
            <Dropdown overlay={() => downloadMenu(packageName)} trigger={['click']}>
                <Tooltip title="Download">
                    <Button type="link" size="small">
                        <i className="alpha-icon lg download-icon" />
                    </Button>
                </Tooltip>
            </Dropdown>

            <Tooltip title="Preview">
                <Button type="link" size="small" target="_blank" rel="noreferrer" href={href}>
                    <i
                        className="alpha-icon lg form-anchor no-hover-animation"
                        style={{ backgroundSize: '20px 20px', backgroundPosition: 'center' }}
                    />
                </Button>
            </Tooltip>
        </div>
    );
};

export default observer(TestProjectPackageActions);
