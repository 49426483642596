import { RcFile } from 'antd/lib/upload/interface';
import { ApplicationDefinitionType } from './ApplicationDefinition';

export default class ApplicationDefinitionCreateModel {
    constructor(
        public type: ApplicationDefinitionType,
        public name: string,
        public applicationId: string,
        public workflowId: string,
        public state: string,
        public projectId: string,
        public settings: string,
        public meta: string,
        public extension: string,
        public icon?: RcFile,
        public capabilities?: string
    ) {}
}
