import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { TestProjectRunTopicPreview } from '../components';
import { TestProjectStores } from '../stores';
import { STORE_TEST_PROJECT_RUN_TOPIC_PREVIEW } from '../stores/constants';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';

const TestProjectBaselinesPage: React.FC<TestProjectStores> = ({ projectTestProjectsRunTopicPreviewUI }) => {
    return (
        <HasPermission permissionClaim={AppPermissions.CanManageTestProjects}>
            <TestProjectRunTopicPreview store={projectTestProjectsRunTopicPreviewUI!} />
        </HasPermission>
    );
};

export default inject(STORE_TEST_PROJECT_RUN_TOPIC_PREVIEW)(observer(TestProjectBaselinesPage));
