import { observable, action } from 'mobx';
import _ from 'lodash';

export type ErrorMsg = {
    stackTrace: string;
    detail: string;
    title: string;
    status: number;
    type: string;
};

export class ErrorStore {
    @observable
    errors: Error[] = [];

    @action
    async addError(resp: Response | ErrorMsg | undefined | Error) {
        if (!resp) {
            return;
        }

        console.log(resp);
        if (resp instanceof Error) {
            this.errors.push(new Error(JSON.stringify(resp)));
        } else if (!(resp instanceof Response) && resp.title) {
            const error = new Error(resp.title);
            this.errors.push(error);
            console.error(error.message);
        } else if (
            resp instanceof Response &&
            resp.headers &&
            resp.headers.get('Content-Type') === 'application/json'
        ) {
            const msg = <ErrorMsg>await resp.json();
            const error = new Error(msg.title);
            this.errors.push(error);
            console.error(error.message);
        } else {
            this.errors.push(new Error(JSON.stringify(resp)));
        }
    }

    @action
    async addBasicError(error: Error) {
        this.errors.push(error);
        console.error(error.message);
    }

    @action
    removeError(error: Error) {
        _.remove(this.errors, function (n: Error) {
            return _.isEqual(error, n);
        });
    }
}

export default ErrorStore;
