import * as React from 'react';
import { inject, Observer } from 'mobx-react';
import { Row, Col, Button, Input, AutoComplete, Select, DatePicker, Form } from 'antd';
import searchSessionsAutoCompleteOptionsRenderer from './SearchSessionsAutoCompleteOptionsRenderer';
import { SessionsVisualStore } from '../stores';
import { STORE_SESSIONS } from '../constants';
import { observer } from 'mobx-react-lite';

const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker } = DatePicker;

type Props = {
    sessionsStore: SessionsVisualStore;
};

const SessionsFilter: React.FC<Props> = ({ sessionsStore }) => {
    const [form] = Form.useForm();
    React.useEffect(() => {
        handleClear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionsStore.currentProject]);
    const searchForAutocomplete = sessionsStore.searchForAutocomplete;
    const performSearch = sessionsStore!.performSearch;
    const optionRenderer = searchSessionsAutoCompleteOptionsRenderer(sessionsStore);

    const applicationNames = sessionsStore.applicationNames;
    const formItemLayout = {
        labelCol: {
            style: {
                width: '120px'
            }
        },
        wrapperCol: {
            style: {
                width: 'calc(100% - 120px)',
                minWidth: '140px'
            }
        }
    };

    const handleClear = () => {
        form.resetFields();
        sessionsStore.clearFilter();
    };

    return (
        <Form className="ant-advanced-search-form" form={form}>
            <Row gutter={24}>
                <Col span={10}>
                    <FormItem {...formItemLayout} name="search">
                        <Observer>
                            {() => (
                                <div className="global-search-wrapper" style={{ width: '100%' }}>
                                    <AutoComplete
                                        className="global-search alpha-autocomplete"
                                        style={{ width: '100%' }}
                                        options={sessionsStore.autocompleteSource.map((m, i) => ({
                                            value: m.packageName,
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            label: optionRenderer(m, i) as any
                                        }))}
                                        onSearch={searchForAutocomplete}
                                        placeholder="Search for package"
                                        defaultActiveFirstOption={false}
                                        showSearch
                                        value={sessionsStore.searchTerm || ''}
                                        onSelect={v => {
                                            const value = v as string;
                                            sessionsStore.performSearch(value);
                                        }}
                                    >
                                        <Input
                                            suffix={
                                                <Button className="search-btn" type="ghost">
                                                    <i
                                                        className="alpha-icon md search-icon"
                                                        style={{ verticalAlign: 'middle' }}
                                                    />
                                                </Button>
                                            }
                                            onPressEnter={e => {
                                                e.currentTarget.blur();
                                                performSearch(e.currentTarget.value);
                                            }}
                                        />
                                    </AutoComplete>
                                </div>
                            )}
                        </Observer>
                    </FormItem>
                </Col>
            </Row>
            <Row gutter={15} style={{ marginTop: '6px' }}>
                <Col span={5}>
                    <FormItem {...formItemLayout} name="applicationName">
                        <Select onChange={sessionsStore.setApplicationName} placeholder="Applications...">
                            {applicationNames.map((k, i) => (
                                <Option key={`kw-${i}`} value={k}>
                                    {k}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                </Col>
                <Col span={5} style={{ display: 'block' }}>
                    <FormItem {...formItemLayout} name="userName">
                        <Select onChange={sessionsStore.setUserName} placeholder="Users...">
                            {sessionsStore.userNames.map((k, i) => (
                                <Option key={`us-${i}`} value={k}>
                                    {k}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                </Col>
                <Col span={5}>
                    <FormItem {...formItemLayout}>
                        <RangePicker style={{ minWidth: 210 }} onChange={sessionsStore.setDates} />
                    </FormItem>
                </Col>
                <Col offset={5} span={4} style={{ textAlign: 'right', display: 'block', float: 'right' }}>
                    <Button
                        className="clear-button"
                        style={{ width: 'calc(100% - 100px)', minWidth: 60, marginRight: 6 }}
                        onClick={handleClear}
                    >
                        Clear
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default inject(STORE_SESSIONS)(observer(SessionsFilter));
