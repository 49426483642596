/* eslint-disable @typescript-eslint/member-ordering */
import { observable } from 'mobx';
import { ConnectionResult, ConnectionEditResult } from '../types';
import type ConnectionTypes from './ConnectionTypes';

export default class Connection {
    id: string;

    @observable
    name: string;

    @observable
    connectionType: ConnectionTypes = 'SqlServer';

    @observable
    connectionString: string;

    @observable
    projectId: string;

    updateDate?: Date;

    updatedBy?: string;

    constructor(con: ConnectionResult) {
        this.id = con.id;
        this.name = con.name;
        this.connectionType = con.connectionType;
        this.connectionString = con.connectionString;
        this.projectId = con.projectId;
        this.updatedBy = con.updatedBy;
        this.updateDate = con.updateDate;
    }

    toJson(): ConnectionEditResult {
        const json: ConnectionEditResult = {
            id: this.id,
            name: this.name,
            type: this.connectionType,
            connectionString: this.connectionString,
            projectId: this.projectId
        };

        return json;
    }
}
