/* eslint-disable max-len */
import * as React from 'react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { Button, Layout, Tooltip } from 'antd';
import UploadedPackagesFilter from '../components/UploadedPackagesFilter';
import { ProjectStores } from '../stores';
import { inject, observer } from 'mobx-react';
import { STORE_UPLOADED_PACKAGES } from '../constants';
import { UploadDocumentsDialog } from '../../upload_data/components';
import { AppPermissions } from '../../authorization/Permissions';
import { HasPermission } from '../../authorization/components/HasPermission';
import UploadedPackagesGridFc from '../components/UploadedPackagesGridFc';
import LayoutHeader from '../../../components/LayoutHeader';
import { Utils } from '../../common/services/Utils';

type Props = ProjectStores;
export class UploadedPackagesPage extends React.Component<Props, object> {
    render() {
        const store = this.props.uploadedPackagesUI!;

        const content = (
            <Layout>
                <LayoutHeader
                    subtitle={Utils.getSubtitle(store!.currentProject)}
                    title="Packages"
                    helpMessage="You can manage, re-index, re-parse and delete packages here."
                    buttons={[
                        <Tooltip key="clearFilters" title="Reset all search queries, filters, and sorting">
                            <Button
                                onClick={store.clearFilters}
                                size="large"
                                className="light"
                                disabled={!store.filtersModified}
                                style={{ marginRight: 15 }}
                            >
                                Clear filters
                            </Button>
                        </Tooltip>,
                        <HasPermission
                            key="upload-documents-button-wrapper"
                            permissionClaim={AppPermissions.CanEditProcessDocuments}
                            entityId={store.currentProject?.id}
                            yes={() => (
                                <Button
                                    data-id="uploaded-packages-upload"
                                    size="large"
                                    type="primary"
                                    onClick={() => store.setUploadDocsDialogVisible(true)}
                                >
                                    Upload documents
                                </Button>
                            )}
                        />
                    ]}
                />
                <Layout.Content>
                    <UploadDocumentsDialog
                        visible={store!.uploadDocsDialogVisible}
                        changeVisible={store!.setUploadDocsDialogVisible}
                        alphaConfigs={store!.defaultAlphaConfigs}
                    />
                    <UploadedPackagesFilter />
                    <UploadedPackagesGridFc store={store} />
                </Layout.Content>
            </Layout>
        );

        return <FlexContentContainer id="uploaded-packages-container" content={content} />;
    }
}

export default inject(STORE_UPLOADED_PACKAGES)(observer(UploadedPackagesPage));
