import appClient from '../../common/services/AppClient';
import { NetworkError } from '../types/CustomErrors';
import type { UserInfo } from '../types/UserInfo';

class UserInfoService {
    async getUserInfo() {
        const url = `${process.env.REACT_APP_USERS_URL}userInfo`;
        const resp = await appClient.get<UserInfo>(url);
        if (resp.isErr() && resp.error.data?.type === NetworkError.type) {
            throw new NetworkError();
        }
        const userInfo = resp.unwrapOr({} as UserInfo);
        return userInfo;
    }
}

const UserService = new UserInfoService();
export default UserService;
