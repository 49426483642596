import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CreateEditConditionDialog, ConditionsActionsRenderer, ConditionsTable, ConditionDetailsRenderer } from '..';
import { ApplicationDefinitionConditionalEditStore } from '../../stores';
import { ProjectApplicationDefinitionEditVisualStore } from '../../../iota_applications/stores';

interface Props {
    store: ApplicationDefinitionConditionalEditStore;
    applicationDefinitionEditStore: ProjectApplicationDefinitionEditVisualStore;
}

const ConditionsRenderer: React.FC<Props> = ({ store, applicationDefinitionEditStore }) => {
    return (
        <div className="conditions-renderer">
            <CreateEditConditionDialog store={store} applicationDefinitionEditStore={applicationDefinitionEditStore} />

            <ConditionsActionsRenderer store={store} />

            <ConditionsTable store={store} applicationDefinitionEditStore={applicationDefinitionEditStore} />

            <ConditionDetailsRenderer store={store} applicationDefinitionEditStore={applicationDefinitionEditStore} />
        </div>
    );
};

export default observer(ConditionsRenderer);
