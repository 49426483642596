import * as React from 'react';
import { observer } from 'mobx-react';
import { Document, Pdf as ReactPdf } from 'react-pdf';
import { Spin } from 'antd';
import security from '../services/SecurityService';
import { VariableSizeList, ListOnItemsRenderedProps } from 'react-window';
import PageRenderer from './PageRenderer';
import { reaction } from 'mobx';
import _ from 'lodash';
import PreviewVisualStore from '../../pipeline_base/stores/PreviewVisualStore';
// import { PackageViewerVisualStoreBase } from '../../viewer_base/stores';
// import TOC from './Toc';
export enum ContentType {
    Text = 'text/plain',
    Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    Xltm = 'application/vnd.ms-excel.template.macroEnabled.12',
    Xlsm = 'application/vnd.ms-excel.sheet.macroEnabled.12',
    Xls = 'application/vnd.ms-excel',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Pdf = 'application/pdf'
}

type Props<T extends PreviewVisualStore> = {
    store: T;
    renderBlocks?: boolean;
    pageHeaderHeight?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageRenderer?: any;
};

type State = {
    initialContainerHeight: number | null;
    containerHeight: number | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pdf: any;
    currentPage: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cachedPageDimensions: any;
    responsiveScale: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageNumbers: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pages: any;
};

export class PreviewContent<T extends PreviewVisualStore> extends React.Component<Props<T>, State> {
    pageHeaderHeight: number = this.props.pageHeaderHeight || 140;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    documentList: React.RefObject<VariableSizeList>;
    mounted: boolean;
    callResizeHandler: () => void;
    callOrientationChangeHandler: () => void;
    computeRowHeightHandler: (index: number) => number;
    resizeHandler: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    visiblePageUpdateHandler: (props: ListOnItemsRenderedProps) => any;
    pageScrollHandler: () => void;
    pdfContainerWidth: number;
    pdfContainerRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props<T>) {
        super(props);
        this.handleDocumentLoaded = this.handleDocumentLoaded.bind(this);
        this.computeRowHeightHandler = this.computeRowHeight.bind(this);
        this.resizeHandler = this.handleResize.bind(this);
        this.visiblePageUpdateHandler = this.updateCurrentVisiblePage.bind(this);

        this.state = {
            initialContainerHeight: null,
            containerHeight: window.innerHeight - this.pageHeaderHeight,
            pdf: null,
            currentPage: 1,
            cachedPageDimensions: null,
            responsiveScale: 1,
            pageNumbers: new Map(),
            pages: new WeakMap()
        };

        this.documentList = React.createRef();
        this.pdfContainerRef = React.createRef();

        this.callResizeHandler = _.debounce(this.handleResize.bind(this), 50);
        this.callOrientationChangeHandler = _.debounce(this.handleResize.bind(this), 1000);
        this.pageScrollHandler = _.debounce(this.updatePageAfterScroll.bind(this), 100);

        const store = this.props.store;
        reaction(
            () => store!.scrollPosition,
            c => this.scrollPdfToCoordinate(c)
        );
        reaction(
            () => store!.scale,
            () => this.recalcAllHeights()
        );
    }

    recalcAllHeights() {
        this.cachePageDimensions(this.state.pdf);
        this.recomputeRowHeights();
    }

    scrollPdfToCoordinate(c: number) {
        if (this.documentList && this.documentList.current!) {
            this.documentList.current!.scrollTo(c);
        }
    }

    componentDidMount() {
        this.mounted = true;
        window.addEventListener('resize', this.callResizeHandler);
        window.addEventListener('orientationchange', this.callOrientationChangeHandler);
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener('resize', this.callResizeHandler);
        window.removeEventListener('orientationchange', this.callOrientationChangeHandler);
    }

    recomputeRowHeights() {
        if (this.documentList && this.documentList.current) {
            this.documentList.current.resetAfterIndex(0, true);
        }
    }

    updateCurrentVisiblePage(visibleStopIndex: number) {
        this.setState({ currentPage: visibleStopIndex + 1 });
    }

    computeResponsiveScale(pageNumber: number) {
        const { cachedPageDimensions, pages, pageNumbers } = this.state;
        const node = pages.get(pageNumbers.get(pageNumber));

        if (!node) {
            return;
        }
        return node.clientHeight / cachedPageDimensions.get(pageNumber)[1];
    }

    // computeRowHeight(index: number) {
    //   const { cachedPageDimensions, responsiveScale } = this.state;
    //   const store = this.props.store;
    //   if (cachedPageDimensions && responsiveScale && cachedPageDimensions.get(index + 1)) {
    //     // + 20 to introduce gap between pages
    //     return (cachedPageDimensions.get(index + 1)[1] * store!.scale * responsiveScale) + 20;
    //   }
    //   return 768; // Initial height
    // }

    computeRowHeight(index: number) {
        const { cachedPageDimensions, responsiveScale } = this.state;
        if (cachedPageDimensions && responsiveScale && cachedPageDimensions.get(index + 1)) {
            return cachedPageDimensions.get(index + 1)[1] * responsiveScale + 20;
        } else {
            this.recalcAllHeights();
        }
        return 768; // Initial height
    }

    handleResize() {
        this.setState({ containerHeight: window.innerHeight - this.pageHeaderHeight });
        if (this.state.pdf) {
            this.cachePageDimensions(this.state.pdf);
        } else {
            reaction(
                () => this.state.pdf,
                (p, r) => {
                    if (p) {
                        this.cachePageDimensions(this.state.pdf);
                        r.dispose();
                    }
                }
            );
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cachePageDimensions(pdf: any) {
        const store = this.props.store;

        const promises = Array.from({ length: pdf.numPages }, (v, i) => i + 1).map(pageNumber =>
            pdf.getPage(pageNumber)
        );
        let height = 0;

        // Assuming all pages may have different heights. Otherwise we can just
        // load the first page and use its height for determining all the row
        // heights.
        Promise.all(promises).then(pages => {
            if (!this.mounted) {
                return;
            }

            let pageCoordinates: number[] = [0];

            const pageDimensions = new Map();
            const containerWidth = this.pdfContainerRef.current
                ? this.pdfContainerRef.current.offsetWidth
                : this.pdfContainerWidth;
            for (const page of pages) {
                let widthIndex = 2;
                let heightIndex = 3;

                if (page.pageInfo.rotate && page.pageInfo.rotate % 180 !== 0) {
                    widthIndex = 3;
                    heightIndex = 2;
                }

                const sizeCoefficent = containerWidth / page.view[widthIndex];
                const w = page.view[widthIndex] * store!.scale * sizeCoefficent;
                const h = page.view[heightIndex] * store!.scale * sizeCoefficent;

                pageDimensions.set(page.pageIndex + 1, [w, h]);

                const paddingBetweenPages = 20;
                height += h + paddingBetweenPages;
                pageCoordinates.push(height);
            }

            this.setState(
                {
                    cachedPageDimensions: pageDimensions,
                    initialContainerHeight: height
                },
                () => {
                    setTimeout(() => {
                        let newResponsiveScale = this.computeResponsiveScale(1);
                        if (newResponsiveScale) {
                            this.setState({ responsiveScale: newResponsiveScale });
                        }
                        store.setPageCoordinates(pageCoordinates);
                        this.recomputeRowHeights();
                    }, 0);
                }
            );
        });
    }

    updatePageAfterScroll() {
        const store = this.props.store;
        if (store!.pageCoordinates && this.documentList && this.documentList.current) {
            // eslint-disable-next-line dot-notation, @typescript-eslint/dot-notation
            const scrollTop = this.documentList.current.state['scrollOffset'];
            if (store!.pageCoordinates) {
                let pagesBelow = store!.pageCoordinates.filter(p => p >= scrollTop + 5);
                if (pagesBelow && pagesBelow.length > 0) {
                    const pageNumber = store!.pageCoordinates.indexOf(pagesBelow[0]) - 1;
                    store!.setCurrentPage(pageNumber, false);
                    store!.setScrollPosition(scrollTop);
                }
            }
        }
    }

    render() {
        const store = this.props.store;

        if (!store.isReadyToRender(this.state)) {
            return <div />;
        }

        const { scale } = store!;
        const { cachedPageDimensions, containerHeight, pdf, pages, pageNumbers } = this.state;

        const containerStyle = {
            padding: 10,
            overflowY: 'auto',
            height: `calc(100vh - ${this.pageHeaderHeight}px)`
        } as React.CSSProperties;

        const renderDocument = () => {
            if (store!.isSelectedPackagePdf()) {
                store.setUrl();
                return (
                    <Document
                        // eslint-disable-next-line max-len
                        loading={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '90%',
                                    height: '100%'
                                }}
                            >
                                <Spin style={{ flex: '1' }} size="large" />
                            </div>
                        }
                        file={{ url: store.pdfServiceUrl, httpHeaders: { Authorization: 'Bearer ' + security.token } }}
                        onLoadSuccess={this.handleDocumentLoaded}
                    >
                        <div
                            className="pdf-content"
                            ref={this.pdfContainerRef}
                            style={{
                                height: `calc(100vh - ${this.pageHeaderHeight}px)`,
                                overflowY: 'auto',
                                position: 'relative'
                            }}
                        >
                            {cachedPageDimensions && (
                                <React.Fragment>
                                    <VariableSizeList
                                        height={containerHeight!}
                                        width="100%"
                                        itemCount={pdf.numPages}
                                        itemSize={this.computeRowHeightHandler}
                                        itemData={{
                                            scale,
                                            pages,
                                            pageNumbers,
                                            numPages: pdf.numPages,
                                            triggerResize: this.resizeHandler,
                                            renderBlocks: this.props.renderBlocks,
                                            store: store
                                        }}
                                        overscanCount={4}
                                        onItemsRendered={this.visiblePageUpdateHandler}
                                        style={{ willChange: 'auto', overflowY: 'scroll' }}
                                        ref={this.documentList}
                                        onScroll={this.pageScrollHandler}
                                    >
                                        {this.props.pageRenderer || PageRenderer}
                                    </VariableSizeList>
                                </React.Fragment>
                            )}
                        </div>
                    </Document>
                );
            }

            if (store!.selectedPackage!.lines && store!.selectedPackage!.lines.length) {
                const contentType = store!.selectedPackage!.contentType as ContentType;
                if (
                    [ContentType.Text, ContentType.Xls, ContentType.Xlsx, ContentType.Xltm, ContentType.Xlsm].includes(
                        contentType
                    )
                ) {
                    const blocks = store!.selectedPackage!.lines.filter(x => x.blockType === 'LINE_BLOCK');
                    return (
                        <div style={containerStyle}>
                            {blocks.map(x => (
                                <div key={x.id}>{x.text}</div>
                            ))}
                        </div>
                    );
                }

                return <div style={containerStyle}>{store!.selectedPackage!.lines[0].text}</div>;
            }

            return <div style={containerStyle}>&nbsp;</div>;
        };
        return renderDocument();
    }

    private handleDocumentLoaded = (pdf: ReactPdf) => {
        this.setState({ pdf });
        this.props.store!.setPagesInfo(pdf.numPages);
        if (this.pdfContainerRef.current) {
            this.pdfContainerWidth = this.pdfContainerRef.current.offsetWidth;
        }
        this.cachePageDimensions(pdf);
        this.props.store.setPdfIsLoaded(true);
    };
}

export default observer(PreviewContent);
