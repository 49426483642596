import * as React from 'react';
import { CodeSnippetsVisualStore } from '../stores/CodeSnippetsVisualStore';
import { observer } from 'mobx-react-lite';
import { CodeSnippet } from '../models';
import { Spin } from 'antd';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import MonacoEditor from '../../../modules/iota_applications/components/MonacoEditor';
import { editor } from 'monaco-editor';
import { SharedInstanceManager } from '../../iota_applications/services';

type Props = {
    store: CodeSnippetsVisualStore;
};

const CodeSnippetPreview: React.FC<Props> = ({ store }) => {
    const [isLoading, setIsLoading] = React.useState(true);

    if (!store.previewItem) {
        return null;
    }

    const mdParser = new MarkdownIt();
    const previewSnippet = store.previewItem as CodeSnippet;

    const bindEditor = () => {
        const manager = new SharedInstanceManager();
        manager.loadCodeIntoEditor(previewSnippet.code).then(() => setIsLoading(false));
    };

    const didMount = (e: editor.IStandaloneCodeEditor) => {
        e.updateOptions({
            glyphMargin: true,
            lightbulb: {
                enabled: true
            }
        });

        bindEditor();
    };

    return (
        <>
            <div style={{ margin: '12px 0' }}>
                <span style={{ fontWeight: 500, fontSize: 22 }}>{previewSnippet.name}</span>
                <span style={{ fontWeight: 500, fontSize: 22 }}>&nbsp;[{previewSnippet.type}]</span>
            </div>
            {!store.formDialogVisible && (
                <Spin tip="Loading..." spinning={isLoading}>
                    <MonacoEditor
                        height="400"
                        language="C#"
                        theme="vs-light"
                        editorDidMount={didMount}
                        value={previewSnippet.code}
                        options={{ readOnly: true }}
                        snippets={store.codeSnippets}
                    />
                </Spin>
            )}

            <div style={{ margin: '12px 0', color: '#9BA0AA', fontSize: 11 }}>Description</div>
            <MdEditor
                style={{ height: 'calc(100% - 560px)', borderRadius: 4 }}
                name="code-snippet-description"
                renderHTML={text => mdParser.render(text.toString())}
                value={previewSnippet.description || ''}
                config={{ view: { md: false, html: true, menu: false } }}
            />
        </>
    );
};

export default observer(CodeSnippetPreview);
