/* eslint-disable @typescript-eslint/member-ordering */
import { action, observable, runInAction, computed, reaction } from 'mobx';
import { groupBy, Dictionary } from 'lodash';
import type { RuleTypes } from '../models';
import { Connection } from '../models';
import { ErrorStore, ProjectsRootVisualStore } from '../../common/stores';
import { RulesService } from '../services';
import { message } from 'antd';
import BusinessRulesService from '../../../modules/iota_applications/services/BusinessRulesService';
import { TagsGroupModel } from '../models/TagsGroupModel';
import { ApplicationDefinitionsService } from '../../../modules/iota_applications/services';
import {
    ApplicationDefinitionBase,
    ApplicationDefinition,
    ApplicationDefinitionConditional
} from '../../../modules/iota_applications/types/ApplicationDefinition';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { CompileDummyCodeType } from '../types';
import type { TagModel } from '../models/TagModel';
import { RulesImportMappingData } from '../types';
import PipelineStepType from '../types/PipelineStepType';

export type TagName = {
    name: string;
    position?: number | null;
};
export default class RulesStore {
    @observable
    rules: RuleTypes[] = [];

    @observable
    ruleTags: TagModel[] = [];

    @observable
    connections: Connection[] = [];

    @observable
    hiddenTagGroupIds: string[] = [];

    tagsGroups: TagsGroupModel[] = [];

    appDefs: ApplicationDefinitionBase[];

    @observable
    usedTags: string[];

    @observable
    previewedTag: string | undefined;

    groupDeletionSubject: Subject<string> = new Subject<string>();

    newRulesSubject: Subject<RuleTypes> = new Subject<RuleTypes>();

    rulesEditSubject: Subject<string> = new Subject<string>();

    @computed
    get currentProject() {
        return this.rootStore.currentProject;
    }

    @computed
    get groupedByTag(): Dictionary<RuleTypes[]> {
        const res = groupBy(this.rules, function (n: RuleTypes) {
            return n.tag;
        });

        return res;
    }

    constructor(
        private service: RulesService,
        private bussinesRulesService: BusinessRulesService,
        private errorStore: ErrorStore,
        private rootStore: ProjectsRootVisualStore,
        private appDefService: ApplicationDefinitionsService
    ) {}

    @action.bound
    setPreviewedTagId(tagId: string | undefined) {
        this.previewedTag = tagId;
    }

    @action.bound
    setHiddenTagGroupIds(groupIds: string[]) {
        this.hiddenTagGroupIds = groupIds;
    }

    @action.bound
    async updateTagsVersion(projectId: string, version: string) {
        return this.service.updateTagsVersion(projectId, version);
    }

    tagHasBrokenRules(tagId: string) {
        return this.rules.filter(x => x.tagId === tagId).find(x => x.status === 'Broken');
    }

    @action
    async getRules() {
        try {
            const res = await this.service.getRules('All', this.currentProject ? this.currentProject.id : undefined);
            let tags = [] as TagModel[] | null;
            if (this.currentProject) {
                tags = await this.service.getTagsByProject(this.currentProject.id);
            }
            runInAction(() => {
                this.rules = res;
                this.ruleTags = tags || [];
            });

            await this.loadConnections();
        } catch (err) {
            this.errorStore.addError(err);
        }
    }

    async setRuleTags() {
        if (this.currentProject) {
            this.ruleTags = (await this.service.getTagsByProject(this.currentProject!.id)) || [];
        } else {
            reaction(
                () => this.currentProject,
                async (proj, r) => {
                    if (proj != null) {
                        this.ruleTags = (await this.service.getTagsByProject(proj.id)) || [];
                        r.dispose();
                    }
                }
            );
        }
    }

    @action
    async getCodeSnippets() {
        return this.bussinesRulesService.getCodeSnippets();
    }

    @action
    async getCodeSnippetGroups() {
        return this.bussinesRulesService.getCodeSnippetGroups();
    }

    @action
    async updateRulePriority(ruleId: string, priority: number) {
        return await this.service.updateRulePriority(this.currentProject!.id, ruleId, priority);
    }

    @action
    async delete(id: string) {
        return this.service.delete(this.currentProject!.id, id);
    }

    @action
    async deleteTag(tagId: string) {
        const groupId = this.tagsGroups.find(t => t.tagIds.includes(tagId))!.id!;
        return this.service.deleteTag(this.currentProject!.id, groupId, tagId);
    }

    @action
    async loadConnections() {
        const res = await this.service.getConnections(this.currentProject ? this.currentProject.id : undefined);
        runInAction(() => {
            this.connections = res;
        });
    }

    @action
    async save(rule: RuleTypes, isCopy: String = 'false') {
        if (!rule.projectId) {
            rule.projectId = this.currentProject!.id;
        }
        const groupIdForTag = this.checkIfTagExists(rule);
        rule.groupId = groupIdForTag! || rule.groupId;

        // Reset tag id in order to prevent updating existing tag
        if (isCopy === 'true') {
            rule.id = null;
            rule.tagId = null;
        }

        const resp = await this.service.saveRule(rule.toJson());
        if (resp.isErr() && resp.error.data?.type === 'NETWORK_ERROR') {
            this.errorStore.addBasicError(new Error('There was a problem handling you request, please try again'));
            return;
        }

        await this.getProjectTagsVersion();
        return resp.map(savedRule => {
            if (!rule.id) {
                this.newRulesSubject.next(savedRule);
            }

            return savedRule;
        });
    }

    async updateTag(tagId: string, tagName: string) {
        var res = this.service.updateRuleTag(this.currentProject!.id, tagId, tagName);
        await this.getProjectTagsVersion();
        return res;
    }

    @action
    async updateRuleState(ruleId: string, state: string) {
        const rule = this.rules.find(r => r.id === ruleId);
        rule!.state = state as 'Enabled' | 'Disabled';
        this.service.updateRuleState(this.currentProject!.id, ruleId, state);
    }

    @action
    async saveConnection(connection: Connection) {
        if (!connection.projectId) {
            connection.projectId = this.currentProject!.id;
        }

        const res = await this.service.updateConnection(this.currentProject!.id, connection.toJson());

        await res.asyncMap(() => this.loadConnections());
        return res;
    }

    @action
    async deleteConnection(id: string) {
        await this.service.deleteConnection(this.currentProject!.id, id);
    }

    @action
    async executeRule(rule: RuleTypes, packageIds: string[]) {
        try {
            const res = await this.service.executeRule(this.currentProject!.id, rule.toJson(), packageIds);

            res.filter(r => r.previewResult?.rule?.pipelineExecutionErrorMsg).forEach(r => {
                this.errorStore.addBasicError(new Error(r.previewResult.rule.pipelineExecutionErrorMsg));
            });

            return res;
        } catch (err) {
            this.errorStore.addBasicError(err);
            return null;
        }
    }

    async importRules(data: FormData) {
        return this.service.importRules(data, this.currentProject!.id);
    }

    async commitImportedRules(
        ruleIdGroupDict: {},
        tagActionDict: {},
        importConfigData: RulesImportMappingData[],
        commitAction: string
    ) {
        var res = this.service.commitImportedRules(ruleIdGroupDict, tagActionDict, importConfigData, commitAction);

        await this.getProjectTagsVersion();
        return res;
    }

    async getEditorModels() {
        return this.service.getEditorModels(this.currentProject!.id);
    }

    async exportRules(ids: string[]) {
        return this.service.exportRules(this.currentProject!.id, ids);
    }

    async exportAllProjectRules() {
        return this.service.exportAllProjectRules(this.currentProject!.id);
    }

    validateConnection(connectionString: string, connectionType: string) {
        return this.service.validateConnection(this.currentProject!.id, connectionString, connectionType);
    }

    async exportConnection(id: string) {
        return this.service.exportConnection(id, this.currentProject!.id);
    }

    async executeBusinessRuleCode(code: string, compileDummyCodeType?: CompileDummyCodeType) {
        const resp = await this.bussinesRulesService.executeBusinessRuleCode(code, compileDummyCodeType);
        resp.map(() => message.success('Code has been successfully compiled')).mapErr(err =>
            message.error(err.data?.title)
        );

        return resp;
    }

    @action
    async getTagsGroups(projectId?: string) {
        try {
            const groups = await this.service.getTagsGroupsByProject(projectId || this.currentProject!.id);
            return (this.tagsGroups = groups!);
        } catch (err) {
            this.errorStore.addError(err);
            return [];
        }
    }

    @action
    async createGroup(projectId: string, name: string, description: string, id?: string) {
        var res = await this.service.createGroup(projectId, name, description, id);
        await this.getProjectTagsVersion();

        return res;
    }

    @action
    async deleteGroup(id: string) {
        var res = this.service.deleteGroup(this.currentProject!.id, id);
        await this.getProjectTagsVersion();

        return res;
    }

    @action
    async updateGroupRowPosition(dragIndex: number, dropIndex: number) {
        const lastModified = this.tagsGroups.find(g => g.position === dragIndex)!.updateDate;
        const newUpdateDate = await this.service.updateGroupRowPosition(
            this.currentProject!.id,
            dragIndex,
            dropIndex,
            lastModified
        );
        this.tagsGroups.forEach(t => (t.updateDate = newUpdateDate));
    }

    @action
    async updateTagRowPosition(destGroupId: string, dragIndex: number, dropIndex: number) {
        try {
            const group = this.tagsGroups.find(g => g.id === destGroupId)!;
            const lastModified = group.updateDate;
            this.updateTagsPositionInUIModel(destGroupId, dragIndex, dropIndex);
            group.updateDate = await this.service.updateTagRowPosition(
                this.currentProject!.id,
                destGroupId,
                dragIndex,
                dropIndex,
                lastModified
            );
        } catch (ex) {
            console.error(ex);
        }
    }

    @action
    async moveTagToAnotherGroup(draggedRecord: TagModel, destGroupId: string) {
        try {
            const positionsInNewGroup = this.ruleTags.filter(x => x.groupId === destGroupId).map(v => v.position);
            const draggedItemGroupId = draggedRecord.groupId;
            const tag = this.ruleTags.find(x => x.id === draggedRecord.id)!;
            tag.groupId = destGroupId;
            tag.position = positionsInNewGroup.length ? Math.max(...positionsInNewGroup) + 1 : 0;
            if (draggedItemGroupId) {
                const draggedItemsGroupTags = this.ruleTags.filter(r => r.groupId === draggedItemGroupId);
                draggedItemsGroupTags.forEach(d => {
                    if (d.position! > draggedRecord.position!) {
                        d.position = d.position! - 1;
                    }
                });
            }

            const originGroup = this.tagsGroups.find(t => t.id === draggedItemGroupId)!;
            const destGroup = this.tagsGroups.find(t => t.id === destGroupId)!;
            const originGroupLastModified = (originGroup && originGroup!.updateDate) || null;
            const destGroupLastModified = destGroup!.updateDate;

            const newUpdateDate = await this.service.moveTagToAnotherGroup(
                this.currentProject!.id,
                destGroupId,
                draggedRecord.id!,
                originGroupLastModified,
                destGroupLastModified
            );
            if (originGroup) {
                originGroup.updateDate = newUpdateDate;
            }
            destGroup.updateDate = newUpdateDate;
        } catch (ex) {
            console.error(ex);
        }
    }

    @action.bound
    checkIfTagExists(rule: RuleTypes) {
        if (rule.tagId != null) {
            return this.ruleTags.find(r => r.id === rule.tagId)!.groupId;
        }
        return;
    }

    async executeBusinessRuleQuery(connectionId: string, query: string) {
        const resp = await this.bussinesRulesService.executeBusinessRuleQuery(connectionId, query);
        resp.map(() => message.success('Query has been successfully executed')).mapErr(() =>
            message.error('Error occured during query execution')
        );
        return resp;
    }

    async validateRegexPatternValue(
        value: string,
        regex: string,
        stepType: PipelineStepType,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        parameters: Map<string, any>,
        ruleId: string
    ) {
        return this.service.validateRegexPatternValue(
            this.currentProject!.id,
            value,
            regex,
            stepType,
            parameters,
            ruleId
        );
    }

    async getAppDefinitions() {
        if (this.currentProject) {
            this.appDefs = await this.appDefService.getApplicationDefinitionsForProject(this.currentProject!.id);

            const allBindings = this.appDefs.map(a => {
                if (a instanceof ApplicationDefinition) {
                    return a.bindings;
                }

                if (a instanceof ApplicationDefinitionConditional) {
                    return a.allInputGroupBindings;
                }

                return [];
            });

            const bindings = _.flatten(allBindings).filter(x => x && x.type === 'tag');

            this.usedTags = bindings.map(b => b.value) as string[];
        }
    }

    async loadAppDefinitions() {
        return this.appDefService.getApplicationDefinitionsForProject(this.currentProject!.id);
    }

    getAuthToken() {
        return this.service.getAuthToken();
    }

    @action.bound
    async getProjectTagsVersion() {
        if (!this.currentProject) {
            return;
        }

        var resp = await this.service.getProjectTagsVersion(this.currentProject.id);
        this.currentProject.setTagsVersion(resp);
    }

    @action.bound
    async getPinnedRules() {
        if (!this.currentProject) {
            return;
        }

        var resp = await this.service.getPinnedRules(this.currentProject.id);
        return resp;
    }

    @action.bound
    async pinRule(ruleId: string) {
        if (!this.currentProject) {
            return;
        }

        var resp = await this.service.pinRule(this.currentProject.id, ruleId);
        return resp;
    }

    @action.bound
    async unpinRule(ruleId: string) {
        if (!this.currentProject) {
            return;
        }

        var resp = await this.service.unpinRule(this.currentProject.id, ruleId);
        return resp;
    }

    @action
    private updateTagsPositionInUIModel(destGroupId: string, dragIndex: number, dropIndex: number) {
        const ruleTags = this.ruleTags.filter(r => r.groupId === destGroupId);
        ruleTags.forEach(r => {
            if (r.position === dragIndex) {
                r.position = dropIndex;
            } else {
                if (dropIndex < dragIndex && r.position! <= dragIndex && r.position! >= dropIndex) {
                    r.position = r.position! + 1;
                } else if (dropIndex > dragIndex && r.position! >= dragIndex && r.position! <= dropIndex) {
                    r.position = r.position! - 1;
                }
            }
        });
    }
}
