import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Spin, Button } from 'antd';
import CodeBusinessRuleVisualStore from '../stores/CodeBusinessRuleVisualStore';
import MonacoEditor from './MonacoEditor';
import { editor } from 'monaco-editor';
import { BlockOutlined, BorderOutlined, CaretRightOutlined } from '@ant-design/icons';

type Props = {
    store: CodeBusinessRuleVisualStore;
    dataId?: string;
};

export const CodeBusinessRule: React.FC<Props> = observer(({ store, dataId }) => {
    function didMount(e: editor.IStandaloneCodeEditor) {
        e.updateOptions({
            glyphMargin: true,
            lightbulb: {
                enabled: true
            }
        });

        store.bindEditor();
    }

    const [isFullScreen, setIsFullScreen] = React.useState(false);

    React.useEffect(() => {
        return store.dispose;
    }, [store]);

    const footer = () => {
        return (
            <>
                <Button
                    style={{ float: 'left', backgroundColor: '#98c8a0' }}
                    type="default"
                    size="large"
                    icon={<CaretRightOutlined />}
                    onClick={store.executeCode}
                >
                    Compile
                </Button>
                <Button
                    data-id={dataId ? `${dataId}-modal-cancel` : 'code-editor-modal-cancel'}
                    size="large"
                    className="light"
                    onClick={store.cancel}
                >
                    Cancel
                </Button>
                <Button
                    data-id={dataId ? `${dataId}-modal-ok` : 'code-editor-modal-ok'}
                    size="large"
                    type="primary"
                    onClick={store.save}
                >
                    OK
                </Button>
            </>
        );
    };

    const modalTitle = () => {
        const iconStyle: React.CSSProperties = { verticalAlign: 'baseline' };
        const icon = isFullScreen ? <BlockOutlined style={iconStyle} /> : <BorderOutlined style={iconStyle} />;
        const tooltip = isFullScreen ? 'Minimize' : 'Maximize';

        return (
            <div style={{ textAlign: 'right' }}>
                <Button
                    style={{ height: 16, padding: '7px 0' }}
                    type="link"
                    onClick={() => setIsFullScreen(!isFullScreen)}
                    title={tooltip}
                >
                    {icon}
                </Button>
            </div>
        );
    };

    return (
        <Modal
            title={modalTitle()}
            width={isFullScreen ? '100%' : '70%'}
            visible={store.isOpened}
            maskClosable
            destroyOnClose={false}
            closable={false}
            afterClose={store.cancel}
            footer={footer()}
            onCancel={store.cancel}
            className="code-editor-modal"
            centered
        >
            <Spin tip="Loading..." spinning={store.isLoading}>
                <MonacoEditor
                    width="100%"
                    height={isFullScreen ? 'calc(100vh - 220px)' : '400px'}
                    language="C#"
                    theme="vs-light"
                    editorDidMount={didMount}
                    value={store.code}
                    onChange={store.updateCode}
                    dataId={dataId}
                    snippets={store.codeSnippets}
                />
            </Spin>
        </Modal>
    );
});

// const WrappedAddApplicationDefinition = Form.create()(AddApplicationDefinition);
// export default WrappedAddApplicationDefinition;
export default CodeBusinessRule;
