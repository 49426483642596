import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tree, Empty } from 'antd';
import type { TreeProps } from 'antd/es/tree';
import { ReferenceDataVisualStore } from '../../stores';
import TableGroupTitle from './TableGroupTitle';
import TableTitle from './TableTitle';
import './ReferenceDataTree.less';

const { TreeNode } = Tree;

interface Props {
    store: ReferenceDataVisualStore;
}

const ReferenceDataTree: React.FC<Props> = ({ store }) => {
    const [sortOrder, setSortOrder] = React.useState('asc');

    const toggleSort = () => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

    const onDrop: TreeProps['onDrop'] = info => {
        const nodeKey = info.node.key.toString().split('key-')[1];

        const dragNodeKey = info.dragNode.key.toString().split('key-')[1];

        const tableName = store.tableNames.find(name => name === dragNodeKey);

        if (!tableName) {
            return;
        }

        const groupToDrop =
            store.tableGroups.find(g => g.id === nodeKey) ||
            store.tableGroups.find(g => g.tableNames.some(name => name === nodeKey));

        if (!groupToDrop) {
            const groupToRemove = store.tableGroups.find(g => g.tableNames.includes(tableName));

            if (groupToRemove) {
                groupToRemove.removeTable(tableName);
            }

            return;
        }

        if (groupToDrop.tableNames.includes(tableName)) {
            return;
        }

        const prevGroup = store.tableGroups.find(
            g => g.id !== groupToDrop.id && g.tableNames.some(name => name === tableName)
        );

        if (prevGroup) {
            prevGroup.removeTable(tableName);
        }

        groupToDrop.addTable(tableName);
    };

    return (
        <>
            <i className="alpha-icon sort-arrows-icon ref-data-sort-icon" onClick={toggleSort} />

            {!store.tableGroups.length && !store.tableNames.length ? (
                <Empty className="empty-tree" />
            ) : (
                <Tree
                    className="alpha-portal-tree no-indent reference-data-tree"
                    draggable
                    onDrop={onDrop}
                    motion={false}
                >
                    {store.tableGroups
                        .slice()
                        .sort((a, b) =>
                            sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
                        )
                        .map(tableGroup => (
                            <TreeNode
                                key={`group-key-${tableGroup.id}`}
                                title={<TableGroupTitle store={store} tableGroup={tableGroup} />}
                                switcherIcon={<i className="alpha-icon lg expand-icon" />}
                            >
                                <TreeNode className="table-group-empty-node" key={`empty-group-key-${tableGroup.id}`} />

                                {tableGroup.tableNames
                                    .filter(name => store.tableNames.includes(name))
                                    .slice()
                                    .sort((a, b) => (sortOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a)))
                                    .map(tableName => (
                                        <TreeNode
                                            key={`group-${tableGroup.id}-table-key-${tableName}`}
                                            className="table-group-child-node"
                                            title={<TableTitle store={store} tableName={tableName} />}
                                        />
                                    ))}
                            </TreeNode>
                        ))}

                    {store.tableNames
                        .filter(name => !store.tableGroups.some(g => g.tableNames.includes(name)))
                        .slice()
                        .sort((a, b) => (sortOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a)))
                        .map(tableName => (
                            <TreeNode
                                key={`table-key-${tableName}`}
                                title={<TableTitle store={store} tableName={tableName} />}
                            />
                        ))}
                </Tree>
            )}
        </>
    );
};

export default observer(ReferenceDataTree);
