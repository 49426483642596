import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormTypesStore, FormTypesVisualStore } from '../stores';
import { FormBlock } from '../types';

type Props = {
    store: FormTypesStore;
    visualStore: FormTypesVisualStore;
};

const FormRegionBlockCreateDialog: React.FC<Props> = ({ store, visualStore }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (store.newBlockInitialValues) {
            form.setFieldsValue(store.newBlockInitialValues);
            store.newBlockInitialValues = undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visualStore.isFormBlockCreateDialogVisible]);

    const handleCancel = () => {
        visualStore.setIsFormBlockCreateDialogVisible(false);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(async values => {
                const createdSuccessfully = await store.createFormRegionBlock(
                    values.name,
                    values.blockPage,
                    values.bBox
                );

                if (createdSuccessfully) {
                    removePreviousBlock();
                    handleCancel();
                }
            })
            .catch(() => {
                // do nothing
            });
    };

    const removePreviousBlock = async () => {
        const values = form.getFieldsValue(true);
        if (
            values.previousBlockId &&
            values.previousBlockIndex != null &&
            values.previousBlockPage != null &&
            store.selectedFormTemplateRegion
        ) {
            const deletedSuccessfully = await store.deleteBlockFromRegion(
                values.previousBlockId,
                values.previousBlockPage,
                values.previousBlockIndex
            );

            if (deletedSuccessfully) {
                setTimeout(() => {
                    store.setIsLoadingBlocks(true);
                    store.getFormPartsForProject().then(() => {
                        store.getFormTypesForProject();
                        store.setIsLoadingBlocks(false);
                    });
                }, 0);
            }
        }
    };

    const validateName = (name: string) => {
        if (!store.selectedFormTemplateRegion) {
            return Promise.reject('Select form region, please');
        }

        const currentRegion = store.currentRegionBlocks[store.selectedFormTemplateRegion];
        let regionBlocks: FormBlock[] = [];

        for (let page in currentRegion) {
            if (currentRegion[page]) {
                regionBlocks = regionBlocks.concat(currentRegion[page]);
            }
        }

        if (regionBlocks.some(b => store.getBlockTitleById(b.blockId) === name)) {
            return Promise.reject('Form region block with such name already exists');
        }

        return Promise.resolve();
    };

    return (
        <Modal
            wrapProps={{ 'data-id': 'New form region block' }}
            className="alpha-portal-dialog"
            title="New form region block"
            visible={visualStore.isFormBlockCreateDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button
                    data-id="form-region-blocks-add-dialog-cancel"
                    className="light"
                    key="back"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="form-region-blocks-add-dialog-submit"
                    key="submit"
                    size="large"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Add block
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                        { required: true, message: 'Provide name for the form region block, please' },
                        { validator: (__, name) => validateName(name) }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="blockPage" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="bBox" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="previousBlockId" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="previousBlockIndex" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="previousBlockPage" hidden>
                    <Input type="hidden" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(FormRegionBlockCreateDialog);
