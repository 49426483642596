import * as React from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { ViewerStores } from '../stores';
import { STORE_DOCUMENT_VIEW } from '../constants';
import DocumentViewer from '../components/DocumentViewer';

export interface DocumenViewerPageProps {
    match: {
        params: {
            path: string;
        };
    };
}

type Props = ViewerStores & DocumenViewerPageProps;

const DocumentViewerPage: React.FC<Props> = ({ documentViewUI: store, match }) => {
    if (!store) {
        return null;
    }

    return <DocumentViewer store={store} path={match.params.path} />;
};

export default inject(STORE_DOCUMENT_VIEW)(observer(DocumentViewerPage));
