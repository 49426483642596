import * as React from 'react';
import { Avatar, Menu, Dropdown } from 'antd';
import { observer } from 'mobx-react-lite';
import WrappedUserProfileEditForm from '../components/UserProfileEdit';
import { UserProfileStore } from '../stores';
import WrappedResetPasswordForm from './ResetPasswordDialog';

type Props = {
    store: UserProfileStore;
};
const UserProfile: React.FC<Props> = ({ store }) => {
    const [imageUrl, setimageUrl] = React.useState('');

    React.useEffect(() => {
        let hasCancelled = false;
        const avatarIconFileId = store.userProfile && store.userProfile.avatarIconFileId;
        if (avatarIconFileId) {
            store.getAvatarIcon(avatarIconFileId).then(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (val: any) =>
                    hasCancelled
                        ? () => {
                              return;
                          }
                        : setimageUrl(URL.createObjectURL(val) || ''),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (error: any) =>
                    hasCancelled
                        ? () => {
                              return;
                          }
                        : () => console.log(error)
            );
        }

        return () => {
            hasCancelled = true;
        };
    }, [store.userProfile, store]);

    const menu = () => {
        const isDomainUser = store.userProfile && store.userProfile.isDomainUser;
        return (
            <Menu>
                <Menu.Item key="1" onClick={() => store.setEditUserProfileVisible(true)}>
                    My Profile
                </Menu.Item>
                <Menu.Item
                    key="2"
                    onClick={() => store.setResetPasswordDialogVisible(true)}
                    style={isDomainUser ? { display: 'none' } : {}}
                >
                    Change password
                </Menu.Item>
                <Menu.Item key="3" onClick={store.logout}>
                    Logout
                </Menu.Item>
            </Menu>
        );
    };

    const getUserInitial = () => {
        return (
            (store.userProfile && store.userProfile.firstName && store.userProfile.firstName.charAt(0).toUpperCase()) ||
            (store.userProfile?.userName && store.userProfile?.userName.charAt(0).toUpperCase())
        );
    };

    return (
        <>
            <WrappedUserProfileEditForm store={store} />
            <WrappedResetPasswordForm store={store} />
            <div className="avatar-icon">
                <Dropdown overlay={() => menu()} trigger={['click']}>
                    {imageUrl ? <Avatar size={32} src={imageUrl} /> : <Avatar size={32}>{getUserInitial()}</Avatar>}
                </Dropdown>
            </div>
        </>
    );
};

export default observer(UserProfile);
