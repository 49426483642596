import * as React from 'react';
import { observer } from 'mobx-react-lite';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineVisualStore from '../stores/PipelineVisualStore';
import MonacoEditor from '../../iota_applications/components/MonacoEditor';
import { Button, Modal, Spin, Tooltip, Form } from 'antd';
import { editor } from 'monaco-editor';
import { SharedInstanceManager } from '../../iota_applications/services';
import { CompileDummyCodeType, Parameter } from '../types';
import { FormInstance } from 'antd/lib/form';
import { BlockOutlined, BorderOutlined, CaretRightOutlined } from '@ant-design/icons';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
    dataId: string;
};
export const ParametersCodeBlockEditor: <
    T extends PipelineVisualStore<TPipelineStepType>,
    TPipelineStepType extends string
>(
    props: Props<T, TPipelineStepType>
) => React.ReactElement<Props<T, TPipelineStepType>> = ({ param, form, toolTip, step, store, stepKey }) => {
    const [showModal, setShowModal] = React.useState(false);
    const [isLoading, setisLoading] = React.useState(true);
    const [value, setValue] = React.useState(form.getFieldValue(param.name) || step.getParameterValues(param.name));
    const [isFullScreen, setIsFullScreen] = React.useState(false);

    React.useEffect(() => {
        store.loadCodeSnippets();
        store.loadCodeSnippetGroups();
    }, [store]);

    const bindEditor = () => {
        const manager = new SharedInstanceManager();
        manager.loadCodeIntoEditor(form.getFieldValue(param.name)).then(() => setisLoading(false));
    };

    const didMount = (e: editor.IStandaloneCodeEditor) => {
        e.updateOptions({
            glyphMargin: true,
            lightbulb: {
                enabled: true
            },
            automaticLayout: true
        });

        bindEditor();
    };

    const save = () => {
        form.setFieldsValue({ code: value });
        setShowModal(false);
    };

    const cancel = () => {
        setValue(form.getFieldValue(param.name));
        setShowModal(false);
    };

    const handleSubmit = async () => {
        store.validateForm(form, step, stepKey, false);
    };
    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }
    const handleCodeRunClick = async () => {
        setisLoading(true);
        await store.executeBusinessRuleCode(value, CompileDummyCodeType.CodeStep);
        setisLoading(false);
    };

    const footer = () => {
        return (
            <>
                <Button
                    style={{ float: 'left', backgroundColor: '#98c8a0' }}
                    type="default"
                    size="large"
                    icon={<CaretRightOutlined />}
                    onClick={handleCodeRunClick}
                    data-id="code-block-dialog-compile"
                >
                    Compile
                </Button>
                <Button data-id={'code-block-dialog-cancel'} className="light" size="large" onClick={cancel}>
                    Cancel
                </Button>
                <Button data-id={'code-block-dialog-save'} size="large" type="primary" onClick={save}>
                    Save
                </Button>
            </>
        );
    };

    let control = (
        <Tooltip title={value} overlayClassName="code-tooltip-overlay">
            <Button
                type={value && value.trim() !== '' ? 'primary' : 'ghost'}
                style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}
                onClick={() => setShowModal(true)}
            >
                {value && value.trim() !== '' ? 'Edit' : 'Add'}
            </Button>
        </Tooltip>
    );

    const modalTitle = () => {
        const iconStyle: React.CSSProperties = { verticalAlign: 'baseline' };
        const icon = isFullScreen ? <BlockOutlined style={iconStyle} /> : <BorderOutlined style={iconStyle} />;
        const tooltip = isFullScreen ? 'Minimize' : 'Maximize';

        return (
            <div style={{ textAlign: 'right' }}>
                <Button
                    style={{ height: 16, padding: '7px 0' }}
                    type="link"
                    onClick={() => setIsFullScreen(!isFullScreen)}
                    title={tooltip}
                >
                    {icon}
                </Button>
            </div>
        );
    };

    if (showModal === true) {
        control = (
            <Modal
                title={modalTitle()}
                width={isFullScreen ? '100%' : '70%'}
                visible={showModal}
                maskClosable
                destroyOnClose={false}
                onCancel={cancel}
                onOk={save}
                closable={false}
                className="code-editor-modal"
                footer={footer()}
                centered
            >
                <Spin tip="Loading..." spinning={isLoading}>
                    <div data-id="code-block-dialog-content">
                        <MonacoEditor
                            width="100%"
                            height={isFullScreen ? 'calc(100vh - 220px)' : '400px'}
                            language="C#"
                            theme="vs-light"
                            value={value}
                            onChange={code => {
                                setValue(code);
                                store.addParameters({ code }, step, false);
                            }}
                            editorDidMount={didMount}
                            snippets={store.codeSnippets}
                        />
                    </div>
                </Spin>
            </Modal>
        );
    }

    return (
        <div>
            <Form.Item
                label={toolTip}
                name={param.name}
                rules={[
                    {
                        required: !step.isDisabled && param.required,
                        message: 'Please input parameter value'
                    }
                ]}
                initialValue={param.defaultValue}
            >
                {control}
            </Form.Item>
        </div>
    );
};

export default observer(ParametersCodeBlockEditor);
