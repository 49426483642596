import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FieldBindingsStore } from '../stores';

type Props = {
    store: FieldBindingsStore;
};

const FieldBindingInputCreateDialog: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (!store.isCreateInputDialogVisible) {
            store.setCurrentSectionId(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.isCreateInputDialogVisible]);

    const handleCancel = () => {
        store.setIsCreateInputDialogVisible(false);
        form.resetFields();
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(async values => {
                var createdSuccessfully = await store.createField(values.name);
                if (createdSuccessfully) {
                    handleCancel();
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <Modal
            className="alpha-portal-dialog"
            title="New field binding input"
            visible={store!.isCreateInputDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            destroyOnClose
            width={600}
            centered
            footer={[
                <Button
                    data-id="field-binding-add-input-dialog-cancel"
                    className="light"
                    key="back"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="field-binding-add-input-dialog-submit"
                    key="submit"
                    size="large"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Add input
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Provide name for the project field group, please' }]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(FieldBindingInputCreateDialog);
