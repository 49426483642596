/* eslint-disable max-len */
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Collapse, Form, Layout, Tooltip, Upload, message } from 'antd';
import { FormField } from '../stores/ProjectApplicationDefinitionEditVisualStore';
import { IotaAppStores } from '../stores';
import { BindingType } from '../types';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import LayoutHeader from '../../../components/LayoutHeader';
import { Utils } from '../../common/services/Utils';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { ApplicationInputBindingsRenderer } from '.';
import { Dictionary } from 'lodash';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import BindingsImportModal from './BindingsImportModal/BindingsImportModal';
import { Prompt } from 'react-router';

const { Panel } = Collapse;

type Props = IotaAppStores;

export const ApplicationDefinitionEditInputs: React.FC<Props> = ({ projectApplicationDefinitionEditUI: store }) => {
    const formItemLayout = {
        labelCol: {
            xs: { span: 5 },
            sm: { span: 7 }
        },
        wrapperCol: {
            xs: { span: 16 },
            sm: { span: 16 }
        }
    };

    const [form] = Form.useForm();

    React.useEffect(() => {
        store?.setIsFormChanged(false);
        return () => {
            store?.setIsFormChanged(false);
        };
    }, [store]);

    React.useEffect(() => {
        function beforeUnload(e: BeforeUnloadEvent) {
            if (!store?.isFormChanged) {
                return;
            }
            e.preventDefault();
        }

        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [store?.isFormChanged]);

    if (!store) {
        return null;
    }

    const handleSubmit = (values: {}) => {
        store.save(values);
    };

    const renderInputs = (section: string | undefined) => {
        return (
            <div>
                {store.inputs
                    .filter(i => i.section === section)
                    .map((i, index) => {
                        let bindings = store.localBindings[i.id] || [];
                        if (!bindings.length) {
                            bindings = [{ inputId: i.id, type: BindingType.constant, value: null }];
                        }
                        return (
                            <ApplicationInputBindingsRenderer
                                key={`${i.id}-${index}`}
                                store={store}
                                form={form}
                                input={i}
                                bindings={bindings}
                                index={index}
                                handleInputValuesChange={handleInputValuesChange}
                                previewInput={(inputId, bindingIndex) => store.previewInput(inputId, bindingIndex)}
                            />
                        );
                    })}
            </div>
        );
    };

    const getInitialValues = () => {
        const obj = Object.create(null);

        store.inputs.forEach(x => {
            const bindings = store.localBindings[x.id] || [];
            if (bindings.length) {
                obj[x.id] = bindings.map(b => {
                    return { value: getValue(b.value as string, b.type), type: b.type };
                });
            } else {
                obj[x.id] = [{ value: null, type: BindingType.constant }];
            }
            return obj;
        });

        return obj;
    };

    const getValue = (value: string, type: string) => {
        if (type === BindingType.tag) {
            return store.getTagById(value);
        }
        return value;
    };

    const handleInputValuesChange = (inputId: string) => {
        const fields = form.getFieldValue(inputId);
        store.updateLocalBindings(inputId, fields);
    };

    const onValuesChange = (input: Dictionary<FormField[]>) => {
        Object.entries(input).forEach(([inputId]) => handleInputValuesChange(inputId));
        store.setIsFormChanged(true);
    };

    const onFileChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        if (status === 'done') {
            const uploadResponse = info.file.response;
            store.handleImportResponse(uploadResponse);
        } else if (status === 'error') {
            console.log(info.file.response);
            if (info.file?.response?.status === 409) {
                message.warning(info.file.response.title);
                return;
            }
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const handleBeforeUpload = async (file: RcFile) => {
        if (!file.name.endsWith('.iota_bindings')) {
            message.error('Invalid file format. Please upload a .iota_bindings file');
            return Promise.reject();
        }
        store.setCurrentImportFile(file);
        return store.setHeaders();
    };

    const sections = [...new Set(store.inputs.map(i => i.section))];
    const defActiveKey =
        sections.length && sections.some(s => !!s) ? (sections.filter(s => !!s) as string[]) : ['general-inputs'];

    const getLastUpdateTimeLabel = () => {
        const dataIsEmpty = !store.currentAppDef?.lastUpdatedBy && !store.currentAppDef?.lastUpdatedTime;
        if (!store.currentProject || !store.currentAppDef || store.isLoadingUsers || dataIsEmpty) {
            // store.isLoadingUsers
            return null;
        }

        const updatedBy = store.getUserNameById(store.currentAppDef.lastUpdatedBy ?? '');
        const updatedAt = Utils.formatDateStringShort(store.currentAppDef.lastUpdatedTime);

        return (
            <>
                Updated at: {updatedAt} by {updatedBy}
            </>
        );
    };

    const handleCancel = () => {
        store.setIsFormChanged(false);
        store.navigateToList();
    };

    return !store.isLoading ? (
        <Layout style={{ height: '100%' }}>
            <Prompt when={store.isFormChanged} message="You have unsaved changes. Are you sure you want to leave?" />
            <BindingsImportModal store={store} />
            <LayoutHeader
                title={`Edit inputs for ${store.currentAppDef!.name}`}
                buttons={[
                    <span key="updated-data" style={{ marginRight: 12, color: '#9BA0AA' }}>
                        {getLastUpdateTimeLabel()}
                    </span>,
                    <Tooltip key="export-button-container" title="Export">
                        <span
                            data-id="button-Export"
                            className={'headerButton rule-export'}
                            onClick={() => store.exportAppBindings()}
                        >
                            <i className="alpha-icon xs arrow-up" style={{ verticalAlign: 'text-top' }} />
                        </span>
                    </Tooltip>,
                    <Tooltip key="file-uploader" title="Import">
                        <Upload
                            showUploadList={false}
                            className="headerButton"
                            name="file"
                            onChange={onFileChange}
                            action={store.importBindingsActionUrl}
                            headers={store.fileImportActionHeaders}
                            beforeUpload={file => handleBeforeUpload(file)}
                        >
                            <span data-id="button-Import" key="import-button-container">
                                <i className="alpha-icon xs arrow-down" style={{ verticalAlign: 'text-top' }} />
                            </span>
                        </Upload>
                    </Tooltip>,
                    <Button
                        key="iota-application-preview"
                        data-id="iota-application-preview"
                        onClick={store.previewApplication}
                        htmlType="button"
                        className="light"
                        size="large"
                    >
                        Preview Application
                    </Button>,
                    <Button
                        key="iota-inputs-cancel"
                        data-id="iota-inputs-cancel"
                        onClick={handleCancel}
                        htmlType="button"
                        className="light"
                        size="large"
                    >
                        Cancel
                    </Button>,
                    <HasPermission
                        key="iota-inputs-save"
                        entityId={store.currentProject?.id}
                        permissionClaim={AppPermissions.CanEditIotaApplications}
                        yes={() => (
                            <Button
                                data-id="iota-inputs-save"
                                form="iota-edit-inputs-form"
                                type="primary"
                                htmlType="submit"
                                size="large"
                            >
                                Save changes
                            </Button>
                        )}
                        no={() => (
                            <Button data-id="iota-inputs-save" type="primary" htmlType="submit" disabled size="large">
                                Save changes
                            </Button>
                        )}
                    />
                ]}
                helpMessage="Manage how input values are extracted from documents here."
                subtitle={Utils.getSubtitle(store.currentProject)}
            />
            <Layout.Content style={{ overflowY: 'auto' }}>
                <Form
                    id="iota-edit-inputs-form"
                    className="form-asterisk-right"
                    onFinish={handleSubmit}
                    initialValues={getInitialValues()}
                    onValuesChange={onValuesChange}
                    form={form}
                    {...formItemLayout}
                >
                    <Collapse
                        className="alpha-collapse-container"
                        bordered={false}
                        expandIconPosition="left"
                        defaultActiveKey={defActiveKey}
                        style={{ marginBottom: 20 }}
                    >
                        {sections.map(s =>
                            s ? (
                                <Panel className="alpha-collapse-panel" key={s} header={s}>
                                    {renderInputs(s)}
                                </Panel>
                            ) : (
                                <Panel className="alpha-collapse-panel" key="general-inputs" header="Inputs">
                                    {renderInputs(s)}
                                </Panel>
                            )
                        )}
                    </Collapse>
                </Form>
            </Layout.Content>
        </Layout>
    ) : (
        <LoadingIndicator />
    );
};

export default observer(ApplicationDefinitionEditInputs);
