import { Pagination } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import TestProjectRunTopicPreviewStore from '../../stores/TestProjectRunTopicPreviewStore';
import { Document, Page } from 'react-pdf';
import { SecurityService } from '../../../common/services';
import './TestProjectRunTopicPreview.less';

type Props = {
    store: TestProjectRunTopicPreviewStore;
};

const TestProjectBaselinesPage: React.FC<Props> = ({ store }) => {
    const [pages, setPages] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(1);

    React.useEffect(() => {
        document.body.classList.add('rule-preview-page');
        store.readPreviewDataFromStorage();
        window.addEventListener('storage', () => store.readPreviewDataFromStorage());

        return () => {
            window.removeEventListener('storage', () => store.readPreviewDataFromStorage());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (store.currentBlock) {
            setPageNumber(store.currentBlock.p + 1);
        }
    }, [store.currentBlock]);

    const renderCurrentBlock = () => {
        if (!store.currentBlock || store.currentBlock.p !== pageNumber - 1) {
            return null;
        }

        const block = store.currentBlock;
        const height = (block.h / block.ph) * 100;
        const width = (block.w / block.pw) * 100;
        const bottom = ((block.y - block.h) / block.ph) * 100;
        const left = (block.x / block.pw) * 100;

        return (
            <div
                style={{
                    position: 'absolute',
                    height: `${height}%`,
                    width: `${width}%`,
                    bottom: `${bottom}%`,
                    left: `${left}%`,
                    background: '#33f14a52',
                    zIndex: 1000
                }}
            />
        );
    };

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setPages(numPages);
    }

    return (
        <div className="test-run-pdf-viewer-wrapper">
            <div className="test-run-pdf-viewer-content-wrapper">
                {pages > 1 ? (
                    <Pagination
                        defaultCurrent={1}
                        current={pageNumber}
                        showQuickJumper
                        showLessItems
                        pageSize={1}
                        total={pages}
                        onChange={page => setPageNumber(page)}
                    />
                ) : null}
                <Document
                    file={{
                        url: `${process.env.REACT_APP_MANAGE_URL}document/${encodeURIComponent(store.packageId)}${'?access_token=' + encodeURIComponent(SecurityService.token)}`
                    }}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <div style={{ position: 'relative' }}>
                        <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotations={false} />
                        {renderCurrentBlock()}
                    </div>
                </Document>
            </div>
        </div>
    );
};

export default observer(TestProjectBaselinesPage);
