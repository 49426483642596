import { PackageLine, Package } from '../models';
import { BLOCK_TYPE } from './BlockType';

export type AnchorResult = {
    id: string;
    keyword: string;
    aliases: string[];
};

export type PackageResult = {
    id: string;
    fileName: string;
    filePath: string;
    projectId: string;
    contentType: string;
    state: PackageStateResult;
    operationState: string[];
    userTags: string[];
};

export type NamedEntity = {
    start: number;
    end: number;
    text: string;
};

export type SearchResult = {
    searchPackageLines: {
        result: FieldResult[];
        pageInfo: {
            total: number;
        };
    };
};

export type ProjectByIdResult = {
    projectById: ProjectResult | null;
};

export type AllProjectResult = {
    allProjects: ProjectResult[];
};

export type SearchPackagesResult = {
    searchPackages: {
        result: PackageResult[];
        pageInfo: {
            total: number;
        };
    };
};

export type ProjectResult = {
    id: string;
    name: string;
    type: string;
    keywords: string[];
    labels: AnchorResult[];
    namedEntities: {
        label: string;
        text: string;
        entities: NamedEntity[];
    }[];
    packages: PackageResult[] | null;
};

export type FieldResult = {
    rowId: number;
    page: number;
    x: number;
    y: number;
    w: number;
    h: number;
    pageWidth: number;
    pageHeight: number;
    text: string;
    normalizedText: string;
    anchor: string;
    imagePath: string;
    hasImage: boolean;
    packageId: string;
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalProperties: any;
    namedEntities: NamedEntity[];
    blockType: BLOCK_TYPE;
};

export enum PackageStateResult {
    Ready = 'Ready',
    Parsed = 'Parsed',
    Importing = 'Importing',
    Processing = 'Processing',
    Broken = 'Broken'
}

export class PackageLinesResponse {
    total: number = 0;
    lines: PackageLine[] = [];
}

export class PackagesResponse {
    total: number = 0;
    lines: Package[] = [];
}

export class ImportInputsResponse {
    importedSuccessfully: boolean;
    canProceed: boolean;
    responseMessage?: string;
}
