/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action, computed, runInAction } from 'mobx';
import { SharedInstanceManager } from '../services';
import BusinessRulesService from '../services/BusinessRulesService';
import { message } from 'antd';
import { CodeSnippet, CodeSnippetGroup } from '../../administration/models';
import { getFullSnippetPath } from '../../common/Utils';
import { CompileDummyCodeType } from '../../rules/types';

export default class CodeBusinessRuleVisualStore {
    @observable
    code: string;

    @observable
    isOpened = false;

    @observable
    isLoading = true;

    @observable
    codeSnippets: CodeSnippet[] = [];

    @observable
    codeSnippetGroups: CodeSnippetGroup[] = [];

    currentCode: string;

    @computed
    get codePreview() {
        return this.code ? this.code.substring(0, Math.min(this.code.length, 10)) + '...' : 'Edit';
    }

    constructor(
        code: string,
        private onSave: (code: string) => void,
        private service: BusinessRulesService,
        private onCancel: (() => void) | null = null
    ) {
        this.code = code || '';
    }

    bindEditor() {
        const manager = new SharedInstanceManager();
        manager.loadCodeIntoEditor(this.code).then(() => runInAction(() => (this.isLoading = false)));
    }

    @action.bound
    showModal() {
        this.currentCode = this.code;
        this.isOpened = true;
    }

    @action.bound
    async loadCodeSnippets() {
        var result = await this.service.getCodeSnippets();
        if (result) {
            runInAction(() => {
                this.codeSnippets = result!;
                this.createFullCodeSnippetPaths();
            });
        }
    }

    @action.bound
    async loadCodeSnippetGroups() {
        var result = await this.service.getCodeSnippetGroups();
        if (result) {
            runInAction(() => {
                this.codeSnippetGroups = result!;
                this.createFullCodeSnippetPaths();
            });
        }
    }

    @action.bound
    loadFile() {
        // this.createModel();
    }

    @action.bound
    updateCode(code: string) {
        this.code = code;
    }

    @action.bound
    save() {
        this.onSave(this.code);
        this.isOpened = false;
    }

    @action.bound
    cancel() {
        this.code = this.currentCode;
        if (this.onCancel) {
            this.onCancel();
        }
        this.isOpened = false;
    }

    @action.bound
    dispose() {
        this.isLoading = true;
    }

    @action.bound
    async executeCode() {
        runInAction(() => (this.isLoading = true));
        const resp = await this.service.executeBusinessRuleCode(this.code, CompileDummyCodeType.BusinessRule);
        resp.map(() => {
            message.success('Code has been successfully compiled');
        }).mapErr(err => {
            message.error(err.data?.title);
        });

        runInAction(() => (this.isLoading = false));
    }

    @action.bound
    createFullCodeSnippetPaths() {
        if (this.codeSnippets.length && this.codeSnippetGroups.length) {
            for (let snippet of this.codeSnippets) {
                snippet.fullSnippetGroupPath = getFullSnippetPath(snippet.groupId!, this.codeSnippetGroups);
            }
        }
    }
}
