import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { FlexContentContainer } from '../../common/components';
import { FormTypes } from '../components';
import { FormTypesStores } from '../stores';
import { STORE_PROJECT_FORM_TYPES, STORE_PROJECT_FORM_TYPES_VISUAL } from '../stores/constants';
import { STORE_PROJECTS } from '../../common/constants';
import { RootStores } from '../../common/stores';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';

type Props = FormTypesStores & RootStores;

const FormTypesPage: React.FC<Props> = ({ projectFormTypesUI, projectFormTypesVisualUI, projects }) => {
    if (!projectFormTypesUI || !projectFormTypesVisualUI || !projects) {
        return null;
    }

    return (
        <HasPermission permissionClaim={AppPermissions.CanManageFormTemplates}>
            <FlexContentContainer
                content={
                    <FormTypes
                        store={projectFormTypesUI}
                        visualStore={projectFormTypesVisualUI}
                        projectsStore={projects}
                    />
                }
            />
        </HasPermission>
    );
};

export default inject(
    STORE_PROJECT_FORM_TYPES,
    STORE_PROJECT_FORM_TYPES_VISUAL,
    STORE_PROJECTS
)(observer(FormTypesPage));
