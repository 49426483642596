import { Form, Select } from 'antd';
import * as React from 'react';
import { SelectWhenScroll } from './SelectWhenScroll';
const { Option } = Select;

type Props = {
    mode?: 'multiple' | 'tags';
};

export const BlockTypesDropdown: React.FC<Props> = ({ mode }) => {
    return (
        <Form.Item label="Exclude Block types" name="excludedBlockTypes" data-id="rule-block-types-input">
            <SelectWhenScroll mode={mode || 'multiple'} placeholder="Select block types">
                <Option value="LINE_BLOCK">LINE</Option>
                <Option value="TEXTBOX_BLOCK">TEXTBOX</Option>
                <Option value="TABLE_BLOCK">TABLE</Option>
                <Option value="CELL_BLOCK">CELL</Option>
                <Option value="HORIZONTAL_LINE_BLOCK">HORIZONTAL</Option>
                <Option value="CLUSTER_BLOCK">CLUSTER</Option>
                <Option value="HORIZONTAL_MULTILINE_BLOCK">HORIZONTAL_MULTILINE</Option>
                <Option value="PARAGRAPH_BLOCK">PARAGRAPH</Option>
                <Option value="PAGE_BLOCK">PAGE</Option>
                <Option value="WORD_BLOCK">WORD</Option>
                <Option value="DOCUMENT_TABLE_BLOCK">DOCUMENT_TABLE</Option>
                <Option value="FORM_ITEM_BLOCK">FORM_ITEM</Option>
                <Option value="SMART_BLOCK">SMART</Option>
            </SelectWhenScroll>
        </Form.Item>
    );
};
