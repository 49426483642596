import appClient, { ResultApi } from '../../common/services/AppClient';
import {
    FormType,
    FormPart,
    FormPartCoordinatesReference,
    FormRegionBlockBbox,
    FormTypeImportTableData,
    UpdateFormRegionBlockModel,
    FormPartDetailsModel,
    FormTypePackageLinesModel,
    FormPartAdjustedBlock
} from '../types';
import security from '../../common/services/SecurityService';
import { Utils } from '../../common/services/Utils';

export default class FormTypesService {
    async getProjectFormTypes(projectId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `formTypes/types?projectId=${projectId}`;
        const resp = await appClient.get<FormType[]>(url);
        return resp.unwrapOr(Array<FormType>());
    }

    async createFormType(name: string, projectId: string) {
        const request = {
            name,
            projectId
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'formTypes/types';
        return await appClient.post(url, request);
    }

    async getProjectFormParts(projectId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `formTypes/form-parts-for-project?projectId=${projectId}`;
        const resp = await appClient.get<FormPart[]>(url);
        return resp.unwrapOr(Array<FormPart>());
    }

    async getVariationFormParts(variationId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `formTypes/form-parts-for-variation?variationId=${variationId}`;
        const resp = await appClient.get<FormPart[]>(url);
        return resp.unwrapOr(Array<FormPart>());
    }

    async createFormVariation(name: string, formTypeId: string, packageId: string, referenceVariationId?: string) {
        const request = {
            name,
            formTypeId,
            packageId,
            referenceVariationId
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'formTypes/variations';
        return await appClient.post(url, request);
    }

    async createFormTemplateRegion(formPartId: string, name: string, projectId: string) {
        const request = {
            formPartId,
            name,
            projectId
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'formTypes/template-regions';
        return await appClient.post(url, request);
    }

    async createFormRegionBlock(
        templateRegionId: string,
        blockTitle: string,
        blockPage: number,
        bBoxes: FormRegionBlockBbox[] = []
    ): Promise<ResultApi<string>> {
        const request = {
            templateRegionId,
            blockTitle,
            bBoxes,
            blockPage
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'formTypes/region-blocks';
        return await appClient.post(url, request);
    }

    async updateFormRegionBlock(
        templateRegionId: string,
        blockTitle: string,
        blockId: string,
        blockPage: number,
        bBoxes: FormRegionBlockBbox[] = []
    ) {
        const request = {
            templateRegionId,
            blockTitle,
            bBoxes,
            blockId,
            blockPage
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'formTypes/region-blocks';
        return await appClient.update(url, request);
    }

    async updateMultipleFormRegionBlocks(regionId: string, page: number, blocks: UpdateFormRegionBlockModel[]) {
        const url =
            process.env.REACT_APP_MANAGE_URL +
            `formTypes/template-regions/${regionId}/page/${page}/region-blocks-batch`;
        return await appClient.update(url, blocks);
    }

    async createFormPart(
        name: string,
        projectId: string,
        formVariationId: string,
        detectorParts?: string[],
        ignoreParts?: string[],
        coordinatesReference?: FormPartCoordinatesReference
    ) {
        const request = {
            name,
            projectId,
            formVariationId,
            detectorParts,
            ignoreParts,
            coordinatesReference
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'formTypes/form-parts';
        return await appClient.post(url, request);
    }

    async updateFormPart(
        name: string,
        formPartId: string,
        detectorParts: string[],
        coordinatesReference: FormPartCoordinatesReference,
        ignoreParts?: string[]
    ) {
        const request = {
            name,
            formPartId,
            detectorParts,
            ignoreParts,
            coordinatesReference
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'formTypes/form-parts';
        return await appClient.update(url, request);
    }

    async deleteFormPart(formPartId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `formTypes/form-parts/${formPartId}`;
        return await appClient.delete(url);
    }

    async deleteFormType(formTypeId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `formTypes/types/${formTypeId}`;
        return await appClient.delete(url);
    }

    async deleteFormVariation(formVariationId: string, formTypeId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `formTypes/types/${formTypeId}/variations/${formVariationId}`;
        return await appClient.delete(url);
    }

    async deleteFormRegion(regionId: string, formPartId: string) {
        const url =
            process.env.REACT_APP_MANAGE_URL + `formTypes/form-parts/${formPartId}/template-regions/${regionId}`;
        return await appClient.delete(url);
    }

    async exportFormType(typeId: string) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'formTypePostForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';

        await security.invoke(token => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url = process.env.REACT_APP_MANAGE_URL + `formTypes/types/${typeId}/export${t}`;
            Utils.downloadFile(url, mapForm, 'formTypePostForm');
            return Promise.resolve();
        });
    }

    async importFormType(data: FormData): Promise<ResultApi<FormTypeImportTableData>> {
        let url = process.env.REACT_APP_MANAGE_URL + 'formTypes/types/import';
        return await appClient.post(url, data);
    }

    async commitImportedFormType(projectId: string, tableData: FormTypeImportTableData): Promise<ResultApi<string>> {
        const request = {
            projectId,
            fileName: tableData.generatedFileName,
            formTypeImportMappingData: tableData.mapperTableData
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'formTypes/types/commit';
        return await appClient.post(url, request);
    }

    async updateFormType(formTypeId: string, name: string) {
        const request = {
            formTypeId,
            name
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'formTypes/types';
        return await appClient.update(url, request);
    }

    async updateFormVariation(formTypeId: string, variationId: string, name: string, packageId: string) {
        const request = {
            formTypeId,
            variationId,
            name,
            packageId
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'formTypes/variations';
        return await appClient.update(url, request);
    }

    async updateFormRegion(formPartId: string, regionId: string, name: string) {
        const request = {
            regionId,
            formPartId,
            name
        };
        const url = process.env.REACT_APP_MANAGE_URL + 'formTypes/template-regions';
        return await appClient.update(url, request);
    }

    async getFormPartDetails(formPartId: string): Promise<ResultApi<FormPartDetailsModel>> {
        const url = process.env.REACT_APP_MANAGE_URL + `formTypes/form-parts/${formPartId}/details`;
        return await appClient.get<FormPartDetailsModel>(url);
    }

    async getFormPartAdjustmentsForPackage(
        formPartId: string,
        packageId: string
    ): Promise<ResultApi<FormPartAdjustedBlock[]>> {
        const url =
            process.env.REACT_APP_MANAGE_URL + `formTypes/form-parts/${formPartId}/preview-package/${packageId}`;
        return await appClient.get<FormPartAdjustedBlock[]>(url);
    }

    async getFormTypePackageLines(
        projectId: string,
        formTypeId?: string
    ): Promise<ResultApi<FormTypePackageLinesModel[]>> {
        let url = process.env.REACT_APP_MANAGE_URL + `formTypes/package-lines?projectId=${projectId}`;

        if (formTypeId) {
            url += `&formTypeId=${formTypeId}`;
        }

        return await appClient.get(url);
    }

    async calculateFuzzyScore(input1: string, input2: string): Promise<ResultApi<number>> {
        const url = process.env.REACT_APP_MANAGE_URL + 'formTypes/calculate-fuzzy-score';
        return await appClient.post(url, { input1, input2 });
    }
}
