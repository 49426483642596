import * as React from 'react';
import { observer, inject } from 'mobx-react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { STORE_USERS_MANAGER_UI } from '../../../modules/administration/constants';
import { AdministrationStores } from '../../../modules/administration/stores';
import { AuthConsumer } from '../../authorization/AuthContext';
import RolesManagement from '../components/RolesManagement';

type Props = AdministrationStores;
@inject(STORE_USERS_MANAGER_UI)
@observer
export default class RolesManagementPage extends React.Component<Props, object> {
    render() {
        const usersManagerStore = this.props.UsersManager!;
        const content = <AuthConsumer>{() => <RolesManagement store={usersManagerStore} />}</AuthConsumer>;

        if (!usersManagerStore) {
            return null;
        }

        return <FlexContentContainer content={content} />;
    }
}
