import { RuleBaseResult } from '../types';
import RuleBase from './RuleBase';
import PipelineStepType from '../types/PipelineStepType';
import RuleType from '../types/RuleType';

export default abstract class TagRuleBase<T, R extends RuleBaseResult<RuleType, PipelineStepType>> extends RuleBase<
    T,
    RuleType,
    PipelineStepType,
    R
> {}
