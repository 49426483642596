import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input, Row, Col, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { WorkflowTextInputRowEntry } from '../../../rules/types';
import './WorkflowTextInputRow.less';

interface Props {
    input: WorkflowTextInputRowEntry;
    index: number;
    disabled?: boolean;
    onItemDelete: () => void;
}

const WorkflowTextInputRow: React.FC<Props> = ({ input, index, disabled, onItemDelete }) => {
    return (
        <>
            <Row gutter={16} align="middle" className="workflow-text-input-row-item">
                <Col span={11}>
                    <Form.Item
                        name={['overriddenWorkflowParameters', index, 'key']}
                        initialValue={input.id}
                        rules={[{ required: true, message: 'Please enter parameter name!' }]}
                        className="workflow-text-input-row-form-item"
                    >
                        <Input placeholder="Name" disabled={disabled || !input.isCustom} />
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                        name={['overriddenWorkflowParameters', index, 'value']}
                        initialValue={input.value}
                        rules={[{ required: true, message: 'Please enter parameter value!' }]}
                        className="workflow-text-input-row-form-item"
                    >
                        <Input placeholder="Value" disabled={disabled} />
                    </Form.Item>
                </Col>
                <Col span={2} className="workflow-text-input-icon-col">
                    {input.isCustom && (
                        <i onClick={!disabled ? onItemDelete : undefined} className="alpha-icon md delete-icon" />
                    )}
                    {!input.isCustom && input.description && (
                        <Tooltip title={input.description}>
                            <InfoCircleOutlined className="workflow-text-input-row-icon" />
                        </Tooltip>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default observer(WorkflowTextInputRow);
