import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { CodeSnippetsVisualStore } from '../stores/CodeSnippetsVisualStore';
import { Button, Tree, Menu, Dropdown, Modal, Layout } from 'antd';
import WrappedCodeSnippetForm from './CodeSnippetForm';
import WrappedCodeSnippetGroupForm from './CodeSnippetGroupForm';
import CodeSnippetsTreeItemPreview from './CodeSnippetsTreeItemPreview';
import LayoutHeader from '../../../components/LayoutHeader';
import { CodeSnippetGroup } from '../models';
const { TreeNode } = Tree;
const { Content, Sider } = Layout;

type Props = {
    store: CodeSnippetsVisualStore;
};

const CodeSnippets: React.FC<Props> = ({ store }) => {
    React.useEffect(() => {
        store.loadCodeSnippetGroups();
        store.loadCodeSnippets();
    }, [store]);

    const renderSnippetNodes = (groupId: string) => {
        return (
            <>
                {store.codeSnippets
                    .filter(s => s.groupId === groupId)
                    .map(s => (
                        <TreeNode
                            data-id={s.id}
                            data-type="snippet"
                            title={
                                <Dropdown overlay={() => snippetMenu(s.id!)} trigger={['contextMenu']}>
                                    <span>{s.name}</span>
                                </Dropdown>
                            }
                            key={s.id}
                            switcherIcon={<i className="alpha-icon md document-icon" />}
                        />
                    ))}
            </>
        );
    };

    const getTreeIcon = (group: CodeSnippetGroup) => {
        const g = store.codeSnippetGroups.find(x => x.parent === group.id);
        return !g ? <i className="alpha-icon md document-icon" /> : undefined;
    };

    const renderGroupNodes = (parentId: string | null) => {
        return (
            <>
                {store.codeSnippetGroups
                    .filter(g => g.parent === parentId)
                    .map(g => (
                        <TreeNode
                            data-id={g.id}
                            data-type="group"
                            title={
                                <Dropdown overlay={() => groupMenu(g.id!)} trigger={['contextMenu']}>
                                    <span>{g.name}</span>
                                </Dropdown>
                            }
                            key={g.id}
                            switcherIcon={getTreeIcon(g)}
                        >
                            {renderGroupNodes(g.id || null)}
                            {renderSnippetNodes(g.id!)}
                        </TreeNode>
                    ))}
            </>
        );
    };

    const groupMenu = (id: string) => {
        return (
            <Menu>
                <Menu.Item key="1" onClick={() => store.editGroup(id)}>
                    Edit group
                </Menu.Item>
                <Menu.Item key="2" onClick={() => store.addSubgroup(id)}>
                    Add subgroup
                </Menu.Item>
                <Menu.Item key="3" onClick={() => store.addSnippetToGroup(id)}>
                    Add snippet
                </Menu.Item>
                <Menu.Item key="4" onClick={() => store.deleteGroup(id)}>
                    Delete
                </Menu.Item>
            </Menu>
        );
    };

    const snippetMenu = (id: string) => {
        return (
            <Menu>
                <Menu.Item key="1" onClick={() => store.editSnippet(id)}>
                    Edit snippet
                </Menu.Item>
                <Menu.Item key="2" onClick={() => store.deleteSnippet(id)}>
                    Delete
                </Menu.Item>
            </Menu>
        );
    };

    return (
        <>
            <Modal
                visible={store.formDialogVisible}
                centered
                title={store.selectedSnippet ? 'Edit snippet' : 'Add snippet'}
                onCancel={() => store.setFormDialogVisible(false)}
                maskClosable={false}
                destroyOnClose
                width={'80%'}
                footer={null}
                closable={false}
            >
                <WrappedCodeSnippetForm store={store} />
            </Modal>
            <WrappedCodeSnippetGroupForm store={store} />
            <Layout className="screen-size" style={{ ...{ height: '100%', background: '#fff' } }}>
                <LayoutHeader
                    title="Code snippets"
                    helpMessage=" "
                    buttons={[
                        <Button key="1" type="primary" size="large" onClick={store.addGroup}>
                            Add group
                        </Button>
                    ]}
                />
                <Layout>
                    <Sider style={{ background: '#fff', overflow: 'auto' }}>
                        <Tree
                            selectedKeys={store.previewItem ? [store.previewItem.id!] : undefined}
                            showLine={{ showLeafIcon: true }}
                            showIcon={false}
                            className="alpha-portal-tree without-line with-leaf-icons sm-labels"
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onSelect={(keys, info: any) =>
                                store.selectTreeItem(
                                    info.node.props['data-id'],
                                    info.node.props['data-type'],
                                    info.selected
                                )
                            }
                        >
                            {renderGroupNodes(null)}
                        </Tree>
                    </Sider>
                    <Content style={{ paddingLeft: 10 }}>
                        <CodeSnippetsTreeItemPreview store={store} />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};

export default observer(CodeSnippets);
