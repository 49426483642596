/* eslint-disable @typescript-eslint/member-ordering */
import { observable, computed, action, runInAction, reaction } from 'mobx';
import _ from 'lodash';
import { PreviewVisualStore } from '../../pipeline_base/stores';
import { Project, Package, PackageLine, PackageState } from '../../common/models';
import { PreviewResultWithPackageId } from '../types';
import type { RuleTypes } from '../models';
import { FILTER_DEBOUNCE_TIME } from '../../pipeline_base/stores/PreviewVisualStore';
import RulesStore from './RulesStore';
import { ProjectsRootVisualStore } from '../../common/stores';
import { AntTreeNodeExpandedEvent } from 'antd/lib/tree';
import { TreeItem } from 'react-sortable-tree';
import { Utils } from '../../common/services/Utils';
import { Subscription } from 'rxjs';
import { PackageChange } from '../../common/types';

const STORAGE_SELECTED_PREVIEW_TAGS_ID = (projectId: string) => {
    return `${projectId}-selectedPreviewTags`;
};
const STORAGE_SEARCHED_PREVIEW_PKG_QUERY_ID = (projectId: string) => {
    return `${projectId}-searchedPreviewPkgQuery`;
};

export default class RulePreviewVisualStore extends PreviewVisualStore {
    @observable
    checkedKeys: string[] = [];

    @observable
    selectedProject: Project | null;

    @observable
    marker: { [id: string]: string } = {};

    @observable
    entriesExpandedKeys: { [id: string]: string[] } = {};

    @observable
    previewResults: PreviewResultWithPackageId[] | null;

    currentCollapsedNodeKey: string;

    @observable
    filteredTreeData: TreeItem[] = [];

    @observable
    checkAllKeys: boolean;

    @observable
    isExecuting = false;

    @observable
    expandedKeys: string[] = [];

    @observable
    filter: string | null = null;

    @observable
    documentsFilter: string | null = null;

    @observable
    inputValue: string = '';

    @observable
    rule: RuleTypes;

    @observable
    totalPackagesCount: number = 0;

    @observable
    packagesListPage: number = 1;

    @observable
    interactiveLabelsWindows = observable.map<string, Window>();

    @observable
    private packagesIds: string[] = [];

    private projectsIdx = new Map<string, Project>();

    private packagesIdx = new Map<string, Package>();

    private debounceFilterNodes: Function;

    private debounceSearchDocuments: Function;

    private subscription: Subscription | null = null;

    readonly pageSize: number = 25;

    get storageFilteredTags() {
        const sessionTagValues =
            this.projectsVisualStore.currentProject &&
            sessionStorage.getItem(STORAGE_SELECTED_PREVIEW_TAGS_ID(this.projectsVisualStore.currentProject.id));
        return (sessionTagValues && sessionTagValues.split(',')) || [];
    }

    get storagePkgSearchQuery() {
        return (
            (this.projectsVisualStore.currentProject &&
                sessionStorage.getItem(
                    STORAGE_SEARCHED_PREVIEW_PKG_QUERY_ID(this.projectsVisualStore.currentProject.id)
                )) ||
            ''
        );
    }

    @computed
    get filteredPreviewResults() {
        if (!this.previewResults) {
            return [];
        }

        if (!this.filter) {
            return this.previewResults;
        }

        return this.previewResults.map(x => ({
            packageId: x.packageId,
            previewResult: {
                rule: x.previewResult.rule,
                entries: x.previewResult.entries.filter(y =>
                    y.field.normalizedText.trim().match(new RegExp(this.filter!, 'i'))
                )
            }
        }));
    }

    @computed
    get filteredDocumentsResults() {
        return this.projects.map(x => {
            let project = new Project(x.id, x.title, x.keywords);
            let filter = this.documentsFilter;

            if (this.loadedPackages[x.id]) {
                project.isPackagesLoaded = true;

                let packages =
                    filter && filter.length
                        ? this.loadedPackages[x.id].filter(s => s.name.trim().match(new RegExp(filter!, 'i')))
                        : this.loadedPackages[x.id];
                if (this.currentTags && this.currentTags.length) {
                    packages = packages.filter(
                        p => _.intersection(p.userTags, this.currentTags).length === this.currentTags.length
                    );
                }
                project.packages = packages.sort(Utils.sortPackagesByDate);
            }

            return project;
        });
    }

    @computed
    get filteredDocumentsForCurrentProject() {
        if (this.projectsVisualStore.currentProject) {
            let currProject = this.projectsVisualStore.currentProject;
            let project = new Project(currProject.id, currProject.title, currProject.keywords);
            let filter = this.documentsFilter
                ? this.documentsFilter.replace(/[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g, '\\$&')
                : null;
            if (this.loadedPackages[project.id]) {
                project.isPackagesLoaded = true;

                let packages =
                    filter && filter.length
                        ? this.loadedPackages[project.id].filter(s => s.name.trim().match(new RegExp(filter!, 'i')))
                        : this.loadedPackages[project.id];
                if (this.currentTags && this.currentTags.length) {
                    packages = packages.filter(
                        p => _.intersection(p.userTags, this.currentTags).length === this.currentTags.length
                    );
                }
                project.packages = packages.slice().sort(Utils.sortPackagesByDate);
            }

            return project;
        } else {
            return null;
        }
    }

    @computed
    get selectAllIndeterminate() {
        return !!(
            this.readyPackages.length > 0 &&
            this.checkedKeys.length > 0 &&
            this.checkedKeys.length !== this.readyPackages.length
        );
    }

    @computed
    get project(): Project | null {
        return this.selectedProject;
    }

    @computed
    get checkedPackages(): string[] {
        return this.checkedKeys.filter(c => this.packagesIds.indexOf(c) !== -1);
    }

    @computed
    get projects(): Project[] {
        return this.rootStore.projects;
    }

    @computed
    get readyPackages() {
        return this.packages.filter(this.packageIsReady);
    }

    @observable
    packages: Package[] = [];

    @action.bound
    reset() {
        this.filteredTreeData = [];
        this.previewResults = [];
        this.selectedPackage = null;
    }

    @action.bound
    setCheckAllKeys(check: boolean) {
        const selectAll = check && !!this.readyPackages;
        const checkedPackages = selectAll ? this.readyPackages.map(p => p.id) : [];
        this.checkPackages(checkedPackages);
    }

    @action.bound
    setMarker(id: string, obj: string) {
        this.marker[id] = obj;
    }

    @action.bound
    setFilteredTreeData(treeData: TreeItem[]) {
        this.filteredTreeData = treeData;
    }

    @action
    selectProjectById(id: string) {
        this.selectedProject = this.projects.find(p => p.id === id)!;
        if (this.selectedProject && !this.selectedProject.isPackagesLoaded) {
            this.rootStore.getPackages(this.selectedProject);
        }

        this.lines = [];
        this.setCurrentPage(-1);
        this.selectedPackage = null;
        this.totalPages = 0;
    }

    @action
    expand(keys: string[]) {
        this.expandedKeys = keys;
    }

    @action.bound
    addInteractiveLabelsWindow(packageId: string, window: Window) {
        this.interactiveLabelsWindows.set(packageId, window);
    }

    constructor(
        public readonly ruleToPreview: RuleTypes,
        private readonly rulesStore: RulesStore,
        projectStore: ProjectsRootVisualStore
    ) {
        super(projectStore);
        this.debounceFilterNodes = _.debounce(this.filterNodes, FILTER_DEBOUNCE_TIME);
        this.debounceSearchDocuments = _.debounce(this.searchDocuments, FILTER_DEBOUNCE_TIME);

        if (!ruleToPreview.groupId) {
            ruleToPreview.groupId = rulesStore.checkIfTagExists(ruleToPreview)!;
            ruleToPreview.projectId = rulesStore.currentProject?.id!;
        }

        this.setRule(ruleToPreview);

        reaction(
            () => this.rootStore.projects,
            proj => {
                proj.forEach(p => this.projectsIdx.set(p.id, p));
            },
            { fireImmediately: true }
        );

        this.rootStore.projects
            .filter(x => x.isPackagesLoaded)
            .map(p => p.packages)
            .forEach(pkgs => {
                pkgs.forEach(p => this.packagesIdx.set(p.id, p));
                this.packagesIds = [...this.packagesIds, ...pkgs.map(x => x.id)];
            });

        reaction(
            () => this.projectsVisualStore.currentProject,
            proj => {
                if (proj) {
                    projectStore.fetchTags();
                }
            }
        );

        setInterval(() => {
            this.interactiveLabelsWindows.forEach((window, packageId) => {
                if (window.closed) {
                    this.interactiveLabelsWindows.delete(packageId);
                }
            });
        }, 1000);
    }

    @action.bound
    setRule(newRule: RuleTypes) {
        this.rule = newRule;
    }

    @action.bound
    onComponentLoad() {
        this.loadCurrentProject();
        this.setTags(this.storageFilteredTags || []);
        this.setDocNameInputValue(this.storagePkgSearchQuery);
        this.filterDocuments(this.storagePkgSearchQuery);
        this.loadProjectPackages();
    }

    @action.bound
    setPackagesListPage(page: number) {
        this.packagesListPage = page;
    }

    @action.bound
    handlePackageChanges(packageChange: PackageChange) {
        if (!this.projectsVisualStore.currentProject) {
            return;
        }

        if (
            this.projectsVisualStore.currentProject.id === packageChange.projectId &&
            packageChange.state === PackageState.Ready
        ) {
            this.loadProjectPackages();
        }
    }

    @action.bound
    async loadProjectPackages() {
        try {
            this.setIsLoadingPackages(true);
            const response = await this.loadPackages(
                this.packagesListPage - 1,
                undefined,
                this.pageSize,
                this.documentsFilter
            );

            runInAction(() => {
                this.packages = response.lines;
                this.totalPackagesCount = response.total;

                const projectId = response.lines[0]?.project.id;
                if (projectId) {
                    this.loadedPackages[projectId] = response.lines;
                }

                this.packagesIds = [...this.packagesIds, ...response.lines.map(x => x.id)];
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setIsLoadingPackages(false);
        }
    }

    @action
    filterNodes(query: string) {
        this.filter = query;
    }

    @action
    filterDocuments(query: string) {
        this.documentsFilter = query;
    }

    @action
    setInputValue(query: string) {
        this.inputValue = query;
    }

    @action
    debouncedFilterNodes(query: string) {
        runInAction(() => {
            this.debounceFilterNodes(query);
        });
    }

    @action.bound
    searchDocuments(query: string) {
        this.filterDocuments(query);
        this.setPackagesListPage(1);
        this.loadProjectPackages();
    }

    @action
    debouncedSearchDocuments(query: string) {
        runInAction(() => {
            this.debounceSearchDocuments(query);
        });
        this.savePkgSearchQueryInStorage(query);
    }

    saveFilteredTagsInStorage(tags: string[]) {
        sessionStorage.setItem(`${this.projectsVisualStore.currentProject!.id}-selectedPreviewTags`, tags.join());
    }

    savePkgSearchQueryInStorage(query: string) {
        sessionStorage.setItem(`${this.projectsVisualStore.currentProject!.id}-searchedPreviewPkgQuery`, query);
    }

    @action
    async loadCurrentProject() {
        if (this.projectsVisualStore.currentProject != null) {
            if (this.projectsVisualStore.currentProject.isPackagesLoaded) {
                let proj = this.projectsVisualStore.currentProject;
                runInAction(() => {
                    this.loadedPackages[proj.id] = proj.packages;
                    this.projectsIdx.set(proj.id, proj);
                    this.packagesIds = [...this.packagesIds, ...proj.packageIds];

                    proj.packages.forEach(p => this.packagesIdx.set(p.id, p));
                    if (this.checkedKeys.indexOf(proj.id) !== -1) {
                        this.checkedKeys = [
                            ...this.checkedKeys,
                            ...proj.packages.filter(this.packageIsReady).map(p => p.id)
                        ];
                    }
                });
            } else {
                await this.loadProject(this.projectsVisualStore.currentProject);
            }
        }
    }

    @action
    async loadProject(proj: Project) {
        if (proj.isPackagesLoaded) {
            return;
        }

        await this.rootStore.getPackages(proj);

        runInAction(() => {
            this.loadedPackages[proj.id] = proj.packages;
            this.projectsIdx.set(proj.id, proj);
            this.packagesIds = [...this.packagesIds, ...proj.packageIds];

            proj.packages.forEach(p => this.packagesIdx.set(p.id, p));
            if (this.checkedKeys.indexOf(proj.id) !== -1) {
                this.checkedKeys = [...this.checkedKeys, ...proj.packages.filter(this.packageIsReady).map(p => p.id)];
            }
        });
    }

    isChecked(pkg: string) {
        return this.checkedKeys.indexOf(pkg) !== -1;
    }

    getPackageNameById(id: string) {
        if (this.packagesIdx.has(id)) {
            return this.packagesIdx.get(id)!.name;
        }

        return id;
    }

    setUrl() {
        const path = encodeURIComponent(this.selectedPackage!.id);
        this.pdfServiceUrl = process.env.REACT_APP_MANAGE_URL + `document/${path}`;
    }

    getProjectNameById(id: string) {
        if (this.projectsIdx.has(id)) {
            return this.projectsIdx.get(id)!.title;
        }

        return id;
    }

    @action
    checkPackages(checks: string[]) {
        const projects = checks.filter(c => this.projectsIdx.has(c) && this.expandedKeys.indexOf(c) === -1);
        if (projects.length) {
            this.expandedKeys = [...this.expandedKeys, ...projects];
        }

        this.checkedKeys = checks;
        this.checkAllKeys = this.checkedKeys.length ? this.checkedKeys.length === this.readyPackages.length : false;
    }

    @action.bound
    handlePackageCheck(pkg: string, checked: boolean) {
        if (checked) {
            this.checkedKeys = [...this.checkedKeys, pkg];
        } else {
            this.checkedKeys = this.checkedKeys.filter(x => x !== pkg);
        }
    }

    @action
    async selectPackage(pkgId: string | null) {
        if (!pkgId || pkgId === this.selectedPackage?.id) {
            this.selectedPackage = null;
            return;
        }

        // for (const proj of this.filteredDocumentsResults) {
        //  if (!proj.isPackagesLoaded) {
        //      continue;
        //  }

        //  for (const pkg of proj.packages) {
        //      if (pkg.id === pkgId) {
        //          await this.selectProjectById(proj.id);
        //          runInAction(() => this.setPackage(pkg));
        //          return;
        //      }
        //  }
        // }

        const pkg = this.packages.find(p => p.id === pkgId);

        if (!pkg) {
            return;
        }

        await this.selectProjectById(pkg.project.id);
        runInAction(() => this.setPackage(pkg));
    }

    @action
    async execute() {
        if (!this.checkedPackages.length) {
            return;
        }

        this.isExecuting = true;

        const filteredPackages = this.checkedPackages.filter(
            p => !!this.filteredDocumentsForCurrentProject!.packages.find(x => x.id === p)
        );

        let result = await this.rulesStore.executeRule(this.rule, filteredPackages);
        result = result ? result.filter(r => r.previewResult.entries) : result;

        console.log('Received execution result: ', result);
        this.currentCollapsedNodeKey = '';
        runInAction(() => {
            if (result && result.length) {
                this.previewResults = result;
            } else {
                this.previewResults = null;
            }
            this.isExecuting = false;
        });
    }

    @action
    setEntriesExpandedKeys(id: string, n: string[], event: AntTreeNodeExpandedEvent | undefined) {
        this.entriesExpandedKeys[id] = n;
        if (event && event.expanded === false && event.node) {
            this.currentCollapsedNodeKey = event.node.props.eventKey!;
        }
    }

    @action
    addExpandedKey(id: string, key: string) {
        if (!this.entriesExpandedKeys[id]) {
            this.entriesExpandedKeys[id] = [];
        }
        this.entriesExpandedKeys[id].push(key);
    }

    @action
    highlightBlock(rowId: number, pkgId: string, selected: boolean | undefined, scrollToPos: boolean = true) {
        if (selected) {
            const rows = this.filteredPreviewResults
                .map(p =>
                    p.previewResult.entries.map(e => e.field).find(fi => fi.rowId === rowId && fi.packageId === pkgId)
                )
                .filter(r => r != null);
            if (rows.length > 0 && rows[0]) {
                const row = rows[0]!;
                if (!this.selectedPackage || this.selectedPackage.id !== row.packageId) {
                    this.setPdfIsLoaded(false);
                    this.setPageCoordinates([]);
                    this.selectPackage(row.packageId);
                }

                if (!this.pageCoordinates || this.pageCoordinates.length === 0) {
                    reaction(
                        () => this.pageCoordinates,
                        (p, r) => {
                            if (p && p.length > 0) {
                                this.highlightBlock(rowId, pkgId, selected, scrollToPos);
                                r.dispose();
                            }
                        }
                    );
                    return;
                }

                let pdfContent = document.querySelector(`.react-pdf__Page[data-page-number='${row.page + 1}']`);
                if (!pdfContent) {
                    if (scrollToPos) {
                        this.setCurrentPage(row.page);
                    }

                    setTimeout(() => {
                        this.highlightBlock(rowId, pkgId, selected, scrollToPos);
                    }, 200);
                    return;
                }

                let sizeCoefficient = pdfContent.clientHeight / row.pageHeight;
                let bottomPos = (row.y * sizeCoefficient - row.h * sizeCoefficient) * this.scale;
                let height = row.h * this.scale * sizeCoefficient;
                let width = row.w * this.scale * sizeCoefficient;
                let left = row.x * this.scale * sizeCoefficient;

                const bottomRelativePos = bottomPos / pdfContent.clientHeight;
                const leftRelativePos = left / pdfContent.clientWidth;
                const heightRelativePos = height / pdfContent.clientHeight;
                const widthRelativePos = width / pdfContent.clientWidth;

                if (scrollToPos) {
                    this.scrollToRow(row, sizeCoefficient, bottomPos);
                }

                this.setHighlightBlockProps({
                    height: `${heightRelativePos * 100}%`,
                    width: `${widthRelativePos * 100}%`,
                    left: `${leftRelativePos * 100}%`,
                    bottom: `${bottomRelativePos * 100}%`,
                    visibility: 'visible',
                    position: 'absolute',
                    page: row.page,
                    packageId: row.packageId
                });
            }
        } else {
            this.setHighlightBlockProps(undefined);
        }
    }

    getSizeCoefficient(row: PackageLine) {
        let sizeCoefficient = 1;
        if (
            this.pageCoordinates &&
            this.pageCoordinates[row.page] != null &&
            this.pageCoordinates[row.page + 1] != null
        ) {
            sizeCoefficient = (this.pageCoordinates[row.page + 1] - this.pageCoordinates[row.page]) / row.pageHeight;
        }
        return sizeCoefficient;
    }

    scrollToRow(row: PackageLine, sizeCoefficient: number, bottomPos: number) {
        if (this.isPdfLoaded) {
            this.setCurrentPage(row.page, true);

            // With timeout to make sure that page is rendered correctly, if the jump between pages is too big
            setTimeout(() => {
                const docPreviewContainer = document.getElementById('rule-document-preview-container');
                const highlightedBlock = docPreviewContainer?.querySelector('.highlighted-block');

                if (highlightedBlock) {
                    highlightedBlock.scrollIntoView({ block: 'center', inline: 'center' });
                }
            }, 100);
        } else {
            reaction(
                () => this.isPdfLoaded,
                (isLoaded, r) => {
                    if (isLoaded) {
                        setTimeout(() => {
                            this.setCurrentPage(row.page, true);
                            this.setScrollPosition(
                                this.scrollPosition + (row.pageHeight * sizeCoefficient - (bottomPos + row.h))
                            );
                            r.dispose();
                        }, 300);
                    }
                }
            );
        }
    }

    syncPackageIds() {
        let currProject = this.projectsVisualStore.currentProject;
        if (this.loadedPackages[currProject!.id]) {
            this.packagesIds = this.loadedPackages[currProject!.id].map(p => p.id);
            this.loadedPackages[currProject!.id].forEach(p => this.packagesIdx.set(p.id, p));
        }
    }

    subscribeToPackageChanges() {
        this.subscription = this.projectsVisualStore.projectsStore.packageChanges.subscribe(p =>
            this.handlePackageChanges(p)
        );
    }

    unsubscribeFromPackageChanges() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private packageIsReady(pkg: Package) {
        return pkg.serverState === PackageState.Ready;
    }
}
