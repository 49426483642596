import * as React from 'react';
import { CodeSnippetsVisualStore } from '../stores/CodeSnippetsVisualStore';
import { observer } from 'mobx-react-lite';
import { Input, Select, Button, Form, Modal } from 'antd';
import { CodeSnippetGroup } from '../models';

const { Option } = Select;

type Props = {
    store: CodeSnippetsVisualStore;
};

const CodeSnippetGroupForm: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (!store.groupDialogVisible) {
            form.resetFields();
        } else {
            const snippetGroup = store.selectedSnippetGroup;
            const parentGroup = store.selectedParentGroup;
            if (snippetGroup) {
                form.setFieldsValue({
                    id: snippetGroup.id,
                    name: snippetGroup.name,
                    parent: snippetGroup.parent,
                    description: snippetGroup.description
                });
            } else if (parentGroup) {
                form.setFieldsValue({
                    parent: parentGroup.id
                });
            }
        }
    }, [store.groupDialogVisible, form, store.selectedParentGroup, store.selectedSnippetGroup]);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);
                store!.saveSnippetGroup(values as CodeSnippetGroup);
                store!.setGroupDialogVisible(false);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const formItemStyle = { display: 'block' };

    return (
        <Modal
            visible={store.groupDialogVisible}
            centered
            title={store.selectedSnippetGroup ? 'Edit snippet group' : 'Add snippet group'}
            onCancel={() => store.setGroupDialogVisible(false)}
            maskClosable={false}
            destroyOnClose
            closable={false}
            footer={[
                <Button
                    data-id="iota-add-group-dialog-cancel"
                    size="large"
                    className="light"
                    key="back"
                    onClick={() => store.setGroupDialogVisible(false)}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="iota-add-group-dialog-submit"
                    size="large"
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Save group
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item
                    label="Name"
                    colon={false}
                    name="name"
                    rules={[{ required: true, message: 'Provide name for snippet.' }]}
                    style={formItemStyle}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Parent Group" colon={false} name="parent" style={formItemStyle}>
                    <Select allowClear>
                        {store.codeSnippetGroups
                            .slice()
                            .filter(g =>
                                store.selectedSnippetGroup
                                    ? g.id !== store.selectedSnippetGroup.id &&
                                      !store.selectedSnippetGroupChildren.find(childGroup => childGroup.id === g.id)
                                    : true
                            )
                            .map(g => (
                                <Option key={g.id} value={g.id as string}>
                                    {g.name}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Description" colon={false} name="description" style={formItemStyle}>
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Modal>
    );
};

// TODO: Add group selector
export default observer(CodeSnippetGroupForm);
