import { RootStores } from '../../common/stores';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import {
    STORE_APPLICATION_DEFINITION_CONDITIONAL_BINDING_PREVIEW,
    STORE_APPLICATION_DEFINITION_CONDITIONAL_EDIT
} from './constants';
import { STORE_PROJECT_APPLICATION_DEFINITION_EDIT } from '../../iota_applications/stores/constants';
import { STORE_ROUTER } from '../../common/constants';
import { STORE_RULES } from '../../rules/constants';
import { ApplicationDefinitionsService } from '../../iota_applications/services';
import ApplicationDefinitionConditionalEditStore from './ApplicationDefinitionConditionalEditStore';
import ApplicationDefinitionConditionalService from '../services/ApplicationDefinitionConditionalService';
import ApplicationDefinitionConditionalBindingPreviewStore from './ApplicationDefinitionConditionalBindingPreviewStore';

export { default as ApplicationDefinitionConditionalEditStore } from './ApplicationDefinitionConditionalEditStore';
export { default as ApplicationDefinitionConditionalBindingPreviewStore } from './ApplicationDefinitionConditionalBindingPreviewStore';

export function injectApplicationDefinitionConditionalStores(obj: RootStores) {
    const applicationDefinitionsService = new ApplicationDefinitionsService();
    const service = new ApplicationDefinitionConditionalService();

    obj[STORE_APPLICATION_DEFINITION_CONDITIONAL_BINDING_PREVIEW] =
        new ApplicationDefinitionConditionalBindingPreviewStore(
            obj,
            obj[STORE_PROJECTS_ROOT_UI],
            obj[STORE_RULES],
            obj[STORE_ROUTER],
            applicationDefinitionsService,
            service
        );

    obj[STORE_APPLICATION_DEFINITION_CONDITIONAL_EDIT] = new ApplicationDefinitionConditionalEditStore(
        obj[STORE_PROJECTS_ROOT_UI],
        obj[STORE_PROJECT_APPLICATION_DEFINITION_EDIT],
        service
    );
}

export type ApplicationDefinitionConditionalStores = {
    [STORE_APPLICATION_DEFINITION_CONDITIONAL_EDIT]?: ApplicationDefinitionConditionalEditStore;
    [STORE_APPLICATION_DEFINITION_CONDITIONAL_BINDING_PREVIEW]?: ApplicationDefinitionConditionalBindingPreviewStore;
};
