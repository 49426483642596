import * as React from 'react';
import { observer, inject } from 'mobx-react';
import FlexContentContainer from '../../common/components/FlexContentContainer';
import { AuthConsumer } from '../../authorization/AuthContext';
import AlphaConfigTable from '../components/AlphaConfigTable';
import { STORE_ALPHA_CONFIG } from '../../../modules/common/constants';
import { RootStores } from '../../../modules/common/stores';

type Props = RootStores;
@inject(STORE_ALPHA_CONFIG)
@observer
export default class AlphaConfigPage extends React.Component<Props, object> {
    render() {
        const alphaConfigStore = this.props.AlphaConfig!;
        const content = <AuthConsumer>{() => <AlphaConfigTable store={alphaConfigStore} />}</AuthConsumer>;

        return <FlexContentContainer content={content} />;
    }
}
