/* eslint-disable @typescript-eslint/member-ordering */
import { observable, set, action, computed } from 'mobx';
import _ from 'lodash';
import { UploadFile } from 'antd/lib/upload/interface';
import Anchor from '../../../models/Anchor';
import Package from './Package';
import PackageLine from './PackageLine';
import PredefinedLabel from '../../project_management/models/PredefinedLabel';
import PackageLineState from './PackageLineState';
import PackageState from './PackageState';
import { AnchorResult, SmartIndexSettings } from '../types';
import ProjectState from './ProjectState';
import Label from './Label';
import type { ProjectTagsVersionInfo } from '../types/ProjectTagsVersionInfo';
import FeatureFlags from './FeatureFlags';

export const keywords = ['MAIN_CLASS_OF_BUSINESS', 'REINSURED', 'REINSURER', 'PERIOD', 'TERRITORY', 'TYPE'];
export const postfixes = ['LEFT', 'MIDDLE', 'ANGLE', 'QUADRANT'];

export default class Project {
    @observable
    id: string;

    @observable
    title: string;

    @observable
    type: string;

    @observable
    color: string;

    @observable
    anchors: Anchor[] = [];

    @observable
    labels: Label[] = [];

    @observable
    predefinedLabels: PredefinedLabel[] = [];

    @observable
    packages: Package[] = [];

    @observable
    importingPackages: Package[] = [];

    @observable
    keywords: string[] = keywords;

    @observable
    aliasesMap = observable.map();

    @observable
    state: ProjectState = ProjectState.Ready;

    @observable
    tagsVersion?: ProjectTagsVersionInfo;

    @observable
    isPackagesLoaded: boolean = false;

    @observable
    packageIds: string[] = [];

    packageProperties: string[];

    unfilteredPackages: Package[] = [];

    initialAliases: AnchorResult[] = [];

    featureFlags: string | null;

    storedFeatureFlags: FeatureFlags | null;

    smartIndexSettings: SmartIndexSettings | null;

    constructor(
        id: string,
        title: string,
        projectKeywords: string[] = keywords,
        type?: string,
        color?: string,
        tagsVersion?: ProjectTagsVersionInfo,
        featureFlags?: string,
        storedFeatureFlags?: FeatureFlags | null,
        smartIndexSettings?: SmartIndexSettings | null
    ) {
        set(this, { id: id, title: title });
        this.keywords = projectKeywords;
        this.color = color || 'blue';
        this.tagsVersion = tagsVersion;
        this.featureFlags = featureFlags || null;
        this.storedFeatureFlags = storedFeatureFlags || null;
        this.packageProperties = _.flatMap(projectKeywords.map(k => postfixes.map(p => k + '+' + p)));
        this.smartIndexSettings = smartIndexSettings || null;

        if (type) {
            this.type = type;
        }
    }

    @computed
    get dirtyRows(): PackageLine[] {
        return _.flatMap(this.packages.map(k => k.lines.map(p => p))).filter(
            x => x.state !== PackageLineState.Unmodified
        );
    }

    @computed
    get additionalProperties(): string[] {
        return _.flatMap(this.anchors.map(k => postfixes.map(p => k.keyword + '+' + p)));
    }

    @computed
    get dirtyPackages() {
        return this.packages.filter(
            p =>
                p.state === PackageState.ChangedAnchors ||
                p.state === PackageState.ChangedLabels ||
                p.state === PackageState.ChangedManualText
        );
    }

    @computed
    get currentLabels() {
        // eslint-disable-next-line no-underscore-dangle
        return this._calculateLabelsDiff();
    }

    @action.bound
    setTagsVersion(versionInfo: ProjectTagsVersionInfo | undefined) {
        this.tagsVersion = versionInfo;
    }

    @action
    addAnchor(anchor: Anchor) {
        const existent = this.anchors.find(a => a === anchor || a.keyword === anchor.keyword);
        if (existent) {
            return;
        }
    }

    @action
    addPackage(file: UploadFile) {
        const pkg = new Package(this, '1', file.name);
        pkg.size = file.size as number;

        for (let l = 0; l < 10; l++) {
            // const line = new PackageLine(pkg, 1, `Text Line ${l} ${content}`);
            // pkg.lines.push(line);
        }

        this.packages.push(pkg);
    }

    @action
    addPackages(packages: Package[]) {
        packages.forEach(p => this.packages.push(p));
    }

    @action
    addPackageLines(lines: PackageLine[]) {
        lines.forEach(l => {
            const pkg = this.packages.find(p => p.id === l.pkg.id)!;
            if (pkg && pkg.lines.indexOf(l) === -1) {
                pkg.lines.push(l);
            }
        });
    }

    @action
    removePackageFilter() {
        this.packages = this.unfilteredPackages;
    }

    private _calculateLabelsDiff() {
        const linesMapper = (lines: PackageLine[]) =>
            lines.filter(c => c.labelText).map(l => ({ text: l.normalizedText, label: l.labelText! }));

        const addedLabelsMapper = (lines: PackageLine[]) =>
            lines.filter(c => !c.initialLabel && c.label).map(l => ({ text: l.normalizedText, label: l.labelText! }));

        const removedLabelsMapper = (lines: PackageLine[]) =>
            lines
                .filter(
                    c =>
                        (c.initialLabel && c.label === Label.None) ||
                        (c.initialLabel && c.initialLabel.text !== c.labelText)
                )
                .map(l => ({ text: l.normalizedText, label: l.initialLabel!.text }));

        const initialLabels = _.flatten(
            this.initialAliases.map(l => l.aliases.map(a => ({ text: a, label: l.keyword })))
        );
        const addedLabels = _.flatten(this.packages.map(p => addedLabelsMapper(p.lines)));
        const removedLabels = _.flatten(this.packages.map(p => removedLabelsMapper(p.lines)));
        const setLabels = _.flatten(this.packages.map(p => linesMapper(p.lines)));

        const labels = [
            ...addedLabels,
            ...initialLabels.filter(x => !removedLabels.find(r => r.label === x.label && r.text === x.text)),
            ...setLabels
        ];

        const groupedLabels = _.chain(labels)
            .groupBy(l => l.label)
            .map((data, keyword) => ({ keyword, aliases: _.uniq(_.map(data, 'text')) }))
            .value();

        return groupedLabels;
    }
}
