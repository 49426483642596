import { RootStores } from '../../common/stores';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import { STORE_PYDANTIC_SCHEMA_GENERATOR } from './constants';
import PydanticSchemaGeneratorStore from './PydanticSchemaGeneratorStore';
import SchemaGeneratorService from '../services/SchemaGeneratorService';

export function injectSchemaGeneratorStores(obj: RootStores) {
    const service = new SchemaGeneratorService();

    obj[STORE_PYDANTIC_SCHEMA_GENERATOR] = new PydanticSchemaGeneratorStore(obj[STORE_PROJECTS_ROOT_UI], service);
}

export type PydanticSchemaGeneratorStores = {
    [STORE_PYDANTIC_SCHEMA_GENERATOR]?: PydanticSchemaGeneratorStore;
};
