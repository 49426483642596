import { computed, action, observable } from 'mobx';
import PredefinedLabel, { PredefinedLink } from '../models/PredefinedLabel';
import ProjectKeywordRenameModel from '../../common/models/ProjectKeywordRenameModel';
import Keyword from '../../common/models/ProjectKeyword';
import { Package } from '../../common/models';
import { ProjectsRootVisualStore } from '../../common/stores';

export class ProjectMetadataVisualStore {
    @observable
    isLoading: boolean = false;

    @observable
    packages: Package[] = [];

    @observable
    renameList: ProjectKeywordRenameModel[] = [];

    @observable
    keywordsFormValid: boolean = true;

    @observable
    typeKeywords: string[] = [];

    @observable
    projectKeywords: Keyword[] = [];

    @observable
    newKeyword: string = '';

    @computed
    get currentProject() {
        return this.rootStore.currentProject;
    }

    @computed
    get predefinedKeywords() {
        return this.currentProject ? this.currentProject!.predefinedLabels : [];
    }

    @computed
    get autocompleteDataSource() {
        let keywords = this.typeKeywords.filter(t => t.toLowerCase().includes(this.newKeyword.toLowerCase()));

        if (this.projectKeywords) {
            keywords = keywords.filter(t => this.projectKeywords.filter(k => k.keyword === t).length === 0);
        }

        return keywords;
    }

    constructor(private rootStore: ProjectsRootVisualStore) {}

    @action.bound
    setNewKeyword(newKw: string) {
        this.newKeyword = newKw;
    }

    @action.bound
    setProjectKeywords(keywords: Keyword[]) {
        this.projectKeywords = keywords;
    }

    @action.bound
    setRenameList(renameList: ProjectKeywordRenameModel[]) {
        this.renameList = renameList;
    }

    @action.bound
    setKeywordsFormValid(keywordsFormValid: boolean) {
        this.keywordsFormValid = keywordsFormValid;
    }

    @action.bound
    setTypeKeywords(typeKeywords: string[]) {
        this.typeKeywords = typeKeywords;
    }

    @action
    addLink(label: PredefinedLabel) {
        label.links.push(new PredefinedLink('NEW TEXT'));
    }

    @action
    renameKeyword(keyword: Keyword, newVal: string) {
        let keywords = this.projectKeywords.slice();
        const index = keywords.indexOf(keyword);
        keywords[index].keyword = newVal;
        this.setProjectKeywords(keywords);
    }
}

export default ProjectMetadataVisualStore;
