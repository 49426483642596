import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox } from 'antd';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import { Parameter } from '../types';
import Form, { FormInstance } from 'antd/lib/form';

type Props = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    step: PipelineStep<any>;
    projectId: string;
};
export const ParametersCheckboxEditor: React.FC<Props> = ({ param, toolTip }) => {
    return (
        <div>
            <Form.Item
                label={null}
                colon={false}
                name={param.name}
                rules={[
                    {
                        required: param.required,
                        message: 'Please input parameter value'
                    }
                ]}
                valuePropName={'checked'}
            >
                <Checkbox>
                    <span style={{ color: 'black', fontSize: 14 }}>{toolTip}</span>
                </Checkbox>
            </Form.Item>
        </div>
    );
};

export default observer(ParametersCheckboxEditor);
