import {
    ApplicationDefinition,
    ApplicationDefinitionDto,
    ApplicationDefinitionConditional,
    ApplicationDefinitionConditionalDto
} from '../types/ApplicationDefinition';

export default class ApplicationDefinitionFactory {
    static createApplicationDefinition(data: ApplicationDefinitionDto | ApplicationDefinitionConditionalDto) {
        switch (data.type) {
            case 'ApplicationDefinition':
                return new ApplicationDefinition(data as ApplicationDefinitionDto);
            case 'ApplicationDefinitionConditional':
                return new ApplicationDefinitionConditional(data as ApplicationDefinitionConditionalDto);
            default:
                throw new Error('Unsupported application definition');
        }
    }
}
