import security from './SecurityService';
import AppolloClient, { ApolloQueryResult, QueryOptions, OperationVariables } from 'apollo-boost';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
// @ts-ignore
import introspectionQueryResultData from './fragmentTypes.json';
import { SpanKind, SpanStatusCode, trace } from '@opentelemetry/api';
import { message } from 'antd';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

const maxRetryAmount = 3;

let client: AppolloClient<{}>;

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function execQuery<T, TVariables = OperationVariables>(
    options: QueryOptions<TVariables>,
    retryCount = 0
): Promise<ApolloQueryResult<T>> {
    const { token, refreshed } = await security.getOrRefreshToken();
    if (refreshed || !client) {
        const cache = new InMemoryCache({ fragmentMatcher });
        client = new AppolloClient({
            cache: cache,
            uri: process.env.REACT_APP_DATA_URL,
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }

    const tracer = trace.getTracer('web_portal');
    // @ts-ignore
    const queryName = options.query.definitions[0]?.name?.value || 'graphql-query';
    const parentSpan = tracer.startSpan(queryName, {
        kind: SpanKind.CLIENT,
        startTime: Date.now()
    });

    const traceId = parentSpan.spanContext().traceId;
    const spanId = parentSpan.spanContext().spanId;

    options.context = {
        ...options.context,
        headers: {
            'ot-tracer-traceid': traceId,
            'ot-tracer-spanid': spanId,
            'ot-tracer-sampled': 'true'
        }
    };

    try {
        const result = await client.query<T>(options);
        parentSpan.end(Date.now());
        return result;
    } catch (err) {
        console.error(err);

        parentSpan.setStatus({
            code: SpanStatusCode.ERROR,
            message: err.message
        });

        parentSpan.setAttributes({
            'http.url': process.env.REACT_APP_DATA_URL!,
            'http.method': 'POST',
            'peer.service': 'service_data'
        });

        parentSpan.end(Date.now());
        if (retryCount < maxRetryAmount) {
            const retryTime = Math.pow(2, retryCount);
            console.log(`Retrying ${queryName} in ${retryTime} seconds...`);
            await sleep(1000 * retryTime);
            retryCount++;
            return await execQuery(options, retryCount);
        }

        message.error(`GraphQL error: ${err.message}`);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const emptyData: any = [];
        return { data: emptyData, errors: [], loading: false, networkStatus: 7, stale: false };
    }
}
