import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { message, Upload, Button, Modal } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import security from '../../common/services/SecurityService';
import { MLStorageStores } from '../stores';
import { inject } from 'mobx-react';
import { STORE_PROJECT_ML_MODELS } from '../constants';

const Dragger = Upload.Dragger;

type Props = MLStorageStores;

export const MLModelRevisionEditor: React.FC<Props> = ({ projectMLModelsUI: store }) => {
    const mlStore = store!;
    const [action, setAction] = React.useState('');
    React.useEffect(() => {
        mlStore.setRevisionFileList([]);
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mlStore.currentModel, mlStore]);

    React.useEffect(() => {
        mlStore.loadProjectMLModels();
    }, [mlStore.currentProject, mlStore]);

    React.useEffect(() => {
        if (!mlStore.isAddRevisionDialogVisible) {
            mlStore.setCurrentMLModel(undefined);
        }
    }, [mlStore, mlStore.isAddRevisionDialogVisible]);

    const init = () => {
        if (mlStore.currentModel) {
            setAction(
                process.env.REACT_APP_MANAGE_URL +
                    `projects/${mlStore.currentModel.projectId}/ml_models/${mlStore.currentModel.id}/upload`
            );
        }
    };

    const handleUpload = (info: UploadChangeParam) => {
        const status = info.file.status;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }

        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            store?.loadProjectMLModels();
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    return (
        <Observer>
            {() => (
                <Modal
                    title="Add revision"
                    visible={store!.isAddRevisionDialogVisible}
                    className="alpha-portal-dialog two-columns"
                    closable={false}
                    maskClosable={false}
                    destroyOnClose
                    width={600}
                    centered
                    footer={[
                        <Button
                            key="add-revision-dialog-close"
                            data-id="add-revision-dialog-close"
                            className="light"
                            size="large"
                            onClick={() => store!.setIsAddRevisionDialogVisible(false)}
                        >
                            Close
                        </Button>
                    ]}
                >
                    <div data-id="add-revision-dialog-form">
                        <Dragger
                            name="files"
                            className="alpha-portal-upload-dragger"
                            onChange={handleUpload}
                            action={action}
                            multiple
                            showUploadList
                            headers={{ Authorization: `Bearer ${security.token}` }}
                            style={{ height: '30%' }}
                        >
                            <p className="ant-upload-hint">
                                <i className="alpha-icon lg upload-icon" />
                                Drag and drop file here to upload...
                            </p>
                        </Dragger>
                    </div>
                </Modal>
            )}
        </Observer>
    );
};

export default inject(STORE_PROJECT_ML_MODELS)(observer(MLModelRevisionEditor));
