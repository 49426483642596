import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RulesStores } from '../../rules/stores';
import { FlexContentContainer } from '../../common/components';
import { FieldBindingsList } from '../components';
import { FieldBindingsStores } from '../stores';
import { STORE_PROJECT_FIELD_BINDINGS } from '../stores/constants';
import { STORE_RULES_IMPORT } from '../../rules/constants';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';

type Props = FieldBindingsStores & RulesStores;

const FieldBindingsPage: React.FC<Props> = ({ projectFieldBindingsUI, RulesImportUI }) => {
    return (
        <HasPermission permissionClaim={AppPermissions.CanManageProjectFields}>
            <FlexContentContainer
                content={<FieldBindingsList store={projectFieldBindingsUI!} rulesImportStore={RulesImportUI!} />}
            />
        </HasPermission>
    );
};

export default inject(STORE_PROJECT_FIELD_BINDINGS, STORE_RULES_IMPORT)(observer(FieldBindingsPage));
