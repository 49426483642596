import { Alert, Button, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { DocumentSelectionStep } from '.';
import { TestProjectDashboardStore, TestProjectWizardStore } from '../stores';

type Props = {
    wizardStore: TestProjectWizardStore;
    dashboardStore: TestProjectDashboardStore;
};

const TestProjectBaselinesUpdateDialog: React.FC<Props> = ({ wizardStore, dashboardStore }) => {
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const handleCancel = () => {
        wizardStore.setBaselinesEditDialogVisible(false);
        wizardStore.resetBaselineSwitch();
        setErrorMessage(null);
    };

    const handleSave = () => {
        if (wizardStore.newPackagesForBaselineSwitch.length === 0) {
            setErrorMessage('Please select at least one document!');
        } else {
            setErrorMessage(null);
            wizardStore
                .switchBaselinesForPackage()
                .then(successful => {
                    if (successful) {
                        handleCancel();
                        dashboardStore.loadTestProject();
                        dashboardStore.loadTestProjectBaselines();
                    }
                })
                .catch(() => {
                    // Do nothing
                });
        }
    };

    return (
        <Modal
            visible={wizardStore.baselinesEditDialogVisible}
            title={'Edit documents for baselines'}
            onCancel={handleCancel}
            maskClosable={false}
            centered
            destroyOnClose
            width={850}
            footer={[
                <Button key="save-packages" type="primary" onClick={handleSave} size="large">
                    Save
                </Button>
            ]}
        >
            {errorMessage && <Alert message={errorMessage} style={{ marginBottom: 12 }} type="error" />}
            <DocumentSelectionStep
                store={wizardStore}
                selectedPackages={wizardStore.newPackagesForBaselineSwitch}
                setSelectedPackages={wizardStore.setNewPackagesForBaselineSwitch}
                skipPackageLoading
            />
        </Modal>
    );
};

export default observer(TestProjectBaselinesUpdateDialog);
