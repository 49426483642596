import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Divider, Form } from 'antd';
import { ChatbotStore } from '../../stores';
import { ChatbotSettingsValues } from '../../types';
import ChatbotHeader from './ChatbotHeader';
import ChatbotDisplayItems from './ChatbotDisplayItems';
import ChatbotInput from './ChatbotInput';
import useResizeableDiv from '../../../common/hooks/useResizeableDiv';
import './ChatbotDialog.less';

const localStorageKey = 'chatbot-dialog-size';
const initialWidth = 350;
const initialHeight = 500;

type Props = {
    store: ChatbotStore;
};

const ChatbotDialog: React.FC<Props> = ({ store }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const { size, isResizing, handleResize, setSize } = useResizeableDiv({ ref, initialHeight, initialWidth });

    const [settingsForm] = Form.useForm<ChatbotSettingsValues>();

    React.useEffect(() => {
        const sizeJson = localStorage.getItem(localStorageKey);

        if (sizeJson) {
            setSize(JSON.parse(sizeJson));
        }
    }, [setSize]);

    React.useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(size));
    }, [size]);

    return (
        <div
            ref={ref}
            className={`chatbot-dialog ${!store.dialogVisible ? 'hidden' : ''}`}
            style={{ width: size.width, height: size.height }}
        >
            <ChatbotHeader
                store={store}
                isResizing={isResizing}
                settingsForm={settingsForm}
                handleResize={handleResize}
            />
            <Divider />
            <ChatbotDisplayItems store={store} />
            <Divider />
            <ChatbotInput store={store} settingsForm={settingsForm} />
        </div>
    );
};

export default observer(ChatbotDialog);
